<template>
	
	
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import Popper from "vue3-popper";
	import ContextHelpPopper from "@/components/common/ContextHelpPopper.vue";
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import Quota from '@/microServices/quotas';
	import OuterCard from '@/components/common/OuterPopCard.vue';
	import I164HubJoinPanel from './components/I164HubJoinPanel.vue';
	import I164HubMember from './components/I164HubMember.vue';
	import I164HubStatus from './components/I164HubStatus.vue';
	import I164HubParticipation from './components/I164HubParticipation.vue';
	import I164HubMemberEndpointGroup from './components/I164HubMemberEndpointGroup.vue';
	import I164HubMemberIdentity from './components/I164HubMemberIdentity.vue';
	import I164HubMemberIdentityDataTypes from './components/I164HubMemberIdentityDataTypes.vue';
	import I164HubMemberEndpointKey from './components/I164HubMemberEndpointKey.vue';
	

	const route = useRoute();
	const router = useRouter();


	const { t }  = useI18n();
	
	const mainStore = useMainStore();
	Quota.initialiseStore(mainStore);
	
	const state = reactive({
		i164IndustryHubs: undefined,
		activeIndustryHub: undefined,
		i164HubMemberUUID: undefined,
		activeI164HubMember: undefined,
		assets: {
			i164HubMember: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubMemberIdentity: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubStatus: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubParticipation: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubMemberEndpointGroup: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubMemberEndpointKey: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
		},
		tabsTop: 'first',
		tabsInner: 'messagesSending',
		filterValues: {
			timePeriod: 'day'
		},
		pagination: {
					page: 2,
					length: 4,
					totalVisible: 7,
				},	
		filters: {		
			timePeriod: [
				{value: 'day', title:'Day View'},
				{value: 'week', title:'Week View'},
				{value: 'month', title:'Month View'}				
			],			
		},	
	});

	

	const i164HubMemberEndpointKeyEvent = (event) => {
		console.log('i164HubMemberEndpointKeyEvent '+event.state);
		if (event.state == 'refreshRequired') {
			console.log("Refreshing Data");
			loadIndustryHub(state.activeIndustryHub.primaryUUID);
		}
	}

	const i164HubMemberEndpointGroupEvent = (event) => {
		console.log('i164HubMemberEndpointGroupEvent '+event.state);
		if (event.state == 'refreshRequired') {
			console.log("Refreshing Data");
			loadIndustryHub(state.activeIndustryHub.primaryUUID);
		}
	}


	const changeTimePeriod = () => {
		console.log('changing time period');
	}

	const deleteHub = (voxletInstanceUUID) => {
		deleteHubRequest(voxletInstanceUUID);

	}
	const approveHub = (voxletInstanceUUID) => {
		approveHubRequest(voxletInstanceUUID);
	}

	const submitHub = (voxletInstanceUUID) => {
		submitHubRequest(voxletInstanceUUID);

	}


	const endHub = (voxletInstanceUUID) => {
		endHubRequest(voxletInstanceUUID);

	}


	const endHubRequest = async (voxletInstanceUUID) => {
		const data = new URLSearchParams();
		data.set('voxletInstanceUUID', voxletInstanceUUID);	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/endTelecomsIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								listNetworkHubs();
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}
	
	
	const submitHubRequest = async (voxletInstanceUUID) => {
		const data = new URLSearchParams();
		data.set('voxletInstanceUUID', voxletInstanceUUID);	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/submitTelecomsIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								listNetworkHubs();
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}

	const approveHubRequest = async (voxletInstanceUUID) => {
		const data = new URLSearchParams();
		data.set('voxletInstanceUUID', voxletInstanceUUID);	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/approveTelecomsIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								listNetworkHubs();
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}

	const deleteHubRequest = async (voxletInstanceUUID) => {
		const data = new URLSearchParams();
		data.set('voxletInstanceUUID', voxletInstanceUUID);	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/deleteTelecomsIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								listNetworkHubs();
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}

	const checkQuotas = () => {
		fetchQuotas();
	}

	const fetchQuotas = async () => {
			const configuration = {
				resourceNames : ['i164.voxlets.networkHub.pojo.I164HubMember','i164.voxlets.networkHub.pojo.I164HubMemberIdentity'],
				resourceAllocationType: 4,
			}
			const data = new URLSearchParams();
			data.set('quotaRequestConfiguration', JSON.stringify(configuration));	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/voxletServices/fetchQuotas', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								
								
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}

		const deployConfiguration = async () => {		
			const data = new URLSearchParams();
			state.activeI164HubMember;
			data.set('voxletInstanceUUID', state.activeI164HubMember.voxletInstanceUUID);		
			data.set('i164HubMemberUUID', state.activeI164HubMember.primaryUUID);		
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/deployMembershipConfiguration', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {							
								console.log(d);															
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}

	const listNetworkHubs = async () => {			
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/listIndustryHubs', {
					method: 'get',			
					//body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {															
								state.i164IndustryHubs = d.i164IndustryHubs;
								if (d.i164IndustryHubs.length >= 1) {									
									mainStore.appDataStore.networkHub.i164IndustryHubUUID = d.i164IndustryHubs[0].primaryUUID;
									loadIndustryHub(d.i164IndustryHubs[0].primaryUUID);

								}
								
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}

		const loadIndustryHub = async (voxletInstanceUUID) => {			
			const data = new URLSearchParams();
			data.set('voxletInstanceUUID', voxletInstanceUUID);	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/loadIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {							
								console.log(d);
								if (state.i164HubMemberUUID) {
									state.activeIndustryHub = d.i164IndustryHub;
									selectI164HubMember();
								} else {
									//Push to first member by default
									if (d.i164IndustryHub.i164HubMembers[0]) {
										console.log("pusing to router");
										router.push({name: 'networkHubSelectedMember',params: {i164HubMemberUUID: d.i164IndustryHub.i164HubMembers[0].primaryUUID }});
									} else {
										//Should not be possible!
									}
									
								}
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
		}

		const selectI164HubMember = (i164HubMemberUUID) => {
			if (!i164HubMemberUUID) {
				i164HubMemberUUID = state.i164HubMemberUUID;
			}		
			const i164HubMembers = state.activeIndustryHub.i164HubMembers;
			for (let i = 0; i < i164HubMembers.length;i++) {
				if (i164HubMembers[i].primaryUUID == i164HubMemberUUID) {
					state.activeI164HubMember = i164HubMembers[i];
					break;
				}
			}	
			refreshAllComponents();	
		}

		const refreshConfiguration = (assetName) => {				
			switch (assetName){
				case 'i164HubMemberEndpointGroup':				
					state.assets.i164HubMemberEndpointGroup.refreshConfiguration.version++;				
					state.assets.i164HubMemberEndpointGroup.refreshConfiguration = {
						onboarding: state.assets.i164HubMemberEndpointGroup.refreshConfiguration.onboarding,
						version: state.assets.i164HubMemberEndpointGroup.refreshConfiguration.version
					};				
					break;
				case 'i164HubMemberEndpointKey':				
					state.assets.i164HubMemberEndpointKey.refreshConfiguration.version++;				
					state.assets.i164HubMemberEndpointKey.refreshConfiguration = {
						onboarding: state.assets.i164HubMemberEndpointKey.refreshConfiguration.onboarding,
						version: state.assets.i164HubMemberEndpointKey.refreshConfiguration.version
					};				
					break;
			}									
		}

		const refreshAllComponents = () => {
			refreshConfiguration('i164HubMemberEndpointGroup');
			refreshConfiguration('i164HubMemberEndpointKey');				
		}

	onUpdated(() => {	
		if (route.params.i164HubMemberUUID) {
			state.i164HubMemberUUID = route.params.i164HubMemberUUID;			
		} 
		listNetworkHubs();
	});

	onMounted(() => {
		if (route.params.i164HubMemberUUID) {
			state.i164HubMemberUUID = route.params.i164HubMemberUUID;			
		} 
		listNetworkHubs();
	});
</script>

<style scoped>
	.card-floater {
		overflow: visible;
	}

	.help-format {
		font-size: 10px;
		text-decoration: underline;
		text-decoration-style: dashed;
		cursor: pointer;
	}

	:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
	
</style>