<template>
	<v-container fluid class="px-6 py-6 bg-white" v-if="state.contentPage" >		
		
		<v-row v-if="!state.helpButton">
			<v-col class="mt-5">
				<div v-if="state.contentPage.content && state.contentPage.content.title">					
						<ContentBlockDisplay :pageContext="pageContext"  :contentConfiguration="state.contentConfiguration.title" contentFieldName="title" :contentFragments="state.contentPage.content.title">
						</ContentBlockDisplay>					
				</div>								
			</v-col>
		</v-row>
		<v-row>
			<v-col class="mt-5">			
				<div v-if="state.contentPage.content && state.contentPage.content.paragraph">					
						<ContentBlockDisplay :pageContext="pageContext"  :contentConfiguration="state.contentConfiguration.paragraph" contentFieldName="paragraph" :contentFragments="state.contentPage.content.paragraph">
						</ContentBlockDisplay>					
				</div>								
			</v-col>
		</v-row>
		<v-row>	
			<v-col class="d-flex mt-5">
					<p class="font-italic theme-font-other text-caption">Updated:</p> <p class="ml-2 text-caption">{{ contentPage.pageInfo.updateDate }}</p>	
					<v-spacer align="right"><p class="theme-font  text-caption">{{ contentPage.pageInfo.contentReference }}</p></v-spacer>
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import { inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ContentBlockDisplay from '../types/ContentBlockDisplay.vue';

const mainStore = useMainStore();

let pageContext = {
		stepCount: 0,
	};

const initialisePageContext = () => {
	pageContext = {
		stepCount: 0,
	};
}


const state = reactive({	
	contentPage: undefined,
	helpButton: false,
	contentConfiguration: {
		paragraph: {
			multiplicity: "multiple",
			contentTypes: [
				{value: 'textBlock', title:'Paragraph'},		
				{value: 'titleBlock', title:'Title'},
				{value: 'stepBlock', title:'Step'},
				{value: 'codeBlock', title:'Code Block'},							
			]
		},
		title: {
			multiplicity: "single",
			contentTypes: [				
				{value: 'titleBlock', title:'Title'},		
			]
		}
	}
});

const props = defineProps({		
	contentPage: {
		type: Object,
		required: true,
	},
	helpButton: Boolean,
});

onUpdated(() => {
	console.log('Updated');
	if (props.contentPage) {
		state.contentPage = props.contentPage;
	}
	if (props.helpButton) {
		state.helpButton = props.helpButton;
	}
	initialisePageContext();
});

onMounted(() => {
	if (props.contentPage) {
		console.log('Mounted');
		state.contentPage = props.contentPage;
	}
	if (props.helpButton) {
		state.helpButton = props.helpButton;
	}
	initialisePageContext();
});

</script>


<style scoped>


</style>