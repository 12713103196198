<template>
	<v-spacer v-if="state.contentFragment">

		<v-row>
			<v-col>			
			<!--<v-row>
					<v-col>
						<v-select :items="state.values.size" label="Heading Size" variant="outlined" item-title="title"
						item-value="value"  @update:modelValue="valid(this)"
						v-model="state.contentFragment.size"></v-select>
					</v-col>
				</v-row>								-->		
				<v-row>
					<v-col>				
						<v-textarea variant="outlined" label="Text" hide-details="auto" v-model="state.contentFragment.text" @update:modelValue="valid(this)"></v-textarea>					
					</v-col>
				</v-row>
				
				<v-row>
					<v-col>
						<v-btn  :disabled="!state.dataReady"  :class="(state.dataReady) ? 'theme-bg-highlight' :''" @click="completed(this)"><p v-if="state.action == 'add'">Create</p><p v-if="state.action == 'update'">Update</p></v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>						
					
	</v-spacer>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';

const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const props = defineProps({		
	contentConfiguration: {
		type: Object,
		required: true,
	},
	action: {
		type: String,
		required: true,
	},
	contentFieldName: {
		type: String,
		required: true,
	},
	contentFragment: {
		type: Object
	},
	position: {
		type: Number
	}
});

const state = reactive({	
	dataReady: false,	
	action: undefined,
	contentConfiguration: undefined,			
	contentFragment: undefined,
	position: 0,
	values: {					
		size: [
			{value: 1, title:'H1'},		
			{value: 2, title:'H2'},	
			{value: 3, title:'H3'},	
			{value: 4, title:'H4 (default)'},	
			{value: 5, title:'H5'},	
			{value: 6, title:'H6'},				
		]
	}
});

const emits = defineEmits(['close','dataEvents']);

const fireDataReadyEvent = () => {
		emits('dataEvents',
			{
				state: 'ready',
				action: state.action,
				position: state.position,
				contentFieldName: state.contentFieldName,
				contentFragment: state.contentFragment,				
			}
		);
}

const close = (a) => {	
	emits('close');
}

const valid = (event) => {
	if (state.contentFragment.text) {
		state.dataReady = true;
		return true;
	}
	return false;
}

const completed = (event) => {
	if (valid(event)) {
		fireDataReadyEvent();
	}	
}

onUpdated(() => {

});

onMounted(() => {
	if (props.contentConfiguration) {
		state.contentConfiguration = props.contentConfiguration;
		console.log(" contentConfiguration is "+state.contentConfiguration);
	} 
	if (props.contentFieldName) {
		state.contentFieldName = props.contentFieldName;
		console.log(" contentFieldName is "+state.contentFieldName);
	} 
	if (props.action) {
		state.action = props.action;
	} 
	if (props.contentFragment) {
		state.contentFragment =props.contentFragment;
	} else {
		state.contentFragment = {
			type: 'textBlock',
			text: undefined,
			size: 4
		}
	}
	if (props.position) {
		state.position = props.position;
	} else {
		state.position = 0;
	}
});
</script>

<style>

</style>