<template>
	<v-container fluid class="px-6 py-6">
		<v-row>
			<v-col>
				<v-row>
					<v-col class="d-flex" cols="10">
						<v-avatar class="theme-bg-highlight shadow border-radius-xl mt-1" size="48"
							color="avatar_background"><v-icon class="vx-text-dark theme-avatar-color"
								size="32">dashboard</v-icon></v-avatar>
						<p class="mt-3 ml-3 font-weight-bold text-h4 vx-text-dark theme-font-bold">Industry Hub</p>
					</v-col>
					<v-col cols="2">
					
					</v-col>
				</v-row>
			</v-col>
		</v-row>
		<v-row class="mt-10">
			<v-col cols="0" sm="1" md="2"></v-col>
			<v-col cols="12" sm="10"  md="8">
				<OuterCard>
					<v-row>
						<v-col>
							<p class="theme-font text-h5">Quick Start Application</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<p class="theme-font-other">									
								To get started we just need the names that will be used on the account. 
							</p>
						</v-col>
					</v-row>													
					<v-row>
						<v-col>
							<v-row>
								<v-col class="theme-font text-h6">Legal Name</v-col>
							</v-row>
							<v-row>
								<v-col>	
									<p class="theme-font-other">The Legal name for your business.</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="mt-3 ">	<v-text-field  @focusin="checkForm(this)" @focusout="checkForm(this)" label="Legal Name *" variant="outlined" v-model="state.quickStartApplication.legalName"></v-text-field> </v-col>
							</v-row>						
							<v-row>
								<v-col>	<v-divider/> </v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-row>
								<v-col class="theme-font text-h6">Member Name</v-col>
							</v-row>
							<v-row>
								<v-col>	
									<p class="theme-font-other">The name you woud like to be known as to the members.</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="mt-3 ">	<v-text-field @focusin="checkForm(this)" @focusout="checkForm(this)"  variant="outlined" v-model="state.quickStartApplication.memberName"></v-text-field> </v-col>
							</v-row>						
							<v-row>
								<v-col>	<v-divider/> </v-col>
							</v-row>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-row>
								<v-col class="theme-font text-h6">Gaining Provider Trading Name</v-col>
							</v-row>
							<v-row>
								<v-col>	
									<p class="theme-font-other">The name your business is known to your customers.</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="mt-3 ">	<v-text-field @focusin="checkForm(this)" @focusout="checkForm(this)" variant="outlined" v-model="state.quickStartApplication.gainingProviderTradingName"></v-text-field> </v-col>
							</v-row>						
						
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<p align="right"><v-btn :class="(state.readyToSubmit) ? 'theme-bg-highlight' : ''" :disabled="!state.readyToSubmit" @click="quickStart()">Next</v-btn></p>
						</v-col>
					</v-row>
				</OuterCard>
			</v-col>			
			
			<v-col cols="0" sm="1"  md="2"></v-col>
		</v-row>		
	</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import Quota from '@/microServices/quotas';
	
	import OuterCard from '@/components/common/OuterPopCard.vue';

	const { t }  = useI18n();
	
	const router = useRouter();

	const mainStore = useMainStore();
	Quota.initialiseStore(mainStore);


	const state = reactive({	
		readyToSubmit: false,
		quickStartApplication: {
			legalName: undefined,
			memberName: undefined,
			gainingProviderTradingName: undefined,
			initialAccessIPV4Address: "195.10.199.4",
			initialAccessIPV4Prefix: 32,
			endpointUrl: "https://api.example.com/endPoint",
		}
	});



const checkForm = () => {
	if (state.quickStartApplication.legalName) {
		if (!state.quickStartApplication.memberName) {
			state.quickStartApplication.memberName = state.quickStartApplication.legalName;
		}
		if (!state.quickStartApplication.gainingProviderTradingName) {
			state.quickStartApplication.gainingProviderTradingName = state.quickStartApplication.legalName;
		}
	}	
	state.readyToSubmit = formValid();
	
 }

const formValid = () => {
	if (state.quickStartApplication.legalName) {
		return true;
	}
	return false;
}

const quickStart = () => {
	quickStartSubmit();
}

	const quickStartSubmit = async () => {		
			const quickStartConfiguration = {
				legalName: state.quickStartApplication.legalName,
				memberName: state.quickStartApplication.memberName,
				gainingProviderTradingName: state.quickStartApplication.gainingProviderTradingName,
				initialAccessIPV4Address: "35.246.39.73",
				initialAccessIPV4Prefix: 32
			};
			const data = new URLSearchParams();
			data.set('quickStartConfiguration', JSON.stringify(quickStartConfiguration));	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/quickStartCreateIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {							
								console.log(d);							
								mainStore.displaySnackBar("Industry Hub Created");
								mainStore.appDataStore.networkHub.i164IndustryHubUUID = d.voxletInstanceUUID;
								router.push({name: 'networkHubDashboard'});
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}


	onMounted(() => {
		const storedName = mainStore.phoneServiceDetails.accountHolderName;
		if (storedName) {
			state.quickStartApplication.legalName = storedName;
			state.quickStartApplication.memberName = storedName;
			state.quickStartApplication.gainingProviderTradingName = storedName;
			checkForm();
		}
	});
</script>

<style scoped>
	
</style>