<template>
	<v-container fluid class="px-6 py-6 h-100">	
		<v-row >			
			<v-col>
				<v-tabs v-model="state.tabsTop">	
					<v-tab value="home" :class="(state.tabsTop== 'home') ? 'bg-active_tab_color':''" >
					
						<v-avatar
							class="shadow border-radius-xl"
							size="32"  color="avatar_background"
						><v-icon class="vx-text-dark theme-avatar-color" size="24">groups</v-icon></v-avatar>							
							<p class="ml-2 text-h6 theme-font">Users</p>
					</v-tab>
					<v-tab :value="'user_'+phoneServiceUserParticpation.serviceUserUUID" v-for="phoneServiceUserParticpation,index in state.phoneServiceUserParticpations" :key="index" :class="(state.tabsTop== 'user_'+phoneServiceUserParticpation.serviceUserUUID) ? 'bg-active_tab_color':''" class="pl-1 pr-0">
						<v-icon class="vx-text-dark" size="24">person</v-icon><p class="ml-2 text-h6 theme-font">{{ phoneServiceUserParticpation.firstName}} {{ phoneServiceUserParticpation.lastName}}</p> <v-icon @click.prevent.stop="closeUserTab(this,phoneServiceUserParticpation)" size="x-small" class="ml-4 mb-4">close</v-icon>
					</v-tab>	
					<!--<v-tab value="bundles" :class="(state.tabsTop== 'bundles') ? 'bg-active_tab_color':''">
						<p class="text-h6 theme-font">Bundles</p>
					</v-tab>
					<v-tab value="tariffs" :class="(state.tabsTop== 'tariffs') ? 'bg-active_tab_color':''">
						<p class="text-h6 theme-font">Tariffs</p>
					</v-tab>		-->							
				</v-tabs>						
				<v-divider></v-divider>							
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-window v-model="state.tabsTop" class="fill-height bg-white  mt-0 pt-0">
					<v-window-item value="home">
						<ListUserParticipation @selectUser="selectUser"></ListUserParticipation>
					</v-window-item>
					<v-window-item :value="'user_'+phoneServiceUserParticpation.serviceUserUUID" v-for="phoneServiceUserParticpation,index in state.phoneServiceUserParticpations" :key="index" >
						<DisplayUserParticipation :serviceUserUUID="phoneServiceUserParticpation.serviceUserUUID" />
					</v-window-item>

				</v-window>
			</v-col>
		</v-row>
		<v-row class="pd-10">
			&nbsp;
		</v-row>			
	</v-container>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ListUserParticipation from './ListUserParticipation.vue';
import DisplayUserParticipation from './DisplayUserParticipation.vue';




const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const state = reactive({	
	tabsTop: 'home',
	phoneServiceUserParticpations: [],	
});

const selectUser = (selectUserEvent) => {	
	const phoneServiceUserParticpation = selectUserEvent.phoneServiceUserParticpation;
	let openNewTab = true;
	for (let i = 0; i < state.phoneServiceUserParticpations.length;i++) {
		if (state.phoneServiceUserParticpations[i].primaryUUID == phoneServiceUserParticpation.primaryUUID) {
			openNewTab = false;
			break;
		}
	}
	if (openNewTab) {
		state.phoneServiceUserParticpations.push(phoneServiceUserParticpation);
	}		
	state.tabsTop = 'user_'+phoneServiceUserParticpation.serviceUserUUID;
}

const closeUserTab = (event,phoneServiceUserParticpation) => {
	for (let i = 0; i < state.phoneServiceUserParticpations.length;i++) {
		if (state.phoneServiceUserParticpations[i].primaryUUID == phoneServiceUserParticpation.primaryUUID) {
			state.phoneServiceUserParticpations.splice(i,1);
			state.tabsTop = 'home';
			break;
		}
	}
}
</script>

<style>
</style>