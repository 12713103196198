<template>
	<div class="theme-mainPanel fill-height" >					
			<v-row class="mb-5 fill-height" >
				<v-col md="4" ></v-col>
				<v-col md="4" class="fill-height d-flex align-center justify-center" >					
					<v-form @keyup.enter="doLoginRequest()">
						<v-card  class="card-shadow border-radius-md box">
							<v-card-text v-if="!state.requested">
								<v-row>
								<v-col class="ma-5" align="center">
									<v-card class="theme-dark-background-gradient" width="180" align="center">
										<v-spacer><v-img :src="i164Transparent" width="160" height="72" class="pa-1"></v-img></v-spacer>
									</v-card>
								</v-col>
							</v-row>	
								<v-row>
									<v-col class=""><p class="text-h5 theme-font">Reset your i164 Account Password</p></v-col>
								</v-row>
								<v-row>
									<v-col class=""><p class="text-body-2 theme-font-other">Please enter the email address that is associated to your account.</p></v-col>
								</v-row>
								<v-row>
									<v-col v-if="state.requestOutcome == -1" align="center" class="mb-4">
										<p><v-icon class="text-red">warning</v-icon>Email missing.</p>
									</v-col>
									<v-col v-if="state.requestOutcome == 910" align="center" class="mb-4">
										<p><v-icon class="text-red">warning</v-icon>An account with that email could not be found.</p>
									</v-col>							
									<v-col v-if="state.requestOutcome == -99998" align="center" class="mb-4">
										<p><v-icon class="text-red">warning</v-icon>There has been an error.</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field label="Email Address" hide-details="auto" v-model="state.form.emailAddress" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)"></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="text-center mt-5">
										<v-btn @click="doResetPassword()" :disabled="!state.dataReady" :class="(state.dataReady) ? 'theme-bg-highlight' :''">CONTINUE</v-btn>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="ml-2 mt-2 text-center">
										<p class="pl-2 pr-2 text-caption text-decoration-underline bg-white theme-pointer" @click="goToLogin()">Go back to Sign-in</p>
									</v-col>
								</v-row>	
							</v-card-text>
							<v-card-text v-else>
								<v-row>
								<v-col class="ma-5" align="center">
									<v-card class="theme-dark-background-gradient" width="180" align="center">
										<v-spacer><v-img :src="i164Transparent" width="160" height="72" class="pa-1"></v-img></v-spacer>
									</v-card>
								</v-col>
							</v-row>	
								<v-row>
									<v-col class=""><p class="text-h5 theme-font">Reset your Account Password</p></v-col>
								</v-row>
								<v-row>
									<v-col class=""><p class="text-body-2 theme-font-other">An Email has been sent to <b>{{ state.form.emailAddress }}</b> with details on how to reset your password.</p></v-col>
								</v-row>
								<v-row>
									<v-col class="ml-2 mt-2 text-center">
										<p class="pl-2 pr-2 text-caption text-decoration-underline bg-white theme-pointer" @click="goToLogin()">Go back to Sign-in</p>
									</v-col>
								</v-row>	
							</v-card-text>
						</v-card>										
					</v-form>
				</v-col>
				<v-col md="4"></v-col>
			</v-row>					
			<v-footer app class="vx-off-color-background">
				<v-row>					
					<v-col><v-img :src="servedUpLogo" max-width="150"  class="align-right"></v-img></v-col>
					<v-col><div style="text-align:right;">&copy; {{ getCopyDate() }} Served Up Ltd</div></v-col>
				</v-row>
			</v-footer>
	</div>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import { useRouter } from "vue-router";
import {useMainStore} from '../stores/MainStore.js';
import voxhubLogo from '@/assets/images/voxhub_greenOnWhite.svg';
import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
import i164Transparent from '@/assets/images/i164_transparent.png';
const mainStore = useMainStore();

const router = useRouter();

import { useDate } from 'vuetify/labs/date';
const getCopyDate = () => {
	const date = useDate();
	return date.getYear(new Date());
}

onMounted(() => {
 console.log('Mounted ForgottenPassword');	
});

const state = reactive({
		dataReady: false,
		failureReason: undefined,
		requested: false,
		requestOutcome: 0,			
		form: {	
			emailAddress: "",			
		}		
});

const valid = (event) => {
	if (state.form.emailAddress) {
		state.dataReady = state.form.emailAddress.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
	} else {
		state.dataReady = false;
	}
}

const validateEmail = (email) => {
  return ;
};

const goToLogin = () => {
	router.push({ name: 'login'});
}

const doResetPassword = async () => {	
			const data = new URLSearchParams();
			data.set('emailAddress', state.form.emailAddress);		
			await fetch(mainStore.getBaseUrl('plustag')+'/forgottenPasswordReset', {
				method: 'POST',
				body: data,
				credentials: 'include',
				redirect: 'follow',																									
			}).then((response) => {
				response.json().then( 
					d => {
						console.log(d);			
						state.requestOutcome = d.outcome;
						if (d.outcome == 1) {
							console.log("Password Reset Begins");
							state.requested = true;						
						} 
					}									
				)
				return response;
			}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
				state.failureReason = SERVER_ERROR;
			});		
		}

</script>

<style scoped>
.box {
		border: 1px solid rgb(var(--v-theme-box_border_color));	
	}
</style>
