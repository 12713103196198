<template>
	<OuterCard>
		<v-row>
			<v-col v-if="state.i164HubMember" class="pa-0">
				<BoxHeader headerText="Member Details" :dataReady="(true)" readyIcon="check_circle"/>
				<v-row>
					<v-col class="ma-5 mt-0">
						<v-row>
							<v-col class="theme-font-other font-weight-bold">Legal Name</v-col>
							<v-col class="theme-font-other">{{ state.i164HubMember.legalName }}</v-col>			
						</v-row>
						<v-row>
							<v-col class="theme-font-other font-weight-bold">Member Name</v-col>
							<v-col class="theme-font-other">{{ state.i164HubMember.memberName }}</v-col>			
						</v-row>
					</v-col>
				</v-row>												
			</v-col>
			<v-col v-else>
				<v-row>
					<p class="theme-font-other"><v-progress-circular
										indeterminate
										color="rgb(var(--v-theme-loading_spinner_color))"									
									></v-progress-circular></p>
				</v-row>
			</v-col>
		</v-row>
				
			
	</OuterCard>
	
</template>
<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import OuterCard from '@/components/common/OuterPopCard.vue';
import BoxHeader from '@/components/BoxHeader.vue';

	const props = defineProps({
		refreshConfiguration: {
			type: Object,
			required: true,
		},	
		configuration: {
			type: Object,
			required: true
		},
		assetData: {
			type: Object,			
		},		
		readOnly: Boolean,
	});

	const state = reactive({
		onboarding: undefined,
		refreshVersion: 0,
		readOnly: false,
		dataReady: false,
		i164HubMember: undefined,		
	});

	onMounted(() => {
		if (props.assetData) {
			state.i164HubMember = props.assetData;	
			state.dataReady = true;		
		}
	});

</script>

<style scoped>

</style>
