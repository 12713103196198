<template>
	<v-container fluid class="px-6 py-6 h-100">
		<v-row>
			<v-col class="pa-0 ma-0">
				<v-row>
					<v-col>
						<p class="theme-font-other text-body-2 text-justify">
							Billing provides a complete solution to charging customers for calls. It's quick and easy to create call bundles to use with your telephone services.
						</p>
						<p class="theme-font-other text-body-2  text-justify mt-2">
							To help you get up and running we provide ready-to-go managed tariffs. All you need to do is use our intelligent pricing engine to set your call charges.
						</p>	
						<p class="theme-font-other text-body-2  text-justify mt-2">
							Our goal orientated billing ensures you reach your target margins and let's you know how this is impacted as wholesale prices go up and down.
						</p>					
					</v-col>
					<v-col cols="8">				
						<v-row>
							<v-col>
								<p class="theme-font text-h5">
								Billing Overview
								</p>
							</v-col>
						</v-row>		
						<v-divider></v-divider>
						<v-row>
							<v-col col="6" class="ma-2">										
								<v-row>											
									<v-col>
										<v-hover>
											<template v-slot:default="{ isHovering, props }">
												<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
													<BoxHeader headerText="Live Billing"/>
												
													<v-card-text>																																	
															<v-row >
																<v-col class="theme-font text-h6 mb-0">Bundles</v-col>
																<v-col class="text-center text-h6 mb-0">2</v-col>																	
															</v-row>
															<v-row>
																<v-col class="theme-font text-h6 pt-1">Tariffs</v-col>
																<v-col class="text-center text-h6 pt-1">1</v-col>																	
															</v-row>
															<v-row>
																<v-col class="theme-font text-h6 pt-1">Status</v-col>
																<v-col class="theme-font-other text-center text-h6 pt-1">OK</v-col>
															</v-row>
													
														</v-card-text>
													</v-card>
											</template>
										</v-hover>
									</v-col>									
								</v-row>	
															
							</v-col>
							<v-col  cols="6" class="ma-2" >
								<v-row>
									<v-col>
										<v-hover>
											<template v-slot:default="{ isHovering, props }">
												<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
													<BoxHeader headerText="Billing Notices"/>													
													<v-card-text>																																	
														<p class="text-caption">19-06-2023</p>
														<p>New: July Managed Tariffs</p>
														<p class="text-caption mt-2">17-06-2023</p>
														<p>German Mobile Changes</p>	
														<p class="text-caption mt-2">15-05-2023</p>
														<p>New: June Managed Tariffs</p>	
														<p align="right" class="text-caption text-decoration-underline mb-n3 mt-2 theme-pointer">MORE</p>					
														</v-card-text>
													</v-card>
											</template>
										</v-hover>
									
										
									</v-col>
								</v-row>		
							</v-col>
						</v-row>

					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
	
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import BoxHeader from '@/components/BoxHeader.vue';

const { t }  = useI18n();
	
const router = useRouter();
const route = useRoute();

const mainStore = useMainStore();

let selectedImage = undefined;

const state = reactive({	
	version: 0,	
	
	values: {		
		tariffSelect: [
			{value: 'abc', title:'Magrathea Direct'},
			{value: 'def', title:'Magrathea Gold'},			
		]
	}	
});


onMounted(() => {

});



</script>


<style scoped>

.f-roboto {
	font-family: Roboto !important;
}

.f-arial {
	font-family: Arial !important;
}

.f-helvetica {
	font-family: Helvetica !important;
}

.f-times {
	font-family: Times !important;
}



</style>