<template>
	<OuterCard>
		<v-row>
			<v-col v-if="state.i164HubMemberEndpointKey" class="pa-0">
				<BoxHeader headerText="Server Authorisation Key (Sending)"/>
				<v-row>
					<v-col class="ma-5 mt-0">
						<v-row>
							<v-col class="theme-font-other font-weight-bold">Name</v-col>
							<v-col class="theme-font-other">{{ state.i164HubMemberEndpointKey.keyName}}</v-col>			
							<v-col class="theme-font-other">
								<v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text mr-5" @click="revealEndpointKey()" v-if="!state.authenticationKey">REVEAL Key</v-btn>
								<v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text mr-5" @click="hideEndpointKey()" v-else>Hide Key</v-btn>
							</v-col>			
						</v-row>
						<v-row v-if="state.authenticationKey">
							<v-col class="theme-font-other font-weight-bold" cols="4">Auth Key</v-col>
							<v-col class="theme-font-other" cols="8">{{ state.authenticationKey}}</v-col>
								
						</v-row>
						<v-row v-if="!state.managePermittedNetworks">
							<v-col class="theme-font-other d-flex font-weight-bold ">
								<p class="" >Permitted IP Addresses</p>
								
							</v-col>						
						</v-row>
						<v-row v-if="!state.managePermittedNetworks">
							<v-col class="theme-font-other">
								
								<p class="pl-0 text-truncate">
									{{concatPermittedNetworks(state.i164HubMemberEndpointKey.i164EndpointKeyData.permittedNetworks)}}
								</p>
								<p v-if="!state.managePermittedNetworks" class="text-caption text-decoration-underline theme-pointer" align="right" @click="managePermittedNetworks()">EDIT</p>
							</v-col>			
						</v-row>
						<v-row v-else>
							<v-col>
								<v-table  class="listTable">														
									<thead class="bg-vx_green_900">
										<tr>						
											<th class="text-body-2 theme-mini-listing-header-text" colspan="3">
												<div class="d-flex">
												<p>Permitted Networks</p>										
												<v-spacer align="right" v-if="state.managePermittedNetworks && state.mode == 'listMode'"><v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text mr-5" @click="addNewNetwork()"><v-icon class="theme-list-action-icon">add</v-icon> Address</v-btn></v-spacer>
											</div>
											</th>									
										</tr>							
									</thead>						
									<tbody>
										<tr v-for="permittedNetwork,index in state.i164HubMemberEndpointKey.i164EndpointKeyData.permittedNetworks" :key="index">
											<!--<td class="text-center"><v-icon v-if="i164HubMemberEndpoint.active" class="text-success">check_circle</v-icon><v-icon v-if="!i164HubMemberEndpoint.active" class="text-failure">cancel</v-icon></td>-->
											<td class="text-center">{{permittedNetwork.name}}</td>
											<td class="text-center">{{permittedNetwork.ipAddress}} / {{ permittedNetwork.prefix }}</td>
											
											<td class="text-center">
											<v-menu location="center">
													<template v-slot:activator="{ props }">
														<v-icon v-bind="props">menu</v-icon>
													</template>
													
													<v-list>												
													
														<v-list-item>
															<v-list-item-title class="theme-pointer" @click="removePermittedNetwork(permittedNetwork);">Remove</v-list-item-title>
														</v-list-item>
													</v-list>
												
												</v-menu>	
											</td>								
										</tr>								
									</tbody>
								</v-table>

							</v-col>
						</v-row>
						<v-row v-if="state.mode == 'addNew'">
							<v-col class="">
								<v-divider class="mb-5"/>
								<v-row :class="state.permittedNetworkReady ? 'bg-vx_green_900' : ''">							
									<v-col class="d-flex pa-1 pl-5">
										
										<v-icon :class="state.permittedNetworkReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon>
										<p class="theme-font text-h5">Add New Network</p>														
									</v-col>		
								</v-row>
								<v-row>
									<v-col><v-divider /></v-col>
								</v-row>
								<v-row>
									<v-col><v-text-field variant="outlined" label="Name *" hide-details="auto" v-model="state.permittedNetwork.name" @update:modelValue="isNetworkValid()"></v-text-field></v-col>
								</v-row>
								<v-row>
									<v-col style="max-width:120px">
										<v-select variant="outlined" :items="state.values.addressTypes" label="Type" item-title="title"
										item-value="value" 
										v-model="state.addressType"  @update:modelValue="isNetworkValid()" hide-details="true"></v-select>
									</v-col>
									<v-col>
										<v-text-field variant="outlined" label="IP Address *" hide-details="auto" v-model="state.permittedNetwork.ipAddress"  @update:modelValue="isNetworkValid()"></v-text-field>
									</v-col>						
									<v-col style="max-width:100px">
										<v-text-field variant="outlined" label="Prefix *" hide-details="auto" v-model="state.permittedNetwork.prefix"  @update:modelValue="isNetworkValid()"></v-text-field>								
									</v-col>					
									</v-row>
								<v-row>
									<v-col align="center">								
										<v-btn @click="resetForm()">Cancel</v-btn>
									</v-col>
									<v-col align="center">								
										<v-btn :class="state.permittedNetworkReady ? 'theme-bg-highlight' : ''" :disabled="!state.permittedNetworkReady">
											<p v-if="state.mode == 'addNew'" @click="addPermittedNetwork()">Add</p>
										</v-btn>
									</v-col>							
								</v-row>
							</v-col>
						</v-row>
						</v-col>
				</v-row>
			</v-col>
			<v-col v-else>
				<v-row>
					<p class="theme-font-other"><v-progress-circular
										indeterminate
										color="rgb(var(--v-theme-loading_spinner_color))"									
									></v-progress-circular></p>
				</v-row>
			</v-col>
		</v-row>
				
			
	</OuterCard>
	
</template>
<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import OuterCard from '@/components/common/OuterPopCard.vue';
import BoxHeader from '@/components/BoxHeader.vue';

	const mainStore = useMainStore();


	const props = defineProps({
		refreshConfiguration: {
			type: Object,
			required: true,
		},	
		configuration: {
			type: Object,
			required: true
		},
		assetData: {
			type: Object,			
		},		
		readOnly: Boolean,
	});

	const state = reactive({
		onboarding: undefined,
		refreshVersion: 0,
		readOnly: false,
		dataReady: false,
		i164HubMemberEndpointKey: undefined,
		managePermittedNetworks: false,	
		mode: 'listMode',
		permittedNetworkReady: false,		
		permittedNetwork: {			
			name: '',
			ipAddress: '',
			prefix: 32
		},
		addressType: 4,
		values: {
			prefixes: [
				{value: 32, title:'32'},
				{value: 30, title:'30'},
				{value: 28, title:'28'},
				{value: 26, title:'26'},
				{value: 24, title:'34'}		
			],
			addressTypes: [
				{value: 4, title:'ipv4'},
				{value: 6, title:'ipv6'},				
			]
		},
		authenticationKey: undefined,
	});

	const resetForm = () => {
		state.mode = 'listMode';
	}

	const addNewNetwork = () => {
		state.addressType = 4;
		state.permittedNetwork = {
			name: '',
			ipAddress: '',
			prefix: 32
		};
		state.mode = 'addNew';
	}

	const addPermittedNetwork = () => {
		if (isNetworkValid()) {
			addPermittedNetworkRequest();
		}
	}


	const removePermittedNetwork = (permittedNetwork) => {
		removePermittedNetworkRequest(permittedNetwork);
	}



	const addPermittedNetworkRequest= async () => {
		const data = new URLSearchParams();
		data.set('i164HubMemberEndpointKeyUUID', state.i164HubMemberEndpointKey.primaryUUID);	
		data.set('permittedNetworkName', state.permittedNetwork.name );	
		data.set('permittedNetworkIpAddress', state.permittedNetwork.ipAddress );	
		data.set('permittedNetworkPrefix', state.permittedNetwork.prefix );	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/addPermittedNetwork', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {	
								resetForm();						
								fireDataRefreshEvent();
								mainStore.displaySnackBar('Added Network');							
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}


	const removePermittedNetworkRequest= async (permittedNetwork) => {
		const data = new URLSearchParams();
		data.set('i164HubMemberEndpointKeyUUID', state.i164HubMemberEndpointKey.primaryUUID);	
		data.set('permittedNetworkIpAddress', permittedNetwork.ipAddress );	
		data.set('permittedNetworkPrefix', permittedNetwork.prefix );	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/removePermittedNetwork', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {	
								resetForm();						
								fireDataRefreshEvent();
								mainStore.displaySnackBar('Removed Network');								
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}


	const hideEndpointKey = () => {
		state.authenticationKey = undefined;			
	}

	const revealEndpointKey = () => {
		revealEndpointKeyRequest();
	}

	const revealEndpointKeyRequest= async (permittedNetwork) => {	
		const data = new URLSearchParams();
		data.set('i164HubMemberEndpointKeyUUID', state.i164HubMemberEndpointKey.primaryUUID);		
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/revealI164HubMemberEndpointKey', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {	
								state.authenticationKey = d.authenticationKey;
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}



	const isNetworkValid = () => {
		let valid = true;
		if (state.permittedNetwork) {
			if (state.permittedNetwork.name && state.permittedNetwork.name.length > 0 ) {
			} else {
				valid = false;
			}
			if (state.permittedNetwork.ipAddress && state.permittedNetwork.ipAddress.length > 0) {
				if (state.addressType == 4) {
					if (!/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(state.permittedNetwork.ipAddress)) {  
						valid = false;
					}
				} else if (state.addressType == 6) {
					if (!/^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/.test(state.permittedNetwork.ipAddress)) { 
						valid = false;
					}
				} else {
					valid = false;
				}
			} else {
				valid = false;
			}
			if (state.permittedNetwork.prefix) {
				if (state.addressType == 4) {
					if (/\d/.test(state.permittedNetwork.prefix)) {
						if (state.permittedNetwork.prefix > 24 && state.permittedNetwork.prefix < 33) {

						} else {
							valid = false;
						}
					} else {
						valid= false;
					}
				} else if (state.addressType == 6) {
					if (/\d/.test(state.permittedNetwork.prefix)) {
						if (state.permittedNetwork.prefix > 127 && state.permittedNetwork.prefix < 129) {

						} else {
							valid = false;
						}
					} else {
						valid= false;
					}
				} else {
					valid = false;
				}
			} else {
				valid = false;
			}
		} else {		
			valid = false;
		}
		state.permittedNetworkReady = valid;
		return valid;	
		
	}

	const managePermittedNetworks = () => {
		state.managePermittedNetworks = true;
	}

	const concatPermittedNetworks = (permittedNetworks) => {
		let output = "";
		if (permittedNetworks) {
			for (let i = 0; i < permittedNetworks.length;i++) {
				output = output +permittedNetworks[i].ipAddress+'/'+permittedNetworks[i].prefix;
				if (i + 1 <permittedNetworks.length) {
					output = output +', ';
				}
			}
		}
		return output;
	}

const emits = defineEmits(['dataEvents']);

const fireDataRefreshEvent = (event) => {		
	emits('dataEvents',
		{
			state: 'refreshRequired'
		}
	);
}

	const refreshState = () => {		
		if (props.assetData) {
			console.log("RefreshState");
			state.i164HubMemberEndpointKey = props.assetData;	
			state.dataReady = true;		
		}
	}

	onUpdated(() => {				
		if (props.refreshConfiguration.version > state.refreshVersion) {
			state.refreshVersion = props.refreshConfiguration.version;
			if (props.refreshConfiguration.onboarding) {				
				state.onboarding = props.refreshConfiguration.onboarding;
			}				
			refreshState();
		}
	});

	onMounted(() => {
		refreshState();
	});


</script>

<style scoped>

</style>
