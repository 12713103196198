<template>
		<div class="theme-mainPanel fill-height" >					
			<v-row class="mb-5 fill-height" >
				<v-col md="4" ></v-col>
				<v-col md="4" class="fill-height d-flex align-center justify-center" >
					<v-card  class="card-shadow border-radius-md loadingBox" >
						<v-card-text class="align-center ml-5">
							<div v-if="state.verificationOutcome == 1 || state.verificationOutcome == 908 ">
								<v-row>
									<v-col><p class="text-h4 theme-font vx-text-dark">
										Email Verified.
									</p></v-col>								
								</v-row>
								<v-row>
									<v-col>
										<p class="text-body-1 theme-font-other">Thank you for verifying your email address.</p>
										<p class="text-body-1 theme-font-other">This completes the set-up of your account.</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="mt-2 text-center">
										<p class="pl-2 pr-2 text-caption text-decoration-underline bg-white pointer" @click="goToLogin()">Sign-in to your Account</p>
									</v-col>
								</v-row>
							</div>
							<div v-if="state.verificationOutcome == 907">
								<v-row>
									<v-col><p class="text-h4 theme-font vx-text-dark">
										Link Invalid
									</p></v-col>								
								</v-row>
								<v-row>
									<v-col>
										<p class="text-body-1 theme-font-other">This link is invalid.</p>										
									</v-col>
								</v-row>
							</div>
							<div v-if="state.verificationOutcome == 909">
								<v-row>
									<v-col><p class="text-h4 theme-font vx-text-dark">
										Link Expired
									</p></v-col>								
								</v-row>
								<v-row>
									<v-col>
										<p class="text-body-1 theme-font-other">This link has either been used or has expired.</p>										
									</v-col>
								</v-row>							
							</div>
							<div v-if="state.verificationOutcome == -1 || state.verificationOutcome == -99998">
								<v-row>
									<v-col><p class="text-h4 theme-font vx-text-dark">
										Server Error
									</p></v-col>								
								</v-row>
								<v-row>
									<v-col>
										<p class="text-body-1 theme-font-other">Sorry, we have been unable to verify your email at this time.</p>										
									</v-col>
								</v-row>
							</div>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4" ></v-col>
			</v-row>
				</div>
</template>

<style scoped>

.pointer {
	cursor: pointer;
}
</style>

<script setup>
import { reactive, onMounted,onUnmounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';

const route = useRoute();
const router = useRouter();

const mainStore = useMainStore();


onMounted(() => {
 console.log('Mounted Register Form');
		mainStore.pollingForLogin = true;	//Forces turn off of polling
});

onUnmounted(() => {
			console.log('UnMounted Register Form');	
			mainStore.pollingForLogin = false;	//Forces turn off of polling	
});

const state = reactive({
		failureReason: undefined,
		verificationOutcome: 0,
		verificationType: null,
		emailAddress: null,		
});


const goToLogin = () => {
	router.push({ name: 'login'});
}


const verifyAccessCode = async (accessCode,verificationType) => {	
	const data = new URLSearchParams();
		await fetch(mainStore.getBaseUrl('plustag')+'/quartermass/accessCodeVerification/'+accessCode+"/"+verificationType, {
			method: 'POST',
			body: data,
			credentials: 'include',
			redirect: 'follow',																									
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)
					state.verificationOutcome = d.outcome;							
					if (d.outcome == 1) {
						state.emailAddress = d.emailAddress;
						state.verificationType = d.verificationType;						
					} else {

					}
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
			
	});		
}

console.log(JSON.stringify(route.params.accessCode));
if (route.params.accessCode) {
	verifyAccessCode(route.params.accessCode,'newUserEmailVerification');
}
</script>