
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import 'vuetify/styles'
import { aliases, md } from 'vuetify/iconsets/md'


// Vuetify
import { createApp } from 'vue'
import { createVuetify } from 'vuetify';
import { VuetifyDateAdapter } from 'vuetify/labs/date/adapters/vuetify';

const voxhubLightTheme = {
  dark: false,
  colors: {
    background: '#f0f2f5',
    surface: '#ffffff',
    primary: '#6200EE',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
		menu_background: '#f0f2f5',		
		menu_separator: '#231f20',		
		menu_selected_color: '#e0ea81',
  }
}

const servedUpTheme = {
  dark: false,
  colors: {
		background: '#ffffff',
		primary: "#157578",
		secondary: "#606060",
		success: '#4CAF50',
    warning: '#FB8C00',
		failure: '#ff0000',
		dark: "#005B5E",
		light: "#68C3C3",
		white: "#ffffff",
		primary_text: "#ffffff",
		secondary_text: "#ffffff",
		selected_color: "#cbdb2a",
		vx_green_100: "#161804",
		vx_green_200: "#43490c",
		vx_green_300: "#707914",
		vx_green_400: "#9daa1d",
		vx_green_500: "#cada25",
		vx_green_600: "#d6e255",
		vx_green_700: "#e1eb86",
		vx_green_800: "#edf3b6",
		vx_green_900: "#f9fbe7",
		dark_100: "#004951",
		dark_200: "#005B5E",
		dark_300: "#007070",
		dark_400: "#008888",
		dark_500: "#157578",
		dark_600: "#2D8C8C",
		dark_700: "#44A2A2",
		dark_800: "#5CB8B8",
		dark_900: "#73CFCF",
		light_100: "#AED9D9",
		light_200: "#BFE6E6",
		light_300: "#D1F2F2",
		light_400: "#E3FFFF",
		light_500: "#FFFFFF",
		light_600: "#FFFFFF",
		light_700: "#FFFFFF",
		light_800: "#FFFFFF",
		light_900: "#FFFFFF",
		primary_100: "#004951",
		primary_200: "#005B5E",
		primary_300: "#007070",
		primary_400: "#008888",
		primary_500: "#157578",
		primary_600: "#2D8C8C",
		primary_700: "#44A2A2",
		primary_800: "#5CB8B8",
		primary_900: "#73CFCF",
		secondary_100: "#424242",
		secondary_200: "#606060",
		secondary_300: "#7E7E7E",
		secondary_400: "#9D9D9D",
		secondary_500: "#BDBDBD",
		secondary_600: "#D9D9D9",
		secondary_700: "#F6F6F6",
		secondary_800: "#F2F2F2",
		secondary_900: "#E9E9E9",
		avatar_background: "#004951",
		avatar_color: "#44A2A2",
		action_button_text: '#ffffff',
		action_button_background: '#004951',
		menu_background: '#ffffff',		
		menu_separator: '#157578',		
		menu_selected_color: '#e1eb86',
		menu_selected_icon_color: "#e1eb86",
		login_box_border_color: '#004951',
		loading_box_border_color: '#004951',
		loading_spinner_color: '#008888',
		box_border_color: '#004951',
		check_passed_color: '#004951',
		check_failed_color: '#BDBDBD',
		lifecycle_timeline_active_color: '#004951',
		lifecycle_timeline_inactive_color: '#BDBDBD',
		lifecycle_timeline_warning_color: '#FF0000',
		active_tab_color: "#BDBDBD",
		active_sub_tab_color: "#D9D9D9",
	}	
};

export default createVuetify({
	  icons: {
			defaultSet: 'md',
			aliases,
			sets: {
				md,
			}
		},
  	theme: {			
			defaultTheme: 'servedUpTheme',
			themes: {servedUpTheme}
		},
		date: {
			adapter: VuetifyDateAdapter,
		}
	}
)