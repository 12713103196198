<template>	
		<v-container fluid class="px-6 py-0 h-100">			
			<v-row>
				<v-col align="right">		
					<v-btn class="ml-2 theme-list-action-button-background   text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col md="0">	
					<v-card class="card-shadow border-radius-md d-flex flex-column">												
						<v-table  class="" density="compact">														
							<thead class="theme-list-header-background">
								<tr>
									<th class="text-center text-h6 theme-listing-header-text">Type</th>
									<th class="text-center text-h6 theme-listing-header-text">Name</th>									
									<th class="text-center text-h6 theme-listing-header-text">Reference</th>	
									<th class="text-center text-h6 theme-listing-header-text">Apps</th>	
									<th class="text-center text-h6 theme-listing-header-text"></th>	
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="voxletServiceContext in state.voxletServiceContexts"  :key="voxletServiceContext.primaryUUID">
									<td class="text-center">{{ t( 'statics.voxletServiceContextTypes.'+voxletServiceContext.voxletServiceContextType ) }}</td>			
									<td class="text-center">{{ voxletServiceContext.serviceAccountContextCustomerReference }}</td>
									<td class="text-center">{{ voxletServiceContext.serviceAccountContextDisplayName }}</td>
									<td class="text-center">Basic Line</td>
									<td class="text-center"></td>							
								</tr>			
								<tr v-if="state.voxletServiceContexts.length == 0">
									<td colspan="4" class="theme-font text-center text-h6">No Records Found</td>
								</tr>																					
							</tbody>
						</v-table>

						 <v-spacer></v-spacer>
							<!--<v-pagination
										v-model="state.pagination.page"
										:length="state.pagination.length"
										:total-visible="state.pagination.totalVisible"
										></v-pagination>-->
					</v-card>	
				</v-col>
			</v-row>
			
		</v-container>
</template>

<script setup>
  import { defineProps,inject, ref, reactive,onMounted } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';

	const { t }  = useI18n();
	const mainStore = useMainStore();
	const props = defineProps({
        phoneServiceName: {
            type: String
        }
    });

const state = reactive({
      voxletServiceContexts: [],
			filterValues: {
					searchText: '',
					voxletServiceContextType: 'customerAccount',
					recordCount: 25,
				},
			filters: {
				filterByType :  [
					{ type: 'all', typeDescription: 'Show All Types' },
          { type: 'customerAccount', typeDescription: 'Customer' },
          { type: 'projectAccount', typeDescription: 'Project' },
          { type: 'stockAccount', typeDescription: 'Stock' },
        ],
				recordCount: [
					{value: 10, title:'10'},
					{value: 25, title:'25'},
					{value: 50, title:'50'},
					{value: 100, title:'100'},					
					],
			},
			pagination: {
					page: 2,
					length: 4,
					totalVisible: 7,
				}
    });





	const changeVoxletServiceContextType = () => {
		getVoxletServiceContexts();
	}

	const changeRecordCount = (value) => {
		console.log("Value is "+value);
	}

	const doSearch = (value) => {
		console.log("Value is "+value);		
		mainStore.displaySnackBar("Searching");
		getVoxletServiceContexts();
	}

	const refreshList = () => {
		console.log("refreshList");
		mainStore.displaySnackBar("Refreshing");
		getVoxletServiceContexts();
	}

	const getVoxletServiceContexts = async () => {
		const apiUrl = mainStore.getAPIUrl();
		const data = new URLSearchParams();
		data.set('searchText', state.filterValues.searchText);	
		if (state.filterValues.voxletServiceContextType &&  state.filterValues.voxletServiceContextType != 'all') {
			data.set('voxletServiceContextType', state.filterValues.voxletServiceContextType);	
		}		
		console.log( state.filterValues.voxletServiceContextType)
		await fetch(apiUrl+'/voxletServices/listVoxletServiceContexts', {
					method: 'post',				
					body: data,
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == -3) {
							} else {
									state.voxletServiceContexts = d.voxletServiceContexts;
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);

			});		
		}

	onMounted(() => {
		getVoxletServiceContexts();
	});


  
</script>

<style scoped>
.listRow {
		height: 100%;	
	}

	.listDisplay {	
		height: calc( 100% - 100px );
	}

	.listTable {	
		height: calc( 100% - 100px);
	}
</style>