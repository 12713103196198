<template>
	<v-spacer v-if="state.contentFragment">

		<v-row>
			<v-col>			
		
				<v-row>
					<v-col>	
						<v-sheet align="center" :max-height="200" :min-height="200"  v-if="state.contentFragment.imageUUID" >												
							<v-spacer  class="d-flex flex-wrap ">
								
								<v-card class="cardDimensions"  @click="showImageBrowser()">			
									<v-img :src="mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)"/>
								</v-card>
							
							</v-spacer>
						</v-sheet>
						<v-sheet class="d-flex flex-wrap " :max-height="300" :min-height="300"  v-else>	
							<v-btn @click="showImageBrowser()" >Select Image</v-btn>
						</v-sheet>
					</v-col>
				</v-row>
				<v-row>
					<v-col>				
						<v-text-field v-model="state.contentFragment.width"  hide-details="auto" variant="outlined" 
							label="Width" @update:modelValue="valid(this)"></v-text-field>					
					</v-col>
				</v-row>
				<v-row>
					<v-col>				
						<v-text-field v-model="state.contentFragment.height"  hide-details="auto" variant="outlined" 
							label="Height" @update:modelValue="valid(this)"></v-text-field>					
					</v-col>
				</v-row>
				<v-row>
					<v-col>				
						<v-textarea variant="outlined" label="Text" hide-details="auto" v-model="state.contentFragment.text" @update:modelValue="valid(this)"></v-textarea>					
					</v-col>
				</v-row>
				<v-row>
					<v-col>				
						<v-select :items="state.values.imagePosition" label="Image Poistion" variant="outlined" item-title="title"
						item-value="value"  @update:modelValue="valid(this)"
						v-model="state.contentFragment.imagePosition"></v-select>				
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn  :disabled="!state.dataReady"  :class="(state.dataReady) ? 'theme-bg-highlight' :''" @click="completed(this)"><p v-if="state.action == 'add'">Select</p><p v-if="state.action == 'update'">Update</p></v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>											
	</v-spacer>
	<v-dialog v-model="state.showImageBrowser" persistent>
		<v-row>
			<v-col cols="0">
			</v-col>
			<v-col cols="12">
				<v-card class="">
					<BoxHeader headerText="Image Browser" :closeButton="(true)" @close="closeImageBrowser"></BoxHeader>
					<v-card-text>							
							<ImageBrowser @dataEvents="selectImages" :configuration="state.imageBrowserConfiguration"/>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="0">
			</v-col>
		</v-row>
	</v-dialog>		
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ImageBrowser from '../manage/ImageBrowser.vue';
import BoxHeader from '@/components/BoxHeader.vue';

const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const props = defineProps({		
	contentConfiguration: {
		type: Object,
		required: true,
	},
	action: {
		type: String,
		required: true,
	},
	contentFieldName: {
		type: String,
		required: true,
	},
	contentFragment: {
		type: Object
	},
	position: {
		type: Number
	}
});

const state = reactive({	
	dataReady: false,	
	showImageBrowser: false,
	action: undefined,
	contentConfiguration: undefined,			
	contentFragment: undefined,
	position: 0,	
	imageBrowserConfiguration: {
			browserMode: 'select',
		},
	values: {		
		imagePosition: [
			{value: 'left' , title:'Image Left'},
			{value: 'right', title:'Image Right'},
		]
	},	
});

const emits = defineEmits(['close','dataEvents']);

const fireDataReadyEvent = () => {
		emits('dataEvents',
			{
				state: 'ready',
				action: state.action,
				position: state.position,
				contentFieldName: state.contentFieldName,
				contentFragment: state.contentFragment,				
			}
		);
}

const close = (a) => {	
	emits('close');
}

const valid = (event) => {
	if (state.contentFragment.imageUUID) {
		state.dataReady = true;
		return true;
	}
	return false;
}

const selectImages = (event) => {
		console.log(event.selectedImages);
		if (event.selectedImages) {
			state.contentFragment.imageUUID = event.selectedImages[0];
		}
		valid(event);
		closeImageBrowser();
	}

const showImageBrowser = () => {
	state.showImageBrowser = true;
}

const closeImageBrowser = () => {
	state.showImageBrowser = false;
}


const completed = (event) => {
	if (valid(event)) {
		fireDataReadyEvent();
	}	
}

onUpdated(() => {

});

onMounted(() => {
	if (props.contentConfiguration) {
		state.contentConfiguration = props.contentConfiguration;
		console.log(" contentConfiguration is "+state.contentConfiguration);
	} 
	if (props.contentFieldName) {
		state.contentFieldName = props.contentFieldName;
		console.log(" contentFieldName is "+state.contentFieldName);
	} 
	if (props.action) {
		state.action = props.action;
	} 
	if (props.contentFragment) {
		state.contentFragment =props.contentFragment;
	} else {
		state.contentFragment = {
			type: 'textImageBlock',
			imageUUID: undefined,
			text: undefined,			
		}
	}
	if (props.position) {
		state.position = props.position;
	} else {
		state.position = 0;
	}
});
</script>

<style scoped>

.cardDimensions {
	min-width: 200px;
	min-height: 200px;
	max-width: 200px;
	max-height: 200px;
}

</style>