<template>
	<v-container fluid class="px-6 py-6 h-100">	
			<v-row>			
				<v-col>
					<p class="theme-font text-h5">Insert Support Portal Here</p>
				</v-col>
			</v-row>
	</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';


	const { t }  = useI18n();
		
	const router = useRouter();

	const mainStore = useMainStore();

	const state = reactive({	
		tabsTop: 'home',	
	});
</script>

<style>
</style>