<template>
	<v-container>		
			<v-row>			
				<v-col>	
					<v-card class="card-shadow border-radius-md d-flex flex-column">					
						<v-table  class="" density="compact">														
							<thead class="theme-list-header-background" >
								<tr>
									<th class="text-center text-body-1 theme-listing-header-text">Status</th>										
									<th class="text-center text-body-1 theme-listing-header-text">Name</th>																							
									<th class="text-center text-body-1 theme-listing-header-text">Host</th>
									<th class="text-center text-body-1 theme-listing-header-text"><v-btn class="theme-list-action-button-background text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon" size="small">refresh</v-icon></v-btn></th>
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="pstnProvider,index in state.pstnProviders"  :key="index">																							
									<td class="text-center ">{{pstnProvider.status}}</td>
									<td class="text-center ">{{pstnProvider.providerName}}</td>
									<td class="text-center ">{{pstnProvider.host}}</td>
									<td class="text-center">
										<v-menu location="center">
											<template v-slot:activator="{ props }">
												<v-icon v-bind="props">menu</v-icon>
											</template>

											<v-list>
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="managePSTNProvider(pstnProvider);">Manage Provider</v-list-item-title>
												</v-list-item>
											</v-list>
										
										</v-menu></td>
								</tr>								
								<tr v-if="state.pstnProviders.length == 0">
									<td colspan="5" class="theme-font text-center text-h6">No Records Found</td>
								</tr>	
								
							</tbody>

						</v-table>
					
					</v-card>	
				</v-col>
			</v-row>
		
		</v-container>
		<v-dialog v-model="state.showPSTNForm">
			<v-row>
				<v-col md="4" sm="2">					
				</v-col>
				<v-col cols="12" sm="8" md="4">
					<v-card>
						<BoxHeader headerText="Create Tariff" :closeButton="true" @close="closePSTNForm"/>										
						<v-card-text>		
							<PSTNForm :tariff="state.selectedTariff" @dataEvents="tariffComplete"/>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4" sm="2">					
				</v-col>
			</v-row>

		</v-dialog>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import PSTNForm from './components/PSTNForm.vue';
	import BoxHeader from '@/components/BoxHeader.vue';
	import uuid from 'uuid-random';

	
	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const emits = defineEmits(['dataEvents']);

	const state = reactive({
		pstnProviders: [],
		selectedPSTNProvider: undefined,
		showPSTNForm: false,				
		filters: {		
			recordCount: [
				{value: 10, title:'10'},
				{value: 25, title:'25'},
				{value: 50, title:'50'},
				{value: 100, title:'100'},					
			],			
		},
		values: {
			pricePer: [
				{value: 1 , title:'Per Second'},
				{value: 2, title:'Per Minute'},					
			]
		}		
	});

	const fireDataReadyEvent = (event) => {				
		emits('dataEvents',
			{
				state: 'manageTariff',
				tariff: state.selectedPSTNProvider,
			}
		);
	}

	const managePSTNProvider = (pstnProvider) => {
		state.selectedPSTNProvider = pstnProvider;
		fireDataReadyEvent();
	}

	

	const listPstnProviders = async () => {
			/**
				const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/serviceBilling/listTarrifs', {
					method: 'get',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {
									state.serviceAccountInvoices = d.serviceAccountInvoices;
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
			*/
		}

		

		const refreshList = async () => {
			
		}


		onUpdated(() => {	
			
		});


		onMounted(() => {
			let pstnProvider =  {
				primaryUUID: uuid(),			
				status: 'live',
				providerName: 'Magrathea',
				host: 'sipgw.magrathea.net',
				
			};	
			state.pstnProviders.push(pstnProvider);		
			pstnProvider =  {
				primaryUUID: uuid(),			
				status: 'offline',
				providerName: 'Served Up',
				host: 'uk-sbc.voxhub.net',
				
			};	
			state.pstnProviders.push(pstnProvider);		
		});

</script>

<style scoped>

    
</style>