<template>
	<v-container fluid class="px-6 py-6 h-100">
			<v-row>
				<v-col class="pa-0 ma-0">
					<v-row>
						<v-col cols="6">
							<v-card>
								<BoxHeader headerText="Rest API"/>						
								<v-card-text>												
									<v-row>
									<v-col>
										<p class="theme-font text-h6">API</p>

										<p class="theme-font-other ml-5 text-green font-weight-bold">ACTIVE</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="pt-0">
										<p class="theme-font text-h6">API Base URL</p>
									
									<p class="theme-font-other ml-5">https://{{ mainStore.phoneServiceName }}.api.i164.co.uk/api/commands-key/</p>
									</v-col>
								</v-row>							
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="6">
							<I164HubMemberEndpointKey :refreshConfiguration="state.assets.i164HubMemberEndpointKey.refreshConfiguration" :asset-data="i164HubMemberEndpointKey" :configuration="{}" @dataEvents="i164HubMemberEndpointKeyEvent"   :readOnly="(true)"/>
						</v-col>
						
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</template>
	
	<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import BoxHeader from '../BoxHeader.vue';
import I164HubMemberEndpointKey from '@/apps/networkHub/components/I164HubMemberEndpointKey.vue';

	
	const { t }  = useI18n();
		
	const router = useRouter();
	
	const mainStore = useMainStore();
	
	const state = reactive({	
		tabsTop: 'home',
		assets: {
			i164HubMemberEndpointKey: {
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			}
		}	
	});

	const i164HubMemberEndpointKey =  {
                                "primaryUUID": "4a1b4af2-1d27-4540-9b4a-f21d27b540b6",
                                "phoneServiceUUID": "aa74311c-ac38-41fa-b431-1cac3841fabd",
                                "voxletInstanceUUID": "a53a6c4f-6980-4077-ba6c-4f6980907751",
                                "i164HubMemberIdentityUUID": "ed02a1c3-1056-4eeb-82a1-c310569eeb42",
                                "keyName": "Main Key",
                                "createDate": "2023-05-13 16:50:36.0",
                                "i164EndpointKeyData": {
                                    "bcpidKey": "GWHWY",
                                    "permittedNetworks": [
                                        {
                                            "name": "Initial",
                                            "ipAddress": "35.246.39.73",
                                            "prefix": "32",
                                            "address": "35.246.39.73/32"
                                        },
                                        {
                                            "name": "Test Network",
                                            "ipAddress": "46.247.112.203",
                                            "prefix": "32",
                                            "address": "46.247.112.203/32"
                                        },
                                        {
                                            "name": "Test Network",
                                            "ipAddress": "78.32.136.127",
                                            "prefix": "32",
                                            "address": "78.32.136.127/32"
                                        },
                                        {
                                            "name": "Test Network",
                                            "ipAddress": "10.211.55.14",
                                            "prefix": "32",
                                            "address": "10.211.55.14/32"
                                        }
                                    ]
                                },
                                "bcpidKey": {
                                    "bcpidKey": "GWHWY",
                                    "permittedNetworks": [
                                        {
                                            "name": "Initial",
                                            "ipAddress": "35.246.39.73",
                                            "prefix": "32",
                                            "address": "35.246.39.73/32"
                                        },
                                        {
                                            "name": "Test Network",
                                            "ipAddress": "46.247.112.203",
                                            "prefix": "32",
                                            "address": "46.247.112.203/32"
                                        },
                                        {
                                            "name": "Test Network",
                                            "ipAddress": "78.32.136.127",
                                            "prefix": "32",
                                            "address": "78.32.136.127/32"
                                        },
                                        {
                                            "name": "Test Network",
                                            "ipAddress": "10.211.55.14",
                                            "prefix": "32",
                                            "address": "10.211.55.14/32"
                                        }
                                    ]
                                }
                            };
	

		

	const i164HubMemberEndpointKeyEvent = (event) => {
		
	}

	
	onMounted(() => {

	});
	
	</script>
	
	<style>
	
	</style>
	