<template>
	<v-container fluid class="px-10 py-6 h-100" v-if="state.serviceAccountInvoice">
		<v-card>
			<v-card-text>
				<v-row>
					<v-col cols="3">
						<v-img :src="servedUpLogo" min-width="150" ></v-img>
					</v-col>
					<v-col cols="6">
						
					</v-col>
					<v-col cols="3">
						<p class="text-h3">INVOICE</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="6">
						<v-row v-if="state.serviceAccountInvoice.companyName">
							<v-col class="pb-1">
								{{ state.serviceAccountInvoice.companyName }}
							</v-col>
						</v-row>	
						<v-row v-if="state.serviceAccountInvoice.address1">
							<v-col class="pt-0 pb-1">
								{{ state.serviceAccountInvoice.address1 }}
							</v-col>
						</v-row>	
						<v-row v-if="state.serviceAccountInvoice.address2">
							<v-col class="pt-0 pb-1">
								{{ state.serviceAccountInvoice.address2 }}
							</v-col>
						</v-row>
						<v-row v-if="state.serviceAccountInvoice.address3">
							<v-col class="pt-0 pb-1">
								{{ state.serviceAccountInvoice.address3 }}
							</v-col>
						</v-row>	
						<v-row v-if="state.serviceAccountInvoice.cityTown">
							<v-col class="pt-0 pb-1">
								{{ state.serviceAccountInvoice.cityTown }}
							</v-col>
						</v-row>
						<v-row v-if="state.serviceAccountInvoice.county">
							<v-col class="pt-0 pb-1">
								{{ state.serviceAccountInvoice.county }}
							</v-col>
						</v-row>
						<v-row v-if="state.serviceAccountInvoice.postcode">
							<v-col class="pt-0 pb-1">
								{{ state.serviceAccountInvoice.postcode }}
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="6">
						<v-row>
							<v-col>{{ state.serviceAccountInvoice.invoiceId }}</v-col><v-col>{{ state.serviceAccountInvoice.invoiceDate }}</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						Invoice Items
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-row v-for="serviceAccountInvoiceItem,index in state.serviceAccountInvoice.serviceAccountInvoiceItems" :key="index">
							<v-col cols="1">
								{{ serviceAccountInvoiceItem.quantity }}
							</v-col>
							<v-col cols="1">
								{{ serviceAccountInvoiceItem.grantedLicenseId }}
							</v-col>
							<v-col  cols="4">
								{{ serviceAccountInvoiceItem.licensePackageName }}
							</v-col>
							<v-col cols="2">
								{{ serviceAccountInvoiceItem.fromDate }}
							</v-col>
							<v-col cols="2">
								{{ serviceAccountInvoiceItem.toDate }}
							</v-col>
							<v-col cols="2">
								{{ serviceAccountInvoiceItem.amount }}
							</v-col>
						</v-row>
					</v-col>					
				</v-row>
				<v-row>
					<v-col cols="8"></v-col>
					<v-col cols="2">Sub-Total</v-col>
					<v-col cols="2"> {{ calcSubTotal() }}</v-col>
				</v-row>
				<v-row>
					<v-col cols="8"></v-col>
					<v-col cols="2">VAT</v-col>
					<v-col cols="2">{{ calcVat() }}</v-col>
				</v-row>
				<v-row>
					<v-col cols="8"></v-col>
					<v-col cols="2">Total</v-col>
					<v-col cols="2">{{ calcTotal() }}</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
	import i164Transparent from '@/assets/images/i164_transparent.png';
	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();
	const route = useRoute();

	const state = reactive({
		serviceAccountInvoiceUUID: undefined,
		serviceAccountInvoice: undefined,					
	});

	const refreshState = () => {		
		if (props.assetData) {
			state.i164HubMemberEndpointGroup = props.assetData;	
			state.dataReady = true;		
		}
	}

	const loadServiceInvoice = async () => {
				const apiUrl = mainStore.getAPIUrl();	
				const data = new URLSearchParams();
				data.set('serviceAccountInvoiceUUID', state.serviceAccountInvoiceUUID);					
				await fetch(apiUrl+'/serviceBilling/loadServiceAccountInvoice', {
					method: 'post',	
					body: data,			
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == -3) {
							} else {
									state.serviceAccountInvoice = d.serviceAccountInvoice;
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
		}

		const calcSubTotal = () => {
			let subTotal = 0;
			if (state.serviceAccountInvoice) {
				for (let i = 0; i < state.serviceAccountInvoice.serviceAccountInvoiceItems.length;i ++) {
					subTotal += state.serviceAccountInvoice.serviceAccountInvoiceItems[i].amount;
				}
			}
			return subTotal;
		}

		const calcVat = () => {
			let subTotal = calcSubTotal();
			return (subTotal * (state.serviceAccountInvoice.vatForThisInvoice/100))			
		}

		const calcTotal = () => {
			const total = calcSubTotal()+ calcVat();
			return total;
		}


	onUpdated(() => {	
			
		
	});


		onMounted(() => {
			if (route.params.serviceAccountInvoiceUUID) {
				state.serviceAccountInvoiceUUID = route.params.serviceAccountInvoiceUUID;
				loadServiceInvoice();
			} 
		});


</script>

<style scoped>


</style>