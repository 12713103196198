<template>
		<div class="theme-mainPanel fill-height" >					
			<v-row class="mb-5 fill-height" >
				<v-col cols="0" sm="2" md="4" ></v-col>
				<v-col cols="12" sm="8" md="4" class="fill-height d-flex align-center justify-center" >
					<v-card  class="card-shadow border-radius-md loadingBox" >
						<v-card-text class="align-center ma-5" >
							<v-row>
								<v-col class="ma-5"><v-img :src="servedUpLogo" max-width="250" ></v-img></v-col>
							</v-row>
							<v-row>
								<v-col>
									<p class="text-h5 theme-font vx-text-dark">
											You do not have permission to view this page.
									</p>
								</v-col>								
							</v-row>	
							<v-row v-if="route.meta.permissionsRequired">
								<v-col>
									<p class="text-h5 theme-font vx-text-dark mb-2">Permissions Required</p>
									<p class="text-body-2 ml-2" v-for="permission,index in route.meta.permissionsRequired" :key="index">
										{{ t("statics.permissions."+permission.replaceAll('.','_')) }}
									</p>								
								</v-col>								
							</v-row>	
							<v-row v-if="route.meta.rolesAllowed">
								<v-col>
									<p class="text-h5 theme-font vx-text-dark">Roles Required</p>
									<p class="text-body-2" v-for="role,index in route.meta.rolesAllowed" :key="index">
										{{ t("statics.roles."+role.replaceAll('.','_')) }}
									</p>
								</v-col>								
							</v-row>							
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="0" sm="2" md="4" ></v-col>
			</v-row>
		</div>
</template>

<script setup>
	import { useRouter,useRoute } from "vue-router";
	import { useMainStore } from "../stores/MainStore.js";
	import voxhubLogo from '@/assets/images/voxhub_greenOnWhite.svg';
	import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
	import { useI18n } from 'vue-i18n';

	const { t }  = useI18n();

	const router = useRouter();
	const route = useRoute();

	const mainStore = useMainStore();

	const goToPlustagServices = () => {
		mainStore.phoneServiceName = 'plustag';
		router.push({name: 'plustagServices'});
	}


</script>

<style scoped>

</style>