<template>
<v-container fluid class="px-6 py-6 h-100">
		<v-row>
			<v-col class="pa-0 ma-0">
				<v-row>
					<v-col  cols="4">
						<p class="theme-font-other text-body-2 text-justify">
							Quotas are used to control access to the i164 applications. They are used to place limits on the use of resources on our platform.
						</p>
						<p class="theme-font-other text-body-2 text-justify mt-2">
							Quotas are set based on sensible usage of our applications to ensure that there are some checks to prevent unwanted over-use of resources and in some cases credit limit accounts.
						</p>						
						<p class="theme-font-other text-body-2 text-justify mt-2">
							We can apply quotas to each account to different levels and in most cases we try to set them up initially with values that properly reflect your expected usage.
						</p>	
					</v-col>
					<v-col cols="8">
						<v-card>
							<BoxHeader headerText="Service Quotas"/>							
							<v-card-text>
								
								<v-row>
									<v-col>
										<v-row>
											<v-col cols="6">
												
											</v-col>
											<v-col class="text-center theme-font text-h6 font-weight-bold mt-2 mb-0" cols="3">
												Quota
											</v-col>
											<v-col class="text-center theme-font text-h6 font-weight-bold mt-2 mb-0" cols="3">
												Used
											</v-col>											
										</v-row>
										<v-row v-if="state.apps.lines">
											<v-col>
												<p class="theme-font text-h6">Basic Lines</p>
											</v-col>
										</v-row>
										<v-row  v-if="state.apps.lines" :set="quotaSummary = fetchQuota('service:lines:basicLine:lineCount')">
											<v-col cols="6">
												<p class="ml-5">{{ t("statics.quotas."+quotaSummary.resourceName.replaceAll(':','_')) }}</p>
											</v-col>
											<v-col cols="3" class="text-center" >
												{{ quotaSummary.quota  }}
											</v-col>
											<v-col cols="3" class="text-center">
												{{ quotaSummary.quotaUsed }}
											</v-col>
										</v-row>
										<v-row v-if="state.apps.content">
											<v-col>
												<p class="theme-font text-h6">Content</p>
											</v-col>
										</v-row>
										<v-row  v-if="state.apps.content" :set="quotaSummary2 = fetchQuota('service:content:documentCount')">
											<v-col cols="6" >
												<p class="ml-5">{{ t("statics.quotas."+quotaSummary2.resourceName.replaceAll(':','_')) }}</p>
											</v-col>
											<v-col cols="3"  class="text-center">
												{{ quotaSummary2.quota  }}
											</v-col>
											<v-col cols="3"  class="text-center">
												{{ quotaSummary2.quotaUsed }}
											</v-col>
										</v-row>
										<v-row v-if="state.apps.servicePassport">
											<v-col>
												<p class="theme-font text-h6">Service Passport</p>
											</v-col>
										</v-row>										
										<v-row  v-if="state.apps.servicePassport" :set="quotaSummary3 = fetchQuota('service:networkHub:telecoms:createMemberIdentity')">
											<v-col cols="6">
												<p class="ml-5">{{ t("statics.quotas."+quotaSummary3.resourceName.replaceAll(':','_')) }}</p>
											</v-col>
											<v-col cols="3" class="text-center" >
												{{ quotaSummary3.quota  }}
											</v-col>
											<v-col cols="3" class="text-center" >
												{{ quotaSummary3.quotaUsed }}
											</v-col>
										</v-row>
										<v-row  v-if="state.apps.servicePassport" :set="quotaSummary4 = fetchQuota('service:servicePassport:consumer:passportCount')">
											<v-col cols="6">
												<p class="ml-5">{{ t("statics.quotas."+quotaSummary4.resourceName.replaceAll(':','_')) }}</p>
											</v-col>
											<v-col cols="3" class="text-center" >
												{{ quotaSummary4.quota  }}
											</v-col>
											<v-col cols="3" class="text-center" >
												{{ quotaSummary4.quotaUsed }}
											</v-col>
										</v-row>
										<v-row  v-if="state.apps.servicePassport" :set="quotaSummary5 = fetchQuota('service:servicePassport:business:access')">
											<v-col cols="6">
												<p class="ml-5">{{ t("statics.quotas."+quotaSummary5.resourceName.replaceAll(':','_')) }}</p>
											</v-col>
											<v-col cols="3" class="text-center" >
												{{ quotaSummary5.quota  }}
											</v-col>
											<v-col cols="3" class="text-center" >
												{{ quotaSummary5.quotaUsed }}
											</v-col>
										</v-row>

									</v-col>
								</v-row>				
							</v-card-text>
						</v-card>
						
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import BoxHeader from '../BoxHeader.vue';


const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const state = reactive({	
	tabsTop: 'home',	
	apps: {
		servicePassport: false,
		lines: false,
		content: false,
	}
});


const initialiseQuotas = () => {
	state.apps.lines = mainStore.getQuota().hasAnyQuota('service:lines:access');
	state.apps.content = mainStore.getQuota().hasAnyQuota('service:content:access');
	state.apps.servicePassport = mainStore.getQuota().hasAnyQuota('service:networkHub:telecoms:createHub');
}

const fetchQuota = (resourceName) => {
	return mainStore.getQuota().fetchQuota(resourceName);
}

onMounted(() => {
		initialiseQuotas();
});

/**
 * 	store: undefined,
	userQuotas: ['user:core:phoneService:createService'],
	serviceQuotas: ['service:networkHub:telecoms:createHub',
									'service:networkHub:telecoms:createMemberIdentity',
									'service:servicePassport:consumer:access',
									'service:servicePassport:consumer:passportCount',
									'service:servicePassport:business:access',
									'service:lines:access',
									'service:lines:basicLine:lineCount',
									'service:content:access',
									'service:content:documentCount'
								]
 */
</script>

<style>

</style>