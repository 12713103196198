import { useRoute , createRouter, createWebHistory, createWebHashHistory} from "vue-router";
import {useMainStore} from "../stores/MainStore.js";
import GuestMain from "../components/GuestMain.vue";
import MainInterface from "../components/MainInterface.vue";
import MainDashboard from "../components/MainDashboard.vue";
import ChildHolder from "./ChildHolder.vue";
import Dashboard from "../components/Dashboard.vue";
import LoginView from "../components/LoginView.vue";
import RegisterView from "../components/RegisterView.vue";
import ForgottenPasswordView from "../components/ForgottenPasswordView.vue";
import AddEditLine from "../apps/lines/AddEditLine.vue";
import DevicesMain from "../microServices/devices/DevicesMain.vue";
import NumbersMain from "../microServices/numbers/NumbersMain.vue";
import ServiceContextMain from "../microServices/contexts/ServiceContextMain.vue";
import BrandingMain from "../microServices/branding/BrandingMain.vue";
import PlustagMain from "../components/plustag/PlustagMain.vue"
import PlustagDashboard from "../components/plustag/PlustagDashboard.vue"
import PlustagAccount from "../components/plustag/PlustagAccount.vue"
import PlustagServices from "../components/plustag/PlustagServices.vue"
import PlustagServiceApplicationForm from "../components/plustag/phoneServiceCreation/PlustagServiceApplicationForm.vue"
import QuartermassMain from "../components/QuartermassMain.vue";
import InviteUserToService from "../components/quartermass/inviteUserToService/InviteUserToService.vue";
import InviteExistingUserToService from "../components/quartermass/inviteUserToService/InviteExistingUserToService.vue";
import NewUserEmailVerification from "../components/quartermass/newUserEmailVerification/NewUserEmailVerification.vue";
import ForgottenPasswordEmailReset from "../components/quartermass/forgottenPasswordEmailReset/ForgottenPasswordEmailReset.vue";

import DisplayUserParticipation from "../components/userParticipation/DisplayUserParticipation.vue";
import UserMain from "../components/userParticipation/UserMain.vue";
import BasicLine from "../apps/lines/BasicLine.vue";
import LinesMain from "../apps/lines/LinesMain.vue";
import ListServicePassports from "../apps/servicePassport/ListServicePassports.vue";
import ServicePassportMain from "../apps/servicePassport/ServicePassportMain.vue";

import NetworkHubDashboard from "../apps/networkHub/NetworkHubDashboard.vue";
import NetworkHubPanel from "../apps/networkHub/NetworkHubPanel.vue";
import NetworkHubApplication from "../apps/networkHub/NetworkHubApplication.vue";
import GainCustomer from "../apps/servicePassport/GainCustomer.vue";
import GainCustomerMessage from "../apps/servicePassport/GainCustomerMessage.vue";
import AccountMain from "../components/account/AccountMain.vue";
import ListServiceInvoices from "../components/account/ListServiceInvoices.vue";
import InvoiceDisplay from "../components/account/InvoiceDisplay.vue";
import DeveloperMain from "../components/developer/DeveloperMain.vue";
import DocumentationFrontPage from "../components/content/DocumentationFrontPage.vue";
import WebPagePage from "../components/content/manage/WebPagePage.vue";
import ArticlePage from "../components/content/manage/ArticlePage.vue";
import SplashPage from "../components/content/manage/SplashPage.vue";
import FaqPage from "../components/content/manage/FaqPage.vue";
import CommandPage from "../components/content/manage/CommandPage.vue";
import HelpButtonPage from "../components/content/manage/HelpButtonPage.vue";
import ListContent from "../components/content/manage/ListContent.vue";
import BillingMain  from "../microServices/billing/BillingMain.vue";
import PSTNMain  from "../microServices/pstn/PSTNMain.vue";
import PageNotFound from "../components/PageNotFound.vue"


import permissions from "../commands/permissions.js";




const ACCESS_FOR_ALL = "accessForAll";
const GUESTS_ONLY = "guestsOnly";
const QUARANTINE = "holdingRoom";
const ONLY_AUTHORISED = "onlyAuthorised";

const routes = [
	{
		path: "/",
		component: ChildHolder,
		props: false,
		meta: {
			mainMenuNodePath: ["login"],
			accessibility: GUESTS_ONLY,
		},
		children: [
			{
				path: "",
				name: "default",
				initialTargetPath: "default",
				component: LoginView,
				props: false,
				meta: {
					mainMenuNodePath: ["login"],
					accessibility: GUESTS_ONLY,
				}
			},
			{
				path: "/login",
				name: "login",
				initialTargetPath: "login",
				component: LoginView,
				props: false,
				meta: {
					mainMenuNodePath: ["login"],
					accessibility: GUESTS_ONLY,
				}
			}, {
				path: "/register",
				name: "register",
				initialTargetPath: "register",
				component: RegisterView,
				props: false,
				meta: {
					lastName: 'default',
					lastParams: {},
					mainMenuNodePath: ["register"],
					accessibility: GUESTS_ONLY,
				}
			}, {
				path: "/forgottenPassword",
				name: "forgottenPassword",
				initialTargetPath: "forgottenPassword",			
				component: ForgottenPasswordView,
				props: false,
				meta: {
					lastName: 'default',
					lastParams: {},
					mainMenuNodePath: ["forgottenPassword"],
					accessibility: ACCESS_FOR_ALL,
				}
			}
		]
	}, {
		path: "/plustag",
		component: PlustagMain,
		meta: {
			mainMenuNodePath: ["dashboard"],
			accessibility: ONLY_AUTHORISED,
		},
		children: [
			{
				path: "",
				name: "plustag",
				initialTargetPath: "plustag",		
				component: PlustagDashboard,
				props: false,
				meta: {
					mainMenuNodePath: ["dashboard"],
					accessibility: ONLY_AUTHORISED,
				}
			}, {
				path: "account",
				name: "plustagAccount",
				initialTargetPath: "plustagAccount",		
				component: PlustagAccount,
				props: false,
				meta: {
					mainMenuNodePath: ["dashboard"],
					accessibility: ONLY_AUTHORISED,					
				}
			}, {
				path: "services",
				name: "plustagServices",
				initialTargetPath: "plustagServices",		
				component: PlustagServices,
				props: false,
				meta: {
					mainMenuNodePath: ["plustagServices"],
					accessibility: ONLY_AUTHORISED,
				}
			}, {
				path: "serviceForm",
				name: "plustagNewServiceForm",
				initialTargetPath: "plustagNewServiceForm",		
				component: PlustagServiceApplicationForm,
				meta: {
					mainMenuNodePath: ["dashboard"],
					accessibility: ONLY_AUTHORISED,
				}
			}, {
				path: "serviceForm/:phoneServiceName/:selectedStage",
				name: "plustagEditServiceForm",
				initialTargetPath: "plustagEditServiceForm",		
				component: PlustagServiceApplicationForm,
				meta: {
					mainMenuNodePath: ["dashboard"],
					accessibility: ONLY_AUTHORISED,
				}
			}
		]
	}, {
		path: "/assembler/:phoneServiceName",
		props: false,
		component: MainInterface,
		meta: {
			mainMenuNodePath: ["dashboard"],
			accessibility: ONLY_AUTHORISED,
		},
		children: [
			{
				path: "dashboard",
				name: "dashboard",
				initialTargetPath: "dashboard",		
				props: false,
				component: MainDashboard,
				meta: {
					mainMenuNodePath: ["dashboard"],
					accessibility: ONLY_AUTHORISED,
				}
			}, {
				path: "userProfile",
				name: "userProfile",
				initialTargetPath: "userProfile",		
				component: Dashboard,
				meta: {
					mainMenuNodePath: ["userProfile"],
					accessibility: ONLY_AUTHORISED,
				}
			}, 
			{
				path: "account",				
				name: "account",
				props: false,
				component: AccountMain,
				meta: {
					mainMenuNodePath: ["account"],
					accessibility: ONLY_AUTHORISED,
					permissionsRequired: ['serviceBilling.invoices.view'],
				},
				children: [
					{
						path: "listServiceInvoices",
						name: "listServiceInvoices",
						initialTargetPath: "listServiceInvoices",								
						component: ListServiceInvoices,
						meta: {
							mainMenuNodePath: ["account"],
							accessibility: ONLY_AUTHORISED,
						}
					},
						{
							path: "invoice/:serviceAccountInvoiceUUID",
							name: "invoiceDisplay",
							initialTargetPath: "invoiceDisplay",
							component: InvoiceDisplay,
							meta: {
								mainMenuNodePath: ["account"],
								accessibility: ONLY_AUTHORISED,
							}
						}
				]
			},
			{
				path: "users",				
				component: ChildHolder,
				meta: {
					mainMenuNodePath: ["users"],
					accessibility: ONLY_AUTHORISED,					
				},
				children: [
					{
						path: "",
						name: "users",				
						component: UserMain,
						meta: {
							mainMenuNodePath: ["users"],
							accessibility: ONLY_AUTHORISED,
							permissionsRequired: ['phoneServices.participation.manage'],
						},
					},
				{
						path: "user/:serviceUserUUID",
						name: "user",
						initialTargetPath: "user",
						component: DisplayUserParticipation,
						meta: {
							mainMenuNodePath: ["users"],
							accessibility: ONLY_AUTHORISED,
							permissionsRequired: ['phoneServices.participation.manage'],
						}
					}
				]
			},
			{
				path: "voxletServiceContexts",				
				initialTargetPath: "voxletServiceContexts",						
				component: ChildHolder,
				meta: {
					mainMenuNodePath: ["voxletServiceContexts"],
					accessibility: ONLY_AUTHORISED,
					permissionsRequired: ['microAccounts.general.view'],
				},
				children: [
					{
						path: "",
						name: 'voxletServiceContexts',						
						component: ServiceContextMain,
						meta: {
							mainMenuNodePath: ["voxletServiceContexts"],
							accessibility: ONLY_AUTHORISED,
							permissionsRequired: ['microAccounts.general.view'],
						},
					}
				]
			},
			{
				path: "devices",				
				initialTargetPath: "devices",	
				component: ChildHolder,
				meta: {
					mainMenuNodePath: ["devices"],
					accessibility: ONLY_AUTHORISED,
					permissionsRequired: ['microDevices.general.view'],
				},
				children: [
					{
						path: "",
						name: 'devices',						
						component: DevicesMain,
						meta: {
							mainMenuNodePath: ["devices"],
							accessibility: ONLY_AUTHORISED,
							permissionsRequired: ['microDevices.general.view'],
						},
					}
				]
			},
			{
				path: "numbers",
				initialTargetPath: "numbers",	
				component: ChildHolder,
				meta: {
					mainMenuNodePath: ["numbers"],
					accessibility: ONLY_AUTHORISED,
					permissionsRequired: ['microNumbers.general.view'],
				},
				children: [
					{
						path: "",
						name: 'numbers',						
						component: NumbersMain,
						meta: {
							mainMenuNodePath: ["numbers"],
							accessibility: ONLY_AUTHORISED,
							permissionsRequired: ['microNumbers.general.view'],
						},
					}
				]
			},
			{
				path: "pstn",
				name: "pstn",
				initialTargetPath: "pstn",	
				component: PSTNMain,
				meta: {
					mainMenuNodePath: ["pstn"],
					accessibility: ONLY_AUTHORISED,
				}
			},
			{
				path: "billing",
				name: "billing",
				initialTargetPath: "billing",	
				component: BillingMain,
				meta: {
					mainMenuNodePath: ["billing"],
					accessibility: ONLY_AUTHORISED,
				}
			},
			{
				path: "branding",
				name: "branding",
				initialTargetPath: "branding",	
				component: BrandingMain,
				meta: {
					mainMenuNodePath: ["billing"],
					accessibility: ONLY_AUTHORISED,
				}
			},
			{
				path: "lines",
				
				props: false,
				component: ChildHolder,
				meta: {
					mainMenuNodePath: ["lines"],
					accessibility: ONLY_AUTHORISED,
					permissionsRequired: ['voxletLines.basicLine.view'],
				},
				children: [
					{
					path: "",
					name: "lines",					
					initialTargetPath: "createBasicLine",	
					props: false,
					component: LinesMain,
					meta: {
						mainMenuNodePath: ["lines"],
						accessibility: ONLY_AUTHORISED,
						permissionsRequired: ['voxletLines.basicLine.view'],
					}
				},
				{
					path: "createBasicLine",
					name: "createBasicLine",
					initialTargetPath: "createBasicLine",	
					props: false,
					component: BasicLine,
					meta: {
						mainMenuNodePath: ["lines"],
						accessibility: ONLY_AUTHORISED,
						permissionsRequired: ['voxletLines.basicLine.view'],
					}
				},
				{
					path: "basicLine/:voxletInstanceUUID",
					name: "basicLine",
					initialTargetPath: "basicLine",
					component: BasicLine,
					meta: {
						mainMenuNodePath: ["lines"],
						accessibility: ONLY_AUTHORISED,
						permissionsRequired: ['voxletLines.basicLine.view'],
					}
				},
				{
					path: "addEditLine",
					name: "lineDetail",
					initialTargetPath: "lineDetail",
					component: AddEditLine,
					meta: {
						mainMenuNodePath: ["lines"],
						accessibility: ONLY_AUTHORISED,
						permissionsRequired: ['voxletLines.basicLine.view'],
					}
				}],
			},
			{
				path: "teamsRouter",
				name: "teamsRouter",
				initialTargetPath: "teamsRouter",
				component: Dashboard,
				meta: {
					mainMenuNodePath: ["teamsRouter"],
					accessibility: ONLY_AUTHORISED,
				}
			},
			{
				path: "networkHub",
				props: false,
				component: ChildHolder,
				meta: {
					mainMenuNodePath: ["networkHub"],
					accessibility: ONLY_AUTHORISED,
					permissionsRequired: ['networkHub.general.viewHub'],
				},
				children: [{
					path: "",
					name: "networkHubPanel",
					initialTargetPath: "networkHubPanel",
					component: NetworkHubPanel,
					meta: {
						mainMenuNodePath: ["networkHubPanel"],
						accessibility: ONLY_AUTHORISED,
						permissionsRequired: ['networkHub.general.viewHub'],
					}
				},{
					path: "networkHubDashboard",
					name: "networkHubDashboard",	
					initialTargetPath: "networkHubDashboard",					
					component: NetworkHubDashboard,
					meta: {
						mainMenuNodePath: ["networkHub"],
						accessibility: ONLY_AUTHORISED,
						permissionsRequired: ['networkHub.general.viewHub'],
					}
				},{
					path: "selectedMember/:i164HubMemberUUID",
					name: "networkHubSelectedMember",
					initialTargetPath: "networkHub",
					component: NetworkHubPanel,
					meta: {
						mainMenuNodePath: ["networkHub"],
						accessibility: ONLY_AUTHORISED,
						permissionsRequired: ['networkHub.general.viewHub'],
					}
				}
				/**,{
					path: "networkHubSettingsHubMemberIdentity/:i164HubMemberUUID/:i164HubMemberIdentityUUID",
					name: "networkHubPanel",
					component: NetworkHubPanel,
					meta: {
						mainMenuNodePath: ["networkHubPanel"],
						accessibility: ONLY_AUTHORISED,
					}
				} */,{
					path: "networkHubApplication",
					name: "networkHubApplication",
					initialTargetPath: "networkHubApplication",
					component: NetworkHubApplication,
					meta: {
						mainMenuNodePath: ["networkHub"],
						accessibility: ONLY_AUTHORISED,
						permissionsRequired: ['networkHub.general.viewHub'],
					}
				}]
			},
			{
				path: "servicePassport",
				props: false,
				component: ChildHolder,
				meta: {
					mainMenuNodePath: ["servicePassport"],
					accessibility: ONLY_AUTHORISED,
				},
				children: [{
					path: "",
					name: "servicePassport",
					initialTargetPath: "servicePassport",
					component: ServicePassportMain,
					meta: {
						mainMenuNodePath: ["servicePassport"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "listServicePassports",
					name: "listServicePassports",
					initialTargetPath: "listServicePassports",
					component: ListServicePassports,
					meta: {
						mainMenuNodePath: ["servicePassport"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "gainCustomer",
					name: "gainCustomer",
					initialTargetPath: "gainCustomer",
					component: GainCustomer,
					meta: {
						mainMenuNodePath: ["servicePassport"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "gainCustomerMessage",
					name: "gainCustomerMessage",
					initialTargetPath: "gainCustomerMessage",
					component: GainCustomerMessage,
					meta: {
						mainMenuNodePath: ["servicePassport"],
						accessibility: ONLY_AUTHORISED,
					}
				}]
			},{
				path: "developer",
				name: "developer",
				props: false,
				component: DeveloperMain,
				meta: {
					mainMenuNodePath: ["developer"],
					accessibility: ONLY_AUTHORISED,
				}
			},{
				path: "content",
				props: false,				
				component: ChildHolder,
				meta: {
					mainMenuNodePath: ["contentManager"],
					accessibility: ONLY_AUTHORISED,
				},
				children: [{
					path: "",
					name: "contentFrontPage",
					initialTargetPath: "contentFrontPage",
					component: DocumentationFrontPage,
					meta: {
						mainMenuNodePath: ["content"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "contentManager",
					name: "contentManager",
					initialTargetPath: "contentManager",
					component: ListContent,
					meta: {
						mainMenuNodePath: ["contentManager"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "webPage/:contentPageInfoUUID",
					name: "webPage",
					initialTargetPath: "webPage",
					component: WebPagePage,
					meta: {
						mainMenuNodePath: ["content"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "article/:contentPageInfoUUID",
					name: "article",
					initialTargetPath: "article",
					component: ArticlePage,
					meta: {
						mainMenuNodePath: ["content"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "faq/:contentPageInfoUUID",
					name: "faq",
					initialTargetPath: "faq",
					component: FaqPage,
					meta: {
						mainMenuNodePath: ["content"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "command/:contentPageInfoUUID",
					name: "command",
					initialTargetPath: "command",
					component: CommandPage,
					meta: {
						mainMenuNodePath: ["content"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "help/:contentPageInfoUUID",
					name: "helpButton",
					initialTargetPath: "helpButton",
					component: HelpButtonPage,
					meta: {
						mainMenuNodePath: ["content"],
						accessibility: ONLY_AUTHORISED,
					}
				},{
					path: "splash/:contentPageInfoUUID",
					name: "splash",
					initialTargetPath: "splash",
					component: SplashPage,
					meta: {
						mainMenuNodePath: ["content"],
						accessibility: ONLY_AUTHORISED,
					}
				},
				
			]
			}
		],
	}, {
		path: "/quartermass/accessCodeVerification/:accessCode",
		props: false,
		component: QuartermassMain,
		meta: {
			mainMenuNodePath: ["quartermass"],
			accessibility: QUARANTINE,
		},
		children: [
			{
				path: "newUserEmailVerification",
				name: "newUserEmailVerification",
				initialTargetPath: "newUserEmailVerification",
				component: NewUserEmailVerification,
				meta: {
					lastName: 'default',
					lastParams: {},
					mainMenuNodePath: ["newUserEmailVerification"],
					accessibility: QUARANTINE,
				}
			}, {
				path: "forgottenPasswordEmailReset",
				name: "forgottenPasswordEmailReset",
				initialTargetPath: "forgottenPasswordEmailReset",
				component: ForgottenPasswordEmailReset,
				meta: {
					lastName: 'default',
					lastParams: {},
					mainMenuNodePath: ["forgottenPasswordEmailReset"],
					accessibility: QUARANTINE,
				}
			}, {
				path: "inviteUserToService",
				name: "inviteUserToService",
				initialTargetPath: "inviteUserToService",				
				component: InviteUserToService,
				meta: {
					lastName: 'default',
					lastParams: {},
					mainMenuNodePath: ["inviteUserToService"],
					accessibility: QUARANTINE,
				}
			}, {
				path: "inviteExistingUserToService",
				name: "inviteExistingUserToService",
				initialTargetPath: "inviteExistingUserToService",	
				component: InviteExistingUserToService,
				meta: {
					lastName: 'default',
					lastParams: {},
					mainMenuNodePath: ["inviteExistingUserToService"],
					accessibility: QUARANTINE,
				}
			}
		],
	},{ 
		path: '/contentLink', 
		component: PageNotFound,
		beforeEnter: (to, from) => {
		
      return false;
    },
	},{ path: '/:pathMatch(.*)*', component: PageNotFound },
]

const router = createRouter({
    history: createWebHashHistory("/"),
		mode:"hash",
    routes
});

router.beforeEach((to,from,next) => {
	const mainStore = useMainStore();
	
	if (to.path == '/contentLink') {
		console.log('contentLink '+to.query.id);
		mainStore.showContentUUID = to.query.id;
	}
	if (!(to.name === 'login')) {
		if (to.meta.lastName) {
			mainStore.lastName = to.meta.lastName;
			mainStore.lastParams = to.meta.lastParams;
		} else {
			mainStore.lastName = to.name;
			mainStore.lastParams = to.params;
		}
	}
	
	if(!mainStore.loggedIn  && !(to.name === 'login')) {   
		if (to.meta.accessibility == ONLY_AUTHORISED) {
    	next({name:'login'});
		} else {
			next();
		}
  } else next(); 
	
/**
	if (mainStore.loggedIn == false) {			
		if (to.meta.accessibility == ONLY_AUTHORISED) {	
			if (to.initialTargetPath) {
				mainStore.initialTargetPath = to.initialTargetPath;
			}	else {
				mainStore.initialTargetPath = to.name;
			}								
			mainStore.initialTargetPhoneServiceName = to.params.phoneServiceName;	
			console.log("Accessing Authorised Page "+to.name+" "+to.initialTarget+" in "+to.params.phoneServiceName)
			if (mainStore.appLoaded) {
				return "/login";
			}		
		}
	} */
});

router.afterEach((to) => {
    const mainStore = useMainStore();
		if (to.meta.mainMenuNodePath) {
			mainStore.selectedMainMenuNode = to.meta.mainMenuNodePath;
		}
    
		/**
		 * We can check this here as it allows us to set the state of the component
		 */		
		let userInRole = undefined;
		let userPermitted = undefined;
		if (to.meta.rolesAllowed) {					
			console.log('Requires Role '+to.meta.rolesAllowed);
			userInRole = permissions.isUserInAnyRole(to.meta.rolesAllowed);
			console.log("Does user have role "+userInRole);						
		} else {

		}
		if (to.meta.permissionsRequired) {
			console.log('Requires Permission '+to.meta.permissionsRequired);
			userPermitted = permissions.isUserPermitted(to.meta.permissionsRequired);
			console.log("Does user have permission "+userPermitted);					
		} else {

		}
		if (userInRole == undefined && userPermitted == undefined) {
			to.meta.allowedToView = true;
		} else {
			to.meta.allowedToView = (userInRole || userPermitted );
		}
		
		console.log('lastName '+mainStore.lastName+" and lastParams "+mainStore.lastParams);
});

export default router;