<template>
	<v-container class="pt-0">
		<v-tabs  v-model="state.tabsTop" class="mt-0">
			<v-tab name="selectTariffType"  :class="(state.tabsTop == 'selectTariffType') ? 'bg-active_tab_color':''">
				<p class="theme-font text-body-1">Type</p>
			</v-tab>
			<v-tab name="tariffDetails"  :class="(state.tabsTop== 'tariffDetails') ? 'bg-active_tab_color':''" v-if="state.tariffTypeComplete">
				<p class="theme-font text-body-1">Details</p>
			</v-tab>
		</v-tabs>
		<v-divider/>
		<v-window v-model="state.tabsTop">
			<v-window-item value="selectTariffType" class="full-height">
				<v-row>
					<v-col class="mt-2 mb-0 pb-0">					
						<v-radio-group
							v-model="state.tariffType"
							@focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)" 
							inline								
							>
							<v-radio
								label="Managed"
								value="managed"									
							>		
						</v-radio>						
							<v-radio
								label="Custom"
								value="custom"
							></v-radio>
							<p class="ma-2 text-body-2" v-if="state.tariffType == 'managed'">A managed tariff provides all the data and means you have to only worry about pricing destinations.</p>
							<p class="ma-2 text-body-2" v-if="state.tariffType == 'custom'">A custom tariff requires all number data and pricing.</p>
						</v-radio-group>
					</v-col>
				</v-row>
				<v-row v-if="state.tariffType == 'managed'">
					<v-col class="mt-0">		
						<v-divider/>			
						<v-row>
							<v-col class="mt-2">
								<p class="text-body-2">These are the managed tariffs available for you.</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="mt-0 pt-0">								
								<v-radio-group
										v-model="state.parentTariffName"											
										@focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)" 																			
										>
										<v-radio
											label="Magrathea Direct"
											value="Magrathea Direct"																
										></v-radio>										

										<v-radio
											label="Magrathea Gold"
											value="Magrathea Gold"
										></v-radio>
									</v-radio-group>

							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row v-if="state.tariffType == 'custom'">
					<v-col>		

					</v-col>
				</v-row>
				<v-row>
					<v-col class="mb-3">
						<v-spacer align="right">
							<v-btn :disabled="!state.tariffTypeComplete" :class="(state.tariffTypeComplete) ? 'theme-bg-highlight' : ''" @click="showTab('tariffDetails');">Next</v-btn>
						</v-spacer>
					</v-col>
				</v-row>
			</v-window-item>
			<v-window-item value="tariffDetails" >
				<v-row>
					<v-col>
						<p class="theme-font mt-2 text-h5" v-if="state.tariffType == 'managed'">Managed Tariff</p>
						<p class="theme-font mt-2 text-h5" v-if="state.tariffType == 'custom'">Custom Tariff</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field  @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)"  @keyup.native.enter="validInput(this)" label="Tariff Name" variant="outlined" v-model="state.tariff.tariffName"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-textarea @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)" variant="outlined" label="Tariff Description" hide-details="auto" v-model="state.tariff.tariffDescription"></v-textarea>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-text-field  @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)"  @keyup.native.enter="validInput(this)" label="Minimum Charge (pence)" variant="outlined" v-model="state.tariff.minimumCharge"></v-text-field>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-select
							v-model="state.tariff.pricePer"
							:items="state.values.pricePer"
							variant="outlined"
							label="Price Per"
							item-title="title"
							item-value="value"	
							@update:modelValue="valid(this)"	
						>
						</v-select>
					</v-col>
				</v-row>
				<v-row>
					<v-col class="mb-3">
						<v-row>
							<v-col>								
									<v-btn :class="(state.tariffTypeComplete) ? 'theme-bg-highlight' : ''" @click="showTab('selectTariffType');">Back</v-btn>								
							</v-col>
							<v-col>
								<v-spacer align="right">
									<v-btn :disabled="!state.dataReady" :class="(state.tariffTypeComplete) ? 'theme-bg-highlight' : ''" @click="createTariff();">Create</v-btn>
						</v-spacer>
							</v-col>
						</v-row>
						
					</v-col>
				</v-row>
			</v-window-item>
		</v-window>		
		
	</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import uuid from 'uuid-random';


	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const props = defineProps({
			tariff: {
					type: Object,
					required: false,
			}
    });

	const state = reactive({
		dataReady: false,
		tabsTop: 'selectTariffType',
		tariffTypeComplete: false,
		tariffType: 'managed',
		parentTariffUUID: undefined,
		parentTariffName: undefined,
		tariff: {		
			primaryUUID: undefined,
			tariffType: undefined,	
			startDate: undefined,
			endDate: undefined,			
			tariffName: undefined,
			tariffDescription : undefined,
			minimumCharge: undefined,
			pricePer: undefined,
		},
		values: {
			pricePer: [
				{value: 1 , title:'Per Second'},
				{value: 2, title:'Per Minute'},					
			]
		}		
	});


	
const emits = defineEmits(['dataEvents']);

const fireDataReadyEvent = (event) => {			
	const tariff =  {
		primaryUUID: uuid(),
		parentTariffUUID: state.parentTariffUUID,
		parentTariffName: state.parentTariffName,
		tariffType:  state.tariffType,	
		startDate: state.tariff.startDate,
		endDate: state.tariff.endDate,			
		tariffName: state.tariff.tariffName,
		tariffDescription : state.tariff.tariffDescription,
		minimumCharge: state.tariff.minimumCharge,
		pricePer: state.tariff.pricePer,
	}	
	emits('dataEvents',
		{
			state: 'ready',
			tariff: tariff,
		}
	);
}

	const createTariff = () => {
		fireDataReadyEvent();
	}

	const showTab = (tabName) => {
		state.tabsTop = tabName;
	}

	const valid = () =>{
		if (state.tariffType == 'custom') {
			state.tariffTypeComplete = true;
		} else {
			if (state.parentTariffName) {
				state.tariffTypeComplete = true;
			} else {
				state.tariffTypeComplete = false;
			}
		}
	
		if (!state.tariff.tariffName) {
			state.dataReady = false;
			return;
		}
		if (!state.tariff.minimumCharge) {
			state.dataReady = false;
			return;
		}
		if (!state.tariff.pricePer) {
			state.dataReady = false;
			return;
		}
		state.dataReady = true;
	}

	onUpdated(() => {			
		if (props.tariff) {
			state.tariff.tariffName = props.tariff.tariffName;
		}
	});


	onMounted(() => {		
		if (props.tariff) {
			state.tariff.tariffName = props.tariff.tariffName;
		}
	});

</script>

<style lang="scss">

.v-label {
	opacity: unset;
}

.v-input__details {
	min-height: unset;
}

.v-messages {
	min-height: unset;
}

</style>