<template><v-hover v-if="state.onboarding" >
 <template v-slot:default="{ isHovering, props }">
	<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
		<BoxHeader headerText="Device Details" :dataReady="state.dataReady" readyIcon="check_circle"/>
		<v-card-text>
			<v-row v-if="state.deviceConfiguration.deviceRequests.length > 0">
				<v-col class="ma-5">
					<v-row>
						<v-col class="d-flex">
							<p class="theme-font-other font-weight-bold">Requested Phone(s)</p><v-spacer  align="right"><v-btn size="x-small" @click="removeDevice(this)" v-if="!state.readOnly">CHANGE</v-btn></v-spacer>
						</v-col>					
					</v-row>
					<v-row>												
						<v-col class="ml-5">
							<p class="theme-font-other" v-for="selectedDevice,index in state.deviceConfiguration.deviceRequests"  :key="index">{{ t("statics.devices.deviceType."+selectedDevice.deviceType)}}</p>
						</v-col>											
					</v-row>												
				</v-col>
			</v-row>
			<v-row v-if="state.deviceConfiguration.deviceRequests.length == 0 || (state.selectionMode =='multiple')">
				<v-col class="ma-5">				
					<v-row >
						<v-col>
							<p class="theme-font">Select a Device Type</p>
							<v-list>
								<v-list-item v-for="device, index in state.availableDevices" :key="index">
									<template v-slot:prepend>
										<v-icon icon="device_hub" size="16" class="mr-3"></v-icon>
									</template>
									<v-list-item-title class="theme-pointer theme-font-other" @click="selectDevice(device)">{{ t("statics.devices.deviceType."+device.deviceType)}}</v-list-item-title>
								</v-list-item>
							</v-list>
			
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row v-if="state.deviceAssets">
				<v-col class="ma-5">
					<v-row>
						<v-col class="d-flex">
							<p class="theme-font-other font-weight-bold"><v-icon class="text-lifecycle_timeline_active_color mt-0 mr-2">device_hub</v-icon> Device Created</p>
						</v-col>					
					</v-row>
					<v-row>												
						<v-col class="d-flex">
							<v-list>
								<v-list-item v-for="device,index in state.deviceAssets"  :key="index">								
								<v-list-item-title class="theme-pointer theme-font-other">UUID: {{ device.primaryUUID }} </v-list-item-title>
							</v-list-item>
						</v-list>					
						</v-col>											
					</v-row>												
				</v-col>
				
			</v-row>
		</v-card-text>
	</v-card>
	</template>
</v-hover>
	<v-card v-else>
		<v-card-text>Initialising</v-card-text>
	</v-card>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
import BoxHeader from '@/components/BoxHeader.vue';

	const me = this;

	const { t }  = useI18n();
	
	const route = useRoute();

	const mainStore = useMainStore();
	
	const state = reactive({
		onboarding: undefined,
		refreshVersion: 0,
		dataReady: false,
		readOnly: false,
		selectionMode: 'single',
		deviceConfiguration: {			
			deviceRequests: [],
		},		
		availableDevices: [
			{			
				deviceType: 1000,
			}
		],
		deviceAssets: undefined,
	});

	const emits = defineEmits(['dataEvents']);

	const fireDataReadyEvent = (event) => {		
		emits('dataEvents',
			{
				state: 'ready',
				deviceRequests: state.deviceConfiguration.deviceRequests,
			}
		);
	}

	const fireDataNotReadyEvent = (event) => {
			emits('dataEvents',
				{
					state: 'notReady',
				deviceRequests: state.deviceConfiguration.deviceRequests,
				}
			);
	}


	const props = defineProps({		
		refreshConfiguration: {
			type: Object,
			required: true,
		},
		selectionMode: String,		
		deviceConfiguration: {
			type: Object,
			required: true
		},	
		availableDevices: {
			type: Array,
			required: false,
		},
		assetData: {
			type: Array,			
		},
		readOnly: Boolean,
	});
	

	const removeDevice = (index) => {
		state.deviceConfiguration.deviceRequests.splice(index, 1);		
		state.dataReady = false;
		fireDataNotReadyEvent();		
	}

	const selectDevice = (device) => {
		state.deviceConfiguration.deviceRequests.push(device);
		state.dataReady = true;
		fireDataReadyEvent();	
		saveData();	
	}

	const saveData = () => {
			
	}

	const refreshState = () => {
		if (props.readOnly) {
				state.readOnly = props.readOnly;
		}
		if (props.availableDevices) {
			state.availableDevices = props.availableDevices;
		} 
		if (props.selectionMode) {
			state.selectionMode = props.selectionMode;
		}
		
		if (props.deviceConfiguration.deviceRequests && props.deviceConfiguration.deviceRequests.length == 1) {			
			state.deviceConfiguration.deviceRequests = props.deviceConfiguration.deviceRequests;
			state.dataReady = true;
			saveData();	
			fireDataReadyEvent();			
		}
		if (props.assetData) {
			console.log('Found Devices');
			state.deviceAssets = props.assetData;
		}
		
	}

	onUpdated(() => {
		if (props.refreshConfiguration.version > state.refreshVersion) {
			state.refreshVersion = props.refreshConfiguration.version;
			if (props.refreshConfiguration.onboarding) {				
				state.onboarding = props.refreshConfiguration.onboarding;
			}	
			console.log("onUpdated Devices ");
			refreshState();
		}
	});

	onMounted(() => {
		refreshState();
	});

</script>

<style scoped>


</style>