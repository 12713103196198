<template>
	<div class="theme-mainPanel fill-height">
		<v-container>
		<v-row class="mb-5 fill-height" >
			<v-col cols="12" sm="6" >
				<v-spacer class="theme-bg-off-color fill-height ma-5 pa-5">
					<v-row>
					<v-col class="ma-5" align="center">
						<v-card class="theme-dark-background-gradient" width="180" align="center">
							<v-spacer><v-img :src="i164Transparent" width="160" height="72" class="pa-1"></v-img></v-spacer>
						</v-card>
					</v-col>
				</v-row>	
					<v-row>
						<v-col>
							<p class="text-h5 theme-font">Sign up to i164 Wholesale</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col>
							<p class="theme-font-other">
								Create a user account using the form on this page. Once complete you can create a service account to use the following services.
							</p>	
							<p class="theme-font mt-2 text-h6">Service Passport</p>	
							<p class="theme-font-other mt-2 ml-1">For telecom retailers that want a ready to go switching platform. Service Passport is compliant with the OTS consumer regulations and follows the GPBL process for business swicthing.</p>
							
							<p class="theme-font mt-5 text-h6">i164 Wholesale VoIP Line Platform</p>	
							<p class="theme-font-other mt-2 ml-1">Designed as an instant solution for anyone that wants to offer their own basic VoIP telephone line services.</p>
																	
						</v-col>
					</v-row>
				</v-spacer>
			</v-col>
			<v-col cols="12" sm="6" >				
				<v-form @keyup.enter="createAccount()">
					<v-card  class="card-shadow border-radius-md box" >
						<v-card-text v-if="!state.requested">
							<v-row>
								<v-col class="ma-5"><p class="text-h5 theme-font">Account Sign Up</p></v-col>
							</v-row>
							<v-row >
								<v-col v-if="state.requestOutcome == -2" align="center" class="mb-4">
									<p><v-icon class="text-red">warning</v-icon> Missing Parameters</p>
								</v-col>
								<v-col v-if="state.requestOutcome == 912" align="center" class="mb-4">
									<p><v-icon class="text-red">warning</v-icon>Login Server Unavailable</p>
								</v-col>
								<v-col v-if="state.requestOutcome == 904" align="center" class="mb-4">
									<p><v-icon class="text-red">warning</v-icon>A User with that email already exists.</p>
								</v-col>
								<v-col v-if="state.requestOutcome == -99998 || state.requestOutcome == -1" align="center" class="mb-4">
									<p><v-icon class="text-red">warning</v-icon>Server Error</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-row>
										<v-col>
											<v-text-field id="firstName" name="firstName" label="First Name*" type="text" v-model="state.userForm.firstName" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>
										</v-col>
										<v-col>
											<v-text-field id="lastName" name="lastName" label="Last Name*" type="text"  v-model="state.userForm.lastName" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>
										</v-col>
									</v-row>									
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field label="Email Address*" hide-details="auto" autocomplete="username" v-model="state.userForm.emailAddress" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="mt-6">
									<v-text-field id="password" name="password" label="Password*" type="password" autocomplete="current-password"  v-model="state.userForm.password" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>
									<p class="text-caption text-center">Length longer than 8 with at least one special character.</p>
									<PasswordBar :password="state.userForm.password" @dataEvents="passwordReady"/>											
								</v-col>
							</v-row>
							<v-row>
								<v-col class="mt-0">
									<v-text-field id="password2" name="password2" label="Confirm Password*" type="password" autocomplete="current-password"  v-model="state.userForm.password2" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="text-center">
									<v-btn @click="createAccount()" :disabled="!state.dataReady" :class="(state.dataReady) ? 'theme-bg-highlight':''">Create Account</v-btn>
								</v-col>
							</v-row>
							<v-row>
							<v-col class="ml-2 mt-2 d-flex">
								<p class="text-caption bg-white">Already have an account?</p>  <p class="ml-2 pl-2 pr-2 text-caption text-decoration-underline bg-white theme-pointer" @click="goToLogin()">Sign in</p>
							</v-col>
						</v-row>
						</v-card-text>
						<v-card-text v-if="state.requested">
							<v-row>
								<v-col class="ma-5"><p class="text-h5 theme-font">Account Sign Up</p></v-col>
							</v-row>
							<v-row >
								<v-col>
									<p class="text-body-2 ma-2">Your user account is nearly ready.</p>
									<p class="text-body-2 ma-2">We just need you to verify your email address. </p>
									<p class="text-body-2 ma-2">We have sent an email to <b>{{ state.userForm.emailAddress }}</b> with a link that you can use to complete the set-up of your account.</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="ml-2 mt-2 d-flex">
									<p class="text-caption bg-white">When you are ready.</p>  <p class="ml-2 pl-2 pr-2 text-caption text-decoration-underline bg-white theme-pointer" @click="goToLogin()">Sign in</p>
								</v-col>
							</v-row>
						</v-card-text>						
					</v-card>					
				</v-form>
				
			</v-col>
		</v-row>			
	</v-container>		
		<v-footer app class="vx-off-color-background">
			<v-row>				
				<v-col><v-img :src="servedUpLogo" max-width="150"  class="align-right"></v-img></v-col>
				<v-col><div style="text-align:right;">&copy; {{ getCopyDate() }} Served Up Ltd</div></v-col>
			</v-row>
		</v-footer>
</div>
</template>

<script setup>
import { inject, ref, reactive, onMounted,onUnmounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '../stores/MainStore.js';
import voxhubLogo from '@/assets/images/voxhub_greenOnWhite.svg';
import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
import i164Transparent from '@/assets/images/i164_transparent.png';
import { useI18n } from 'vue-i18n';


import PasswordBar from './plustag/components/PasswordBar.vue';

const { t }  = useI18n();



const mainStore = useMainStore();

const router = useRouter();

const passwordReady = (event) => {
	if (event) {
		state.passwordPassed = (event.state == 'ready');
	} else {
		state.passwordPassed = false;
	}
	
}

import { useDate } from 'vuetify/labs/date';
const getCopyDate = () => {
	const date = useDate();
	return date.getYear(new Date());
}

const state = reactive({
		dataReady: false,
		requested: false,	
		requestOutcome: 0,					
		userForm: {	
			firstName: "",
			lastName: "",				
			emailAddress: "",
			password: "",
			password2: "",
		},
		passwordPassed: false,		
});



const valid = () => {
	if (state.userForm.firstName && state.userForm.lastName && state.userForm.emailAddress && state.userForm.password && state.userForm.password2) {
		const emailValid = state.userForm.emailAddress.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		if (state.passwordPassed && emailValid && (state.userForm.password == state.userForm.password2)) {
			state.dataReady = true;
			return true;
		}	
 	}
	 state.dataReady = false;
	 return false;
}

const goToLogin = () => {
	router.push({ name: 'login'});
}

const createAccount = async () => {	
			const data = new URLSearchParams();
			data.set('emailAddress', state.userForm.emailAddress);
			data.set('password', state.userForm.password);
			data.set('firstName', state.userForm.firstName);
			data.set('lastName', state.userForm.lastName);					
				await fetch(mainStore.getBaseUrl('plustag')+'/registerNewAccount', {
					method: 'POST',
					body: data,
					credentials: 'include',
    			redirect: 'follow',																									
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)		
							state.requestOutcome = d.outcome;					
							if (d.outcome == 1) {
								console.log("Account Created");	
								state.requested = true;															
							} else if (d.outcome == -1) {//Internal Failure

							} else if (d.outcome == -2) {//Missing Parameters

							} else if (d.outcome == 912) {//password not good

							} else if (d.outcome == 904) {//User already exists
							
							} else if (d.outcome == -99998) {//Internal Error
							
							} else {
								state.requestOutcome = d.outcome;
							}
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
					
			});		
		}

onMounted(() => {
 console.log('Mounted Register Form');
	if (mainStore.loggedIn) {
		console.log("Redirecting to Plustag as User is Logged In");
		router.push({name: 'plustag'});
	} else {
		mainStore.pollingForLogin = true;	//Forces turn off of polling
	}
});

onUnmounted(() => {
			console.log('UnMounted Register Form');	
			mainStore.pollingForLogin = false;	//Forces turn off of polling	
});
</script>

<style scoped>
	.box {
		border: 1px solid rgb(var(--v-theme-box_border_color));	
	}

</style>
