import { createI18n } from 'vue-i18n'

const messages = {
    en: {
      appTitle: "i164 Assembler",
		  
			sectionHeadings: {
				userProfile: "Your Profile",
				userParticipation: "Users",
				dashboard: "Dashboard",
				account: "Account Home",
				voxletServiceContexts: "Accounts",
				devices: "Devices",
				lines: "Telephone Lines",
				numbers: "Numbers",
				billing: "Call Billing",
				branding: "Branding",
				teamsRouter: "Microsoft Teams",
				servicePassport: "Service Passport",
				gainCustomer: "Gain Customer",
				doServiceSwitch: "",
				listServicePassports: "List Passports",
				servicePassportDashboard: "Passport Dashboard",
				networkHub: "Network Hub",
				networkHubDashboard: "Hub Dashboard",			
				networkHubPanel: "Control Panel",	
				networkHubApplication: "Industry Hub Application",	
				plustagServices: "Your Services",
				plustagAccount: "Your Accounts",
				content: "Content",
				contentManager: "Manage",
				developer: "Developer",
				pstn: "PSTN Providers",
			},
			statics: {
				voxletServiceContextTypes: {
					"customerAccount": "Customer",
					"projectAccount": "Project",
					"stockAccount": "Stock",
				},
				phoneService: {
					status: {
						"0": "Disabled",		
						"1": "Live",
						"2": "Archived",
						"3": "Provisioning",
						"4": "Awaiting Approval",
						"5": "Incomplete",
					},
					phoneServiceType: {
						1: "Operator",
						2: "Plustag",
						3: "Service",
					}
				},
				lifecyclePhases: {
					user: {
						"preCommitment": "Invited",
						"ready": "Live",
					},
					device: {
						"preCommitment": "Created",
						"ready": "Live",
						"ended": "Ended"
					},					
					number: {
						"preCommitment": "Created",
						"ready": "Live",
						"ended": "Ended"
					},
					basicLine : {
						"preCommitment": "Created",
						"ready": "Live",
						"ended": "Ended"
					}
				},
				appIcons: {
					phoneServices: "account_balance",
					networkHub: "hub",
					servicePassport: "summarize",
					voxletLines: "dialer_sip",
				},
				appNames: {
					phoneServices: "",
					networkHub: "",
					servicePassport: "Service Passport",
					voxletLines: "Lines",
					lines: "Lines",
					developer: "Developer",
				},
				roles: {
					operational_root_owner: "Operator",
					operational_operator_owner: "Operator",
					operational_phoneService_owner: "Account Owner",
					operational_phoneService_admin: "Administrator",
					operational_phoneService_user: "Account User",
				},
				permissions: {
					serviceBilling_invoices_view: "Service Billing: View Invoice Permission",
					networkHub_general_viewHub: "View Network Hub",
					phoneServices_participation_manage: "Phone Service: Manage Participation",
					microAccounts_general_view: "Micro Services: View Accounts",
					microDevices_general_view: "Micro Services: View Devices",
					microNumbers_general_view: "Micro Services: View Numbers",
					microUsageAccounts_general_view: "Micro Services: View Usage Accounts",
				},
				quotas: {
					user_core_phoneService_createService: 'Number of Services a User can Create',
					service_networkHub_telecoms_createHub:'Create a Network Hub',
					service_networkHub_telecoms_createMemberIdentity:'Number of Member Identities',
					service_servicePassport_consumer_access:'Access to Consumer Service Passport Process',
					service_servicePassport_consumer_passportCount:'Service Passport Count',
					service_servicePassport_business_access:'Access to Business Service Passport Process',
					service_lines_access:'Access to Lines Service',
					service_lines_basicLine_lineCount:'Max Live Basic Lines',
					service_content_access:'Access to Content Service',
					service_content_documentCount:'Number of Documents',
				},
				cardBrands: {
					visa: "Visa",
					mastercard: "Mastercard",
					amex: "American Express",
					discover: "Discover",
					diners: "Diners",	
					unionpay: "Union Pay",	
				},
				cardTypes: {
					credit: "Credit",
					debit: "Debit",
				},
				voxletApps: {
					"i164.lines.basicLine": "Basic Line"
				},
				devices: {
					deviceType: {
						1000: "Router Port",
						1001: "Mobile App",
					}
				},
				numbers: {
					countryCodes: {
						44: "UK",
						33: "France",
					},
					areaCodes: {
						1582: "Luton",
						207: "London (Inner)",
						208: "London (Outer)",
					}
				},
				industryHub: {
					processes: {
						all: "Business and Consumer",
						business: "Business Only",
						consumer: "Consumer Only"
					}
				},
				accountType: {
					1: 'Business',
					2: 'Personal',
				},
				tariff: {
					pricePer: {
						1: 'Per Second',
						2: 'Per Minute',	
					},
				},	
				bundle: {
					bundleType: {
						"plan": 'Monthly Plan',
						"addon": 'Add On',	
					},
				},			
				commandOutcomes: {
					1: "Success",
					930: "Your Existing Password is Incorrect",
				}
			}

    }
}

const i18n = createI18n({
    legacy: false,
    locale: "en",
    fallbackLocale: "en",
    messages
});

export default i18n;