<template>
	<OuterCard>
		<v-row>
			<v-col v-if="state.i164HubMemberEndpointGroup" class="pa-0">
				<BoxHeader :headerText="state.i164HubMemberEndpointGroup.endpointGroupName" >
					<template v-slot:action>
						<v-spacer align="right" class="mt-2 mr-2"><v-btn  @click="addNewEndpoint()" size="small" class="pl-2 theme-list-action-button-background  text-action_button_text mr-5"><v-icon class="theme-list-action-icon">add</v-icon>Endpoint</v-btn></v-spacer>					
					</template>
				</BoxHeader>
			<v-row>
				<v-col class="ma-5 mt-0">
				
						<v-row>
							<v-col class="ml-0">
								<v-table  class="listTable">														
									<thead class="bg-vx_green_900">
										<tr>
											<!--<th class="text-center text-body-2 theme-mini-listing-header-text">Active</th>-->
											<th class="text-center text-body-2 theme-mini-listing-header-text">Priority</th>
											<th class="text-center text-body-2 theme-mini-listing-header-text" >Name</th>
															
											<th class="text-center text-body theme-mini-listing-header-text"></th>
										</tr>							
									</thead>						
									<tbody>
										<tr v-for="i164HubMemberEndpoint,index in state.i164HubMemberEndpointGroup.i164HubMemberEndpoints" :key="index">
											
												<!--<td class="text-center"><v-icon v-if="i164HubMemberEndpoint.active" class="text-success">check_circle</v-icon><v-icon v-if="!i164HubMemberEndpoint.active" class="text-failure">cancel</v-icon></td>-->
												<td class="text-center" ><v-icon :class="i164HubMemberEndpoint.active ? 'text-success':'text-failure'" :icon="getPriorityIconName(i164HubMemberEndpoint.priority)"></v-icon></td>
												<td class="text-center">{{ i164HubMemberEndpoint.endpointDetailName }}</td>
												
												
												<td class="text-center">
												<v-menu location="center">
														<template v-slot:activator="{ props }">
															<v-icon v-bind="props">menu</v-icon>
														</template>
														
														<v-list>	
															<v-list-item>
																<v-list-item-title class="theme-pointer" @click="viewEndpoint(i164HubMemberEndpoint);">View</v-list-item-title>
															</v-list-item>											
															<v-list-item>
																<v-list-item-title class="theme-pointer" @click="editEndpoint(i164HubMemberEndpoint);">Edit</v-list-item-title>
															</v-list-item>
															<v-list-item>
																<v-list-item-title class="theme-pointer" @click="deleteExistingI164HubMemberEndpoint(i164HubMemberEndpoint.primaryUUID);">Delete</v-list-item-title>
															</v-list-item>

														</v-list>
													
													</v-menu>	
												</td>
											
										</tr>
															
									</tbody>							
								</v-table>
													
							</v-col>
						</v-row>
						<v-row v-if="state.i164HubMemberEndpoint && state.mode == 'listOnly'">
							<v-col>
								<v-divider class="mb-5"/>
								<v-row class="bg-vx_green_900"  cols="12">
									<v-col class=" pl-5 d-flex"><p class="theme-font text-h5"> Viewing Endpoint ({{ state.i164HubMemberEndpoint.endpointDetailName }})</p>
										<v-spacer align="right">								
											<v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text ml-5" @click="hideEndpoint()">Close</v-btn>
										</v-spacer>
									</v-col>
								</v-row>
								<v-row>
									<v-col cols="12" ><v-divider class="mb-5"/></v-col>
								</v-row>						
								<v-row>
									<v-col cols="2" class="theme-font text-body-2  font-weight-bold">Priority</v-col>
									<v-col cols="10"> <v-icon :class="state.i164HubMemberEndpoint.active ? 'text-success':'text-failure'" :icon="getPriorityIconName(state.i164HubMemberEndpoint.priority)"></v-icon></v-col>
								</v-row>
								<v-row>
									<v-col cols="2" class="theme-font text-body-2  font-weight-bold">Name</v-col>
									<v-col cols="10"> {{ state.i164HubMemberEndpoint.endpointDetailName }} </v-col>
								</v-row>
								<v-row>
									<v-col cols="2" class="theme-font text-body-2  font-weight-bold">Url</v-col>
									<v-col cols="10" class="text-caption"> {{ state.i164HubMemberEndpoint.endpointUrl}} </v-col>
								</v-row>
								<v-row>
									<v-col cols="2" class="theme-font text-body-2 font-weight-bold">Key</v-col>
									<v-col cols="10" class="d-flex">
										<p v-if="state.authenticationKey">{{ state.authenticationKey }}</p>
										<p v-else><p class="font-italic">KEY HIDDEN</p></p>
										<v-spacer align="right">
											<v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text ml-5" @click="hideEndpointKey()" v-if="state.authenticationKey">Hide</v-btn>
											<v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text ml-5" @click="revealEndpointKey(state.i164HubMemberEndpoint.primaryUUID)" v-else>Reveal</v-btn>
										</v-spacer>
									</v-col>
																							
								</v-row>
							</v-col>								
						</v-row>
							
						<v-row v-if="state.mode == 'addNew'||state.mode == 'edit'">
							<v-col class="">
								<v-divider class="mb-5"/>
								<v-row :class="state.i164HubMemberEndpointDataReady ? 'bg-vx_green_900' : ''">							
									<v-col class="d-flex pa-1 pl-5">
										
										<v-icon :class="state.i164HubMemberEndpointDataReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon>
										<p class="theme-font text-h5" v-if="state.mode == 'addNew'">Create New Endpoint</p>
										<p  class="theme-font text-h5" v-if="state.mode == 'edit'">Update Endpoint Details</p>								
									</v-col>		
								</v-row>
								<v-row>
									<v-col><v-divider /></v-col>
								</v-row>
								<v-row>
									<v-col><v-text-field variant="outlined" label="Endpoint Name *" hide-details="auto" v-model="state.i164HubMemberEndpoint.endpointDetailName" @update:modelValue="isEndpointValid()"></v-text-field></v-col>
								</v-row>
								<v-row>		
									<v-col><v-select variant="outlined" :items="state.values.i164Endpoint" label="Endpoint Type" item-title="title"
									item-value="value" 
									v-model="state.i164HubMemberEndpoint.i164Endpoint" @update:modelValue="changeEndpointType();isEndpointValid()" hide-details="true"></v-select></v-col>
								</v-row>
								<v-row>							
									<v-col><v-text-field variant="outlined" label="Endpoint Url *" hide-details="auto" v-model="state.i164HubMemberEndpoint.endpointUrl" :readonly="state.i164HubMemberEndpoint.i164Endpoint" @update:modelValue="isEndpointValid()"></v-text-field></v-col>
								</v-row>
								<v-row>
									<v-col><v-select variant="outlined" :items="state.values.priority" label="Priority" item-title="title"
									item-value="value" 
									v-model="state.i164HubMemberEndpoint.priority"  @update:modelValue="isEndpointValid()" hide-details="true"></v-select></v-col>						
									<v-col><v-select variant="outlined" :items="state.values.active" label="Active" item-title="title"
									item-value="value" 
									v-model="state.i164HubMemberEndpoint.active" @update:modelValue="isEndpointValid()" hide-details="true"></v-select></v-col>
								</v-row>
								<v-row>
									<v-col align="center">								
										<v-btn @click="resetForm()">Cancel</v-btn>
									</v-col>
									<v-col align="center">								
										<v-btn :class="state.i164HubMemberEndpointDataReady ? 'theme-bg-highlight' : ''" :disabled="!state.i164HubMemberEndpointDataReady">
											<p v-if="state.mode == 'addNew'" @click="createNewI164HubMemberEndpoint()">Create</p>
											<p v-if="state.mode == 'edit'" @click="updateExistingI164HubMemberEndpoint()">Update</p>									
											
										</v-btn>
									</v-col>							
								</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
			<v-col v-else>
				<v-row>
					<p class="theme-font-other"><v-progress-circular
										indeterminate
										color="rgb(var(--v-theme-loading_spinner_color))"									
									></v-progress-circular></p>
				</v-row>
			</v-col>


		</v-row>
				
			
	</OuterCard>
	
</template>
<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import OuterCard from '@/components/common/OuterPopCard.vue';
import BoxHeader from '@/components/BoxHeader.vue';

	const mainStore = useMainStore();


	const props = defineProps({
		refreshConfiguration: {
			type: Object,
			required: true,
		},	
		configuration: {
			type: Object,
			required: true
		},
		assetData: {
			type: Object,			
		},		
		readOnly: Boolean,
	});

	/**
	 * mode - listOnly
	 * mode - addNew
	 * mode - edit
	 */
	const state = reactive({
		onboarding: undefined,
		refreshVersion: 0,
		readOnly: false,
		dataReady: false,
		i164HubMemberEndpointGroup: undefined,
		i164HubMemberEndpoint: undefined,
		i164HubMemberEndpointDataReady: false,
		mode: 'listOnly',
		values: {
			i164Endpoint: [
				{value: true, title:'Service Passport'},
				{value: false, title:'Custom'}			
			],
			priority: [
				{value: 1, title:'1 - Primary'},
				{value: 2, title:'2 - Secondary'}		
			],
			active: [
				{value: true, title:'Active'},
				{value: false, title:'Disabled'}		
			]
		},		
		authenticationKey: undefined,
	});

	const emits = defineEmits(['dataEvents']);

const fireDataRefreshEvent = (event) => {		
	emits('dataEvents',
		{
			state: 'refreshRequired'
		}
	);
}



	/**
	 * For the display of the priority icon
	 * @param {*} num 
	 */
	const getPriorityIconName = (num) => {
		switch (num){
				case 1:
					return 'looks_one';					
				case 2:
					return 'looks_two';					
				default:
					return 'looks_'+num;
		}
	}

	const viewEndpoint = (i164HubMemberEndpoint) => {
		state.i164HubMemberEndpoint = i164HubMemberEndpoint;
	}

	const hideEndpoint = () => {
		state.i164HubMemberEndpoint = undefined;
	}

	const revealEndpointKey = (i164HubMemberEndpointUUID) => {
		revealEndpointKeyRequest(i164HubMemberEndpointUUID);
	}

	const hideEndpointKey = () => {
		state.authenticationKey = undefined;
	}

	const resetForm = () => {
		state.mode = 'listOnly';
		state.i164HubMemberEndpoint = undefined;
		state.i164HubMemberEndpointDataReady = false;		
	}

	const addNewEndpoint = () => {
		state.i164HubMemberEndpoint = {
			active: false,
			priority: 1,
		};
		state.mode = 'addNew';
	}

	const editEndpoint = (i164HubMemberEndpoint) => {
		state.i164HubMemberEndpoint = JSON.parse(JSON.stringify(i164HubMemberEndpoint));
		state.mode = 'edit';
		isEndpointValid();
	}

	const isEndpointValid = () => {		
		let valid = true;
		if (state.i164HubMemberEndpoint) {
			if (state.i164HubMemberEndpoint.endpointDetailName && state.i164HubMemberEndpoint.endpointDetailName.length > 0 ) {

			} else {
				valid = false;
			}
			if (state.i164HubMemberEndpoint.endpointUrl && state.i164HubMemberEndpoint.endpointUrl.length > 0) {

			} else {
				valid = false;
			}
			if (state.i164HubMemberEndpoint.i164Endpoint != undefined) {

			} else {
				valid = false;
			}
		} else {		
			valid = false;
		}
		state.i164HubMemberEndpointDataReady = valid;
		return valid;		
	}

	const createNewI164HubMemberEndpoint = () => {
		if (isEndpointValid()) {
			console.log("Adding addI164HubMemberEndpoint");
			addI164HubMemberEndpoint();
		} else {
			console.log("Cannot addI164HubMemberEndpoint");
		}
	}

	const updateExistingI164HubMemberEndpoint = () => {		
			console.log("Updating updateI164HubMemberEndpoint");
			updateI164HubMemberEndpoint();		
	}

	const deleteExistingI164HubMemberEndpoint = (i164HubMemberEndpointUUID) => {

			console.log("Deleting deleteI164HubMemberEndpoint");
			deleteI164HubMemberEndpoint(i164HubMemberEndpointUUID);

	}
	
	const deleteI164HubMemberEndpoint= async (i164HubMemberEndpointUUID) => {
		const data = new URLSearchParams();
		data.set('i164HubMemberEndpointUUID', i164HubMemberEndpointUUID);	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/deleteI164HubMemberEndpoint', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {	
								resetForm();						
								fireDataRefreshEvent();
								mainStore.displaySnackBar('Deleted Endpoint');									
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}

	
	const addI164HubMemberEndpoint= async () => {
		const data = new URLSearchParams();
		data.set('i164HubMemberEndpointGroupUUID', state.i164HubMemberEndpointGroup.primaryUUID);	
		data.set('i164HubMemberEndpoint', JSON.stringify(state.i164HubMemberEndpoint));	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/addI164HubMemberEndpoint', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {	
								resetForm();						
								fireDataRefreshEvent();
								mainStore.displaySnackBar('Added Endpoint');								
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}

		
	const updateI164HubMemberEndpoint = async () => {
		const data = new URLSearchParams();
		data.set('i164HubMemberEndpointUUID', state.i164HubMemberEndpoint.primaryUUID);	
		data.set('i164HubMemberEndpoint', JSON.stringify(state.i164HubMemberEndpoint));	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/updateI164HubMemberEndpoint', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {		
								resetForm();					
								fireDataRefreshEvent();
								mainStore.displaySnackBar('Updated Endpoint');								
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}


	const revealEndpointKeyRequest = async (i164HubMemberEndpointUUID) => {	
		const data = new URLSearchParams();
		data.set('i164HubMemberEndpointUUID', i164HubMemberEndpointUUID);		
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/revealEndpointAuthenticationKey', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {	
								state.authenticationKey = d.authenticationKey;
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}


	const changeEndpointType = () => {
		if (state.i164HubMemberEndpoint.i164Endpoint) {
			state.i164HubMemberEndpoint.endpointUrl = "Service Passport Managed";
		} else {
			state.i164HubMemberEndpoint.endpointUrl = undefined;
		}
	}

	const refreshState = () => {		
		if (props.assetData) {
			state.i164HubMemberEndpointGroup = props.assetData;	
			state.dataReady = true;		
		}
	}

	onUpdated(() => {				
		if (props.refreshConfiguration.version > state.refreshVersion) {
			state.refreshVersion = props.refreshConfiguration.version;
			if (props.refreshConfiguration.onboarding) {				
				state.onboarding = props.refreshConfiguration.onboarding;
			}				
			refreshState();
		}
	});

	onMounted(() => {
		refreshState();
	});

</script>

<style scoped lang="scss">
	$table-header-height: '26px';
</style>
