<template>
	<div class="theme-mainPanel fill-height" >					
			<v-row class="mb-5 fill-height" >
				<v-col md="4" ></v-col>
				<v-col md="4" class="fill-height d-flex align-center justify-center" >					
					<v-form @keyup.enter="doResetPassword()">
						<v-card  class="card-shadow border-radius-md box">
							<v-card-text v-if="state.commandOutcome && state.requestOutcome ==0">
								<v-row>
									<v-col class="ma-5"><v-img :src="servedUpLogo" min-width="150" ></v-img></v-col>
								</v-row>
								<v-row>
									<v-col class=""><p class="text-h5 theme-font">Reset your Account Password</p></v-col>
								</v-row>
								<v-row>
									<v-col class="">										
										<p v-if="state.commandOutcome.outcome == 909"><v-icon class="text-red">warning</v-icon> This password reset link has expired.</p>
										<p v-else><v-icon class="text-red mr-2">warning</v-icon> {{state.commandOutcome.message }}.</p>
								</v-col>
								</v-row>
								<v-row v-if="state.commandOutcome.outcome == 909">
									<v-col class="ml-2 mt-2 text-center">
										<p class="pl-2 pr-2 text-caption text-decoration-underline bg-white theme-pointer" @click="goToForgottenPassword()">Reset Password Again</p>
									</v-col>
								</v-row>
							</v-card-text>
							<v-card-text v-if="!state.requested && state.verificationOutcome == 1">
								<v-row>
									<v-col class="ma-5"><v-img :src="servedUpLogo" min-width="150" ></v-img></v-col>
								</v-row>
								<v-row>
									<v-col class=""><p class="text-h5 theme-font">Reset your Account Password</p></v-col>
								</v-row>
								<v-row>
									<v-col class=""><p class="text-body-2 theme-font-other">Please enter a new password for this account with email address <b>{{ state.emailAddress }}</b>.</p></v-col>
								</v-row>
								<v-row>
									<v-col v-if="state.requestOutcome == -1" align="center" class="mb-4">
										<p><v-icon class="text-red mr-2">warning</v-icon>That email could not be found.</p>
									</v-col>
									<v-col v-if="state.requestOutcome == 911" align="center" class="mb-4">
										<p><v-icon class="text-red mr-2">warning</v-icon>No Password Provided</p>
									</v-col>
									<v-col v-if="state.requestOutcome == 912" align="center" class="mb-4">
										<p><v-icon class="text-red mr-2">warning</v-icon>The password is not strong enough!</p>
									</v-col>															
								</v-row>
								<v-row>
									<v-col class="mt-5"><input type="text" autocomplete="username" ng-hide="true">
										<v-text-field label="Password" type="password" autocomplete="new-password"  hide-details="auto" v-model="state.form.password" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)"></v-text-field>
										<p class="text-caption text-center">Length longer than 8 with at least one special character.</p>
										<PasswordBar :password="state.form.password" @dataEvents="passwordReady"/>								
									</v-col>
								</v-row>
								<v-row>
									<v-col class="mt-5">
										<v-text-field id="password2" name="password2" label="Confirm Password*" type="password" autocomplete="new-password"  v-model="state.form.password2" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>
									</v-col>
								</v-row>
								
								<v-row>
									<v-col class="text-center mt-5">
										<v-btn @click="doResetPassword()" :disabled="!state.dataReady" :class="(state.dataReady) ? 'theme-bg-highlight' :''">Change</v-btn>
									</v-col>
								</v-row>							
							</v-card-text>							
							<v-card-text v-else-if="state.requestOutcome == 1">
								<v-row>
									<v-col class="ma-5"><v-img :src="servedUpLogo" min-width="150" ></v-img></v-col>
								</v-row>
								<v-row>
									<v-col class=""><p class="text-h5 theme-font">Reset your Account Password</p></v-col>
								</v-row>
								<v-row>
									<v-col class=""><p class="text-- theme-font-other">The password for your account with email address <b>{{ state.emailAddress }}</b> has been reset.</p></v-col>
								</v-row>
								<v-row>
									<v-col class="mt-2 text-center">
										<p class="pl-2 pr-2 text-caption text-decoration-underline bg-white theme-pointer" @click="goToLogin()">Sign-in to your Account</p>
									</v-col>
								</v-row>
							</v-card-text>					
						</v-card>										
					</v-form>
				</v-col>
				<v-col md="4"></v-col>
			</v-row>					
			<v-footer app class="vx-off-color-background">
				<v-row>					
					<v-col><v-img :src="servedUpLogo" max-width="150"  class="align-right"></v-img></v-col>
					<v-col><div style="text-align:right;">&copy; 2022 Served Up Ltd</div></v-col>
				</v-row>
			</v-footer>
	</div>
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import {useRoute, useRouter } from "vue-router";
import {useMainStore} from '../../../stores/MainStore.js';
import voxhubLogo from '@/assets/images/voxhub_greenOnWhite.svg';
import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
import i164Transparent from '@/assets/images/i164_transparent.png';
import { useI18n } from 'vue-i18n';
import PasswordBar from '@/components/plustag/components/PasswordBar.vue';

const { t }  = useI18n();
const mainStore = useMainStore();

const route = useRoute();
const router = useRouter();

const valid = (event) => {
	
	if (state.form.password && state.form.password2) {
		if (state.passwordPassed && (state.form.password == state.form.password2)) {			
			state.dataReady = true;
			return true;
		}				
	}			
	state.dataReady = false;
	return false;
}

const passwordReady = (event) => {
	if (event) {
		state.passwordPassed = (event.state == 'ready');
	} else {
		state.passwordPassed = false;
	}
	
}

const state = reactive({
		verificationOutcome: undefined,
		verificationType: undefined,
		emailAddress: undefined,
		requested: false,			
		requestOutcome: 0,
		commandOutcome: undefined,
		form: {	
			password: "",		
			password2: "",	
		}	,
		passwordPassed: false,
});

const goToLogin = () => {
	router.push({ name: 'login'});
}

const goToForgottenPassword = () => {
		router.push({ name: 'forgottenPassword'});
	}
	

const doResetPassword = async () => {	
			const data = new URLSearchParams();
			data.set('password', state.form.password);		
			const apiUrl = mainStore.getQuartermassAPIUrl('plustag');	
			await fetch(apiUrl+'/plustag/passwordEmailReset', {
				method: 'POST',
				body: data,
				credentials: 'include',
				redirect: 'follow',																									
			}).then((response) => {
				response.json().then( 
					d => {
						console.log(d)					
						state.requestOutcome = d.outcome;		
						if (d.outcome == 1) {
							state.requested = true;						
							console.log("Password Reset Complete");					
						} else 	if (d.outcome == -1) {//Failure
							
						} else 	if (d.outcome == 911) {//Password not provided

						} else 	if (d.outcome == 912) {//Password not so good!

						} else {

						}
					}									
				)
				return response;
			}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
				state.requestOutcome = -1;
			});		
		}
	
	const verifyAccessCode = async (accessCode,verificationType) => {	
		const data = new URLSearchParams();
		await fetch(mainStore.getBaseUrl('plustag')+'/quartermass/accessCodeVerification/'+accessCode+"/"+verificationType, {
			method: 'POST',
			body: data,
			credentials: 'include',
			redirect: 'follow',																									
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)
					state.verificationOutcome = d.outcome;							
					if (d.outcome == 1) {
						state.emailAddress = d.emailAddress;
						state.verificationType = d.verificationType;	
					} else {
						const commandOutcome = mainStore.getCommandOutcome(t,d.outcome,d.message);
						state.commandOutcome = commandOutcome;
					}
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
			
	});		
}


onMounted(() => {
	mainStore.lastName = 'plustag';	
	if (route.params.accessCode) {
		verifyAccessCode(route.params.accessCode,'forgottenPasswordEmailReset');
	}	
});

</script>

<style scoped>

.box {
		border: 1px solid rgb(var(--v-theme-box_border_color));	
	}

</style>
