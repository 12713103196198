<template>
	<v-dialog v-model="props.logout" max-width="500px">
		<v-card>
			<v-card-text>				
				<v-row>
					<v-col class="ma-5" align="center">
						<v-card class="theme-dark-background-gradient" width="180" align="center">
							<v-spacer><v-img :src="i164Transparent" width="160" height="72" class="pa-1"></v-img></v-spacer>
						</v-card>
					</v-col>
				</v-row>				
				<v-row>
					<v-col class="text-center"><p class="text-h5 theme-font">Logout of Assembler?</p></v-col>
				</v-row>			
				<v-row>
					<v-col class=""><p class="text-body-2 theme-font-other">This will log you out of the i164 platform for all windows you are currently logged into.</p></v-col>
				</v-row>
				<v-row>
					<v-col class="text-center mt-5">
						<v-btn @click="doLogoutRequest(this)" class="theme-bg-highlight">LOGOUT OF i164</v-btn>
						</v-col>
				</v-row>
				<v-row>
					<v-col class="text-center">
						<p class="mt-5 theme-help-format" @click="close(this)">Close</p>
						</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				
			</v-card-actions>
		</v-card>
	</v-dialog>
	</template>
	
	<script setup>
	import {useMainStore} from '@/stores/MainStore.js';
	import { useRoute, useRouter } from "vue-router";
	import voxhubLogo from '@/assets/images/voxhub_greenOnWhite.svg';
	import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
	import i164Transparent from '@/assets/images/i164_transparent.png';
	const router = useRouter();
	const route = useRoute();
	const mainStore = useMainStore();

	const emits = defineEmits(["close","logout"]);
	
	const self = this;
	
	const close = (a) => {	
		emits('close');
	}
	
	const doLogoutRequest = (a) => {		
		logoutRequest(a);
		close(a);
	}

	const logoutRequest = async (a) => {
				const logoutUrl = mainStore.getLogoutUrl();				
				await fetch(logoutUrl, {
					method: 'get',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {	
									emits('logout');				
									mainStore.initialTargetPhoneServiceName = mainStore.phoneServiceName;
									mainStore.initialTargetPath = route.name;			
									console.log('Logout Successful');
									mainStore.loggedIn = false;
									mainStore.loggedInStatus = null;
									mainStore.verifiedEmail = -1;
									if (props.logoutPath) {
										router.push({name: props.logoutPath});
									}
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);

			});		
		}
	
	const props = defineProps({
		logout: Boolean,	
		logoutPath: {
            type: String,
            required: false
        },
	});
	
	
	</script>

	<style scoped>
		
	</style>