<template>

	<v-row>
		<v-col class="mt-5">
			<div class="text-h5 theme-font">Payment Methods</div>
		</v-col>
	</v-row>
	<v-row>
		<v-col class="mt-5">
			<p>You can add a payment method to your account now or after your account has been approved.</p>
			<p>A payent methid will need to be set-up prior to ordering. No payment will be taken until you place your first order with us.</p>
		</v-col>
	</v-row>
	<v-row class="mt-10">
		<v-col>
			<v-row>
				<v-col class="text-h6 theme-font">Credit Cards</v-col>
				<v-col  align="right" class="mr-5"></v-col>
			</v-row>
		</v-col>
	</v-row>
	<v-row v-if="state.phoneService ">
		<CreditCardServiceListComponent :creditCardConfig="serviceCreditCardConfig" :phoneService="state.phoneService"/>		
	</v-row>
	<v-row class="mt-10">
		<v-col>
			<v-row>
				<v-col class="text-h6 theme-font">Direct Debit</v-col>
				<v-col  align="right" class="mr-5"><!--<v-btn size="small" class="pl-2 theme-list-action-button text-action_button_text" @click="showDirectDebitForm()"><v-icon class="theme-list-action-icon">add</v-icon>Set Up</v-btn>--></v-col>
			</v-row>
		</v-col>
	</v-row>	
	<v-row>
		<v-col class="mt-5">
			<p>You can pay your bills using Direct Debit by setting up a credit account with us. This can be done after you create an account with us.</p>			
		</v-col>
	</v-row>	
</template>
	


<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';

import CreditCardServiceListComponent from '../cards/CreditCardServiceListComponent.vue';

const { t }  = useI18n();

const mainStore = useMainStore();

const serviceCreditCardConfig = {
	listType: 'service',
	mode: 'list',
};


const state = reactive({		
	paymentCardMethods: [],
	phoneService: undefined,
	directDebit: {
		showForm: false,
	},
	rules: {
			required: v => !!v || 'This field is required',
		},
});



const props = defineProps({
		phoneService: {
			type: Object,
			required: true
		},
		billingDetails: {
					type: Object,
					required: true
			}
	});

	const refreshState = () => {
		state.phoneService = props.phoneService;	
		mainStore.refreshLoggedInStatus	=true;
	}

	onUpdated((data) => {		
		refreshState();
	});

	onMounted(() => {
		refreshState();
	});


		
</script>