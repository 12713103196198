<template>
	<v-dialog persistent v-model="state.show">
		<v-row>
					<v-col md="3" sm="2">					
					</v-col>
					<v-col cols="12" sm="8" md="6">
						<v-card>
							<BoxHeader headerText="Invite a User to This Account" closeButton="true" @close="quitInviteUserDialog(this);"/>
							<v-card-text>				
										
								<v-row>
									<v-col class="mt-5"><p class="theme-font-other">We will send an email to the user requesting they join this service.</p></v-col>
								</v-row>
								<v-row>
									<v-col class="pt-5"><v-text-field  density="compact" label="Email Address of the User" prepend-inner-icon="email"
										variant="outlined"  hide-details="auto" autocomplete="none" v-model="state.inviteForm.emailAddress" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)"></v-text-field></v-col>
								</v-row>
								<v-row>
									<v-col class="pt-5"><v-text-field  density="compact" label="First Name" 
										variant="outlined"  hide-details="auto" autocomplete="none" v-model="state.inviteForm.firstName" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)"></v-text-field></v-col>
								</v-row>
								<v-row>
									<v-col class=""><v-text-field  density="compact" label="Last Name" 
										variant="outlined"  hide-details="auto" autocomplete="none" v-model="state.inviteForm.lastName" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)"></v-text-field></v-col>
								</v-row>
								<v-row>
									<v-col class="mb-0" ><v-select
																:items="state.userRoles"
																item-title="roleName"
																item-value="roleId"													
																label="Role"					
																density="compact"
																variant="outlined"
																v-model="state.inviteForm.roleName"		
																@update:modelValue="valid(this)"											 
															></v-select></v-col>
								</v-row>
								<v-row v-if="state.inviteForm.roleName"> 
									<v-col class="mt-0 pt-0">
											<p class="text-caption ma-1" v-if="state.inviteForm.roleName == 'operational.phoneService.owner'">The Account Owner has full control over the account. Including the ability to close the account.</p>
											<p class="text-caption ma-1" v-if="state.inviteForm.roleName == 'operational.phoneService.admin'">An Admin has administrative control only, they cannot invite other users and are limited in their purchasing of services.</p>
											<p class="text-caption ma-1" v-if="state.inviteForm.roleName == 'operational.phoneService.user'">The Account user has basic access priviledges.</p>
											
									</v-col>
								</v-row>
								<v-row>
									<v-col><v-textarea
																	name="messageToUser"
																	label="Reason for the Invite"
																	v-model="state.inviteForm.messageToUser"
																	variant="solo"  
																	hint="This will be included with the invite."
																></v-textarea></v-col>
								</v-row>
								<v-row>
									<v-col class="text-center mt-5">
										<v-btn @click="doInvite(this)" :disabled="!state.dataReady" :class="(state.dataReady) ? 'theme-bg-highlight' :''">Invite User</v-btn>
										</v-col>
								</v-row>							
							</v-card-text>
									
						</v-card>
					</v-col>
				<v-col md="3" sm="2">					
				</v-col>
			</v-row>			
	</v-dialog>
</template>

<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from "vue-router";	
import BoxHeader from '@/components/BoxHeader.vue';

const router = useRouter();
const { t }  = useI18n();
const mainStore = useMainStore();

const props = defineProps({
		show: Boolean,		
	});

const emits = defineEmits(["close"]);

const close = (a) => {	
	emits('close');
}

const quitInviteUserDialog = () => {
	emits('close');
	state.inviteForm.firstName = undefined;
	state.inviteForm.lastName = undefined;
	state.inviteForm.roleName = 'operational.phoneService.user';
	state.inviteForm.emailAddress = undefined;
	state.inviteForm.messageToUser = undefined;
}

const state = reactive({		
	show: false,
	dataReady: false,
	inviteForm: {
			firstName: undefined,
			lastName: undefined,
			emailAddress: undefined,
			roleName: undefined,
			messageToUser: undefined,
		},
		userRoles : [			
				{
					roleId: 'operational.phoneService.owner',
					roleName: t("statics.roles.operational_phoneService_owner"), 
				},{
					roleId: 'operational.phoneService.admin',
					roleName: t("statics.roles.operational_phoneService_admin"), 
				}
				,	{
					roleId: 'operational.phoneService.user',
					roleName: t("statics.roles.operational_phoneService_user"), 
				}
		],
});

const valid = (event) => {
	if (state.inviteForm.firstName && state.inviteForm.lastName && state.inviteForm.emailAddress && state.inviteForm.roleName) {
		const emailValid = state.inviteForm.emailAddress.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		if (emailValid) {
			state.dataReady = true;
			return true;
		}
	}
	state.dataReady = false;
	return false;
}

const doInvite = (event) => {
	const validCheck = valid(event);
	if (!validCheck) {return;}
	inviteUserToPhoneService();
}

const inviteUserToPhoneService = async () => {
	
	const apiUrl = mainStore.getAPIUrl();			
		const data = new URLSearchParams();
		data.set("firstName",state.inviteForm.firstName);
		data.set("lastName",state.inviteForm.lastName);
		data.set("roleName",state.inviteForm.roleName);
		data.set("emailAddress",state.inviteForm.emailAddress);
		data.set("messageToUser",state.inviteForm.messageToUser);

		await fetch(apiUrl+'/phoneServices/inviteUserToPhoneService', {
			body: data,
			method: 'post',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {				
						state.inviteForm.firstName = undefined;
						state.inviteForm.lastName = undefined;
						state.inviteForm.roleName = 'operational.phoneService.user';
						state.inviteForm.emailAddress = undefined;
						state.inviteForm.messageToUser = undefined;
						mainStore.displaySnackBar('User Invited');
						emits('close');
					}	else {

					}		
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});		
	}

const refreshState = () => {
	state.show = props.show;			
}

onUpdated(() => {		
	refreshState();
});

onMounted(() => {
	refreshState();
});



</script>

<style>

</style>
