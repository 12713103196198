<script setup>
    import { reactive } from 'vue';
    import {useMainStore} from '../stores/MainStore.js';
    import { useI18n } from 'vue-i18n';
    import { useDisplay } from 'vuetify';

    const mainStore = useMainStore();
    const { t } = useI18n();
    const { mdAndUp } = useDisplay();

    const state = reactive({
        searchString: null
    });


</script>

<template>
    <v-container>
        <v-row>
            <v-col :cols="(mdAndUp) ? 8 : 12">
                <div class="subTitle">{{ t("serviceSearchTitle") }}</div>
                <v-text-field
                    :label="t('service.serviceAccountContextDisplayName')"
                    v-model="state.searchString"
                    clearable
                    hide-details
                ></v-text-field>    
                <div v-if="state.searchString == null || state.searchString.length < 3" class="noResults">No results</div>
                <template v-if="state.searchString != null && state.searchString.length >= 3">
                    <div class="searchResult" v-for="service in mainStore.voxletServiceContexts" :key="service.primaryUUID">
                        <div>{{ service.serviceAccountContextDisplayName }}</div>
                        <v-btn
                            size="x-small"
                            @click="selectService(service)"
                        >Select</v-btn>
                    </div>
                </template>    
            </v-col>
            <v-col :cols="(mdAndUp) ? 4 : 12">
                <div class="subTitle">Recent</div>
                <a 
                    class="recentService" 
                    v-for="service in mainStore.recentServices" :key="service.primaryUUID"
                    @click="selectService(service)"
                >{{ service.serviceAccountContextDisplayName }}</a>
            </v-col>
        </v-row>
    </v-container>
    
</template>

<style scoped>

    .subTitle {
        font-weight: bold;
        margin-bottom: 10px;
    }

    .noResults {
        width: calc(100% - 20px);
        height: 35px;
        margin: 10px;
        text-align: center;
        background-color: grey;
        color: white;
        line-height: 35px;
    }

    .searchResult {
        display: flex;
        margin: 8px 10px 0px 10px;
    }

    .searchResult div {
        flex-grow: 1;
    }

    .recentService {
        display: block;
        color: blue;
    }

    .recentService:hover {
        text-decoration: underline;
        cursor: pointer;
    }

</style>

<i18n>

    {
        en: {
            serviceSearchTitle: "Search"
        }
    }
    
</i18n>