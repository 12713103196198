<script setup>    
		import { inject, ref, reactive, watch,onMounted } from 'vue';
    import {useMainStore} from '../../stores/MainStore.js';
		import { useRoute, useRouter } from "vue-router";
    import SideMenuSubItemVue from './SideMenuSubItem.vue';
    import { useI18n } from 'vue-i18n';

    const mainStore = useMainStore();
		const router = useRouter();
   
    const { t } = useI18n();

    const props = defineProps({
        menuItem: {
            type: Object,
            required: true
        }
    });


	onMounted(() => {		
		if (props.menuItem.license) {
			state.showMenuItem = mainStore.isUserLicensed(props.menuItem.license); 
		}
	});

		const state = reactive({
				showMenuItem: true,
				showChilren: false
    });

    const showChildren = ref(true);

    const railView = inject("railView");

    const childHolder = ref();

    const beforeEnter = (element) => {
        const parentCompStyle = getComputedStyle(element);
        const compStyle = getComputedStyle(element);

        // calculate the width of the element by looking at its parent's width
        // and then subtracting any borders, margins and padding
        const width = parseInt(parentCompStyle.width) 
            - parseInt(compStyle.marginLeft) - parseInt(compStyle.marginRight) 
            - parseInt(compStyle.paddingLeft) - parseInt(compStyle.paddingRight)
            - parseInt(compStyle.borderLeftWidth) - parseInt(compStyle.borderRightWidth);

        // set the width so that when the position is moved to absolute, and so the
        // width would float out to what ever the screen is we can keep it the same.
        // This way when we query for the height any data will wrap the same and we
        // get an accurate height
        element.style.width = width + "px";
        element.style.position = "absolute";
        element.style.visibility = "hidden";
        element.style.display = "block";
        element.style.height = "auto";

        // get the height
        const openedHeight = getComputedStyle(element).height;

        // set the height to 0 and remove all the other styles above that where only temporary
        element.style.width = null;
        element.style.position = null;
        element.style.visibility = null;
        element.style.display = compStyle.display;
        element.style.height = "0px";

        // change the height for the animation
        requestAnimationFrame(() => {
            element.style.height = openedHeight;
        });
    }

    const beforeLeave = (element) => {
        const currentHeight = getComputedStyle(element).height;
        element.style.height = currentHeight;

        getComputedStyle(element).height;

        requestAnimationFrame(() => {
            element.style.height = 0;
        });
    }
		
		const goTo = (item) => {		
			if (item.plustagRoute)	{
				window.open('/plustag','plustag');
			}		else {
				router.push({name: item.routeName, params:{phoneServiceName: mainStore.phoneServiceName}});
			}	
			
		}

</script>

<template>
	<div v-if="state.showMenuItem ">
    <v-defaults-provider
        :defaults="{
          
        }"
    >
       <!-- <v-tooltip bottom>
            <template v-slot:activator="{props}">-->
     
							<div :class="[railView ? 'topLevelItem topLevelItem_compact' : 'topLevelItem',(mainStore.selectedMainMenuNode && mainStore.selectedMainMenuNode[0] == menuItem.name) ? 'menuItem menuItemSelected' : 'menuLabel']" v-bind="props" @click="showChildren = !showChildren;;goTo(menuItem)">
									<v-icon large :color="(mainStore.selectedMainMenuNode && mainStore.selectedMainMenuNode[0] == menuItem.name) ? 'rgb(var(--v-theme-menu_selected_icon_color))' : 'white'" :class="(railView) ? 'menuIcon_compactView' : 'menuIcon_fullView'">{{ menuItem.icon }}</v-icon>
									<div v-if="railView == false" :class="(mainStore.selectedMainMenuNode && mainStore.selectedMainMenuNode[0] == menuItem.name) ? 'menuLabel menuLabelSelected' : 'menuLabel'">{{ t("sectionHeadings." + menuItem.label) }}</div>												
									<v-icon size="x-small" v-if="menuItem.postIcon" :color="(mainStore.selectedMainMenuNode && mainStore.selectedMainMenuNode[0] == menuItem.name) ? 'rgb(var(--v-theme-menu_selected_icon_color))' : 'white'" :class="(railView) ? 'menuIcon_compactView' : 'menuIcon_fullView'">{{ menuItem.postIcon }}</v-icon>
									<v-icon v-if="menuItem.children && mainStore.selectedMainMenuNode && mainStore.selectedMainMenuNode[0] != menuItem.name" large color="white" class="menuUpDownIcon">expand_more</v-icon>
									<v-icon v-if="menuItem.children && mainStore.selectedMainMenuNode && mainStore.selectedMainMenuNode[0] == menuItem.name" large color="white" class="menuUpDownIcon">expand_less</v-icon>
							</div>

         <!--   </template>
            <span style="font-size: 1.3em;">
                <v-icon large color="white" class="tooltip_icon">{{ menuItem.icon }}</v-icon>
                {{ t("sectionHeadings." + menuItem.label) }}
            </span>
        </v-tooltip>-->
    </v-defaults-provider>
    <transition name="menuChildren" @before-enter="beforeEnter" @before-leave="beforeLeave">
        <div :class="(railView) ? 'childrenHolder_compact' : 'childrenHolder_fullView'" ref="childHolder"  v-if="mainStore.selectedMainMenuNode && mainStore.selectedMainMenuNode[0] == menuItem.name" >
            <SideMenuSubItemVue v-for="item,index in props.menuItem.children" :menuItem="props.menuItem" :subMenuItem="item" :key="index"/>
        </div>
    </transition>
	</div>
</template>

<style scoped>
    .topLevelItem {				
        height: 50px;
				padding-left:15px;
        border-bottom-color: rgb(var(--v-theme-menu_separator));
        border-bottom-style: solid;
        border-bottom-width: 1px;
        display: flex;
        align-items: center;
    }

    .topLevelItem_compact {
				width: 100%;
        justify-content: center;
    }

		.menuUpDownIcon {
			text-decoration: none !important;
			margin-right:10px;
		}

		.menuTitle {
			margin-top: 5px;			
			font-size: 12px;
			padding-left:20px;
			padding-bottom: 5px;
			color: white;
			text-transform: uppercase;
			border-bottom-color: rgb(var(--v-theme-menu_separator));
			border-bottom-style: solid;
      border-bottom-width: 1px;
		}

		.menuItem {			
			width: 100%;
		}

		.menuItemCollapse {
			right:10px;
		}

		.menuItemSelected {
			background-color: rgba(199, 199, 199, 0.2);
		}

    .menuIcon_fullView {
        margin-left: 10px;
        margin-right: 10px;				
        cursor: pointer;
    }

    .menuIcon_compactView {
        margin: 0;
        cursor: pointer;
    }

    .menuLabel {
				width:100%;
        color: white;
        font-size: 14px;
        cursor: pointer;
        text-decoration: none;
    }

		.menuLabelSelected {
			color: rgb(var(--v-theme-menu_selected_color)) !important;
		}

		.menuLabelSelected  i {
			color: rgb(var(--v-theme-menu_selected_icon_color)) !important;
		}

    .childrenHolder_compact {
        overflow: hidden;
    }

    .childrenHolder_fullView {
        padding-left: 20px;
        overflow: hidden;
    }

    .tooltip_icon {
        margin-right: 10px;
    }
    
    .menuChildren-enter-active {
        transition: all 0.5s ease;
    }

    .menuChildren-leave-active {
        transition: all 0.5s ease;
    }

    
</style>