<template>
	<div class="d-flex" v-if="state.checkerScore <= 4 && !state.passwordPassed">
		<v-container class="pa-0 ma-0" style="max-width:80%"><password-meter :password="state.password" @score="onScore" class=""/></v-container>
		<v-container class="pa-0 ma-0"  style="max-width:20%"></v-container>		
	</div>	
	<div class="d-flex" v-else>
		<div class="po-complete"></div>
	</div>
	
</template>

<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import passwordMeter from "vue-simple-password-meter";

const state = reactive({		
	dataReady: false,
	password: undefined,
	checkerScore: 0,
	passwordPassed: false,
	specialCharacter: false,
	lengthCheck: 0,
});

const props = defineProps({
	password: {
			type: String
		},
});


const onScore = ({ score, strength }) => {
	state.checkerScore = score;
}

const valid = () => {
	if (state.password) {	
		state.specialCharacter = (state.password.match(/[^a-z\d]+/i));
		state.lengthCheck = (state.password.length > 8);
		if (state.checkerScore == 4 && state.specialCharacter) {	
				state.passwordPassed = true;
				state.dataReady = true;
				emits('dataEvents',
					{
						state: 'ready'
					}
				);			
				return true;			
		}
	} else {
		state.specialCharacter = false;
		state.lengthCheck = false;
	}
	state.passwordPassed = false;	
	state.dataReady = false;
	emits('dataEvents',
				{
					state: 'notReady'
				}
			);	
	return true;			
}

const emits = defineEmits(["dataEvents"]);

onUpdated(() => {		
	//if (props.password) {
		state.password = props.password;
		valid();
	//}
});

onMounted(() => {
	//if (props.password) {
		state.password = props.password;
		valid();
	//}
});

</script>

<style >

.po-password-strength-bar.risky {
    background-color: #f95e68;
    width: 10%;
}

.po-password-strength-bar.guessable {
		background-color: #f95e68;    
    width: 32.5%;
}

.po-password-strength-bar.weak {
    
		background-color: #fb964d;
    width: 55%;
}

.po-password-strength-bar.safe {
		background-color: #fdd244;    
    width: 77.5%;
}

.po-password-strength-bar.secure {
		background-color: #b0dc53;    
    width: 100%;
}

.po-complete {
	background-color: #35cc62;
	border-radius: 2px;
  transition: all 0.2s linear;
  height: 5px;
  margin-top: 8px;
	width: 100%;
}

</style>