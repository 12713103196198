<template>
<v-container >
		<v-row>
			<v-col >
				<v-row>
					<v-col cols="4" class="mt-4">
						<v-card>
							<BoxHeader headerText="PSTN Providers"/>						
							<v-card-text>																
								<p class="theme-font text-h5 ml-1">
									Connect to the PSTN
								</p>
								<p class="theme-font-other text-body-1 ml-1 mt-2">
									Bring your own provider to i164.
								</p>	
								<p class="theme-font-other text-body-1 ml-1 mt-2">
									Any SIP compliant trunk can be used.
								</p>	
							</v-card-text>
						</v-card>					
					</v-col>
					<v-col cols="8">						
						<ListPSTNProviders/>
					</v-col>					
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import BoxHeader from '@/components/BoxHeader.vue';
import ListPSTNProviders from './ListPSTNProviders.vue';
import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
import i164Transparent from '@/assets/images/i164_transparent.png';

const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const state = reactive({	
	tabsTop: 'home',
});

</script>

<style>

</style>