<template>
	<v-hover v-if="state.onboarding" >
 <template v-slot:default="{ isHovering, props }">
	<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
		<BoxHeader :headerText="getHeaderText()" :dataReady="state.dataReady" readyIcon="check_circle"/>
		<v-card-text>
			<v-row v-if="!state.dataReady">
				<v-col class="center">
					<v-tabs v-model="state.mode" density="compact">
						<v-tab value="newAccount" :class="(state.mode == 'newAccount') ? 'bg-active_tab_color' : ''" >New Account</v-tab>
						<v-tab value="existingAccount" :class="(state.mode == 'existingAccount') ? 'bg-active_tab_color' : ''" >Existing Account</v-tab>
					</v-tabs>
					<v-divider/>
				</v-col>
			</v-row>
			<v-row v-if="!state.dataReady && state.mode == 'newAccount'">
				<v-col class="mt-4">
					<v-row>
						<v-col>
							<v-select :items="state.accountTypes" label="Account Type" variant="outlined" item-title="typeDescription"
								item-value="type" @update:modelValue="changeAccountType"
								v-model="state.serviceAccountContextRequest.voxletServiceContextType"
								:hint="serviceAccountContextTypeHint()"></v-select>
						</v-col>
					</v-row>
					<v-row>
						<v-col><v-text-field v-model="state.serviceAccountContextRequest.serviceAccountContextDisplayName"
								name="customerName" variant="outlined" label="Account Name"
								hint="Used for display purposes to help identify the account in the user interface."></v-text-field></v-col>
					</v-row>
					<v-row>
						<v-col><v-text-field v-model="state.serviceAccountContextRequest.serviceAccountContextCustomerReference"
								name="customerReference" variant="outlined" label="Account Reference"
								hint="A field you can use to reference an account on your system."></v-text-field></v-col>
					</v-row>
					<v-row>
						<v-col>
							<v-btn @click="saveNewAccountData()">Done</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			<v-row v-if="!state.dataReady && state.mode == 'existingAccount'">
				<v-col class="mt-4">
					<v-row>
						<v-col>
							<v-radio-group inline v-model="state.voxletServiceContextSearchType">
								<v-radio label="Customer" value="customerAccount"></v-radio>
								<!--<v-radio label="Project" value="projectAccount"></v-radio>
								<v-radio label="Stock" value="stockAccount"></v-radio>-->
							</v-radio-group>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="d-flex">
							<v-text-field v-model="state.voxletServiceContextSearchText" name="searchText" variant="outlined"
								label="Search for Account" class="mr-5" @keydown.enter="searchVoxletServiceContexts()"></v-text-field>
							<v-btn icon="search" :class="state.voxletServiceContextSearchText ? 'theme-bg-highlight mt-1' : 'mt-1'"
								:disabled="!state.voxletServiceContextSearchText" @click="searchVoxletServiceContexts()"></v-btn>
						</v-col>
					</v-row>
					<v-row v-if="state.voxletServiceContextSearchResults">						
						<v-col v-if="state.voxletServiceContextSearchResults.length > 0">
							<p class="theme-font">Choose an Account</p>
							<v-list>
								<v-list-item v-for="(voxletServiceContext, i) in state.voxletServiceContextSearchResults" :key="i"
									:value="voxletServiceContext" active-color="error">
									<template v-slot:prepend>
										<v-icon icon="account_tree" size="16"  class="mr-3"></v-icon>
									</template>								
									<v-list-item-title class="theme-pointer theme-font-other"
										@click="selectVoxletServiceContext(voxletServiceContext)">{{ voxletServiceContext.serviceAccountContextDisplayName }} ({{ voxletServiceContext.serviceAccountContextCustomerReference }})</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-col>
						<v-col v-else>
							<p class="theme-font text-center text-h6">No Records Found.</p>
						</v-col>
					</v-row>								
				</v-col>
			</v-row>
			<v-row v-if="state.dataReady">
				<v-col>
					<!--<v-row :class="state.dataReady ? 'bg-vx_green_900' : ''" >
						<v-col v-if="state.serviceAccountContextRequest.createNewContext" class="d-flex">
							<v-icon :class="state.dataReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon><p class="theme-font text-h5">New Account</p>
						</v-col>
						<v-col v-if="!state.serviceAccountContextRequest.createNewContext" class="d-flex">
							<v-icon :class="state.dataReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon><p class="theme-font text-h5">Existing Account</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col><v-divider></v-divider></v-col>
					</v-row>-->
					<v-row>
						<v-col class="ma-5">
							<v-row>
								<v-col class="d-flex">
									<p class="theme-font-other font-weight-bold">Account</p><v-spacer  align="right"><v-btn size="x-small" @click="editAccount()" v-if="!state.readOnly">EDIT</v-btn></v-spacer>
								</v-col>
							</v-row>	
							<v-row>
								<v-col md="4" class="theme-font-other font-weight-bold">Type</v-col><v-col md="8" class="theme-font-other">{{ t(
										'statics.voxletServiceContextTypes.' + state.serviceAccountContextRequest.voxletServiceContextType)
								}}
									Account</v-col>
							</v-row>
							<v-row>
								<v-col md="4" class="theme-font-other font-weight-bold">Name</v-col><v-col md="8" class="theme-font-other">{{
										state.serviceAccountContextRequest.serviceAccountContextDisplayName
								}}</v-col>
							</v-row>
							<v-row>
								<v-col md="4" class="theme-font-other font-weight-bold">Reference</v-col><v-col md="8" class="theme-font-other">{{
										state.serviceAccountContextRequest.serviceAccountContextCustomerReference
								}}</v-col>
							</v-row>	
						</v-col>
					</v-row>
									
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
	</template>
	</v-hover>
	<v-card v-else>
		<v-card-text>Initialising</v-card-text>
	</v-card>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
import BoxHeader from '@/components/BoxHeader.vue';


	const { t }  = useI18n();
	
	const mainStore = useMainStore();

	const state = reactive({
		onboarding: undefined,
		mode: 'newAccount',
		refreshVersion: 0,		
		dataReady: false,
		readOnly: false,
		accountTypes: [
          { type: 'customerAccount', typeDescription: 'Customer' },
         // { type: 'projectAccount', typeDescription: 'Project' },
         // { type: 'stockAccount', typeDescription: 'Stock' },
    ],
		voxletServiceContextSearchType: 'customerAccount',
		voxletServiceContextSearchText: undefined,
		voxletServiceContextSearchResults: undefined,		
		serviceAccountContextRequest: {
			createNewContext:  true,
			voxletServiceContextType: 'customerAccount',
			serviceAccountContextDisplayName: '',
			serviceAccountContextCustomerReference: '',
		}
	});

	const emits = defineEmits(['dataEvents']);

	const fireDataReadyEvent = () => {
			emits('dataEvents',
				{
					state: 'ready',
					serviceAccountContextRequest: state.serviceAccountContextRequest,
				}
			);
	}

	const fireDataNotReadyEvent = () => {
			emits('dataEvents',
				{
					state: 'notReady'
				}
			);
	}

	const props = defineProps({		
		refreshConfiguration: {
			type: Object,
			required: true,
		},
		serviceAccountContextConfiguration: {
			type: Object,
			required: true,
		},
		initialMode: String,
		readOnly: Boolean,
	});

	const getHeaderText = () => {
		if (state.serviceAccountContextRequest.createNewContext) {
			return 'New Account';
		} else {
			return 'Existing Account';
		}
	}

	const serviceAccountContextTypeHint = () => {
			if (state.serviceAccountContextRequest.voxletServiceContextType == 'customerAccount') {
				return 'Creates an account that represents a customer.';
			} else if (state.serviceAccountContextRequest.voxletServiceContextType == 'projectAccount') {
				return "Creates an account that represents a project.";
			} else if (state.serviceAccountContextRequest.voxletServiceContextType == 'stockAccount') {
				return 'Creates an account that can be used to store services for later use.';
			}	
			return '';												
	}

	const editAccount = (event) => {
		if (!state.readOnly) {
			if (state.serviceAccountContextRequest.createNewContext) {
				state.mode = 'newAccount';			
			} else {
				state.mode = 'existingAccount';			
			}
			state.dataReady = false;
			fireDataNotReadyEvent();
		}
	}

	const selectVoxletServiceContext = (voxletServiceContext) => {		
		state.dataReady = true;
		fireDataReadyEvent();	
	}



	const saveNewAccountData = () => {		
		if (!state.readOnly) {
			state.dataReady = true;
			fireDataReadyEvent();
		}
	}

	const changeAccountType = () => {

	}

	const searchVoxletServiceContexts = async () => {
		
		const apiUrl = mainStore.getAPIUrl();		
		const data = new URLSearchParams();
		data.set('searchText', state.voxletServiceContextSearchText);	
		await fetch(apiUrl+'/voxletServices/listVoxletServiceContexts', {
			body: data,
			method: 'post',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {				
						state.voxletServiceContextSearchResults = d.voxletServiceContexts;
					}							
				}									
			)
			return response;
		}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);

		});		
	}

	const refreshState = () => {
		if (props.initialMode) {
			state.mode = props.initialMode;
		} 		
		if (props.refreshConfiguration.onboarding) {				
			state.onboarding = props.refreshConfiguration.onboarding;
		}
		if (state.onboarding && state.onboarding != 'creation') {
			state.readOnly = true;
		}
		if (props.readOnly) {
				state.readOnly = props.readOnly;
		}
		if (props.serviceAccountContextConfiguration.serviceAccountContextRequest) {
			state.serviceAccountContextRequest.createNewContext = props.serviceAccountContextConfiguration.serviceAccountContextRequest.createNewContext;
			state.serviceAccountContextRequest.serviceAccountContextCustomerReference = props.serviceAccountContextConfiguration.serviceAccountContextRequest.serviceAccountContextCustomerReference;
			state.serviceAccountContextRequest.serviceAccountContextDisplayName = props.serviceAccountContextConfiguration.serviceAccountContextRequest.serviceAccountContextDisplayName;
			state.serviceAccountContextRequest.voxletServiceContextType = props.serviceAccountContextConfiguration.serviceAccountContextRequest.voxletServiceContextType;
			state.dataReady = true;
			fireDataReadyEvent();
		}
	}

	onUpdated((data) => {		
		if (props.refreshConfiguration.version > state.refreshVersion) {
			state.refreshVersion = props.refreshConfiguration.version;
			console.log("onUpdated Account");
			refreshState();
		}
	});

	onMounted(() => {
		refreshState();
	});

</script>

<style scoped>

</style>
