import { createApp } from 'vue'
import pinia from './stores/stores'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import router from "./router"
import i18n from './locales/i18n';
import '@mdi/font/css/materialdesignicons.css'
import drag from "v-drag";
import ScssPlugin from "./plugins/scss-plugin";
import permissions from "./commands/permissions.js"
import VOtpInput from "vue3-otp-input";



loadFonts()

const app = createApp(App)
  .use(vuetify)
  .use(pinia)
  .use(router)
  .use(i18n)
  .use(drag, {});

router.isReady().then(() => {
	app.component('v-otp-input', VOtpInput);
  app.mount('#app');	
});