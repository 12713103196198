<template>
<v-dialog persistent v-model="state.show">	
	<v-row>
		<v-col md="3" sm="2">					
		</v-col>
		<v-col cols="12" sm="8" md="6">
			<v-card>
				<BoxHeader headerText="Manage User Roles" closeButton="true" @close="close(this);"/>
				<v-card-text>
					<v-row>
						<v-col class="pa-0" >							
							<v-row >								
								<v-col class="ma-2">
									<v-expansion-panels  variant="accordion">
										<v-expansion-panel v-for="app,index in mainStore.phoneServiceApps" :key="index">
											<template v-slot:title>	
												<v-icon>{{ t("statics.appIcons."+app.appName) }}</v-icon>{{ app.appText }}
											</template>													
											<template v-slot:text>	
												<div @click="addRoleToUser(appRole.roleName)" 	v-for="appRole,index in app.appRoles" :key="index">
													{{ appRole.roleText }}								
												</div>													
											</template>
										</v-expansion-panel>
									</v-expansion-panels>								
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</v-col>
		<v-col md="3" sm="2">					
		</v-col>
	</v-row>			
</v-dialog>

</template>
<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import BoxHeader from '@/components/BoxHeader.vue';


const { t }  = useI18n();

const emits = defineEmits(["close"]);

const close = (a) => {	
	emits('close');
	state.show = false;
}

const props = defineProps({
		show: Boolean,
		phoneServiceUserParticipation: {
			type: Object,
			required: true,
		}
	});


	const getAppIcon = () => {

	}



const state = reactive({		
	show: false,
	phoneServiceUserParticipation: undefined,
	commandOutcome: undefined,
	phoneServiceApps: undefined,	
});

const mainStore = useMainStore();


const addRoleToUser =  async (roleName) => {
	const apiUrl = mainStore.getAPIUrl();			
	const data = new URLSearchParams();
		data.set("phoneServiceUserParticipationUUID",state.phoneServiceUserParticipation.primaryUUID);
		data.set("roleName",roleName);	
		await fetch(apiUrl+'/phoneServices/addRoleToUser', {
			body: data,
			method: 'post',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {
						state.phoneServiceApps = d.phoneServiceApps;
					}	else {
						const commandOutcome = mainStore.getCommandOutcome(t,d.outcome,d.message);
						state.commandOutcome = commandOutcome;
					}		
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});					
}



onUpdated(() => {		
	state.show = props.show;
	state.phoneServiceUserParticipation = props.phoneServiceUserParticipation;
});

	onMounted(() => {
		state.show = props.show;
		state.phoneServiceUserParticipation = props.phoneServiceUserParticipation;
	});
</script>

<style scoped>

</style>