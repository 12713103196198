<template>
	<!--
		let tariff =  {
				primaryUUID: uuid(),
				parentTariffUUID: uuid(),
				parentTariffName: 'Magrathea Gold',
				tariffType:  'managed',	
				startDate: undefined,
				endDate: undefined,			
				tariffName: 'Main Tariff',
				tariffDescription : 'Our Default Tariff for Bundles',
				minimumCharge: 1,
				pricePer: 1,
			};
	-->
	<v-container class="px-6 py-0">		
		<v-card class="mb-2 pt-0 bg-secondary_600">
			<v-card-text class="">
				<v-row>
					<v-col class="pt-0">
						<v-row>
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Name</p>
								<p class="theme-font-other text-body-1" >{{ tariff.tariffName }}</p>
							</v-col>
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Based On</p>
								<p class="theme-font-other text-body-1" v-if="tariff.tariffType == 'custom'">{{ tariff.tariffType }}</p><p v-if="tariff.tariffType == 'managed'" class="theme-font-other text-body-1">{{ tariff.parentTariffName }}</p>
							</v-col>
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Start Date</p>
								<p v-if="tariff.startDate" class="theme-font-other text-body-1">{{ tariff.startDate}}</p><p class="theme-font-other text-body-1"  v-else>Not Started</p>			
							</v-col>
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Minimum</p>
								<p class="theme-font-other text-body-1">{{ tariff.minimumCharge }}p</p>										
							</v-col>
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Minimum</p>
								<td class="theme-font-other text-body-1">{{ t('statics.tariff.pricePer.'+tariff.pricePer) }}</td>	
							</v-col>
						</v-row>
					</v-col>
				</v-row>				
			</v-card-text>
		</v-card>												
		<v-row>
			<v-col>
				<v-tabs density="compact"  v-model="state.tabsTop">
					<v-tab value="overview"  :class="(state.tabsTop== 'overview') ? ' bg-active_sub_tab_color':''">
						Overview
					</v-tab>
					<v-tab value="destinations" :class="(state.tabsTop== 'destinations') ? 'bg-active_sub_tab_color':''" >
						<p>Destinations</p>
					</v-tab>
					<v-tab value="pricingRules" :class="(state.tabsTop== 'pricingRules') ? 'bg-active_sub_tab_color':''" >
						<p>Pricing Rules</p>
					</v-tab>
					<v-tab value="pricingQuery" :class="(state.tabsTop== 'pricingQuery') ? 'bg-active_sub_tab_color':''" >
						<p>Pricing Query</p>
					</v-tab>					
				</v-tabs>
				<v-divider></v-divider>
				<v-window v-model="state.tabsTop">
					<v-window-item value="overview">
						<TariffOverview :tariff="tariff"/>
					</v-window-item>
					<v-window-item value="destinations">
						<v-container>	
							<v-row>
								<v-col class="d-flex ml-1">
									<v-card>
										<v-card-text class="pa-1">
											<p class="font-weight-bold">
												Show
											</p>
											<v-btn size="x-small" :class="(state.showMargins) ? 'theme-bg-highlight':''" class="mr-2 mt-1" @click="toggleMargins()">Margins</v-btn>
											<v-btn size="x-small" :class="(state.warnings) ? 'theme-bg-highlight':''" class="mr-2 mt-1" @click="toggleWarnings()">Warnings</v-btn>									
										</v-card-text>
									</v-card>
									<p v-if="state.warnings" class="text-body-1 ml-2 mt-6">Warn &lt; 15% or &gt; 50%</p>									
									
									
								</v-col>							
								<v-col>
									<v-combobox
										density="compact"
										v-model="state.search.countries"
										:items="state.filters.countries"
										variant="outlined"
										label="Filter By Country"
										multiple
										chips
										closable-chips
										@update:modelValue="doFilter(this)"
									></v-combobox>
								</v-col>
							</v-row>						
								
							<v-data-table				
								:items-per-page="state.dataTableConfig.itemsPerPage"
								:headers="state.dataTableConfig.headers"
								:items="state.data"
								item-value="name"
								class="elevation-1"
							>
								<template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
									<tr class="theme-box-header-background">
										<template v-for="column in columns" :key="column.key">
											<td style="background-color:transparent" :class="'text-'+column.align">
												<p class="mr-2 cursor-pointer text-body-1 theme-listing-header-text" @click="() => toggleSort(column)">{{ column.title }} <span v-if="state.showMargins && column.key != 'd'" class="text-caption"><br/>Rate / Cost / Margin</span></p>
												<template v-if="isSorted(column)">
													<v-icon :icon="getSortIcon(column)"></v-icon>
												</template>
												<v-icon v-if="column.removable" icon="$close" @click="() => remove(column.key)"></v-icon>
											</td>
										</template>
									</tr>
								</template>	
								<template v-slot:item.Rp="{ item }">								
									<p :class="(item.selectable.country == 'AZ') ? 'text-warning':''">{{ item.selectable.Rp }}p <span v-if="state.showMargins">/ {{ item.selectable.p }}p</span> <span v-if="state.showMargins">/ {{ calculateMargin(item.selectable.p,item.selectable.Rp) }}%</span></p>									
								</template>	
								<template v-slot:item.Rop="{ item }">								
									<p :class="(item.selectable.country == 'AZ') ? 'text-warning':''">{{ item.selectable.Rop }}p <span v-if="state.showMargins">/ {{ item.selectable.op }}p</span> <span v-if="state.showMargins">/ {{ calculateMargin(item.selectable.op,item.selectable.Rop) }}%</span></p>
								</template>	
								<template v-slot:item.Rw="{ item }">								
									<p :class="(item.selectable.country == 'AZ') ? 'text-warning':''">{{ item.selectable.Rw }}p <span v-if="state.showMargins">/ {{ item.selectable.w }}p</span> <span v-if="state.showMargins">/ {{ calculateMargin(item.selectable.w,item.selectable.Rw) }}%</span></p>
								</template>			
							</v-data-table>
						</v-container>
					</v-window-item>
					<v-window-item value="pricingRules">
							<PricingRules/>
					</v-window-item>	
					<v-window-item value="pricingQuery">
						<PricingQuery/>
					</v-window-item>				
				</v-window>
				
			</v-col>
		</v-row>		
	</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import TariffForm from './components/TariffForm.vue';
	import BoxHeader from '@/components/BoxHeader.vue';
	import DateTimeline from './components/DateTimeline.vue';
	import PricingRules from './components/PricingRules.vue';
	import PricingQuery from './components/PricingQuery.vue';
	import TariffOverview from './TariffOverview.vue';
	import uuid from 'uuid-random';
	import * as fullDestinations from "./components/full-destinations.json";
	import { VDataTable } from 'vuetify/labs/VDataTable';
	
	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const emits = defineEmits(['dataEvents']);

	const rateHeaders =  [
			{
				title: 'Destination',
				align: 'start',
				sortable: false,
				key: 'd',
			},		
			{ title: 'Peak', align: 'center', key: 'Rp' },
			{ title: 'Off Peak', align: 'center', key: 'Rop' },
			{ title: 'Weekend', align: 'center', key: 'Rw' },	
		];

		const marginHeaders =  [
		{
				title: 'Destination',
				align: 'start',
				sortable: false,
				key: 'd',
			},		
			{ title: 'Peak', align: 'center', key: 'Rp' },
			{ title: 'Off Peak', align: 'center', key: 'Rop' },
			{ title: 'Weekend', align: 'center', key: 'Rw' },	
		];


	const state = reactive({
		tabsTop: 'overview',
		view: 'rates',
		warnings: false,
		showMargins: false,
		search: {
			countries: undefined,
			countries2: undefined,
		},
		data: undefined,
		filters: {
			countries: [
				{value: 'AT' , code:"43", title:'Austria +43'},
				{value: 'AZ' , code:"994", title:'Azerbaijan +994'},
				{value: 'US' , code:"1", title:'USA +1'},
				{value: 'GB' , code:"44", title:'United Kingdom +44'},
				{value: 'FR' , code:"33", title:'France +33'},
			]
		},
		dataTableConfig : {
			headers: rateHeaders,
			page: 1,
			pageCount: 10,
			itemsPerPage: 10,
		},		
	});

	const props = defineProps({		
		tariff: {
			type: Object,
			required: true,
		}	
	});

	const timelineData = [
		{
			startDate: '3rd July 2023',
			label: 'July',
			description: 'Complete Tariff Update',			
		},
		{
			startDate: '24th July 2023',
			label: 'August',
			description: 'Several Destinations Updated',
		},
		{
			startDate: '3rd August 2023',
			label: 'August',
			description: 'Complete Tariff Update',
		}

	];

	const toggleMargins = () => {
		state.showMargins = !state.showMargins;
	}

	const toggleWarnings = () => {
		state.warnings = !state.warnings;
		if (state.warnings) {
			state.showMargins = true;		
		}
		doFilter();
	}

	const selectView = (viewName) => {
		state.view = viewName;
		switch (viewName) {
			case 'rates':
				state.dataTableConfig.headers = rateHeaders;
				break;
			case 'margins':
				state.dataTableConfig.headers = marginHeaders;
				break;
		}

		
	}

	const calculateMargin = (cost,rate) => {
		return Math.round( ((rate/cost)*100) -100 );
	}

	const processRates = () => {
		const markup = 1.3;
		const lowMarkup = 1.1;
		for (let i = 0; i < state.data.length;i++) {			
			if (state.data[i].country == 'AZ') {
				state.data[i].Rp = Math.ceil(100*state.data[i].p*lowMarkup)/100;
				state.data[i].Rop =Math.ceil(100* state.data[i].op*lowMarkup)/100;
				state.data[i].Rw = Math.ceil(100*state.data[i].w*lowMarkup)/100;
			} else {
				state.data[i].Rp = Math.ceil(100*state.data[i].p*markup)/100;
				state.data[i].Rop =Math.ceil(100* state.data[i].op*markup)/100;
				state.data[i].Rw = Math.ceil(100*state.data[i].w*markup)/100;
			}
			
			//state.data[i].Rp = Math.round(10*state.data[i].p*markup)/10;
			//state.data[i].Rop =Math.round(10* state.data[i].op*markup)/10;
			//state.data[i].Rw = Math.round(10*state.data[i].w*markup)/10;

			/**
			 * "p": 0.01,
			"op": 0.01,
			"w": 0.01,
			"cc": 0
			 */
		}
	}

	const doFilter = (event) => {
		if (state.warnings) {
			const newData = [];
			for (let i = 0; i < fullDestinations.rates.length;i++) {
			if (fullDestinations.rates[i].country == 'AZ') {
						newData.push(fullDestinations.rates[i]);
				}
			}						
			state.data = newData;
		} else if (state.search.countries && state.search.countries.length > 0) {
			const newData = [];
			for (let i = 0; i < fullDestinations.rates.length;i++) {
				if (fullDestinations.rates[i].country) {
					for (let j = 0; j < state.search.countries.length; j++) {						
						if (fullDestinations.rates[i].country == state.search.countries[j].value||fullDestinations.rates[i].code == state.search.countries[j].value) {
							newData.push(fullDestinations.rates[i]);
						}
					}
				}			
			}
			state.data = newData;
		} else  {
			state.data = fullDestinations.rates;
		}
		processRates();
	}

	onUpdated(() => {	
		
	});


	onMounted(() => {		
		state.data = fullDestinations.rates;
		processRates();		
	});

</script>

<style  lang="scss">



</style>

