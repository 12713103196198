<template>
	<OuterCard>
		<v-row>
			<v-col>
				<v-row>
					<v-col>
						<p class="theme-font text-h5">Join an Industry Hub</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<p class="theme-font-other">
							The Telecoms Business Hub provides private messaging between members across our secure network.
						</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<p class="theme-font-other">
							With dedicated support for standard Industry processes including Gaining Provider Led Business switching.
						</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<p class="theme-font-other">
							All accounts include our User interface and network messaging API.
						</p>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<p class="theme-font-other">
							<v-btn @click="beginQuickStartApplication()" size="small"
								class="pl-2 theme-list-action-button-background  text-action_button_text"><v-icon
									class="theme-list-action-icon">add</v-icon>Apply</v-btn>
						</p>
					</v-col>
				</v-row>
			</v-col>
		</v-row>		
	</OuterCard>
</template>

<script setup>
	import OuterCard from '@/components/common/OuterPopCard.vue';
	import { useRoute, useRouter } from "vue-router";

	const router = useRouter();
	
	const beginQuickStartApplication = () => {
		router.push({name: 'networkHubApplication'});
	}


</script>

<style scoped>

</style>