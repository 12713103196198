<template>
	
	<v-dialog persistent v-model="state.show">
	
				<v-row>
					<v-col md="3" sm="2">					
					</v-col>
					<v-col cols="12" sm="8" md="6">
						<v-card>
							<BoxHeader headerText="Your Credit Cards" :closeButton="state.userPaymentCardMethods.length >= 0" @close="close(this)" />
							<v-card-text>								
								<v-row v-if="state.userPaymentCardMethods.length > 0">
									<v-col class="ml-1 d-flex">
										<p class="mr-3">Select a card for use on this account.</p>		<v-spacer align="right"><v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text"  @click="addCard(this)"><v-icon class="theme-list-action-icon">add</v-icon>New Card</v-btn></v-spacer>
									</v-col>
								</v-row>	
								<v-row  v-if="state.userPaymentCardMethods.length > 0">
									<v-col >
										<CreditCardUserListComponent :userPaymentCardMethods="state.userPaymentCardMethods" :paymentCardMethods="paymentCardMethods" :creditCardConfig="userCreditCardConfig" :phoneService="phoneService" @selectedCard="selectedCard(this)"/>
									</v-col>
								</v-row>
								<v-row v-else>
									<v-col class="w-100 ml-5">
										<p class="mb-4">You have no cards on your account.</p> <v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text"  @click="addCard(this)"><v-icon class="theme-list-action-icon">add</v-icon>New Card</v-btn>
									</v-col>
								</v-row>							
							</v-card-text>
						</v-card>
					</v-col>
					<v-col md="3" sm="2">					
					</v-col>
				</v-row>
			
	</v-dialog>
</template>

<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import CreditCardUserListComponent from './CreditCardUserListComponent.vue';
import BoxHeader from '@/components/BoxHeader.vue';

const emits = defineEmits(["close","selectedCard","startPolling"]);

const close = (a) => {	
	emits('close');
}

const selectedCard = (a) => {
	emits('selectedCard');
}

const state = reactive({		
	show: false,
	userPaymentCardMethods: undefined,
});

const mainStore = useMainStore();

const userCreditCardConfig = {
	mode: 'selection',
};

const props = defineProps({
		show: Boolean,	
		phoneService: {
			type: Object,
			required: true
		},		
		paymentCardMethods: {
			type: Object,
				required: true
		},
		userPaymentCardMethods: {
			type: Object,
			required: true
		}
	});

	const addCard = (e) => {
		emits('startPolling');
		createCheckoutSession();
	}


	const createCheckoutSession = async () => {
		console.log('creating checkout for '+props.phoneService.phoneServiceName);
		const popup = openCardWindow();
		const apiUrl = mainStore.getAPIUrl(props.phoneService.phoneServiceName);				
		await fetch(apiUrl+'/serviceBilling/createCheckoutSession', {
			method: 'get',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == -3) {
					} else {
						popup.location = d.url;
					}							
				}									
			)
			return response;
		}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);

		});		
		}


		const anyCards = () => {
			var anyCards = false;
			if (props.paymentCardMethods.length == 0 && props.userPaymentCardMethods.length > 0) {
				return true;
			} else {
				props.paymentCardMethods.forEach(
					(paymentCardMethod) => {	
						props.userPaymentCardMethods.forEach(
								(userPaymentCardMethod) => {
									if (userPaymentCardMethod.primaryUUID == paymentCardMethod.primaryUUID) {
										anyCards = true;					
									}
								}
							);								
					}		
				);
			
			}
			return anyCards;
		}

		const openCardWindow = () => {
			const winHtml = `<!DOCTYPE html>
					<html>
							<head>
									<title>Add Credit Card</title>
							</head>
							<body>
									<h1>Loading Credit Card Form</h1>
							</body>
					</html>`;

			const winUrl = URL.createObjectURL(
					new Blob([winHtml], { type: "text/html" })
			);

			return window.open(winUrl,'stripe','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600px,height=800px');

		}



	const refreshState = () => {
		state.show = props.show;			
	}

	onUpdated(() => {		
		if (props.userPaymentCardMethods) {
			console.log("Update called")
			state.userPaymentCardMethods = props.userPaymentCardMethods;
		}
		refreshState();
	});

	onMounted(() => {
		if (props.userPaymentCardMethods) {
			state.userPaymentCardMethods = props.userPaymentCardMethods;
		}
		refreshState();
	});



</script>
