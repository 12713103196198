<template>
	<!--
		primaryUUID: uuid(),
				tariffUUID: uuid(),
				bundleName: 'Consumer 1000',
				tariffName: 'Main Tariff',
				bundleType: 'plan',
				includedMinutes: 1000,
				bundlePrice: '4.99'
	-->
	<v-container class="px-6 py-0">		
		<v-card class="mb-2 pt-0 bg-secondary_600">
			<v-card-text class="">
				<v-row>
					<v-col class="pt-0">
						<v-row>
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Name</p>
								<p class="theme-font-other text-body-1" >{{ bundle.bundleName }}</p>
							</v-col>
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Type</p>
								<p class="theme-font-other text-body-1" >{{ t('statics.bundle.bundleType.'+bundle.bundleType) }}</p>
							</v-col>	
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Out of Bundle Tariff</p>
								<p class="theme-font-other text-body-1" >{{ bundle.tariffName }}</p>
							</v-col>						
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Included Minutes</p>
								<p class="theme-font-other text-body-1">{{ bundle.includedMinutes }}p</p>										
							</v-col>
							<v-col>
								<p class="theme-font font-weight-bold mr-2">Bundle Price</p>
								<td class="theme-font-other text-body-1">{{ bundle.bundlePrice }}</td>	
							</v-col>
						</v-row>
					</v-col>
				</v-row>				
			</v-card-text>
		</v-card>												
		<v-row>
			<v-col>
				<v-tabs density="compact"  v-model="state.tabsTop">
				
					<v-tab value="destinations" :class="(state.tabsTop== 'destinations') ? 'bg-active_sub_tab_color':''" >
						<p>Included Destinations</p>
					</v-tab>
									
				</v-tabs>
				<v-window v-model="state.tabsTop">				
					<v-window-item value="destinations">
						<v-container>	
							<v-row>
								<v-col class="d-flex ml-1">
									<v-card>
										<v-card-text class="pa-1">
											<p class="font-weight-bold">
												Show
											</p>
											<v-btn size="x-small" :class="(state.showMargins) ? 'theme-bg-highlight':''" class="mr-2 mt-1" @click="toggleMargins()">Margins</v-btn>												
										</v-card-text>
									</v-card>
									<v-spacer class="mt-3 text-center"><v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text" @click="showDestinationSelector()"><v-icon class="theme-list-action-icon">add</v-icon>Destinations</v-btn></v-spacer>
									
									
								</v-col>							
								<v-col>
									<v-combobox
										density="compact"
										v-model="state.search.countries"
										:items="state.filters.countries"
										variant="outlined"
										label="Filter By Country"
										multiple
										chips
										closable-chips
										@update:modelValue="doFilter(this)"
									></v-combobox>
								</v-col>
							</v-row>						
								
							<v-data-table				
								:items-per-page="state.dataTableConfig.itemsPerPage"
								:headers="state.dataTableConfig.headers"
								:items="state.data"
								:item-value="item => item.destinationCode"								
								class="elevation-1"
								no-data-text="No Destinations Included"
							>
								<template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
									<tr class="theme-box-header-background">
										<template v-for="column in columns" :key="column.key">
											<td style="background-color:transparent" :class="'text-'+column.align">
												<p class="mr-2 cursor-pointer text-body-1 theme-listing-header-text" @click="() => toggleSort(column)">{{ column.title }} <span v-if="state.showMargins && column.key != 'd'" class="text-caption"><br/>Rate / Cost / Margin</span></p>
												<template v-if="isSorted(column)">
													<v-icon :icon="getSortIcon(column)"></v-icon>
												</template>
												<v-icon v-if="column.removable" icon="$close" @click="() => remove(column.key)"></v-icon>
											</td>
										</template>
									</tr>
								</template>	
								<template v-slot:item.d="{ item }">								
									<p v-if="item.selectable.label">{{ item.selectable.label }}</p><p v-else>{{ item.selectable.d }}</p>
								</template>
								<template v-slot:item.Rp="{ item }">								
									<p :class="(item.selectable.country == 'AZ') ? 'text-warning':''">{{ item.selectable.Rp }}p <span v-if="state.showMargins">/ {{ item.selectable.p }}p</span> <span v-if="state.showMargins">/ {{ calculateMargin(item.selectable.p,item.selectable.Rp) }}%</span></p>									
								</template>	
								<template v-slot:item.Rop="{ item }">								
									<p :class="(item.selectable.country == 'AZ') ? 'text-warning':''">{{ item.selectable.Rop }}p <span v-if="state.showMargins">/ {{ item.selectable.op }}p</span> <span v-if="state.showMargins">/ {{ calculateMargin(item.selectable.op,item.selectable.Rop) }}%</span></p>
								</template>	
								<template v-slot:item.Rw="{ item }">								
									<p :class="(item.selectable.country == 'AZ') ? 'text-warning':''">{{ item.selectable.Rw }}p <span v-if="state.showMargins">/ {{ item.selectable.w }}p</span> <span v-if="state.showMargins">/ {{ calculateMargin(item.selectable.w,item.selectable.Rw) }}%</span></p>
								</template>		
								<template v-slot:item.actions="{ item }">																	
									<v-icon

										@click="removeDestination(item.selectable.destinationCode)"
									>
										delete
									</v-icon>
								</template>							
							</v-data-table>
						</v-container>
					</v-window-item>
					
				</v-window>
				
			</v-col>
		</v-row>		
	</v-container>
	<v-dialog v-model="state.showDestinationSelector">
		<v-row>
				<v-col md="2" sm="1">					
				</v-col>
				<v-col cols="12" sm="10" md="8">
					<v-card>
						<BoxHeader headerText="Select Destinations" :closeButton="(true)" @close="closeDestinationSelector"/>
						<v-card-text>
							<DestinationsSelector @dataEvents="selectDestinations" @close="closeDestinationSelector"/>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="2" sm="0">					
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import DestinationsSelector from './components/DestinationsSelector.vue';
	import BoxHeader from '@/components/BoxHeader.vue';
	import uuid from 'uuid-random';
	import * as fullDestinations from "./components/full-destinations.json";
	import * as bundleDestinations from "./components/bundle-destinations.json";
	import { VDataTable } from 'vuetify/labs/VDataTable';
	import { storeToRefs } from 'pinia';
	
	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const emits = defineEmits(['dataEvents']);

	const rateHeaders =  [
			{
				title: 'Destination',
				align: 'start',
				sortable: false,
				key: 'd',
			},		
			{ title: 'Peak', align: 'center', key: 'Rp' },
			{ title: 'Off Peak', align: 'center', key: 'Rop' },
			{ title: 'Weekend', align: 'center', key: 'Rw' },
			{ title: 'Actions', key: 'actions', sortable: false },	
		];

		const marginHeaders =  [
		{
				title: 'Destination',
				align: 'start',
				sortable: false,
				key: 'd',
			},		
			{ title: 'Peak', align: 'center', key: 'Rp' },
			{ title: 'Off Peak', align: 'center', key: 'Rop' },
			{ title: 'Weekend', align: 'center', key: 'Rw' },	
		];


	const state = reactive({
		tabsTop: 'destinations',
		view: 'rates',
		warnings: false,
		showMargins: false,
		search: {
			countries: undefined,
			countries2: undefined,
		},
		data: undefined,
		filters: {
			countries: [
				{value: 'AT' , code:"43", title:'Austria +43'},
				{value: 'AZ' , code:"994", title:'Azerbaijan +994'},
				{value: 'US' , code:"1", title:'USA +1'},
				{value: 'GB' , code:"44", title:'United Kingdom +44'},
				{value: 'FR' , code:"33", title:'France +33'},
			]
		},
		dataTableConfig : {
			headers: rateHeaders,
			page: 1,
			pageCount: 10,
			itemsPerPage: 10,
		},		
		showDestinationSelector: false,
	});

	const props = defineProps({		
		bundle: {
			type: Object,
			required: true,
		}	
	});


	const toggleMargins = () => {
		state.showMargins = !state.showMargins;
	}

	const toggleWarnings = () => {
		state.warnings = !state.warnings;
		if (state.warnings) {
			state.showMargins = true;		
		}
		doFilter();
	}

	const selectView = (viewName) => {
		state.view = viewName;
		switch (viewName) {
			case 'rates':
				state.dataTableConfig.headers = rateHeaders;
				break;
			case 'margins':
				state.dataTableConfig.headers = marginHeaders;
				break;
		}
	}

	const calculateMargin = (cost,rate) => {
		return Math.round( ((rate/cost)*100) -100 );
	}

	const processRates = () => {
		const markup = 1.3;
		const lowMarkup = 1.1;
		for (let i = 0; i < state.data.length;i++) {			
			if (state.data[i].country == 'AZ') {
				state.data[i].Rp = Math.ceil(100*state.data[i].p*lowMarkup)/100;
				state.data[i].Rop =Math.ceil(100* state.data[i].op*lowMarkup)/100;
				state.data[i].Rw = Math.ceil(100*state.data[i].w*lowMarkup)/100;
			} else {
				state.data[i].Rp = Math.ceil(100*state.data[i].p*markup)/100;
				state.data[i].Rop =Math.ceil(100* state.data[i].op*markup)/100;
				state.data[i].Rw = Math.ceil(100*state.data[i].w*markup)/100;
			}		
		}
	}

	const doFilter = (event) => {
		if (state.warnings) {
			const newData = [];
			for (let i = 0; i < bundleDestinations.rates.length;i++) {
			if (bundleDestinations.rates[i].country == 'AZ') {
						newData.push(bundleDestinations.rates[i]);
				}
			}						
			state.data = newData;
		} else if (state.search.countries && state.search.countries.length > 0) {
			const newData = [];
			for (let i = 0; i < bundleDestinations.rates.length;i++) {
				if (bundleDestinations.rates[i].country) {
					for (let j = 0; j < state.search.countries.length; j++) {						
						if (bundleDestinations.rates[i].country == state.search.countries[j].value||bundleDestinations.rates[i].code == state.search.countries[j].value) {
							newData.push(bundleDestinations.rates[i]);
						}
					}
				}			
			}
			state.data = newData;
		} else  {
			state.data = bundleDestinations.rates;
		}
		processRates();
	}

	const removeDestination = (destinationCode) => {
		console.log(destinationCode);
		const data = [];
		for (let i = 0; i < state.data.length;i++ ){
			if (destinationCode != state.data[i].destinationCode) {
				data.push(state.data[i]);
			}
		}
		state.data = data;
	}

	const selectDestinations = (event) => {
		if (event.destinations && event.destinations.length > 0) {
			console.log(JSON.stringify(event.destinations));
			let destination = undefined;
			for (let i = 0; i < fullDestinations.rates.length; i++) {
				destination = fullDestinations.rates[i];
				for (let j = 0; j < event.destinations.length;j++) {
					if (destination.destinationCode == event.destinations[j] ) {
						state.data.push(destination);
					}
				}
			}
		}
	}

	const showDestinationSelector = (event) => {
		state.showDestinationSelector = true;
	}


	const closeDestinationSelector = (event) => {
		state.showDestinationSelector = false;
	}

	onUpdated(() => {	
		
	});


	onMounted(() => {		
		state.data = bundleDestinations.rates;
		processRates();		
	});

</script>

<style  lang="scss">



</style>

