<template>
	<div v-for="contentFragment,index in state.contentFragments" :key="index" class="w-100">
		<div v-if="contentFragment.type == 'splashTemplate'" class="theme-pointer w-100">						
			<v-responsive v-if="contentFragment.imageType == 'image'">
				<div style="width:100%;min-height:20px" :style="(contentFragment.imageType == 'background' && contentFragment.imageUUID) ? getBackgroundImageStyle(contentFragment) : ''">								
					<v-img  v-if="contentFragment.imageType == 'image'" :src="mainStore.getContentImageUrl(contentFragment.imageUUID)"/>
					<div v-html="contentFragment.text"  :class="(contentFragment.outerClass) ? contentFragment.outerClass : ''" :style="(contentFragment.outerStyle) ? contentFragment.outerStyle : ''" v-else></div>
				</div>
			</v-responsive>
			<div style="width:100%;min-height:20px" :style="(contentFragment.imageType == 'background' && contentFragment.imageUUID) ? getBackgroundImageStyle(contentFragment) : ''" v-else>								
					<v-img  v-if="contentFragment.imageType == 'image'" :src="mainStore.getContentImageUrl(contentFragment.imageUUID)"/>
					<div v-html="contentFragment.text"  :class="(contentFragment.outerClass) ? contentFragment.outerClass : ''" :style="(contentFragment.outerStyle) ? contentFragment.outerStyle : ''" v-else></div>
				</div>	
		</div>		
		<div v-if="contentFragment.type == 'relatedPages'" class="d-flex theme-pointer" @click="goToContentPage(contentFragment)">
			<p class="text-caption mt-1 ml-10">{{ contentFragment.contentReference }}</p><p class="theme-font-other text-body-1 ml-5 text-decoration-underline">{{ contentFragment.summaryTitle }}</p>
		</div>
		<div v-if="contentFragment.type == 'imageBlock'">
			<!--<v-responsive :max-width="contentFragment.width" :min-width="contentFragment.width" :max-height="contentFragment.height" :min-height="contentFragment.height">			-->
				<v-card elevation="0">
					<v-img :src="mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)" :width="contentFragment.width" :height="contentFragment.height"/>				
				</v-card>
			<!---</v-responsive>-->
		</div>
		<div v-if="contentFragment.type == 'textImageBlock'">
			<!--<v-responsive :max-width="contentFragment.width" :min-width="contentFragment.width" :max-height="contentFragment.height" :min-height="contentFragment.height">			-->
				<div v-if="contentFragment.imagePosition == 'left'" class="d-flex">
					<v-card elevation="0">
						<v-img :src="mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)" :width="contentFragment.width" :height="contentFragment.height"/>
					</v-card>
					<p class="theme-content-textImageBlock theme-font-other text-body-1 text-justify" :class="'ml-'+state.pageConfiguration.bodyLeftIndent+' mr-'+state.pageConfiguration.bodyRightIndent+' mt-'+state.pageConfiguration.bodyLineSpacing+' mb-'+state.pageConfiguration.bodyLineSpacing" v-html="contentFragment.text"></p>
				</div>
				<div v-if="contentFragment.imagePosition == 'right'" class="d-flex">									
					<p class="theme-content-textImageBlock theme-font-other text-body-1 text-justify" :class="'ml-'+state.pageConfiguration.bodyLeftIndent+' mr-'+state.pageConfiguration.bodyRightIndent+' mt-'+state.pageConfiguration.bodyLineSpacing+' mb-'+state.pageConfiguration.bodyLineSpacing" v-html="contentFragment.text"></p>
					<v-card elevation="0">
						<v-img :src="mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)" :width="contentFragment.width" :height="contentFragment.height"/>
					</v-card>
				</div>
			<!---</v-responsive>-->
		</div>
		<div v-if="contentFragment.type == 'textBlock'"  >
			<p class="theme-content-textBlock theme-font-other text-body-1 text-justify" :class="'ml-'+state.pageConfiguration.bodyLeftIndent+' mr-'+state.pageConfiguration.bodyRightIndent+' mt-'+state.pageConfiguration.bodyLineSpacing+' mb-'+state.pageConfiguration.bodyLineSpacing" v-html="contentFragment.text"></p>
		</div>
		<div v-if="contentFragment.type == 'codeBlock'" class="bg-secondary_800 w-100" :class="'ml-'+state.pageConfiguration.bodyLeftIndent+' mr-'+state.pageConfiguration.bodyRightIndent+' mt-'+state.pageConfiguration.bodyLineSpacing+' mb-'+state.pageConfiguration.bodyLineSpacing" style="border: 1px solid black;position:relative;overflow:hidden">
			<v-btn  style="position:absolute;align:right;right:0;" size="x-small" @click="copyToClipBoard(contentFragment.text)">          
					<p class="text-caption">COPY</p>
        </v-btn>
			<code class="pa-0 text-black theme-content-codeBlock"><pre class="ma-5">{{ contentFragment.text }}</pre></code>
		</div>
		<div v-if="contentFragment.type == 'stepBlock'"  >
			<p class="theme-content-stepBlock" :class="'ml-'+state.pageConfiguration.stepLeftIndent+' ml-'+state.pageConfiguration.stepRightIndent+' mt-'+state.pageConfiguration.stepLineSpacing+' mb-'+state.pageConfiguration.stepLineSpacing">{{ stepFront(index,contentFragment) }} {{ contentFragment.text }}</p>
			{{ stepEnd(index,contentFragment) }}
		</div>
		<div v-if="contentFragment.type == 'titleBlock'" >
			<p class="theme-content-titleBlock mt-2 mb-5" :class="'text-h'+contentFragment.size" >{{ contentFragment.text }}</p>
		</div>
		<div v-if="contentFragment.type == 'commandOutcomeBlock'" class="w-100">
			<v-row v-if="(index == 0)">
				<v-col><p class="font-weight-bold text-center">Outcome</p></v-col>
				<v-col></v-col>
			</v-row>
			<v-row>
				<v-col><p class="font-weight-bold text-center">{{ contentFragment.outcome }}</p></v-col>
				<v-col><p class="text-left">{{ contentFragment.message }}</p></v-col>
			</v-row>
			<!--<v-table>
				<tbody>										
					<tr>	
						<td class="font-weight-bold">{{ contentFragment.outcome }}</td><td><p>{{ contentFragment.message }}</p></td>
					</tr>																
				</tbody>
			</v-table>																	-->
		</div>
		<div v-if="contentFragment.type == 'commandDetailBlock'" class="ma-2 w-100" >
			<v-table>
				<tbody>
					<tr>	
						<td colspan="2">
							<p>{{ contentFragment.description}}</p>
						</td>
					</tr>
					<tr>	
						<td class="font-weight-bold">App</td><td><p>{{ contentFragment.appName}}</p></td>
					</tr>
					<tr>	
						<td class="font-weight-bold">Command Group</td><td><p>{{ contentFragment.commandGroup.replaceAll('.','/') }}</p></td>
					</tr>										
					<tr>	
						<td class="font-weight-bold">Version</td><td><p>{{ contentFragment.version}}</p></td>
					</tr>
					<tr>	
						<td class="font-weight-bold">Auth Types</td><td>
							<p>{{ contentFragment.commandAuthTypes.replaceAll(',',' ')}}</p>
						</td>
					</tr>
					<tr>	
						<td class="font-weight-bold">Endpoint URL</td><td>
							<p>https://{{ mainStore.phoneServiceName }}.api.i164.co.uk/api/{authType}/{{ contentFragment.commandGroup.replaceAll('.','/') }}</p>
						</td>
					</tr>										
				</tbody>
			</v-table>
		</div>
		<div v-if="contentFragment.type == 'propertySchemaBlock'" class="ma-2 w-100" >
			<v-table class=" full-width" density="compact">									
				<tbody>
					<tr class="w-100">									
						<td style="vertical-align:top" class="w-33">
							<p class="theme-font text-h6 font-weight-bold" >{{ contentFragment.propertyName }}</p>
							<p class="theme-font-other text-body-2 mt-2" >{{ contentFragment.description }}</p>
						</td>
						<td class="w-66" style="vertical-align:top">
							<v-table>
								<tbody>
									<tr>	
										<td class="font-weight-bold">Required</td><td><p>{{ contentFragment.required}}</p></td>
									</tr>
									<tr v-if="contentFragment.propertySchema">	
										<td class="font-weight-bold">type</td><td><p>{{ contentFragment.propertySchema.type }}</p></td>
									</tr>
									<tr v-if="contentFragment.propertySchema && contentFragment.propertySchema.minLength">	
										<td class="font-weight-bold">minLength</td><td>{{ contentFragment.propertySchema.minLength }}</td>
									</tr>
									<tr v-if="contentFragment.propertySchema && contentFragment.propertySchema.maxLength">	
										<td class="font-weight-bold">maxLength</td><td>{{ contentFragment.propertySchema.maxLength }}</td>
									</tr>
									<tr v-if="contentFragment.propertySchema && contentFragment.propertySchema.pattern">	
										<td class="font-weight-bold">pattern</td><td>{{ contentFragment.propertySchema.pattern }}</td>
									</tr>
									<tr v-if="contentFragment.propertySchema && contentFragment.propertySchema.exampleValue">	
										<td class="font-weight-bold">exampleValue</td><td>{{ contentFragment.propertySchema.exampleValue }}</td>
									</tr>
								</tbody>
							</v-table>												
						</td>
						
					</tr>																							
				</tbody>
			</v-table>
			<v-divider></v-divider>																
		</div>	
			
	</div>

</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';


const { t }  = useI18n();

const emits = defineEmits(['goToContent']);

const router = useRouter();

const mainStore = useMainStore();

const props = defineProps({		
	contentConfiguration: {
		type: Object,
		required: true,
	},
	contentFieldName: {
		type: String,
		required: true,			
	},
	contentFragments: {
		type: Array,
		required: true,
	},
	pageContext: {
		type: Object,
		required:true,
	},
	pageConfiguration: {
		type: Object,
		required: false,
	}
});

const state = reactive({	
	dataReady: false,	
	edit: false,		
	pageConfiguration: {
		bodyLeftIndent: 3,
		bodyRightIndent: 3,
		bodyLineSpacing: 3,
		stepLeftIndent: 4,
		stepRightIndent: 3,
		stepLineSpacing: 3,
	},
	contentConfiguration: undefined,
	contentFieldName: undefined,
	contentFragments: undefined,
	contentFragment: undefined,
});

const stepFront = (index,contentFragment) => {
	if (contentFragment.bulletType == 'count') {
		props.pageContext.stepCount = props.pageContext.stepCount+1;		
		return props.pageContext.stepCount+'.';
	}
}

const stepEnd = (index,contentFragment) => {
	if (!contentFragment.keepCounting) {
		props.pageContext.stepCount = 0;
	}
}


const  getBackgroundImageStyle = (imageUUID) => {
	return "background: url('"+mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)+"') no-repeat ;background-size: cover;";
}



const copyToClipBoard = (textToCopy) => {
	navigator.clipboard.writeText(textToCopy);
	mainStore.displaySnackBar("Text Copied");
}

const goToContentLink = (primaryUUID) => {
	emits('goToContent',
		{
			primaryUUID: primaryUUID,
		}
	);
}

const goToContentPage = (contentFragment) => {
	emits('goToContent',
		{
			primaryUUID: contentFragment.primaryUUID,
		}
	);
}

onUpdated(() => {

});

onMounted(() => {
	if (props.contentConfiguration) {
		state.contentConfiguration = props.contentConfiguration;
	} 
	if (props.contentFieldName) {
		state.contentFieldName = props.contentFieldName;		
	} 
	if (props.contentFragments) {
		state.contentFragments = props.contentFragments;
	} 	
	if (props.pageConfiguration) {
		state.pageConfiguration = props.pageConfiguration;
	}
	state.counting = false;
});
</script>

<style>
</style>