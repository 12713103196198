<template>
<v-container fluid class="px-6 py-6 h-100">
			<v-row class="mb-2">			
				<v-col md="12">			
					<v-row>
						<v-col class="ma-0 pa-0">
							<v-spacer align="right">
								<v-row>
									<v-col cols="4" sm="7" md="7">
									</v-col>
									<v-col cols="5" sm="3" md="2">	
										<v-select
											:items="state.filters.showValues"								
											label="Filter By Status(s)"
											variant="solo"
											item-title="value"
											item-value="key"
											v-model="state.filterValues.show"
											@update:modelValue="listPhoneServiceUserParticpations"									
											></v-select>
									</v-col>
									<v-col cols="3" sm="1" md="3" class="d-flex mt-4">
										<v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text" @click="inviteUser()"><v-icon class="theme-list-action-icon">person</v-icon>Invite</v-btn>
										<v-btn class="ml-2  theme-list-action-button-background  text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
									</v-col>									
								</v-row>
							</v-spacer>
						</v-col>	
					</v-row>	
						
					 

				</v-col>				
			</v-row>
		
			<v-row>			
				<v-col md="0">
					<v-card class="card-shadow border-radius-md listDisplay d-flex flex-column">						
						
						<v-table  class="listTable"  density="compact">														
							<thead class="theme-list-header-background">
								<tr>
									<th class="text-center text-h6 theme-listing-header-text">Name</th>
									<th class="text-center text-h6 theme-listing-header-text">Email Address</th>
									<th class="text-center text-h6 theme-listing-header-text">Role(s)</th>	
									<th class="text-center text-h6 theme-listing-header-text">Status</th>									
									<th class="text-center text-h6 theme-listing-header-text"></th>
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="phoneServiceUserParticipation in state.phoneServiceUserParticipations"  :key="phoneServiceUserParticipation.primaryUUID">
									<td class="text-center">{{ phoneServiceUserParticipation.firstName }} {{ phoneServiceUserParticipation.lastName }}</td>
									<td class="text-center">{{ phoneServiceUserParticipation.emailAddress }}</td>
									<td class="text-center">
										<v-expansion-panels v-if="phoneServiceUserParticipation.phoneServiceUserGrants.length > 1">
												<v-expansion-panel
													elevation="0"
													>
													<template v-slot:title>	
														<p class="text-center">{{ t("statics.roles."+getOperationalRole(phoneServiceUserParticipation.phoneServiceUserGrants).replaceAll('.','_')) }}</p>
													</template>													
													<template v-slot:text>	
														<p v-for="phoneServiceUserGrant in getAppPhoneServiceUserGrants(phoneServiceUserParticipation.phoneServiceUserGrants)" :key="phoneServiceUserGrant.primaryUUID">
															{{ mainStore.getRoleText(phoneServiceUserGrant.roleName) }}											
														</p>													
													</template>
												</v-expansion-panel>
											</v-expansion-panels>
											<p v-else>{{ t("statics.roles."+getOperationalRole(phoneServiceUserParticipation.phoneServiceUserGrants).replaceAll('.','_')) }}</p>
									</td>	
									<td class="text-center">{{ t("statics.lifecyclePhases.user."+phoneServiceUserParticipation.onboarding) }}</td>	
									<td class="text-center">
										<v-icon @click="manageUser(phoneServiceUserParticipation);">settings</v-icon>
										<!--<v-menu location="center">
											<template v-slot:activator="{ props }">
												<v-icon v-bind="props">menu</v-icon>
											</template>

											<v-list>
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="manageUser(phoneServiceUserParticipation);">Manage User</v-list-item-title>
												</v-list-item>
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="showRemoveUser(phoneServiceUserParticipation);">Remove User</v-list-item-title>
												</v-list-item>
											</v-list>
										
										</v-menu>-->
									</td>								
								</tr>								
							
							</tbody>

						</v-table>

						 
							<!--<v-pagination
										v-model="state.pagination.page"
										:length="state.pagination.length"
										:total-visible="state.pagination.totalVisible"
										></v-pagination>-->
					</v-card>	
				</v-col>
			</v-row>
			<v-row class="pd-10">
				&nbsp;
			</v-row>			
		</v-container>		
		<UserParticipantInviteFormDialog :show="state.showInviteUserDialog" @close="closeInviteUserDialog(this)"/>
		<!--<v-dialog persistent v-model="state.showInviteUserDialog" max-width="500px">
			<v-card>
		
				<v-card-text>				
					<v-row>
						<v-col class="pa-0" >
							<v-row class="theme-dark-background-gradient">
								<v-col class="d-flex ma-2">
									<p class="theme-font text-h5 text-white">Invite a User to Your Account</p>
									<v-spacer align="right" @click="quitInviteUserDialog(this);"><v-icon>close</v-icon></v-spacer>
								</v-col>	
							</v-row>
						</v-col>
					</v-row>				
					<v-row>
						<v-col class="mt-5"><p class="theme-font-other">We will send an email to the user requesting they join this service.</p></v-col>
					</v-row>
					<v-row>
						<v-col class="pt-5"><v-text-field  density="compact" label="Email Address of the User" prepend-inner-icon="email"
        			variant="outlined"  hide-details="auto" autocomplete="none" v-model="state.inviteForm.emailAddress" ></v-text-field></v-col>
					</v-row>
					<v-row>
						<v-col class="pt-5"><v-text-field  density="compact" label="First Name" 
        			variant="outlined"  hide-details="auto" autocomplete="none" v-model="state.inviteForm.firstName" ></v-text-field></v-col>
					</v-row>
					<v-row>
						<v-col class=""><v-text-field  density="compact" label="Last Name" 
        			variant="outlined"  hide-details="auto" autocomplete="none" v-model="state.inviteForm.lastName" ></v-text-field></v-col>
					</v-row>
					<v-row>
						<v-col class="mb-0" ><v-select
													:items="state.userRoles"
													item-title="roleName"
    											item-value="roleId"													
													label="Role"					
													density="compact"
													variant="outlined"
													v-model="state.inviteForm.roleName"													 
												></v-select></v-col>
					</v-row>
					<v-row v-if="state.inviteForm.roleName"> 
						<v-col class="mt-0 pt-0">
								<p class="text-caption ma-1" v-if="state.inviteForm.roleName == 'operational.phoneService.owner'">The Account Owner has full control over the account. Including the ability to close the account.</p>
								<p class="text-caption ma-1" v-if="state.inviteForm.roleName == 'operational.phoneService.admin'">An Admin has administrative control only, they cannot invite other users and are limited in their purchasing of services.</p>
								<p class="text-caption ma-1" v-if="state.inviteForm.roleName == 'operational.phoneService.user'">The Account user has basic access priviledges.</p>
								
						</v-col>
					</v-row>
					<v-row>
						<v-col><v-textarea
														name="messageToUser"
														label="Reason for the Invite"
														v-model="state.inviteForm.messageToUser"
														variant="solo"  
														hint="This will be included with the invite."
													></v-textarea></v-col>
					</v-row>
					<v-row>
						<v-col class="text-center mt-5">
							<v-btn @click="inviteUserToPhoneService()">Invite User</v-btn>
							</v-col>
					</v-row>							
				</v-card-text>
						
			</v-card>
		</v-dialog>
		<v-dialog persistent v-model="state.showRemoveUserDialog" max-width="500px">
			<v-card>
				<v-card-title class="text-h5 theme-font d-flex">Remove User From Account<v-spacer></v-spacer>

                  <v-icon class="ml-5" @click="quitRemoveUserDialog(this)">close</v-icon>

					</v-card-title>
				<v-card-text>				
															
					<v-row v-if="state.phoneServiceUserParticipationToRemove && (state.removeUserErrorOutcome == 926 || state.ownerCount ==1)" class="vx-green-lighter-background">
						<v-col class="pt-5">
							<p class="theme-font-other"><v-icon class="mr-2" color="red">warning</v-icon> You cannot remove this user. There must always be an owner on the account.</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="pt-5"><v-icon class="mr-2">person</v-icon>{{state.phoneServiceUserParticipationToRemove.firstName}} {{state.phoneServiceUserParticipationToRemove.lastName}}</v-col>
					</v-row>
					<v-row>
						<v-col class="pt-5"><v-icon class="mr-2">email</v-icon>{{state.phoneServiceUserParticipationToRemove.emailAddress}}</v-col>
					</v-row>
					
					
					<v-row>
						<v-col class="text-center mt-5">
							<v-btn @click="removeUserFromPhoneService(true,false)" :disabled="(state.removeUserErrorOutcome == 926 || state.ownerCount ==1)">Remove User</v-btn>
							</v-col>
					</v-row>							
				</v-card-text>
						
			</v-card>
		</v-dialog>-->
</template>


<script setup>
  import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import { useRoute, useRouter } from "vue-router";
	import UserParticipantInviteFormDialog from './components/UserParticipantInviteFormDialog.vue';

	const router = useRouter();
	const { t }  = useI18n();
	const mainStore = useMainStore();

	const emits = defineEmits(['selectUser']);

	const state = reactive({
		phoneServiceUserParticipations : undefined,
		filterValues: {
				show: 'showAll',
				recordCount: 10,
		},		
		showInviteUserDialog: false,
		showRemoveUserDialog: false,
		ownerCount: -1,
		phoneServiceUserParticipationToRemove: undefined,
		removeUserErrorOutcome: undefined,
		removeUserErrorMessage: undefined,			
		pagination: {
						page: 2,
						length: 4,
						totalVisible: 7,
			},
		filters: {
				showValues: [
					{ key: 'showAll', value: 'All Users' },
					{ key: 'showCurrent', value: 'Live' },
					{ key: 'showAwaiting', value: 'Invited' },
			],
		}	,
	});



const refreshList = () => {
	listPhoneServiceUserParticpations(true);
}

const inviteUser = () => {	
	state.showInviteUserDialog = true;
}

const closeInviteUserDialog = () => {
	state.showInviteUserDialog = false;
	refreshData();
}

const manageUser = (phoneServiceUserParticpation) => {
	emits('selectUser',
		{
			state: 'selectedUser',
			phoneServiceUserParticpation: phoneServiceUserParticpation
		}
	);
}

const getOperationalRole = (phoneServiceUserGrants) => {
	let operationalRole = 'None';
	phoneServiceUserGrants.forEach(
		phoneServiceUserGrant => {
			if (phoneServiceUserGrant.roleName.startsWith('operational.')) {
				operationalRole = phoneServiceUserGrant.roleName;
			}
		}
	);		
	return operationalRole;
}

const getAppPhoneServiceUserGrants = (phoneServiceUserGrants) => {
	let appGrants = [];
	phoneServiceUserGrants.forEach(
		phoneServiceUserGrant => {
			if (!phoneServiceUserGrant.roleName.startsWith('operational.')) {
				appGrants.push(phoneServiceUserGrant);				
			}
		}
	);	
	return appGrants;
}

const countOwners = () => {
	if (state.phoneServiceUserParticipations) {
		let ownerCount = 0;
		state.phoneServiceUserParticipations.forEach(
			phoneServiceUserParticipation => {
				phoneServiceUserParticipation.phoneServiceUserGrants.forEach(
					phoneServiceUserGrant => {
						if (!phoneServiceUserGrant.stopDate) {
							if (phoneServiceUserGrant.roleName == 'operational.phoneService.owner') {
								ownerCount++;
							}
						}
					}
				)				
		});
		state.ownerCount = ownerCount;
	}
}


const listPhoneServiceUserParticpations = async (show) => {
			const apiUrl = mainStore.getAPIUrl();			
			const data = new URLSearchParams();
			data.set("show",state.filterValues.show);
			await fetch(apiUrl+'/phoneServices/listPhoneServiceUserParticipations', {
				body: data,
				method: 'post',				
				credentials: 'include',
				redirect: 'follow',		
			}).then((response) => {
				response.json().then( 
					d => {
						console.log(d)							
						if (d.outcome == 1) {
							mainStore.displaySnackBar('List Updated');			
							state.phoneServiceUserParticipations = d.phoneServiceUserParticipations;
							countOwners();
						}							
					}									
				)
				return response;
			}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
		});		
	}


	const refreshData = () => {
		listPhoneServiceUserParticpations();
	}

	onMounted(() => {
		refreshData();
	});
	
</script>

<style scoped>

</style>