<template>

  	<v-container fluid class="px-6 py-6 h-100">
			<v-row class="mb-2">			
				<v-col md="12">			
					<v-row>
						<v-col md="3" class="d-flex">
						<v-avatar
							class="shadow border-radius-xl mt-1"
							size="48" color="avatar_background"
						><v-icon class="vx-text-dark theme-avatar-color" size="32">numbers</v-icon></v-avatar>
							<p class="mt-3 ml-3 font-weight-bold text-h4 vx-text-dark theme-font-bold">Services</p>
						</v-col>
						<v-col md="4">
											
						</v-col>
						<v-col md="3">
							
						</v-col>
						<v-col md="2" class="">
					
						</v-col>
					</v-row>
				</v-col>			
			</v-row>
			<v-row>
				<v-col align="right">
					
					<v-btn class="pl-2 theme-list-action-button-background  text-action_button_text" @click="createNewService()" size="small"><v-icon class="theme-list-action-icon">add</v-icon>Service</v-btn>
					<v-btn class="ml-2 theme-list-action-button-background   text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
				</v-col>
			</v-row>
			<v-row class="mb-12 listRow">							
				<v-col md="12">
					<v-card class="card-shadow border-radius-md listDisplay d-flex flex-column">
						
						<v-table class="listTable" density="compact">
							<thead class="theme-list-header-background">
								<tr>
									<th class="text-center text-h6  theme-listing-header-text">Account Holder</th>	
									<th class="text-center text-h6  theme-listing-header-text">Type</th>
									<th class="text-center text-h6  theme-listing-header-text">Service Account Name</th>	
									<th class="text-center text-h6  theme-listing-header-text">Your Roles</th>
									<th class="text-center text-h6  theme-listing-header-text">Status</th>
									<th class="text-center text-h6  theme-listing-header-text">Actions</th>						
								</tr>							
							</thead>
							<tbody>
								<tr v-for="domain in state.phoneServices"  :key="domain.phoneServiceUUID">									
									<td class="text-center">{{ domain.phoneServiceDetails.accountHolderName }}</td>		
									<td class="text-center">{{ t("statics.phoneService.phoneServiceType."+domain.phoneServiceType) }} </td>
									<td class="text-center">{{ domain.phoneServiceName }}</td>		
									<td class="text-center"><span v-for="role in mainStore.getUserRolesForDomain(domain.phoneServiceName)" :key="role">{{ t("statics.roles."+role.replaceAll('.','_')) }}</span></td>	
									<td class="text-center">{{ t("statics.phoneService.status."+domain.status) }} </td>
									<td class="text-center"> 
										<v-menu location="center">
											<template v-slot:activator="{ props }">
												<v-icon 	v-bind="props">menu</v-icon>
											</template>

											<v-list v-if="domain.status == 1">
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="openAssembler(event,domain)">Go To Account</v-list-item-title>
												</v-list-item>
											</v-list>
											<v-list v-else-if="domain.status == 5">
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="loadServiceForm(domain.phoneServiceName)" >Edit Application</v-list-item-title>													
												</v-list-item>
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="state.deleteDomain = domain;state.showDelete = true;">Delete Application</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</td>									
								</tr>								
							</tbody>

						</v-table>
					</v-card>
				</v-col>
			</v-row>
		</v-container>	
		<v-dialog v-model="state.showDelete">
			<v-row>
				<v-col md="4" sm="2">					
				</v-col>
				<v-col cols="12" sm="8" md="4">
					<v-card v-if="state.showDelete">
						<v-card-title class="theme-font">Delete New Service Application?</v-card-title>
						<v-card-text v-if="state.deleteDomain">
							<p>Are you sure you want to delete this application for a new service?</p>
							<p class="mt-5"><b>Account Holder Name:</b> {{ state.deleteDomain.phoneServiceDetails.accountHolderName }}</p>
						</v-card-text>
						<v-card-actions class="d-flex">
							<div><v-btn @click="deletePhoneServiceApplication(state.deleteDomain.phoneServiceName,state.deleteDomain.phoneServiceUUID);">Yes</v-btn></div><div align="right"><v-btn @click="closeDelete()">No</v-btn></div>
						</v-card-actions>
					</v-card>
				</v-col>
				<v-col md="4" sm="2">					
				</v-col>
			</v-row>

		</v-dialog>
</template>

<style scoped l>

.listRow {
		height: 100%;	
	}

	.listDisplay {	
		height: calc( 100% - 100px);
	}

	.listTable {	
		height: calc( 100% - 100px);
	}

</style>

<script setup>
  import { defineProps , inject, ref, reactive,onMounted } from 'vue';
	import { useMainStore } from "../../stores/MainStore.js";
	import { useI18n } from 'vue-i18n';
	import { useRoute, useRouter } from "vue-router";

	const router = useRouter();
	const { t }  = useI18n();

	const props = defineProps({
        phoneServiceName: {
            type: String
        }
    });

	const mainStore = useMainStore();

	const state = reactive({
			phoneServices: [],
			dialogDetails: {
					showDialog: false,
					allocatedNumber: {}
				},
      voxletServiceContexts: [],
			filters: {
				
			},
			tabs: [],			
			showDelete: false,
			deleteDomain: undefined,
    });

	const refreshList = () => {
		getServiceParticipation(true);
	}

		const close = () => {
			state.dialogDetails.showDialog = false;
		}

		const closeDelete = () => {
			state.showDelete = false;	
			state.deleteDomain = undefined;
		
		}

		const deletePhoneServiceApplication = async (phoneServiceName,phoneServiceUUID) => {		
				const apiUrl = mainStore.getAPIUrl(phoneServiceName);			
				const data = new URLSearchParams();
				data.set('phoneServiceUUID', phoneServiceUUID);		
				await fetch(apiUrl+'/phoneServices/deletePhoneServiceApplication', {
					method: 'post',
					body: data,
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {
								mainStore.displaySnackBar('Application Deleted');	
								closeDelete();
								getServiceParticipation();																						
							} else {									
									console.log('Failed '+d.outcome);
									mainStore.displaySnackBar(d.message);	
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
		}


			

		const createNewService = () => {
			router.push({name: 'plustagNewServiceForm'});
		}

		const loadServiceForm = (serviceName) => {
			console.log(serviceName);
			router.push({name: 'plustagEditServiceForm', params:{phoneServiceName: serviceName,selectedStage: 1}});
		}

		const findWindow = (phoneServiceName) => {
				
		}

		const manageTabs = (phoneServiceName) => {

		}

		const openAssembler =  (event,domainDetails) => {e
			var tab = null;
			state.tabs.forEach(
				(_tab) => {
					if ( _tab.name == domainDetails.phoneServiceName) {
						console.log('tab exists');
						tab = _tab;
					}
				}
			);
			if (tab == undefined) {
				const ref = Math.floor(Math.random() * 1000);
				tab = window.open('/#/assembler/'+domainDetails.phoneServiceName+"/account?ref="+ref,domainDetails.phoneServiceName);
				state.tabs.push(tab);
			} else {
				tab.focus();
			}
			return ;
		};

		const getServiceParticipation = async (show) => {
				const apiUrl = mainStore.getAPIUrl('plustag');				
				await fetch(apiUrl+'/plustag/listServiceParticipation', {
					method: 'get',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == -3) {
							} else {
								if (show) {mainStore.displaySnackBar('List Updated');}
								state.phoneServices = d.phoneServices;
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);

			});		
		}

	onMounted(() => {
		getServiceParticipation();
	});
  
</script>
