<template>
	<v-hover  v-if="state.onboarding" >
 <template v-slot:default="{ isHovering, props }">
	<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
		<BoxHeader headerText="Subscriber Address" :dataReady="state.dataReady" readyIcon="check_circle"/>
		<v-card-text>		
			<v-row v-if="!state.selectedPostcodeAddress">
				<v-col class="d-flex ma-5">
					<v-text-field label="Postcode" variant="outlined" v-model="state.postCodeLookup.postCodeToLookup" class="mr-5" @keydown.enter="lookupAddressDetails()"></v-text-field>
					<v-btn icon="search" :class="state.postCodeLookup.postCodeToLookup ? 'theme-bg-highlight mt-1' : 'mt-1'"
								:disabled="!state.postCodeLookup.postCodeToLookup" @click="lookupAddressDetails()"></v-btn>
				</v-col>
			</v-row>
			<v-row v-if="state.postCodeError">
				<v-col class="ma-5">
						{{state.postCodeError}}
				</v-col>
			</v-row>
			<v-row v-if="(state.postCodeLookup.postCodeLookupResults && !state.selectedPostcodeAddress)">	
						<v-col>
							<v-select
								density="compact"
								v-model="state.selectedPostcodeAddress" 												
								@update:modelValue="selectedAddress()"
								:items="state.postCodeLookup.postCodeLookupResults"		
								:item-title="item => (item.addressLine2) ? item.addressLine1+', '+item.addressLine2+', '+item.postTown: item.addressLine1+', '+item.postTown"
								label="Choose an address"
								:item-value="item => item"
								variant="underlined"								
							>	
							</v-select>
						</v-col>									
			</v-row>			
			<v-row v-if="state.selectedPostcodeAddress">	
				<v-col class="ma-5">											
					<v-row>
						<v-col class="d-flex">
							<p class="theme-font-other font-weight-bold">Address</p><v-spacer  align="right"><v-btn size="x-small" @click="changeAddress(this)" v-if="!state.readOnly">CHANGE</v-btn></v-spacer>
						</v-col>
					</v-row>	
					<v-row>
						<v-col>
							<p class="theme-font-other">{{ state.subscriberAddress.addressLine1}}</p>
						</v-col>
					</v-row>		
					<v-row v-if="state.subscriberAddress.addressLine2">
						<v-col>
							<p class="theme-font-other">{{ state.subscriberAddress.addressLine2 }}</p>
						</v-col>
					</v-row>	
					<v-row v-if="state.subscriberAddress.addressLine3">
						<v-col>
							<p class="theme-font-other">{{ state.subscriberAddress.addressLine3 }}</p>
						</v-col>
					</v-row>		
					<v-row>
						<v-col>
							<p class="theme-font-other">{{ state.subscriberAddress.postTown }}</p>
						</v-col>
					</v-row>	
					<v-row>
						<v-col>
							<p class="theme-font-other">{{ state.subscriberAddress.county }}, {{ state.subscriberAddress.postcode }}</p>
						</v-col>
					</v-row>	
					<v-row>
						<v-col align="right">
							<p class="theme-font-other"></p>
						</v-col>
					</v-row>	
					<v-row>
						<v-col>
							<v-divider></v-divider>
						</v-col>
					</v-row>	
					<v-row>
						<v-col  class="d-flex">
							<p class="theme-font-other font-weight-bold">User Details</p><v-spacer  align="right"><v-btn size="x-small" @click="changeUserDetails(this)" v-if="state.dataReady && !state.readOnly">EDIT</v-btn></v-spacer>
						</v-col>
					</v-row>	
					<v-row v-if="!state.dataReady">
						<v-col>
							<v-row>
								<v-col>
									<v-radio-group
										v-model="state.subscriberAddress.usageType"
										inline								
										>
										<v-radio
											label="Personal"
											value="1"
										></v-radio>
										<v-radio
											label="Company"
											value="2"
										></v-radio>
									</v-radio-group>
								</v-col>
							</v-row>
							<v-row v-if="state.subscriberAddress.usageType == 1">													
								<v-col class="d-flex">
										<v-text-field label="First Name" variant="outlined" v-model="state.subscriberAddress.foreName"></v-text-field> <v-text-field label="Last Name" variant="outlined" v-model="state.subscriberAddress.name" class="ml-5"> </v-text-field>
								</v-col>													
							</v-row>	
							<v-row v-if="state.subscriberAddress.usageType == 2">
								<v-col>
									<v-row>
										<v-col><v-text-field label="Business Name" variant="outlined" v-model="state.subscriberAddress.name" class=""></v-text-field></v-col><v-col md="4"> <v-text-field v-model="state.subscriberAddress.businessSuffix" label="Suffix (Ltd)" hint="Ltd, Limited, LLC" variant="outlined"></v-text-field></v-col>
									</v-row>														 
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-btn @click="saveUserDetails(this)">Done</v-btn>
								</v-col>
							</v-row>		
						</v-col>
					</v-row>
					<v-row v-else>
						<v-col v-if="state.subscriberAddress.usageType == 1">
							<v-row>
								<v-col md="4" class="theme-font-other font-weight-bold">Type</v-col><v-col md="8" class="theme-font-other">Personal</v-col>
							</v-row>							
							<v-row>
								<v-col md="4" class="theme-font-other font-weight-bold">First Name</v-col><v-col md="8" class="theme-font-other">{{
										state.subscriberAddress.foreName
								}}</v-col>
							</v-row>
							<v-row>
								<v-col md="4" class="theme-font-other font-weight-bold">Last Name</v-col><v-col md="8" class="theme-font-other">{{
										state.subscriberAddress.name
								}}</v-col>
							</v-row>
						</v-col>
						<v-col v-if="state.subscriberAddress.usageType == 2">
							<v-row>
								<v-col md="4" class="theme-font-other font-weight-bold">Type</v-col><v-col md="8" class="theme-font-other">Suffix</v-col>
							</v-row>			
							<v-row>
								<v-col md="4" class="theme-font-other font-weight-bold">Name</v-col><v-col md="8" class="theme-font-other">{{
										state.subscriberAddress.name
								}}</v-col>
							</v-row>
							<v-row>
								<v-col md="4" class="theme-font-other font-weight-bold">Business</v-col><v-col md="8" class="theme-font-other">{{
										state.subscriberAddress.businessSuffix
								}}</v-col>
							</v-row>
						</v-col>
					</v-row>																														
				</v-col>
			</v-row>														
		</v-card-text>
	</v-card>
	
	</template>
	</v-hover>
	<v-card v-else>
		<v-card-text>Initialising</v-card-text>
	</v-card>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
import { STATEMENT_OR_BLOCK_KEYS } from '@babel/types';
import BoxHeader from '@/components/BoxHeader.vue';


	const { t }  = useI18n();
	
	const route = useRoute();

	const mainStore = useMainStore();
	
	const state = reactive({
		onboarding: undefined,
		refreshVersion: 0,
		dataReady: false,		
		readOnly : false,
		postCodeLookup: {
			postCodeToLookup : 'ID1 1QD',
			postCodeError: undefined,
			postCodeLookupResults: undefined,			
		},
		selectedPostcodeAddress: undefined,
		subscriberAddress: {			
			usageType: "1",
			udprn: undefined,
			foreName: undefined,
			name: undefined,
			businessSuffix: undefined,
			addressLine1: undefined,
			addressLine2: undefined,
			addressLine3: undefined,
			postTown: undefined,
			county: undefined,
			postcode: undefined,
			country: undefined,
		}
	});

	const emits = defineEmits(['dataEvents']);

	const fireDataReadyEvent = () => {
			emits('dataEvents',
				{
					state: 'ready',
					subscriberAddress: state.subscriberAddress,
				}
			);
	}

	const fireDataNotReadyEvent = () => {
			emits('dataEvents',
				{
					state: 'notReady',
					subscriberAddress: state.subscriberAddress,
				}
			);
	}


	const props = defineProps({
		refreshConfiguration: {
			type: Object,
		},
		subscriberAddressConfiguration: {
			type: Object,
		},
		assetData: {
			type: Array,			
		},
		readOnly: Boolean,
	});

	const saveUserDetails =(event) => {		
		state.dataReady = true;
		state.refreshVersion++;
		fireDataReadyEvent();
	}

	const selectedAddress = () => {		
		state.subscriberAddress.udprn = state.selectedPostcodeAddress.udprn;
		state.subscriberAddress.addressLine1 = state.selectedPostcodeAddress.addressLine1;
		state.subscriberAddress.addressLine2 = state.selectedPostcodeAddress.addressLine2;
		state.subscriberAddress.addressLine3 = state.selectedPostcodeAddress.addressLine3;
		state.subscriberAddress.postTown = state.selectedPostcodeAddress.postTown;		
		state.subscriberAddress.county = state.selectedPostcodeAddress.county;
		state.subscriberAddress.postcode = state.selectedPostcodeAddress.postcode;
		if (state.selectedPostcodeAddress.organisationName.length > 0) {
			state.subscriberAddress.usageType = "2";
			if (state.selectedPostcodeAddress.organisationName.endsWith('Limited')) {
				state.subscriberAddress.businessSuffix = 'Limited';
				state.subscriberAddress.name = state.selectedPostcodeAddress.organisationName.substring(0,state.selectedPostcodeAddress.organisationName.length - 7);
			}
		} else {
			state.subscriberAddress.usageType = "1";
		}
	}

	const changeAddress = (event) => {
		state.selectedPostcodeAddress = undefined;
		state.dataReady = false;		
		fireDataNotReadyEvent();
	}


	const changeUserDetails = (event) => {
		state.dataReady = false;
		fireDataNotReadyEvent();
	}

	const lookupAddressDetails = async () => {
			if (!state.postCodeLookup) {
				state.postCodeLookup.postCodeError = 'Need to type a Postcode';
				return;
			}
			const data = new URLSearchParams();
			data.set('postcode', state.postCodeLookup.postCodeToLookup);	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/voxletServices/subscriberAddressLookup', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
									state.postCodeLookup.postCodeLookupResults = d.idealPostcodeResults.postcodeLookupDetails;
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}


	const refreshState = () => {
		if (props.readOnly) {
				state.readOnly = props.readOnly;
		}
		if (props.subscriberAddressConfiguration.subscriberAddress) {		
			state.refreshVersion = props.refreshConfiguration.version;				
			state.selectedPostcodeAddress = true;				
			state.subscriberAddress.usageType = props.subscriberAddressConfiguration.subscriberAddress.usageType;
			state.subscriberAddress.udprn = props.subscriberAddressConfiguration.subscriberAddress.udprn;
			state.subscriberAddress.addressLine1 = props.subscriberAddressConfiguration.subscriberAddress.addressLine1;
			state.subscriberAddress.addressLine2 = props.subscriberAddressConfiguration.subscriberAddress.addressLine2;
			state.subscriberAddress.addressLine3 = props.subscriberAddressConfiguration.subscriberAddress.addressLine3;
			state.subscriberAddress.postTown = props.subscriberAddressConfiguration.subscriberAddress.postTown;		
			state.subscriberAddress.county = props.subscriberAddressConfiguration.subscriberAddress.county;
			state.subscriberAddress.postcode = props.subscriberAddressConfiguration.subscriberAddress.postcode ;			
			state.subscriberAddress.foreName = props.subscriberAddressConfiguration.subscriberAddress.foreName ;			
			state.subscriberAddress.name = props.subscriberAddressConfiguration.subscriberAddress.name ;
			state.subscriberAddress.businessSuffix = props.subscriberAddressConfiguration.subscriberAddress.businessSuffix;
			state.dataReady = true;	
			fireDataReadyEvent();
		}
			
	}

	onUpdated(() => {		
		if (props.refreshConfiguration.version > state.refreshVersion) {
			state.refreshVersion = props.refreshConfiguration.version;
			if (props.refreshConfiguration.onboarding) {				
				state.onboarding = props.refreshConfiguration.onboarding;
			}	
			console.log("onUpdated Subscriber");
			refreshState();
		}
		
	});

	onMounted(() => {
		refreshState();
	});

</script>

<style scoped>


</style>
