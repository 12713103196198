<template>
	<v-container fluid class="px-6 py-6 h-100">
			<v-row>
				<v-col class="pa-0 ma-0">
					<v-row>
						<v-col cols="6">
							<v-card>
								<BoxHeader headerText="Web Hooks"/>						
								<v-card-text>												
									<v-row>
										<v-col>
											<p class="theme-font text-h6">Status</p>
											<p class="theme-font-other ml-5 text-green font-weight-bold">ACTIVE</p>
										</v-col>									
									</v-row>
									<v-row>
										<v-col>
											<p class="theme-font-other ml-5">Messages are sent via HTTP Post to the Endpoint. You can select which messages you would like to receive.</p>
											<p class="theme-font-other ml-5 mt-2">More information about the delivery rules, retention of messages and retry intervals are explained in the article <span class="text-decoration-underline">"Message Delivery Explained"</span>.</p>
										</v-col>
									</v-row>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="6">
							<I164HubMemberEndpointGroup :refreshConfiguration="state.assets.i164HubMemberEndpointGroup.refreshConfiguration" :asset-data="i164HubMemberEndpointGroup" @dataEvents="i164HubMemberEndpointGroupEvent"  :configuration="{}" :readOnly="(true)"/>
						</v-col>
						
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</template>
	
	<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import BoxHeader from '../BoxHeader.vue';
import I164HubMemberEndpointKey from '@/apps/networkHub/components/I164HubMemberEndpointKey.vue';
import I164HubMemberEndpointGroup from '@/apps/networkHub/components/I164HubMemberEndpointGroup.vue';

	
	const { t }  = useI18n();
		
	const router = useRouter();
	
	const mainStore = useMainStore();
	
	const state = reactive({	
		tabsTop: 'home',
		assets: {
			i164HubMemberEndpointGroup: {
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			}
		}	
	});

	const i164HubMemberEndpointGroup =   {
                                "primaryUUID": "dd8d77a8-8128-4b8b-8d77-a881284b8bfa",
                                "phoneServiceUUID": "aa74311c-ac38-41fa-b431-1cac3841fabd",
                                "voxletInstanceUUID": "a53a6c4f-6980-4077-ba6c-4f6980907751",
                                "i164HubMemberIdentityUUID": "ed02a1c3-1056-4eeb-82a1-c310569eeb42",
                                "createDate": "2023-05-13 16:50:36.0",
                                "endpointGroupName": "Primary Endpoint Group",
                                "i164HubMemberEndpoints": [
                                    {
                                        "primaryUUID": "800316aa-b783-4a5c-8316-aab7838a5c9d",
                                        "phoneServiceUUID": "aa74311c-ac38-41fa-b431-1cac3841fabd",
                                        "voxletInstanceUUID": "a53a6c4f-6980-4077-ba6c-4f6980907751",
                                        "i164HubMemberEndpointGroupUUID": "dd8d77a8-8128-4b8b-8d77-a881284b8bfa",
                                        "createDate": "2023-05-13 16:50:36.0",
                                        "endpointDetailName": "Primary Endpoint",
                                        "active": true,
                                        "priority": 1,
                                        "endpointUrl": "https://example.com/webHookEndpoint",
                                        "i164Endpoint": true
                                    }
                                ],
                                "i164HubMemberEndpointRoutingRules": [
                                    {
                                        "primaryUUID": "01b84e80-2958-459c-b84e-802958359cf9",
                                        "phoneServiceUUID": "aa74311c-ac38-41fa-b431-1cac3841fabd",
                                        "voxletInstanceUUID": "a53a6c4f-6980-4077-ba6c-4f6980907751",
                                        "i164HubMemberEndpointGroupUUID": "dd8d77a8-8128-4b8b-8d77-a881284b8bfa",
                                        "createDate": "2023-05-13 16:50:36.0",
                                        "messageType": "*"
                                    }
                                ]
                            };
	

		

	const i164HubMemberEndpointGroupEvent = (event) => {
		
	}

	
	onMounted(() => {

	});
	
	</script>
	
	<style>
	
	</style>
	