<template>
	<v-container fluid class="px-6 py-6 h-100">	
		<v-row >			
			<v-col>
				<v-tabs v-model="state.tabsTop">	
					<v-tab value="home" :class="(state.tabsTop== 'home') ? 'bg-active_tab_color':''">
						<v-avatar
							class="shadow border-radius-xl"
							size="32"  color="avatar_background"
							><v-icon class="vx-text-dark theme-avatar-color" size="24">account_balance</v-icon></v-avatar>							
							<p class="ml-2 text-h6 theme-font">Account Home</p>
					</v-tab>						
					<v-tab value="invoices" :class="(state.tabsTop== 'invoices') ? 'bg-active_tab_color':''">
						<p class="text-h6 theme-font">Invoices</p>
					</v-tab>	
					<v-tab value="payments" :class="(state.tabsTop== 'payments') ? 'bg-active_tab_color':''">
						<p class="text-h6 theme-font">Payments</p>
					</v-tab>		
					<v-tab value="quotas" :class="(state.tabsTop== 'quotas') ? 'bg-active_tab_color':''">
						<p class="text-h6 theme-font">Quotas</p>
					</v-tab>									
				</v-tabs>						
				<v-divider></v-divider>							
			</v-col>
		</v-row>
		<v-row>
			<v-col class="mt-0 pt-0">
				<v-window v-model="state.tabsTop" class="fill-height bg-white mt-0 pt-0">
					<v-window-item value="home" class="">
						<AccountHome></AccountHome>
					</v-window-item>
					<v-window-item value="invoices" class="">
						<ListServiceInvoices/>
					</v-window-item>
					<v-window-item value="payments" class="">
						<PaymentsHome/>
					</v-window-item>
					<v-window-item value="quotas" class="">
						<QuotasManage/>
					</v-window-item>
				</v-window>
			</v-col>
		</v-row>
		<v-row class="pd-10">
			&nbsp;
		</v-row>			
	</v-container>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ListServiceInvoices from './ListServiceInvoices.vue';
import QuotasManage from './QuotasManage.vue';
import AccountHome from './AccountHome.vue';
import PaymentsHome from './PaymentsHome.vue';


const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const state = reactive({	
	tabsTop: 'home',	
});
</script>

<style>
</style>