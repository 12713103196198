<template>
	<v-container fluid class="px-6 py-6 h-100">
			<v-row>
				<v-col class="pa-0 ma-0">
					<v-row v-if="state.phoneServiceDetails">
						<v-col cols="4">
						
						</v-col>
						<v-col cols="8">
							
							<v-card>							
								<BoxHeader headerText="Account Payment Cards"/>
								<CreditCardServiceListComponent :creditCardConfig="serviceCreditCardConfig" :phoneService="phoneService" />														
							</v-card>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</template>
	
	<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import CreditCardServiceListComponent from '../plustag/cards/CreditCardServiceListComponent.vue';
import BoxHeader from '../BoxHeader.vue';
	
	const { t }  = useI18n();
		
	const router = useRouter();
	
	const mainStore = useMainStore();
	
	const state = reactive({	
		tabsTop: 'home',
		phoneServiceDetails: undefined,
		paymentCardMethods: undefined,
		userPaymentCardMethods: [],
	});
	
	const serviceCreditCardConfig = {
		listType: 'service',
		mode: 'list',
	};
	
	
	const phoneService =  mainStore.getDomainDetails(mainStore.phoneServiceName);
	
	const listServicePaymentCards = async () => {
			console.log('listing cards for '+mainStore.phoneServiceName);
			const apiUrl = mainStore.getAPIUrl();				
			await fetch(apiUrl+'/serviceBilling/listServiceCardPaymentMethods', {
				method: 'get',				
				credentials: 'include',
				redirect: 'follow',		
			}).then((response) => {
				response.json().then( 
					d => {									
						if (d.outcome == 1) {			
							if (d.stripeCardPaymentMethods) {
								state.paymentCardMethods = d.stripeCardPaymentMethods;
							}	else {
								state.paymentCardMethods = [];
							}								
						}							
					}									
				)
				return response;
			}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});	
		}
	
	onMounted(() => {
		listServicePaymentCards();
		state.phoneServiceDetails = mainStore.phoneServiceDetails;
	});
	
	</script>
	
	<style>
	
	</style>
	