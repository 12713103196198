<template>
  	<v-container fluid class="px-6 py-0 h-100">			
			<v-row>
				<v-col align="right">		
					<v-btn class="ml-2 theme-list-action-button-background   text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
				</v-col>
			</v-row>
			<v-row class="">			
				<v-col md="0">	
					<v-card class="card-shadow border-radius-md  d-flex flex-column">												
						<v-table  class="" density="compact">														
							<thead class="theme-list-header-background">
								<tr>
									<th class="text-center text-h6 theme-listing-header-text">Type</th>
									<th class="text-center text-h6 theme-listing-header-text">Created</th>
									<th class="text-center text-h6 theme-listing-header-text">App</th>		
									<th class="text-center text-h6 theme-listing-header-text">Status</th>																
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="device in state.devices"  :key="device.primaryUUID">
									<td class="text-center">{{ t('statics.devices.deviceType.'+device.deviceType) }}</td>
									<td class="text-center">{{ device.createDate }}</td>
									<td class="text-center">Basic Line</td>
									<td class="text-center">{{ t('statics.lifecyclePhases.device.'+device.onboarding) }}</td>										
				
								</tr>	
								<tr v-if="state.devices.length == 0">
									<td colspan="4" class="theme-font text-center text-h6">No Records Found</td>									
								</tr>																					
							</tbody>
						</v-table>

						 <v-spacer></v-spacer>
							<!--<v-pagination
										v-model="state.pagination.page"
										:length="state.pagination.length"
										:total-visible="state.pagination.totalVisible"
										></v-pagination>-->
					</v-card>	
				</v-col>
			</v-row>
			
		</v-container>
	
</template>

<script setup>
  import { inject, ref, reactive,defineProps } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';

	const { t }  = useI18n();
	
	const mainStore = useMainStore();
	const props = defineProps({
		phoneServiceName: {
				type: String
		}
  });
		
	const state = reactive({
		devices: [],
		filterValues: {
					searchText: '',
					voxletServiceContextType: 'customerAccount',
					recordCount: 10,
					filterByPhase: 'all',
				},
		filters: {
			lifecyclePhases: [
				{ phase: 'all', phaseDescription: 'Show All' },
				{ phase: 'preCommitment', phaseDescription: 'Awaiting' },
				{ phase: 'ready', phaseDescription: 'Live' },
				{ phase: 'ended', phaseDescription: 'Ended' },
			],
			recordCount: [
				{value: 10, title:'10'},
				{value: 25, title:'25'},
				{value: 50, title:'50'},
				{value: 100, title:'100'},					
				],
		},
			pagination: {
					page: 2,
					length: 4,
					totalVisible: 7,
				}
  });

	


	const changeRecordCount = (value) => {
		console.log("Value is "+value);
	}

	const doSearch = (value) => {
		console.log("Value is "+value);	

		mainStore.displaySnackBar('Searching');
		
		getDevices();
	}

	const refreshList = () => {		
		mainStore.displaySnackBar('Refreshing');		
		getDevices();
	}

	const getDevices = async () => {
		const apiUrl = mainStore.getAPIUrl();
				await fetch(apiUrl+'/devices/listDevices', {
					method: 'get',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {
									state.devices = d.devices;
									mainStore.displaySnackBar('Devices Loaded');		
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);

			});		
		}

	getDevices();


</script>

<style scoped>
	.listRow {
		height: 100%;	
	}

	.listDisplay {	
		height: calc( 100% - 100px );
	}

	.listTable {	
		height: calc( 100% - 100px);
	}
</style>