<template>
	<v-spacer>		
		<v-row>
			<v-col md="1" lg="2">
			</v-col>
			<v-col cols="12" sm="12" md="10" lg="8">
				<v-card>
					<v-card-text>
							<v-row>
								<v-col class="pa-0">
									<v-row class="theme-box-header-background">
										<v-col class="d-flex ma-2 mb-0">
											<p class="theme-font text-h5 text-white">Content ({{state.contentFieldName}})</p>
											<v-spacer align="right" @click="close(this);"><v-icon>close</v-icon></v-spacer>
										</v-col>		
									</v-row>
									<v-row>
										<v-col class="ma-5 mb-0">
											<v-select :items="state.values.contentFragmentTypes" label="Content Type" variant="outlined" item-title="title"
											item-value="value" @update:modelValue="selectContentType(this)"
											:disabled="state.action == 'update'"
											v-model="state.contentFragmentType"></v-select>
										</v-col>
									</v-row>
									<v-row v-if="state.contentFragmentType">
										<v-col class="ma-5 mt-0">
											<div v-if="state.contentFragmentType == 'textBlock'">
												<TextBlockForm :contentConfiguration="state.contentConfiguration" :contentFragment="state.contentFragment" :action="state.action" :contentFieldName="state.contentFieldName" :position="state.position" @dataEvents="dataReadyFired"/>
											</div>
											<div v-if="state.contentFragmentType == 'titleBlock'">
												<TitleBlockForm :contentConfiguration="state.contentConfiguration" :contentFragment="state.contentFragment" :action="state.action" :contentFieldName="state.contentFieldName" :position="state.position" @dataEvents="dataReadyFired"/>
											</div>
											<div v-if="state.contentFragmentType == 'stepBlock'">
												<StepBlockForm :contentConfiguration="state.contentConfiguration" :contentFragment="state.contentFragment" :action="state.action" :contentFieldName="state.contentFieldName" :position="state.position" @dataEvents="dataReadyFired"/>
											</div>
											<div v-if="state.contentFragmentType == 'codeBlock'">
												<CodeBlockForm :contentConfiguration="state.contentConfiguration" :contentFragment="state.contentFragment" :action="state.action" :contentFieldName="state.contentFieldName" :position="state.position" @dataEvents="dataReadyFired"/>
											</div>
											<div v-if="state.contentFragmentType == 'imageBlock'">
												<ImageBlockForm :contentConfiguration="state.contentConfiguration" :contentFragment="state.contentFragment" :action="state.action" :contentFieldName="state.contentFieldName" :position="state.position" @dataEvents="dataReadyFired"/>
											</div>
											<div v-if="state.contentFragmentType == 'textImageBlock'">
												<TextImageBlockForm :contentConfiguration="state.contentConfiguration" :contentFragment="state.contentFragment" :action="state.action" :contentFieldName="state.contentFieldName" :position="state.position" @dataEvents="dataReadyFired"/>
											</div>	
											<div v-if="state.contentFragmentType == 'relatedPages'">
												<RelatedPages :contentConfiguration="state.contentConfiguration" :contentFragment="state.contentFragment" :action="state.action" :contentFieldName="state.contentFieldName" :position="state.position" @dataEvents="dataReadyFired"/>
											</div>			
											<div v-if="state.contentFragmentType == 'splashTemplate'">
												<SplashTemplateForm :contentConfiguration="state.contentConfiguration" :contentFragment="state.contentFragment" :action="state.action" :contentFieldName="state.contentFieldName" :position="state.position" @dataEvents="dataReadyFired"/>
											</div>			
																								
										</v-col>
									</v-row>
								</v-col>
							</v-row>						
						</v-card-text>
				</v-card>
			</v-col>
			<v-col md="1" lg="2">
			</v-col>
		</v-row>
	</v-spacer>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import TextBlockForm from './TextBlockForm.vue';
import TitleBlockForm from './TitleBlockForm.vue';
import StepBlockForm from './StepBlockForm.vue';
import CodeBlockForm from './CodeBlockForm.vue';
import SplashTemplateForm from './SplashTemplateForm.vue';
import ImageBlockForm from './ImageBlockForm.vue';
import TextImageBlockForm from './TextImageBlockForm.vue';
import RelatedPages from './RelatedPages.vue';



const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const props = defineProps({		
	contentConfiguration: {
		type: Object,
		required: true,
	},
	action: {
		type: String,
		required: true,
	},
	contentFieldName: {
		type: String,
		required: true,
	},
	contentFragment: {
		type: Object
	},
	position: {
		type: Number
	}
});

const state = reactive({	
	dataReady: false,	
	action: undefined,
	contentConfiguration: undefined,			
	contentFieldName: undefined,
	contentFragmentType: undefined,
	contentFragment: undefined,
	position: 0,
	values: {		
			contentFragmentTypes: [
				{value: 'textBlock', title:'Paragraph'},		
				{value: 'titleBlock', title:'Title'},
				{value: 'stepBlock', title:'Step'},		
				{value: 'codeBlock', title:'Code'},
				{value: 'splashTemplate', title:'Splash Template'},		
			]
	}
});

const emits = defineEmits(['close','dataEvents']);

const fireDataReadyEvent = () => {
		emits('dataEvents',
			{
				state: 'ready',
				action: state.action,
				position: state.position,
				contentFieldName: state.contentFieldName,
				contentFragment: state.contentFragment,				
			}
		);
}

const dataReadyFired = (_contentEvent) => {
		emits('dataEvents',_contentEvent);
		console.log("dataReadyFired");
	}

const close = (event) => {
	emits('close');
}

const valid = (event) => {
	if (state.contentFragment.text) {
		state.dataReady = true;
		return true;
	}
	return false;
}

const selectContentType = (event) => {
	
}


const completed = (event) => {
	if (valid(event)) {
		fireDataReadyEvent();
	}	
}

onMounted(() => {
	if (props.contentConfiguration) {
		state.contentConfiguration = props.contentConfiguration;
		console.log(" contentConfiguration is "+JSON.stringify(state.contentConfiguration));
		if (state.contentConfiguration.contentTypes) {
			state.values.contentFragmentTypes = state.contentConfiguration.contentTypes;
			if (state.values.contentFragmentTypes.length == 1) {
				state.contentFragmentType = state.values.contentFragmentTypes[0].value;
			}
		}
	} 
	if (props.contentFieldName) {
		state.contentFieldName = props.contentFieldName;
		console.log(" contentFieldName is "+state.contentFieldName);
	} 
	if (props.action) {
		state.action = props.action;
	} 
	if (props.contentFragment) {
		state.contentFragment =props.contentFragment;
		state.contentFragmentType = 	state.contentFragment.type;
	}
	if (props.position) {
		state.position = props.position;
	} else {
		state.position = 0;
	}
});
</script>

<style>

</style>