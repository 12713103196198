<template>
	<v-spacer>
		<v-card v-if="state.contentPage">
			<v-card-text>		
				<v-row>
					<v-col class="d-flex">
						<p class="text-h6 theme-font ml-5">{{state.contentPage.pageInfo.contentReference}}</p>						
						<v-spacer  align="right" >
							<p class="text-caption theme-font text-center">App</p>
							<p class="text-body-2 theme-font-other text-center">{{state.contentPage.pageInfo.appName}}</p>	
						</v-spacer>
						<v-spacer  align="right" >
							<p class="text-caption theme-font text-center">UPDATED</p>
							<p class="text-body-2 theme-font-other text-center">{{state.contentPage.pageInfo.updateDate}}</p>	
						</v-spacer>
						<v-spacer  align="right" >
							<p class="text-caption theme-font text-center">STATUS</p>
							<p class="text-body-2 theme-font-other text-center">{{state.contentPage.pageInfo.status}}</p>	
						</v-spacer>
						<v-spacer  align="right" >
							<p class="text-caption theme-font text-center">PERMISSIONS</p>
							<p class="text-body-2 theme-font-other text-center">{{state.contentPage.pageInfo.permissions}}</p>	
						</v-spacer>
						<v-spacer  align="right" >
							<div v-if="state.edit">
								<v-btn @click="editPageInfo(this)" class="ml-5"><v-icon size="x-small">description</v-icon></v-btn>
								<v-btn @click="edit(this,false)" class="ml-5"><v-icon>edit</v-icon></v-btn>												
							</div>
							<div v-if="!state.edit">
								<v-btn @click="edit(this,true)" class="ml-5"><v-icon @click="edit(this,true)">edit_off</v-icon></v-btn>
							</div>
						</v-spacer>
					</v-col>
				</v-row>				
				
			</v-card-text>
		</v-card>
				
		<v-dialog persistent v-model="state.showDialog">
			<v-row>
				<v-col v-if="state.contentPage">
					<PageInfoForm :contentPage="state.contentPage"  @close="closeDialog(event)" @dataEvents="dataReadyFired"/>
				</v-col>
			</v-row>
		</v-dialog>
	</v-spacer>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import PageInfoForm from './PageInfoForm.vue';


const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const emits = defineEmits(['dataEvents','editEvent']);

const props = defineProps({	
	contentPage: {
		type: Object,
		required: true,
	},
	edit: {
		type: Boolean,
		required: true,
	},
});

const state = reactive({	
	dataReady: false,
	showDialog: false,	
	contentPage: undefined,
	edit: false,	
});

const dataReadyFired = (_pageInfoEvent) => {
	emits('dataEvents',_pageInfoEvent);
}

const edit = (event,flag) => {
	state.edit = flag;
	emits('editEvent',
			{
				edit: flag,		
			}
		);
}

const editPageInfo = () => {
	state.showDialog = true;
}

const closeDialog = (event) => {
		state.showDialog = false;
	}

onUpdated(() => {
	console.log('Updated');
	if (props.edit != undefined) {
			state.edit = props.edit;
		}
	if (props.contentPage) {
		state.contentPage = props.contentPage;
	}
});

onMounted(() => {
	if (props.edit != undefined) {
		state.edit = props.edit;
	}
	if (props.contentPage) {
		state.contentPage = props.contentPage;
	}
});

</script>

<style>

</style>