<template>
	
	<v-dialog persistent v-model="state.show">
		<v-form @keyup.enter="changePassword(this)">
				<v-row>
					<v-col md="3" sm="2">					
					</v-col>
					<v-col cols="12" sm="8" md="6">
						
							<v-card>								
								<v-card-text>
									<v-row>
										<v-col class="pa-0" >
											<v-row class="theme-box-header-background">
												<v-col class="d-flex ma-2">
													<p class="theme-font text-h5 text-white">Change Your Password</p>
													<v-spacer align="right" @click="close(this);"><v-icon>close</v-icon></v-spacer>
												</v-col>	
											</v-row>
										</v-col>
									</v-row>
									<v-row>
										<v-col class="ma-5">
											<v-row>
												<v-col>
													<v-row v-if="state.commandOutcome">
														<v-col>
															<p><v-icon class="text-red">warning</v-icon> {{ state.commandOutcome.message }}</p>								
														</v-col>
													</v-row>
													<v-row v-if="state.commandOutcome">
														<v-col>
															<v-divider></v-divider>
														</v-col>
													</v-row>
													<v-row>
														<v-col><input type="text" autocomplete="username" ng-hide="true">
															<v-text-field id="oldPassword" variant="outlined" name="oldPassword" label="Existing Password*" type="password" autocomplete="current-password"  v-model="state.oldPassword" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>
														</v-col>
													</v-row>
													<v-row>
														<v-col>
															<v-text-field id="newPassword" variant="outlined" name="newPassword" label="New Password*" type="password" autocomplete="new-password"   v-model="state.newPassword" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>								
															<p class="text-caption text-center">Length longer than 8 with at least one special character.</p>
															<PasswordBar :password="state.newPassword" @dataEvents="passwordReady"/>														
														</v-col>
													</v-row>
													<v-row>
														<v-col>
															<v-text-field id="newPassword2" variant="outlined" name="newPassword2" label="Confirm Password*" type="password" autocomplete="new-password"  v-model="state.newPassword2" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>
														</v-col>
													</v-row>
													<v-row>
														<v-col class="text-center">
															<v-btn @click="changePassword(this)" :disabled="!state.dataReady" :class="(state.dataReady) ? 'theme-bg-highlight' :''">Change</v-btn>
														</v-col>
													</v-row>
												</v-col>
											</v-row>
											</v-col>
									</v-row>
									
								
								</v-card-text>
						
							</v-card>
			
					</v-col>
				<v-col md="3" sm="2">					
				</v-col>
			</v-row>	
		</v-form>		
	</v-dialog>


</template>

<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';

import PasswordBar from './PasswordBar.vue';

const { t }  = useI18n();

const emits = defineEmits(["close"]);

const close = (a) => {	
	emits('close');
}

const props = defineProps({
		show: Boolean,	
});



const passwordReady = (event) => {
	if (event) {
		state.passwordPassed = (event.state == 'ready');
	} else {
		state.passwordPassed = false;
	}
	
}

const valid = (event) => {
	
	if (state.oldPassword && state.newPassword) {
		if (state.passwordPassed && (state.newPassword == state.newPassword2)) {			
			state.dataReady = true;
			return true;
		}				
	}			
	state.dataReady = false;
	return false;
}

const state = reactive({		
	show: false,
	dataReady: false,
	oldPassword: undefined,
	newPassword: undefined,
	newPassword2: undefined,
	commandOutcome: undefined,
	passwordPassed: false,
});

const mainStore = useMainStore();

const changePassword = async (event) => {
	const apiUrl = mainStore.getAPIUrl('plustag');			
		const data = new URLSearchParams();
		data.set("oldPassword",state.oldPassword);
		data.set("newPassword",state.newPassword);	
		await fetch(apiUrl+'/plustag/changePassword', {
			body: data,
			method: 'post',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {
						
						close(event);
						mainStore.displaySnackBar('Password Change');
						state.oldPassword = undefined;
						state.newPassword = undefined;
					}	else {
						const commandOutcome = mainStore.getCommandOutcome(t,d.outcome,d.message);
						state.commandOutcome = commandOutcome;
					}		
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});		
			
}



onUpdated(() => {		
	state.show = props.show;
	});

	onMounted(() => {
		state.show = props.show;
	});

</script>

<style>



</style>