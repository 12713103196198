<template>
	<v-container fluid class="px-6 py-6" v-if="state.contentPage" :key="state.version" >
		<v-row>
			<v-col class="d-flex">
				<v-spacer class="elevation-2"><PageControls :edit="state.edit" :contentPage="state.contentPage" @editEvent="editControl" @dataEvents="pageInfoDataEventFired" /></v-spacer>
			</v-col>
		</v-row>	
		<v-row>
			<v-col class="d-flex">
				<v-select :items="state.commandSelectList" label="Command Sync" variant="outlined" 
						item-title="title"
						item-value="value"  
						v-model="state.commandGroup" :disabled="(state.contentPage.content.commandDetails != undefined)"></v-select><v-btn @click="syncCommand(this)" class="ml-2 mt-2">Sync</v-btn>
			</v-col>
		</v-row>	
		<v-row>
			<v-col>
				<div v-if="state.contentConfiguration.title.multiplicity == 'multiple' || !state.contentPage.content.title  || state.contentPage.content.title.length == 0 ">
					<ContentCreator :contentConfiguration="state.contentConfiguration.title" :position="(0)" contentFieldName="title" @dataEvents="dataEventFired" :edit="state.edit"/>
				</div>
				<div v-if="state.contentPage.content && state.contentPage.content.title">					
						<ContentBlock :pageContext="pageContext"  :contentConfiguration="state.contentConfiguration.title" contentFieldName="title" @dataEvents="dataEventFired"  :contentFragments="state.contentPage.content.title"  :edit="state.edit">
						</ContentBlock>					
				</div>								
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<ContentCreator :contentConfiguration="state.contentConfiguration.topParagraph" :position="(0)" contentFieldName="topParagraph" @dataEvents="dataEventFired" :edit="state.edit"/>
				<div v-if="state.contentPage.content && state.contentPage.content.topParagraph">					
						<ContentBlock :pageContext="pageContext"  :contentConfiguration="state.contentConfiguration.topParagraph" contentFieldName="topParagraph" @dataEvents="dataEventFired"  :contentFragments="state.contentPage.content.topParagraph"  :edit="state.edit">
						</ContentBlock>					
				</div>								
			</v-col>
		</v-row>
		<v-row>
			<v-col class="mt-5">
				<p class="theme-font text-h6">Command Details</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<div v-if="state.contentConfiguration.commandDetails.multiplicity == 'multiple' || !state.contentPage.content.commandDetails  || state.contentPage.content.commandDetails.length == 0 ">
					<ContentCreator :contentConfiguration="state.contentConfiguration.commandDetails" :position="(0)" contentFieldName="commandDetails" @dataEvents="dataEventFired" :edit="state.edit"/>
				</div>
				<div v-if="state.contentPage.content && state.contentPage.content.commandDetails">					
						<ContentBlock :pageContext="pageContext"  :contentConfiguration="state.contentConfiguration.commandDetails" contentFieldName="commandDetails" @dataEvents="dataEventFired"  :contentFragments="state.contentPage.content.commandDetails"  :edit="state.edit">
						</ContentBlock>					
				</div>								
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<p class="theme-font text-h6">Input Properties</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<ContentCreator :contentConfiguration="state.contentConfiguration.inputProperties" :position="(0)" contentFieldName="inputProperties" @dataEvents="dataEventFired" :edit="state.edit"/>
				<div v-if="state.contentPage.content && state.contentPage.content.inputProperties">					
						<ContentBlock :pageContext="pageContext"  :contentConfiguration="state.contentConfiguration.inputProperties" contentFieldName="inputProperties" @dataEvents="dataEventFired"  :contentFragments="state.contentPage.content.inputProperties"  :edit="state.edit">
						</ContentBlock>					
				</div>								
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<p class="theme-font text-h6">Outcomes</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<ContentCreator :contentConfiguration="state.contentConfiguration.outcomes" :position="(0)" contentFieldName="outcomes" @dataEvents="dataEventFired" :edit="state.edit"/>
				<div v-if="state.contentPage.content && state.contentPage.content.outcomes">					
						<ContentBlock :pageContext="pageContext"  :contentConfiguration="state.contentConfiguration.outcomes" contentFieldName="outcomes" @dataEvents="dataEventFired"  :contentFragments="state.contentPage.content.outcomes"  :edit="state.edit">
						</ContentBlock>					
				</div>								
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ContentCreator from '../pages/components/ContentCreator.vue';
import content from '../content.js';
import commandSchema from './commandSchema.js';
import ContentBlock from '../types/ContentBlock.vue';
import PageControls from '../pages/components/PageControls.vue';

const { t }  = useI18n();
	
const router = useRouter();
const route = useRoute();

const mainStore = useMainStore();
content.initialiseStore(mainStore);

commandSchema.initialiseStore(mainStore);

let pageContext = {
		stepCount: 0,
	};

const initialisePageContext = () => {
	pageContext = {
		stepCount: 0,
	};
}

const state = reactive({	
	version: 0,
	edit: true,
	contentPageInfoUUID: undefined,
	contentPage: undefined,
	appDescriptors: undefined,
	commandGroup: 'networkHub.addPermittedNetwork',	
	commandSchema: undefined,
	contentConfiguration: {
		title: {
			multiplicity: "single",
			contentTypes: [				
				{value: 'titleBlock', title:'Title'},		
			]
		},
		topParagraph: {
			multiplicity: "multiple",
			contentTypes: [
				{value: 'textBlock', title:'Paragraph'},		
				{value: 'titleBlock', title:'Title'},
				{value: 'stepBlock', title:'Step'},
				{value: 'codeBlock', title:'Code Block'},						
			]
		},
		commandDetails: {
			multiplicity: "single",
			contentTypes: [
				{value: 'commandDetailsBlock', title:'Commmand'},
			]
		},
		inputProperties: {
			multiplicity: "multiple",
			contentTypes: [
				{value: 'inputPropertyBlock', title:'Properties'},
			]
		},
		outcomes: {
			multiplicity: "multiple",
			contentTypes: [
				{value: 'outcomeBlock', title:'Outcomes'},
			]
		}			
	}
});

const pageInfoDataEventFired = (pageInfoEvent) => {
	//console.log(' Page '+JSON.stringify(pageInfoEvent));	
	state.contentPage.pageInfo = pageInfoEvent.pageInfo;
	content.processPageInfoEvent(pageInfoEvent,
		(data) => {
			state.version = data.version;
			state.contentPage.pageInfo.version = data.version;
		}
	);	
	//console.log('Content Now '+JSON.stringify(state.contentPage));
}

const dataEventFired = (contentEvent) => {
	//console.log(' Page '+JSON.stringify(contentEvent));	
	content.processContentEvent(contentEvent,
		(data) => {
			state.version = data.version;
			state.contentPage.pageInfo.version = data.version;
			content.setContentPage(state.contentPage);
		}
	);	
	//console.log('Content Now '+JSON.stringify(state.contentPage));
}


const syncCommand = () => {
	if (state.commandGroup) {
		if (!state.contentPage.content.outcomes) {
			state.contentPage.content.outcomes = [];
		}
		if (!state.contentPage.content.commandDetails) {
			state.contentPage.content.commandDetails = [];
		}
		if (!state.contentPage.content.inputProperties) {
			state.contentPage.content.inputProperties = [];
		}		
		commandSchema.syncCommandContent(state.commandGroup,state.contentPage,
			() => {
					content.persistContentPage((data) => {
						state.version = data.version;
						state.contentPage.pageInfo.version = data.version;
						content.setContentPage(state.contentPage);
					});
			}
		);
	}
}


const editControl = (event) => {
	console.log(event.edit);
	state.edit = event.edit;
}

onUpdated(() => {
	console.log('Updated');
	initialisePageContext();
});

onMounted(() => {
	commandSchema.loadCommandGroups(
		(data) => {
				state.commandSelectList = data;				
			}
	);
	//listAppDescriptors();
	if (route.params.contentPageInfoUUID) {
		state.contentPageInfoUUID = route.params.contentPageInfoUUID;
		content.setContentPageInfoUUID(state.contentPageInfoUUID);
		content.loadContent(
			(data) => {
				state.contentPage = data.contentPage;
				//state.commandGroup = data.contentPage.commandDetails[0].commandGroup;
				state.commandGroup = 'users.loggedInStatus';
				state.version = data.contentPage.pageInfo.version;
				//console.log(JSON.stringify(state.contentPage));
			}
		);
	}	
	initialisePageContext();				
});


</script>

<style>


</style>