<template>
	<v-container fluid class="px-6 py-6 h-100">
		<v-row>
			<v-col class="pa-0 ma-0">
				<v-row>
					<v-col>
						<p class="theme-font-other text-body-2 text-justify">
							You can customise branding across all of your services that come in to contact with your end users.
						</p>
						<p class="theme-font-other text-body-2  text-justify mt-2">
							The majority of the branding that you can control are visual allowing you to customise apps and interfaces. We provide as many options to do this within the boundaries of what is possible in each application. 
						</p>
						<p class="theme-font-other text-body-2  text-justify mt-2">
							Branding options can be also be operational. For example, allowing you to choose your own domains. Another area of customisation would be with emails that need to be sent to customers. 
						</p>
					</v-col>
					<v-col cols="8">
						<v-card>
							<v-card-text>
								<v-row>
									<v-col>
										<p class="theme-font text-h5">
											Global Defaults
										</p>
									</v-col>
								</v-row>		
								<v-divider></v-divider>
								<v-row>
									<v-col class="ma-5">										
										<v-row>											
											<v-col class="">
												<v-select variant="outlined" label="Body Font"
												:items="state.values.fonts"				
													item-title="title"
													item-value="value"			
													v-model="state.globalBranding.bodyFont"></v-select>
											</v-col>
											<v-col > 												
												<p class="text-body-1" :class="'f-'+state.globalBranding.bodyFont">The quick brown fox jumps over the lazy dog</p>
											</v-col>
										</v-row>
										<v-row>											
											<v-col class="">
												<v-select variant="outlined" label="Heading Font"
												:items="state.values.fonts"				
													item-title="title"
													item-value="value"			
													v-model="state.globalBranding.headingFont"></v-select>
											</v-col>
											<v-col>
												<p class="text-h4" :class="'f-'+state.globalBranding.headingFont">Heading One</p>
											</v-col>
										</v-row>
										<v-row>											
											<v-col class="d-flex">
												<div>
													<p class="ml-3 text-caption text-grey-darken-1">Background Colour</p>
													<p class="text-body-1 ml-3 mt-1">{{ state.globalBranding.backgroundColor }}</p>
												</div>
												<v-spacer align="right"><v-btn size="x-small" @click="showPalette('backgroundColor',state.globalBranding.backgroundColor)">CHANGE</v-btn></v-spacer>
											</v-col>
											<v-col>
												<div :style="'background-color: '+state.globalBranding.backgroundColor" class="h-100 w-100">

												</div>
											</v-col>
										</v-row>
										<v-row>											
											<v-col class="d-flex">
												<div>
													<p class="ml-3 text-h6">Logo</p>
												
												</div>
												<v-spacer align="right"><v-btn size="x-small" @click="showImageSelector()">SELECT</v-btn></v-spacer>
											</v-col>
											<v-col>
												<v-img :src="state.globalBranding.logo"/>
											</v-col>
										</v-row>
									</v-col>
								</v-row>				
							</v-card-text>
						</v-card>
						
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
	<v-dialog v-model="state.showPaletteSelector" persistent>
		<v-row>
				<v-col md="4" sm="2">					
				</v-col>
				<v-col cols="12" sm="8" md="4">
					<v-card>
						<v-card-text align="center">
							<p class="theme-font text-h6 mb-2">Select a colour</p>
							<v-color-picker v-model="state.pickerColor" mode="hex"></v-color-picker>
							<v-spacer align="right" class="mt-5"><v-btn @click="closePalette()">Select</v-btn></v-spacer>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4" sm="2">					
				</v-col>
			</v-row>
	</v-dialog>
	<v-dialog v-model="state.showImageSelector" persistent>
		<v-row>
				<v-col md="4" sm="2">					
				</v-col>
				<v-col cols="12" sm="8" md="4">
					<v-card>
						<v-card-text align="center">
							<p class="theme-font text-h6 mb-2">Select an Image</p>
							<v-spacer style="background-color: grey;width:250px;height:250px" class="ma-5">
								<v-img :src="state.selectedImage"/>
							</v-spacer>
							<v-file-input
								label="Image"
								variant="outlined"
								accept="image/png, image/jpeg, image/bmp"
								@update:modelValue="imageSelected"
							></v-file-input>
							<v-spacer align="right" class="mt-5"><v-btn @click="closeImageSelector()">Select</v-btn></v-spacer>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4" sm="2">					
				</v-col>
			</v-row>
	</v-dialog>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';

const { t }  = useI18n();
	
const router = useRouter();
const route = useRoute();

const mainStore = useMainStore();

let selectedImage = undefined;

const state = reactive({	
	version: 0,
	showPaletteSelector: false,
	showImageSelector: false,
	pickerColor: undefined,
	pickerTarget: undefined,
	selectedImage: undefined,
	globalBranding: {
		bodyFont: 'roboto',		
		headingFont: 'roboto',
		backgroundColor: '#cccccc',
		logo: undefined,	
	},
	values: {
		fonts: [
			{value: 'roboto', title:'Roboto'},
			{value: 'arial', title:'Arial'},
			{value: 'times', title:'Times'},
			
		]
	}	
});


const showImageSelector = () => {	
	state.showImageSelector = true;	
}

const closeImageSelector = () => {
	state.showImageSelector = false;	
	state.globalBranding.logo = state.selectedImage;
}

const imageSelected = (files) => {
	console.log('Image Selected '+typeof files);
	for (let i = 0 ; i < files.length;i++) {
		console.log(files[i].name);
		readURL(files[i]);
	}	
	
}

const readURL = (file) => {
  if (file) {
    var reader = new FileReader();
   
		reader.onload = function (e) {
			state.selectedImage = e.target.result;      
    };
    reader.readAsDataURL(file);
  }
}

const showPalette = (target,color) => {	
	state.pickerColor = color;
	state.pickerTarget = target;
	state.showPaletteSelector = true;	
}

const closePalette = () => {
	state.showPaletteSelector = false;	
	switch (state.pickerTarget) {
		case 'backgroundColor':
			state.globalBranding.backgroundColor = state.pickerColor;
	}
}

</script>


<style scoped>

.f-roboto {
	font-family: Roboto !important;
}

.f-arial {
	font-family: Arial !important;
}

.f-helvetica {
	font-family: Helvetica !important;
}

.f-times {
	font-family: Times !important;
}



</style>