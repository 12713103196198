<template>
	<OuterCard>
			<v-row>
				<v-col class="pa-0">
					<v-row class="theme-box-header-background">
					<v-col class="d-flex ma-2">
						<p class="theme-font text-h5 text-white">Account Actions</p>
					</v-col>		
					</v-row>				

					<v-row>
						<v-col  class="d-flex ma-2 theme-pointer" @click="changePassword()">
							<v-icon  class="mr-3  text-primary_100" size="20">chevron_right</v-icon><p>Change Your Password</p>
						</v-col>
					</v-row>
					<v-row>
						<v-col><v-divider></v-divider></v-col>
					</v-row>
					<v-row>
						<v-col  class="d-flex ma-2 theme-pointer" @click="twoFactorSetup()">
							<v-icon  class="mr-3  text-primary_100" size="20" >chevron_right</v-icon><p v-if="mainStore.loggedInStatus.twoFactorRequired">Two Factor Enabled</p><p v-else>Enable Two Factor</p>
						</v-col>
					</v-row>
				</v-col>
			</v-row>		
		</OuterCard>
		<ChangePassword :show="state.showPasswordChange" @close="closeChangePasswordDialog()"/>
		<TwoFactorSetUp :show="state.showTwoFactorSetup"  @close="closeTwoFactorSetUpDialog()"/>
	</template>
	
	<script setup>
	import { defineProps,reactive,onMounted,onUpdated } from 'vue';
	import OuterCard from '@/components/common/OuterPopCard.vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import ChangePassword from './ChangePassword.vue';
	import TwoFactorSetUp from './TwoFactorSetUp.vue';
	
		const mainStore = useMainStore();
	
		const router = useRouter();		

		const state = reactive({		
			showPasswordChange: false,
			showTwoFactorSetup: false,
		});

		const closeChangePasswordDialog = () => {
			state.showPasswordChange = false;
		}

		const changePassword = () =>  {
			console.log('changed password');
			state.showPasswordChange = true;
		}

		const closeTwoFactorSetUpDialog = () => {
			state.showTwoFactorSetup = false;
		}

		const twoFactorSetup = () =>  {	
			if (!mainStore.loggedInStatus.twoFactorRequired && !mainStore.loggedInStatus.twoFactorSetup) {
				state.showTwoFactorSetup = true;
			}					
		}

	
	</script>
	
	<style scoped>
	
	</style>