<script setup>
  import AppLoader from './components/AppLoader.vue';
  

	import {useMainStore} from './stores/MainStore.js';
	import { useRoute, useRouter } from "vue-router";
	import { reactive,watch } from 'vue';
	import { storeToRefs } from 'pinia';
	
	const mainStore = useMainStore();
	const route = useRoute();
	const router = useRouter();

	if (route.params.phoneServiceName) {
		console.log("Setting active phoneservice to "+route.params.phoneServiceName);
		mainStore.phoneServiceName = route.params.phoneServiceName;
	}


const completeLoading = () =>{
	/**
	 * This is broken. It is meant to protect guest pages when the user is looged in but it doesn't
	 * it breaks otehr stuff
	 */
	if (route.meta.accessibility == 'guestsOnly' && mainStore.loggedIn) {
		//router.push({name: 'plustag'});
	}
}

</script>

<template>
  <v-app>
		<AppLoader v-if="!mainStore.appLoaded"/>		
    <v-main v-else>
			<router-view></router-view>		
    </v-main>
  </v-app>
</template>

<style scoped>

</style>
