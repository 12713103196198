<template>
<v-dialog persistent v-model="state.show">
	
	<v-row>
		<v-col md="3" sm="2" lg="4">					
		</v-col>
		<v-col cols="12" sm="8" md="6" lg="4">
			<v-card>
				<v-card-text>				
						<v-row>
							<v-col class="d-flex">
								<v-icon class="text-primary_100 mr-2 mt-1" size="small">lock</v-icon><p class="theme-font text-h5">Enabling Two Factor</p><v-spacer align="right" @click="close(this);"><v-icon>close</v-icon></v-spacer>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row v-if="state.commandOutcome">
							<v-col>
								<p><v-icon class="text-red">warning</v-icon> {{ state.commandOutcome.message }}</p>								
							</v-col>
						</v-row>
						<v-row v-if="state.commandOutcome">
							<v-col>
								<v-divider></v-divider>
							</v-col>
						</v-row>
						<v-row v-if="state.page == 'intro'">
							<v-col>
								<v-row>
									<v-col class="mt-0 pt-0">										
										<p class="text-h5 theme-font">What you will need</p>
										
										<p class="text-body-1 ma-2">1. Any industry standard two factor App. (TOTP)</p>
										<p class="text-caption ma-2 ml-3">Including Authy, andOTP, Google Authenticator, Microsoft Authenticator, LastPass, 1Password and most password managers.</p>																				
										<p class="text-body-1 ma-2">2. A UK Mobile Number</p>
										<p class="text-caption ma-2 ml-3">For account recovery in case you need to reset your two factor details.</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col align="right"><v-btn @click="goToMobileRecovery()"  class="theme-bg-highlight">Begin Set-Up</v-btn></v-col>
								</v-row>								
							</v-col>
						</v-row>
						<v-row v-if="state.page == 'mobileRecovery'">
							<v-col v-if="state.userMobileNumbers && state.userMobileNumbers.length > 0 && !state.newMobileNumber">
								<p class="text-h5 theme-font">Account Recovery</p>
								<p class="text-body-2 ma-2">You have the following mobile numbers verified that you can use to reset two factor. You can add another mobile now if you wish.</p>
								<p class="text-h6 theme-font">Your Verified Mobiles</p>
								<v-list>
									<v-list-item v-for="verifiedMobile,index in state.userMobileNumbers" :key="index">
										<v-icon class="mr-5 theme-icon-verified">verified</v-icon> {{ formatMobileNumber(verifiedMobile.data)  }}
									</v-list-item>
								</v-list>
								<p class="ml-10 text-caption theme-pointer text-decoration-underline" @click="state.newMobileNumber = true">Verify Another Mobile</p>
								<p class="text-body-2 ma-3">The secret key will be displayed on the next screen, please ensure others cannot view your screen before proceeding.</p>
								<v-spacer align="right" class="mt-5">
									<v-btn @click="goToCreateTwoFactorPage()" class="theme-bg-highlight">Display Key</v-btn>
								</v-spacer>
							</v-col>
							<v-col v-if="state.newMobileNumber || (state.userMobileNumbers && state.userMobileNumbers.length == 0)">
								<v-row>
									<v-col class="ma-5 mt-0">
										<p class="text-h5 theme-font">Verifying Your Mobile</p>
										<p class="text-body-2 ma-2">We use your number for account recovery purposes only.</p>
										<v-spacer class="ma-5 mt-6" align="center">
											<v-text-field class="w-75" variant="outlined" id="mobileNumber" name="mobileNumber" label="UK Mobile Number (07XXXXXXXXX)" type="input"  v-model="state.mobileVerification.mobileNumber" :disabled="state.mobileVerification.sent" @focusin="mobileValid(this)" @focusout="mobileValid(this)" @input="mobileValid(this)" ></v-text-field>
											
											<v-btn @click="sendMobileVerificationCode()" :disabled="!state.mobileNumberReady" :class="(state.mobileNumberReady) ? 'theme-bg-highlight' :''" v-if="!state.mobileVerification.sent">Send Code</v-btn>											
										</v-spacer>
										
										<v-spacer v-if="state.mobileVerification.sent" align="center" class="ml-0">	
											<p class="ma-5 mt-0">Please enter the code sent via SMS to your phone.</p>
											<v-otp-input 
												ref="otpInput"
												input-classes="otp-input"
												v-model:value="state.mobileVerification.userCode"
												separator="-"
												:num-inputs="6"
												:should-auto-focus="true"
												:is-input-num="true"
												value=""
												@on-complete="validCode(this)"
											></v-otp-input>

											<v-btn class="mt-8" @click="verifyMobileCode()" :disabled="!state.codeReady" :class="(state.codeReady) ? 'theme-bg-highlight' :''">Verify</v-btn>											
											<v-spacer align="right"><p class="theme-help-format mr-6" @click="state.twoFactor.verificationCode = undefined;validTwoFactor(this)">RESET</p></v-spacer>
										</v-spacer>
									</v-col>
								</v-row>
								<v-row v-if="false">
									<v-col align="left"><v-btn @click="goToIntro()">Back</v-btn></v-col><v-col align="right"><v-btn @click="goToMobileRecovery()">Begin Set-Up</v-btn></v-col>
								</v-row>								
							</v-col>
						</v-row>
						<v-row v-if="state.page == 'createTwoFactor'">							
							<v-col v-if="state.i164TOTPKey">
								<p class="text-h5 theme-font">Scan this barcode with your TOTP App.</p>
								<p class="text-body-2 ma-2"> Click next when you have done this.</p>
								<p><v-img :src="state.i164TOTPKey.qrCodeURL" width="v-col-12 v-col-sm-8 v-col-md-6 v-col-lg-4 pb-0 mb-0"></v-img></p>
								<p class="text-body-2 ma-2"> The secret key is displayed below if you wish to enter it manually.</p>
								<v-spacer align="right"><p class="theme-help-format text-decoration-none mr-6 pt-0">{{state.i164TOTPKey.secretKey}}</p></v-spacer>
								<v-spacer align="right" class="mt-5"><v-btn @click="goToActivateTwoFactor(this)" class="theme-bg-highlight">Next</v-btn></v-spacer>
							</v-col>
							<v-col v-else>
								<p class="theme-font-other"><v-progress-circular
										indeterminate
										color="rgb(var(--v-theme-loading_spinner_color))"									
									></v-progress-circular></p>
							</v-col>
						</v-row>	
						<v-row v-if="state.page == 'activateTwoFactor'">							
							<v-col v-if="state.i164TOTPKey">
								<p class="text-h5 theme-font ">Activate This Key</p>
								<p class="text-body-2 ma-2 mb-5">Enter the 6 digit two factor code displayed in your app. This will activate two factor on your account.</p>		
								<v-otp-input 
												ref="activateInput"
												input-classes="otp-input"
												v-model:value="state.activationCode"
												separator="-"
												:num-inputs="6"
												:should-auto-focus="true"
												:is-input-num="true"
												value=""
												@on-complete="validActivationCode(this)"
											></v-otp-input>							
								<div class="d-flex mt-5">
									<v-btn @click="state.page = 'createTwoFactor'">Back</v-btn><v-spacer align="right"><v-btn @click="activateTwoFactor()"  :disabled="!state.activationCodeReady" :class="(state.activationCodeReady) ? 'theme-bg-highlight' :''">Activate</v-btn></v-spacer>
								</div>
								
							</v-col>							
						</v-row>						
				</v-card-text>
						</v-card>
					</v-col>
					<v-col md="3" sm="2" lg="4">					
					</v-col>
				</v-row>			
	</v-dialog>



</template>

<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';


const { t }  = useI18n();

const mainStore = useMainStore();

const emits = defineEmits(['close']);

const state = reactive({	
		mobileNumberReady: false,	
		codeReady: false,
		activationCodeReady: false,
		show: false,
		commandOutcome: undefined,
		page: 'intro',
		mobileVerification: {
			mobileNumber: undefined,
			sent: false,
			userCode: undefined,
			mobileVerified: false,
		},
		newMobileNumber: false,
		userMobileNumbers: undefined,
		i164TOTPKey: undefined,
		activationCode: undefined,
});

const mobileValid = () => {
	if (state.mobileVerification.mobileNumber) {
		if (state.mobileVerification.mobileNumber.match("^07[\\d]{8,12}|447[\\d]{7,11}$")) {
			state.mobileNumberReady = true;
			return true;
		}
		
	}
	state.mobileNumberReady = false;
	return false;
}

const validCode = (event) => {
	state.codeReady = true;
}

const validActivationCode = (event) => {
	state.activationCodeReady = true;
}

const listValidataUserMobileNumbers = async (event) => {
	const apiUrl = mainStore.getAPIUrl('plustag');			
		await fetch(apiUrl+'/plustag/listValidataUserMobileNumbers', {
			method: 'get',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {
						state.userMobileNumbers = d.userMobileNumbers;											
					}	else {
						const commandOutcome = mainStore.getCommandOutcome(t,d.outcome,d.message);
						state.commandOutcome = commandOutcome;
					}		
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});		
			
}

const verifyMobileCode = async (event) => {
	const apiUrl = mainStore.getAPIUrl('plustag');			
		const data = new URLSearchParams();
		data.set("verificationCode",state.mobileVerification.userCode);
		await fetch(apiUrl+'/plustag/verifyMobileCode', {
			body: data,
			method: 'post',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {
						state.userMobileNumbers = d.userMobileNumbers;
						state.newMobileNumber = false;
						state.mobileVerification.mobileVerified = true;
						mainStore.displaySnackBar('Code Verified');											
					}	else {
						const commandOutcome = mainStore.getCommandOutcome(t,d.outcome,d.message);
						state.commandOutcome = commandOutcome;
					}		
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});		
			
}

const formatMobileNumber = (number) => {
		if (number.startsWith("44")) {
			return "0"+number.substring(2);
		}
		return number;
	}

const sendSMSVerification = async (event) => {
	const apiUrl = mainStore.getAPIUrl('plustag');			
		const data = new URLSearchParams();
		data.set("mobileNumber",state.mobileVerification.mobileNumber);
		await fetch(apiUrl+'/plustag/sendSMSVerification', {
			body: data,
			method: 'post',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {						
						state.mobileVerification.sent = true;						
						mainStore.displaySnackBar('Verification SMS Sent');																											
					}	else {
						const commandOutcome = mainStore.getCommandOutcome(t,d.outcome,d.message);
						state.commandOutcome = commandOutcome;
					}		
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});		
			
}

const createTOTPForUser = async (event) => {
	if (state.i164TOTPKey) {
		return;
	}
	const apiUrl = mainStore.getAPIUrl('plustag');			
		await fetch(apiUrl+'/plustag/createTOTPForUser', {
			method: 'get',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {						
						state.i164TOTPKey = d.i164TOTPKey;											
					}	else {
						const commandOutcome = mainStore.getCommandOutcome(t,d.outcome,d.message);
						state.commandOutcome = commandOutcome;
					}		
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});		
			
}

const activateTOTPForUser = async (event) => {
	const apiUrl = mainStore.getAPIUrl('plustag');			
	const data = new URLSearchParams();
	data.set("verificationCode",state.activationCode);
	await fetch(apiUrl+'/plustag/enableTOTPForUser', {
		method: 'post',				
		body: data,
		credentials: 'include',
		redirect: 'follow',		
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)							
				if (d.outcome == 1) {
					mainStore.displaySnackBar('Two Factor Activated');
					close(event);
				}	else {
					const commandOutcome = mainStore.getCommandOutcome(t,d.outcome,d.message);
					state.commandOutcome = commandOutcome;
				}		
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);
	});		
			
}

const sendMobileVerificationCode = () => {
	sendSMSVerification();
}

const activateTwoFactor = () => {
	activateTOTPForUser();
}

const close = (a) => {	
	state.mobileNumberReady = false;
	state.codeReady = false;
	state.activationCodeReady = false;		
	state.page = 'intro';
	state.newMobileNumber = false;
	state.i164TOTPKey = undefined;
	emits('close');	
}

const props = defineProps({
		show: Boolean,	
});

const goToIntro = () => {
	state.page = 'intro';
}

const goToCreateTwoFactorPage = () => {
	createTOTPForUser();
	state.page = 'createTwoFactor';
}

const goToActivateTwoFactor = () => {	
	state.page = 'activateTwoFactor';
}

const goToMobileRecovery = () => {
	state.page = 'mobileRecovery';
}


onUpdated(() => {		
	state.show = props.show;
	});

	onMounted(() => {
		state.show = props.show;
		listValidataUserMobileNumbers();
		mobileValid();
	});

</script>

<style>

</style>