<template>
	<v-container fluid class="px-6 py-6 h-100">
		<v-row class="mb-2">			
				<v-col md="12">			
					<v-row>
						<v-col class="d-flex">
						<v-avatar
							class="shadow border-radius-xl mt-1"
							size="48" color="avatar_background"
						><v-icon class="vx-text-dark heme-avatar-color" size="32">numbers</v-icon></v-avatar>
							<p class="mt-3 ml-3 font-weight-bold text-h4 vx-text-dark theme-font-bold">New Service Account</p>
						</v-col>	
						<v-col align="right">							
							<v-btn class="pl-2 theme-list-action-button-background  text-action_button_text" @click="exitApplication();" size="small">Exit Application</v-btn>
						</v-col>					
					</v-row>
				</v-col>			
			</v-row>
			<v-row class="mb-12 listRow">							
				<v-col md="12">
				<v-card min-width="750px">
					<v-card-title></v-card-title>
					<v-card-text>
						<v-container>
							<v-row>
								<v-timeline direction="horizontal"  line-color="vx-dark-background">
									<v-timeline-item fill-dot :dot-color="state.selectedStage == 1  ? 'rgb(var(--v-theme-lifecycle_timeline_active_color))' : 'rgb(var(--v-theme-lifecycle_timeline_inactive_color))'">
										<p :class="state.selectedStage == 1 ? 'font-weight-bold theme-font-bold text-h6' : 'vx-text-light-gray theme-font text-h6'">Account Details</p>	
										<!--<div :class="state.selectedStage == 1 ? 'theme-text-highlight-dark' : 'vx-text-dark'">Account Details</div>-->
										<template v-slot:icon>
											<span :class="state.selectedStage == 1 ? 'text-white' : ' text-white'" class="text-h6">1</span>
											<!--<span :class="state.selectedStage == 1 ? 'theme-bg-highlight vx-text-dark' : 'vx-dark-background text-grey'">1</span>-->
										</template>
									</v-timeline-item>
									<v-timeline-item fill-dot :dot-color="state.selectedStage == 2  ? 'rgb(var(--v-theme-lifecycle_timeline_active_color))' : 'rgb(var(--v-theme-lifecycle_timeline_inactive_color))'">
											
										<template v-slot:opposite>
											<p :class="state.selectedStage == 2 ? 'font-weight-bold theme-font-bold text-h6' : 'vx-text-light-gray theme-font text-h6'">Billing</p>
								
										</template>
										<template v-slot:icon>
											<span
											:class="state.selectedStage == 2 ? 'text-white' : 'text-white'" class="text-h6">2</span>
										</template>
									</v-timeline-item>
									<v-timeline-item fill-dot :dot-color="state.selectedStage == 3 ? 'rgb(var(--v-theme-lifecycle_timeline_active_color))' : 'rgb(var(--v-theme-lifecycle_timeline_inactive_color))'">										
										<p :class="state.selectedStage == 3 ? 'font-weight-bold theme-font-bold text-h6' : 'vx-text-light-gray theme-font text-h6'">Terms & Conditions</p>
										<template v-slot:icon>
											<span
											:class="state.selectedStage == 3 ? 'text-white' : 'text-white'" class="text-h6">3</span>
										</template>
									</v-timeline-item>
								</v-timeline>				
							</v-row>

								<div  v-if="state.selectedStage == 1">
									<v-row>
										<v-col>
											You
										</v-col>
									</v-row>
									<v-row>
										<v-col>											
											<PlustagServiceFormServiceDetails :phoneServiceDetailsConfiguration="state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration" :refreshConfiguration="state.assets.phoneServiceDetails.refreshConfiguration" @dataEvents="phoneServiceDetailsDataReadyEvent"/>
										</v-col>
									</v-row>																		
									<v-row class="mt-5">
										<v-col>
											<v-row>
												<v-col class="ml-10"><p v-if="!state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName" class="theme-font text-red">This application has not yet been saved.</p></v-col>										
												<v-col align="right" class="mr-10"><v-btn @click="savePhoneServiceDetails(2);" align="right" :class="state.assets.phoneServiceDetails.dataReady ? 'theme-bg-highlight': ''" :disabled="!state.assets.phoneServiceDetails.dataReady"><p v-if="state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName">Next</p><p v-else>Save</p></v-btn></v-col>
											</v-row>
										</v-col>
									</v-row>
								</div>
								<div  v-if="state.selectedStage == 2">
									<v-row>
										<v-col v-if="state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService">
											<PlustagServiceFormBilling :phoneService="state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService" :billingDetails="state.billingDetails"/>
										</v-col>
									</v-row>
									
									<v-row class="mt-5">
										<v-col>
											<v-row>
												<v-col class="ml-10"><v-btn @click="gotToSelectedStage(1)">Back</v-btn></v-col>
												<v-col class="" align="center"></v-col>
												<v-col align="right" class="mr-10"><v-btn @click="state.selectedStage = 3" align="right" class="theme-bg-highlight">Next</v-btn></v-col>
											</v-row>
										</v-col>
									</v-row>
								</div>
								<div v-if="state.selectedStage == 3">
									<v-row>
										<v-col>
									<PlustagServiceFormTermsAndConditions :termsAndConditions="state.termsAndConditions"/>		
									</v-col>
									</v-row>
									<v-row class="mt-5">
										<v-col>
											<v-row>
												<v-col class="ml-10"><v-btn @click="state.selectedStage = 2">Back</v-btn></v-col>
												<v-col align="right" class="mr-10"><v-btn @click="completePhoneServiceApplication();" align="right" :class="(state.termsAndConditions.acceptedTerms) ? 'theme-bg-highlight': ''" :disabled="!state.termsAndConditions.acceptedTerms" >Complete New Service Application</v-btn></v-col>
											</v-row>
										</v-col>
									</v-row>
								</div>						


						</v-container>
					</v-card-text>
			
				</v-card>
			</v-col>
		</v-row>
	</v-container>
	<v-dialog v-model="state.showCreatedNewPhoneServiceDialog">

		<v-row>
				<v-col md="4" sm="2">					
				</v-col>
				<v-col cols="12" sm="8" md="4">
					<v-card
            width="50%" min-width="400" max-width="400"
            height="40%" min-height="200" max-height="200"
        >
            <v-card-title>New Service Application</v-card-title>
            <v-card-text>
             <p>You have completed and submitted your new service application.</p>
						 <p>Our team will approve this as soon as possible.</p>
            </v-card-text>
            <v-card-actions>
							<v-col class="text-right">
								<v-btn
                    @click="goToServicesList()"
                >Close</v-btn>               
							</v-col>
            </v-card-actions>
        </v-card>
				</v-col>
				<v-col md="4" sm="2">					
				</v-col>
			</v-row>
        
    </v-dialog>
		<v-dialog v-model="state.showSavedAsDraftDialog">

			<v-row>
				<v-col md="4" sm="2">					
				</v-col>
				<v-col cols="12" sm="8" md="4">
					<v-card
            width="50%" min-width="400" max-width="400"
            height="40%" min-height="200" max-height="200"
        >
            <v-card-title class="theme-font text-align-center theme-text-highlight-dark">Application Saved</v-card-title>
            <v-card-text>
             <p>You will find this in your services list when you are ready to complete the process.</p>
            </v-card-text>
            <v-card-actions>
							<v-col class="text-left">
							
							</v-col>
							<v-col class="text-right">
								
								<v-btn
                    @click="goToServicesList()"
                >Close</v-btn>
							</v-col>
            </v-card-actions>
        </v-card>
				</v-col>
				<v-col md="4" sm="2">					
				</v-col>
			</v-row>
       
    </v-dialog>
</template>
	


<script setup>
	import { defineProps,onMounted,onBeforeUnmount, ref, reactive, watch } from 'vue';
	import { useRoute, useRouter,onBeforeRouteLeave} from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useBroadcastChannel } from "vue-composable";
	import PlustagServiceFormServiceDetails from './PlustagServiceFormServiceDetails.vue';
	import PlustagServiceFormBilling from './PlustagServiceFormBilling.vue';
	import PlustagServiceFormTermsAndConditions from './PlustagServiceFormTermsAndConditions.vue';

const {
  supported,
  send,	
} = useBroadcastChannel('voxhub');

	const mainStore = useMainStore();
	const route = useRoute();
	const router = useRouter();

	
	
	const emits = defineEmits(["close"]);
	
	const self = this;
	
	const close = (a) => {	
		emits('close');
	}

	const phoneServiceDetailsDataReadyEvent = (event) => {
		console.log("phoneServiceDetailsDataReadyEvent "+event.state);
		if (event.state == 'ready') {
			//!never update phoneService back!
			//state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService = event.phoneService;
			state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneServiceDetails = event.phoneServiceDetails;
			state.assets.phoneServiceDetails.dataReady = true;		
		} else {
			state.assets.phoneServiceDetails.dataReady = false;	
		}
	}



	const state = reactive({
		selectedStage: 1,
		showCreatedNewPhoneServiceDialog: false,
		showSavedAsDraftDialog: false,
		askAboutLeaving: true,
		assets: {
			phoneServiceDetails: {				
				phoneServiceDetailsConfiguration: {		
					phoneServiceDetails: undefined,
					phoneService: {
						phoneServiceUUID: undefined,
						phoneServiceName: undefined,
					}
				},
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
		},
		validity: {
			phoneServiceDetails: false,
		},
		termsAndConditions: {
			acceptedTerms: false,
		},
		billingDetails: {
			directDebitDetails: {
				valid: false,
			}
		}
	});

	
	const props = defineProps({
		show: Boolean,		
		dialogDetails: {
							type: Object,
							default: {
								allocatedNumber: {},
								showDialog: false
							}
					},
	});

	const gotToSelectedStage = (selectedStage) => {
		router.push({name: 'plustagEditServiceForm', params:{phoneServiceName: state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName}});
		state.selectedStage = selectedStage;
	}

	const goToServicesList = () => {
		state.showSavedAsDraftDialog = false;
		state.askAboutLeaving = false;
		router.push({ name: 'plustag'});
	}


	const exitApplication = () => {
		savePhoneServiceDetails(state.selectedStage,true);
	}
	
	const savePhoneServiceDetails = (selectedStage,quit) => {
		console.log('savePhoneServiceDetails '+state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName);
		if (state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName) {
			updatePhoneServiceDetails(selectedStage,quit);
		} else {
			createNewPhoneService(selectedStage,quit);
		}
	}

	const createNewPhoneService = async (selectedStage,quit) => {
		if (!state.assets.phoneServiceDetails.dataReady) {
			console.log('Exit No save');
			goToServicesList();
			return;
		}
		if (state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneServiceDetails && state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneServiceDetails.phoneServiceDetailsUUID) {
			console.log('saved already');
			return;
		}
			const apiUrl = mainStore.getAPIUrl();			
			const data = new URLSearchParams();
			data.set('phoneServiceDetails', JSON.stringify(state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneServiceDetails));		
			await fetch(apiUrl+'/phoneServices/createNewPhoneService', {
				method: 'post',
				body: data,
				credentials: 'include',
				redirect: 'follow',		
			}).then((response) => {
				response.json().then( 
					d => {
						console.log(d)							
						if (d.outcome == 1) {			
								mainStore.refreshLoggedInStatus	=true;
								state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneServiceDetails = d.phoneServiceDetails;
								state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName = d.phoneService.phoneServiceName;
								state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceUUID = d.phoneService.primaryUUID;												
							
								state.selectedStage = selectedStage;
								if (quit) {
									state.showSavedAsDraftDialog = true;										
							} else {
								router.push({name: 'plustagEditServiceForm', params:{phoneServiceName: state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName,selectedStage: selectedStage}});														
							}
						}							
					}									
				)
				return response;
			}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
		});		
	}

		const updatePhoneServiceDetails = async (selectedStage,quit) => {		
				const apiUrl = mainStore.getAPIUrl(state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName);			
				const data = new URLSearchParams();
				data.set('phoneServiceDetails', JSON.stringify(state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneServiceDetails));		
				await fetch(apiUrl+'/phoneServices/savePhoneServiceDetails', {
					method: 'post',
					body: data,
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome = 1) {
								mainStore.displaySnackBar('Application Saved');
								state.selectedStage = selectedStage;
								if (quit) {
									state.showSavedAsDraftDialog = true;											
								} else {
									router.push({name: 'plustagEditServiceForm', params:{phoneServiceName: state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName,selectedStage: selectedStage}});															
								}
								
								

							} else {									
									console.log('Failed '+outcome);
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
		}

		const refreshConfiguration = (assetName) => {	

			switch (assetName){
				case 'phoneServiceDetails':
					state.assets.phoneServiceDetails.refreshConfiguration.version++;
					state.assets.phoneServiceDetails.refreshConfiguration = {
						onboarding: state.assets.phoneServiceDetails.refreshConfiguration.onboarding,
						version: state.assets.phoneServiceDetails.refreshConfiguration.version						
					};
				
				break;
			}			
			
			
		}
	
	const loadPhoneServiceDetails = async () => {		
				const apiUrl = mainStore.getAPIUrl(state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName);			
				await fetch(apiUrl+'/phoneServices/loadPhoneServiceDetails', {
					method: 'post',
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == -3) {
							} else {
								if (d.phoneServiceDetails) {		
									state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneServiceDetails = d.phoneServiceDetails;																
									refreshConfiguration('phoneServiceDetails');
								} 
								//state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration
									const domainDetails = mainStore.getDomainDetails(d.phoneServiceName);
									if (domainDetails) {										
										state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName = domainDetails.phoneServiceName;
										state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceUUID = domainDetails.phoneServiceUUID;
									}
				
								
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);

			});		
		}
		const completePhoneServiceApplication = async () => {		
				//const apiUrl = mainStore.getAPIUrl(state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName);			
				const apiUrl = mainStore.getAPIUrl('plustag');			
				const data = new URLSearchParams();
				data.set('signupTermsAndConditions', state.termsAndConditions.acceptedTerms);		
				data.set('phoneServiceDetailsUUID', state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneServiceDetails.primaryUUID);		
				//await fetch(apiUrl+'/phoneServices/completePhoneServiceApplication', {
					await fetch(apiUrl+'/plustag/loggedInStatus', {
					method: 'post',
					body: data,
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {
								state.showCreatedNewPhoneServiceDialog = true;
							}					
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);

			});		
		}


	const beforeWindowUnload = ()  => {
		
	}

	onMounted  (() => {
		if (route.params.phoneServiceName) {
			console.log('route.params.phoneServiceName '+route.params.phoneServiceName);
			const domainDetails = mainStore.getDomainDetails(route.params.phoneServiceName);
			if (domainDetails) {										
				state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceName = domainDetails.phoneServiceName;
				state.assets.phoneServiceDetails.phoneServiceDetailsConfiguration.phoneService.phoneServiceUUID = domainDetails.phoneServiceUUID;
			}	else {
				console.log('Did not find domainDetails for '+route.params.phoneServiceName);
			}	
			if (route.params.selectedStage)	{
				state.selectedStage = route.params.selectedStage;
			}
			loadPhoneServiceDetails();
		}

  });

	onBeforeUnmount (() => {
    window.removeEventListener('beforeunload', beforeWindowUnload)		
  });
		
	
	</script>