<template>
	<OuterCard>
		<v-row v-if="state.phoneServiceUserParticipation">
			<v-col class="pa-0">
				<BoxHeader headerText="User Details"/>				
				<v-row>
					<v-col class="ma-2 pt-0 pb-0 ">		
						<v-spacer class="d-flex mt-3">
							<v-icon  class="mr-3 text-primary_100" size="20">done</v-icon>
							<p class="theme-font-other text-body-2">{{ t("statics.lifecyclePhases.user."+phoneServiceUserParticipation.onboarding) }}</p>
						</v-spacer>											
					</v-col>
				</v-row>											
				<v-row>
					<v-col class="ma-2 pt-0 pb-0 mb-3">		
						<div>
							<v-divider></v-divider>
						</div>	
						<v-spacer class="d-flex mt-3">
							<v-icon  class="mr-3 text-primary_100" size="20">person</v-icon>												
							<p class="theme-font-other text-body-2">{{ state.phoneServiceUserParticipation.firstName}} {{ state.phoneServiceUserParticipation.lastName}}</p>
							
						</v-spacer>											
					</v-col>
				</v-row>
				<v-row>
					<v-col class="ma-2 pt-0 pb-0 mb-3">		
						<div>
							<v-divider></v-divider>
						</div>	
						<v-spacer class="d-flex mt-3 mb-3">
							<v-icon  class="mr-3 text-primary_100" size="20">mail</v-icon>
							<p class="theme-font-other text-body-2">{{ state.phoneServiceUserParticipation.emailAddress}}</p>	<v-spacer align="right"><v-icon class="ml-5 theme-icon-verified">verified</v-icon></v-spacer>
						
						</v-spacer>											
					</v-col>
				</v-row>			
				
			</v-col>
		</v-row>	

		<v-row v-else>
			<v-col>
				<p class="theme-font-other"><v-progress-circular
								indeterminate
								color="rgb(var(--v-theme-loading_spinner_color))"									
							></v-progress-circular></p>
						</v-col>
		</v-row>	
	</OuterCard>		
</template>

<script setup>
	import { defineProps,reactive,onMounted,onUpdated } from 'vue';
	import OuterCard from '@/components/common/OuterPopCard.vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
import BoxHeader from '@/components/BoxHeader.vue';


	const { t }  = useI18n();
	const mainStore = useMainStore();
	
	const router = useRouter();		


	const props = defineProps({
		phoneServiceUserParticipation: {
			type: Object,
			required: true,
		}
	});


	const state = reactive({		
		phoneServiceUserParticipation: undefined,
	});


	onMounted(() => {
		if (props.phoneServiceUserParticipation) {
			state.phoneServiceUserParticipation = props.phoneServiceUserParticipation;
		}		
	});

</script>

<style>

</style>