<template>
		<div class="theme-mainPanel fill-height" >					
			<v-row class="mb-5 fill-height" >
				<v-col md="4" ></v-col>
				<v-col md="4" class="fill-height d-flex align-center justify-center" >
					<v-card  class="card-shadow border-radius-md loadingBox" >
						<v-card-text class="align-center ma-5" >
							<v-row>
								<v-col class="ma-5"><v-img :src="servedUpLogo" max-width="250" ></v-img></v-col>
							</v-row>
							<v-row>
								<v-col>
									<p class="text-h5 theme-font vx-text-dark">
									You do not have Access to this Domain.
									</p>
								</v-col>								
							</v-row>	
							<v-row>
								<v-col class="ml-2 mt-2 d-flex">
										<p class="ml-5 pl-2 pr-2 text-caption text-decoration-underline bg-white theme-pointer" @click="goToPlustagServices()">Back to your Services</p>
									</v-col>
								</v-row>					
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4" ></v-col>
			</v-row>
				</div>
</template>

<script setup>
	import { useRouter } from "vue-router";
	import { useMainStore } from "../stores/MainStore.js";
	import voxhubLogo from '@/assets/images/voxhub_greenOnWhite.svg';
	import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
	import i164Transparent from '@/assets/images/i164_transparent.png';
	const router = useRouter();
	const mainStore = useMainStore();

	const goToPlustagServices = () => {
		mainStore.phoneServiceName = 'plustag';
		router.push({name: 'plustagServices'});
	}


</script>

<style scoped>

</style>