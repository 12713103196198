<template>
	<v-container fluid class="px-6 py-6 h-100">	
		<v-row >			
			<v-col>
				
				<v-tabs v-model="state.tabsTop">	
					<v-tab value="home" :class="(state.tabsTop== 'home') ? 'bg-active_tab_color':''" >					
						<v-avatar
							class="shadow border-radius-xl"
							size="32"  color="avatar_background"
						><v-icon class="vx-text-dark theme-avatar-color" size="24">currency_pound</v-icon></v-avatar>							
							<p class="ml-2 text-h6 theme-font">Billing</p>
					</v-tab>	
					<v-tab value="bundles" :class="(state.tabsTop== 'bundles') ? 'bg-active_tab_color':''">
						<p class="ml-2 text-h6 theme-font">Bundles</p>
					</v-tab>
					<v-tab value="tariffs" :class="(state.tabsTop== 'tariffs') ? 'bg-active_tab_color':''">
						<p class="text-h6 theme-font">Tariffs</p>
					</v-tab>	
					<v-tab value="manageTariff" :class="(state.tabsTop== 'manageTariff') ? 'bg-active_tab_color':''" v-if="state.manageTariff">
						<p class="text-h6 theme-font">{{state.manageTariff.tariffName}}</p> <v-icon @click.prevent.stop="closeTariffTab()" size="x-small" class="ml-4 mb-4">close</v-icon>
					</v-tab>
					<v-tab value="manageBundle" :class="(state.tabsTop== 'manageBundle') ? 'bg-active_tab_color':''" v-if="state.manageBundle">
						<p class="text-h6 theme-font">Bundle: {{state.manageBundle.bundleName}}</p> <v-icon @click.prevent.stop="closeBundleTab()" size="x-small" class="ml-4 mb-4">close</v-icon>
					</v-tab>				
				</v-tabs>						
				<v-divider></v-divider>							
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-window v-model="state.tabsTop" class="fill-height bg-white  mt-0 pt-0">
					<v-window-item value="home">
						<BillingHome></BillingHome>
					</v-window-item>
					<v-window-item value="bundles">						
						<ListBundles  @dataEvents="manageBundle"></ListBundles>
					</v-window-item>
					<v-window-item value="tariffs">
						<ListTariffs @dataEvents="manageTariff"></ListTariffs>
					</v-window-item>
					<v-window-item value="manageTariff" class="mt-0" v-if="state.manageTariff">
						<DestinationsManager :tariff="state.manageTariff"/>
					</v-window-item>
					<v-window-item value="manageBundle" class="mt-0" v-if="state.manageBundle">
						<BundleManager :bundle="state.manageBundle"/>
					</v-window-item>
				</v-window>
			</v-col>
		</v-row>
		<v-row class="pd-10">
			&nbsp;
		</v-row>			
	</v-container>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import BillingHome from './BillingHome.vue';
import ListBundles from './ListBundles.vue';
import ListTariffs from './ListTariffs.vue';
import DestinationsManager from './DestinationsManager.vue';
import BundleManager from './BundleManager.vue';

const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const manageTariff = (event) => {
	state.manageTariff = event.tariff;
	state.tabsTop = 'manageTariff';
}

const manageBundle = (event) => {
	state.manageBundle = event.bundle;
	state.tabsTop = 'manageBundle';
}

const closeTariffTab = () => {
	state.manageTariff = undefined;
	state.tabsTop = 'tariffs';
}

const closeBundleTab = () => {
	state.manageBundle = undefined;
	state.tabsTop = 'tariffs';
}


const state = reactive({	
	tabsTop: 'home',
	manageTariff: undefined,
	manageBundle: undefined,	
});

</script>

<style>
</style>