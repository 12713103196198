<template>
<v-container fluid class="px-6 py-6 h-100">
	<!--<v-row class="mb-2">			
		<v-col>			

				<v-row>
					<v-col class="d-flex">
					<v-avatar
						class=" shadow border-radius-xl mt-1"
						size="48"  color="avatar_background"
					><v-icon class="theme-avatar-color" size="32">dialer_sip</v-icon></v-avatar>
						<p class="mt-3 ml-3 font-weight-bold text-h4 vx-text-dark theme-font-bold">Basic Line</p>
					</v-col>
				
				</v-row>
		</v-col>				
	</v-row>-->
	<v-row>
		<v-col>
			<v-card class="bg-secondary_700">
				<v-card-text>
					<v-timeline direction="horizontal" side="end" density="compact" line-color="vx-dark-background">
						<v-timeline-item size="x-small" fill-dot :dot-color="state.onboarding == 'creation'  ? 'rgb(var(--v-theme-lifecycle_timeline_active_color))' : 'rgb(var(--v-theme-lifecycle_timeline_inactive_color))'">
							<p :class="state.onboarding == 'creation' ? 'font-weight-bold theme-font-bold text-h7' : 'vx-text-light-gray theme-font text-h7'">Creating</p>					
						<template v-slot:icon>
							<span :class="state.onboarding == 'creation' ? 'vx-text-dark ' : 'theme-bg-highlight vx-text-dark'"></span>
						</template>
						</v-timeline-item>
						<v-timeline-item size="x-small" fill-dot :dot-color="state.onboarding == 'preCommitment' ? 'rgb(var(--v-theme-lifecycle_timeline_active_color))' : 'rgb(var(--v-theme-lifecycle_timeline_inactive_color))'">
							<p :class="state.onboarding == 'preCommitment' ? ' font-weight-bold text-lifecycle_timeline_active_color text-h7 ' : 'vx-text-light-gray theme-font text-h7'">Created</p>					
							<template v-slot:icon>
								<span :class="state.onboarding == 'preCommitment' ? 'vx-text-light-gray ' : 'theme-bg-highlight vx-text-dark'"></span>
							</template>
						</v-timeline-item>						
						<v-timeline-item size="x-small" fill-dot :dot-color="state.onboarding == 'ready' ? 'rgb(var(--v-theme-lifecycle_timeline_active_color))' : 'rgb(var(--v-theme-lifecycle_timeline_inactive_color))'">
							<p :class="state.onboarding == 'ready' ? 'font-weight-bold theme-font-bold text-h7' : 'vx-text-light-gray theme-font text-h7'">Live</p>					
							<template v-slot:icon>
								<span :class="state.onboarding == 'ready' ? 'vx-text-dark ' : 'theme-bg-highlight vx-text-dark'"></span>
							</template>
						</v-timeline-item>	
						<v-timeline-item size="x-small" fill-dot :dot-color="state.onboarding == 'ended'  ? 'rgb(var(--v-theme-lifecycle_timeline_active_color))' : 'rgb(var(--v-theme-lifecycle_timeline_inactive_color))'">
							<p :class="state.onboarding == 'ended' ? 'font-weight-bold theme-font-bold text-h7' : 'vx-text-light-gray theme-font text-h7'">Ended</p>					
							<template v-slot:icon>
								<span :class="state.onboarding == 'ended' ? 'vx-text-dark ' : 'theme-bg-highlight vx-text-dark'"></span>
							</template>
						</v-timeline-item>					
					</v-timeline>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
	<v-row>
		<v-col>				
				<v-row>
					<v-col>
						
						<v-row>
							<v-col>		
								<AccountSelectionComponent :serviceAccountContextConfiguration="state.assets.serviceAccountContext.serviceAccountContextConfiguration" :refreshConfiguration="state.assets.serviceAccountContext.refreshConfiguration" @dataEvents="serviceAccountContextDataReadyEvent"/>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<SubscriberDetailsComponent :subscriberAddressConfiguration="state.assets.subscriberAddress.subscriberAddressConfiguration" :refreshConfiguration="state.assets.subscriberAddress.refreshConfiguration" :assetData="state.assets.subscriberAddress.subscriberAddressAssets"  @dataEvents="subscriberAddressDataRedayEvenet"/>
							</v-col>
						</v-row>
						
					</v-col>					
					<v-col>							
						<v-row>
							<v-col>
								<NumberSelectionComponent :numberConfiguration="state.assets.numbers.numberConfiguration" :refreshConfiguration="state.assets.numbers.refreshConfiguration" :assetData="state.assets.numbers.numberAssets" @dataEvents="numbersDataReadyEvent" selectionMode="single"/>								
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<DeviceSelectionComponent :readOnly="(true)" :deviceConfiguration="state.assets.devices.deviceConfiguration"  :refreshConfiguration="state.assets.devices.refreshConfiguration" :assetData="state.assets.devices.deviceAssets"  :availableDevices="availableDevices" @dataEvents="devicesDataReadyEvent" selectionMode="single"/>							
							</v-col>
						</v-row>
					</v-col>
					<v-col>
						<v-row>
							<v-col>
								<v-card>
									<BoxHeader headerText="Basic Line Check List" :dataReady="(state.assets.serviceAccountContext.dataReady && state.assets.subscriberAddress.dataReady && state.assets.numbers.dataReady && state.assets.devices.dataReady)" readyIcon="check_circle"/>
									<v-card-text>
										<v-list>
											<v-list-item>
												<template v-slot:prepend>
													<v-icon :class="state.assets.serviceAccountContext.dataReady ? 'text-check_passed_color opacityFix mt-1 mr-2' : 'text-check_failed_color mt-1 mr-2'">check_circle_outline</v-icon> <v-icon icon="account_tree" size="16" class="mr-3"></v-icon>
												</template>
												<v-list-item-title class="ma-0">Account</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<template v-slot:prepend>
													<v-icon :class="state.assets.subscriberAddress.dataReady ? 'text-check_passed_color opacityFix mt-1 mr-2' : 'text-check_failed_color mt-1 mr-2'">check_circle_outline</v-icon> <v-icon icon="contact_page" size="16" class="mr-3"></v-icon>
												</template>
												<v-list-item-title class="ma-0">Subscriber Details</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<template v-slot:prepend>
													<v-icon :class="state.assets.numbers.dataReady ? 'text-check_passed_color opacityFix mt-1 mr-2' : 'text-check_failed_color mt-1 mr-2'">check_circle_outline</v-icon> <v-icon icon="numbers" size="16" class="mr-3"></v-icon>
												</template>
												<v-list-item-title class="ma-0">Number Selection</v-list-item-title>
											</v-list-item>
											<v-list-item>
												<template v-slot:prepend>
													<v-icon :class="state.assets.devices.dataReady ? 'text-check_passed_color opacityFix mt-1 mr-2' : 'text-check_failed_color mt-1 mr-2 '">check_circle_outline</v-icon> <v-icon icon="device_hub" size="16" class="mr-3"></v-icon>
												</template>
												<v-list-item-title class="ma-0 ">Device Selection</v-list-item-title>
											</v-list-item>
										</v-list>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>

						<v-row>
							<v-col v-if="state.onboarding == 'creation'">
								<v-btn @click="createBasicLine()">Create New Line</v-btn>
							</v-col>
							<v-col v-if="state.onboarding == 'preCommitment'">
								<v-btn @click="startBasicLine()">Start Line</v-btn>
							</v-col>
							<v-col v-if="state.onboarding == 'ready'">
								<v-btn @click="endBasicLine()">Stop Line</v-btn>
							</v-col>
						</v-row>
						<v-row>
		<v-col>
		
		</v-col>
	</v-row>
					</v-col>
				</v-row>
					

		</v-col>
	</v-row>
</v-container>

	
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import AccountSelectionComponent from '@/microServices/contexts/AccountSelectionComponent.vue';
	import SubscriberDetailsComponent from '@/microServices/numbers/SubscriberDetailsComponent.vue';
	//import NumberSelectionComponent from '@/microServices/numbers/NumberSelectionComponent.vue';
	import NumberSelectionComponent from '@/microServices/numbers/NumberSelectionComponent2.vue';
	import DeviceSelectionComponent from '@/microServices/devices/DeviceSelectionComponent.vue';
import BoxHeader from '@/components/BoxHeader.vue';

	const { t }  = useI18n();
	
	const route = useRoute();
	const router = useRouter();

	const mainStore = useMainStore();


	const serviceAccountContextDataReadyEvent = (event) => {
		console.log('serviceAccountContextDataStateEvent '+event.state);
		console.log(event.serviceAccountContextRequest);
		state.assets.serviceAccountContext.serviceAccountContextConfiguration.serviceAccountContextRequest = event.serviceAccountContextRequest;
		state.assets.serviceAccountContext.dataReady = (event.state == 'ready');
	}

	const subscriberAddressDataRedayEvenet = (event) => {
		console.log('subscriberAddressDetailsDataStateEvent '+event.state);
		console.log(event.subscriberAddress);
		state.assets.subscriberAddress.subscriberAddressConfiguration.subscriberAddress = event.subscriberAddress;
		state.assets.subscriberAddress.dataReady = (event.state == 'ready');
	}

	const numbersDataReadyEvent = (event) => {
		console.log('numbersDataReadyEvent '+event.state);
		console.log(event.numberRequests);	
		state.assets.numbers.numberConfiguration.numberRequests = event.numberRequests;
		state.assets.numbers.dataReady = (event.state == 'ready');
	}		

	const devicesDataReadyEvent = (event) => {
		console.log('deviceDetailsDataStateEvent '+event.state);
		console.log(event.deviceRequests);
		state.assets.devices.deviceConfiguration.deviceRequests = event.deviceRequests;
		state.assets.devices.dataReady = (event.state == 'ready');
	}	

	


	const availableDevices = [
			{			
				deviceType: 1001,
			}
		]


	const state = reactive({	
		onboarding: undefined,
		voxletInstanceUUID: undefined,
		voxletPackageDetails: undefined,		
		assets: {
			serviceAccountContext: {				
				serviceAccountContextConfiguration: {		
					serviceAccountContextRequest: undefined,	
				},
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			numbers: {
				numberAssets: undefined,
				numberConfiguration: {						
					numberRequests: [],
				},	
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			devices: {
				deviceAssets: undefined,
				deviceConfiguration: {			
					deviceRequests : [
						{			
							deviceType: 1001,
						}
					],
				},
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			subscriberAddress: {
				subscriberAddressAssets: undefined,
				subscriberAddressConfiguration: {		
					subscriberAddress : undefined,
				},
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			}				
		},					
	});

	const prepareSubscriberAddress = () => {
		if (state.assets.subscriberAddress.subscriberAddressConfiguration.subscriberAddress) {
			state.assets.numbers.numberConfiguration.numberRequests.forEach(
				(numberRequest) => {
					numberRequest.subscriberAddress = state.assets.subscriberAddress.subscriberAddressConfiguration.subscriberAddress;
				}
			);
		}
	}


	const createBasicLine = async () => {
				const apiUrl = mainStore.getAPIUrl();	
					
				const data = new URLSearchParams();
				//data.set('basicLineConfiguration', JSON.stringify(basicLineConfiguration));
				prepareSubscriberAddress();
				data.set('numberRequests', JSON.stringify(state.assets.numbers.numberConfiguration.numberRequests));		
				data.set('deviceRequests', JSON.stringify(state.assets.devices.deviceConfiguration.deviceRequests));		
				data.set('serviceAccountContextRequest', JSON.stringify(state.assets.serviceAccountContext.serviceAccountContextConfiguration.serviceAccountContextRequest));		
				await fetch(apiUrl+'/voxletLines/createBasicLine', {
					body: data,
					method: 'post',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome = 1) {
								console.log("pusing to router");
								router.push({name: 'basicLine',params: {voxletInstanceUUID: d.voxletPackageDetails.voxletInstance.primaryUUID}});
							} else {
									
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}

		const startBasicLine = async () => {
				const apiUrl = mainStore.getAPIUrl();	
					
				const data = new URLSearchParams();
				data.set('voxletInstanceUUID', state.voxletInstanceUUID);		
				await fetch(apiUrl+'/voxletLines/startBasicLine', {
					body: data,
					method: 'post',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome = 1) {
								console.log("pusing to router");
								router.push({name: 'basicLine',params: {voxletInstanceUUID: d.voxletPackageDetails.voxletInstance.primaryUUID}});
								state.onboarding = 'ready';
							} else {
									
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}

		const endBasicLine = async () => {
				const apiUrl = mainStore.getAPIUrl();	
					
				const data = new URLSearchParams();
				data.set('voxletInstanceUUID', state.voxletInstanceUUID);		
				await fetch(apiUrl+'/voxletLines/endBasicLine', {
					body: data,
					method: 'post',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome = 1) {
								console.log("pusing to router");
								router.push({name: 'basicLine',params: {voxletInstanceUUID: d.voxletPackageDetails.voxletInstance.primaryUUID}});
								state.onboarding = 'ended';
							} else {
									
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}


		const loadBasicLineDetails = async () => {
		
			const apiUrl = mainStore.getAPIUrl();		
			const data = new URLSearchParams();
			data.set('voxletInstanceUUID', state.voxletInstanceUUID);	
			await fetch(apiUrl+'/voxletLines/loadBasicLineDetails', {
				body: data,
				method: 'post',				
				credentials: 'include',
				redirect: 'follow',		
			}).then((response) => {
				response.json().then( 
					d => {
						console.log(d)							
						if (d.outcome == 1) {	
							updateOnboarding(d.voxletPackageDetails.voxletInstance.onboarding);							
							state.assets.devices.deviceConfiguration.deviceRequests = d.voxletConfigurationHolder.configuration.deviceRequests;
							state.assets.devices.refreshConfiguration.onboarding = state.onboarding;
							if (d.voxletPackageDetails.devices) {state.assets.devices.deviceAssets = d.voxletPackageDetails.devices;}
							state.assets.serviceAccountContext.serviceAccountContextConfiguration.serviceAccountContextRequest = 	d.voxletConfigurationHolder.configuration.serviceAccountContextRequest;						
							state.assets.numbers.numberConfiguration.numberRequests = 	d.voxletConfigurationHolder.configuration.numberRequests;		
							if (d.voxletPackageDetails.allocatedNumbers) {state.assets.numbers.numberAssets = d.voxletPackageDetails.allocatedNumbers;}									
							if (state.assets.numbers.numberConfiguration.numberRequests && state.assets.numbers.numberConfiguration.numberRequests.length > 0) {
								state.assets.subscriberAddress.subscriberAddressConfiguration.subscriberAddress = state.assets.numbers.numberConfiguration.numberRequests[0].subscriberAddress;
						
							}				
							refreshAllComponents();											
							state.voxletPackageDetails = d.voxletPackageDetails;								
						}							
					}									
				)
				return response;
			}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);

			});		
		}

		const refreshConfiguration = (assetName) => {	

			switch (assetName){
				case 'serviceAccountContext':
					state.assets.serviceAccountContext.refreshConfiguration.version++;
					state.assets.serviceAccountContext.refreshConfiguration = {
						onboarding: state.assets.serviceAccountContext.refreshConfiguration.onboarding,
						version: state.assets.serviceAccountContext.refreshConfiguration.version						
					};
				case 'devices':
					state.assets.devices.refreshConfiguration.version++;
					state.assets.devices.refreshConfiguration = {
						onboarding: state.assets.devices.refreshConfiguration.onboarding,
						version: state.assets.devices.refreshConfiguration.version						
					};
				case 'numbers':
					state.assets.numbers.refreshConfiguration.version++;
					state.assets.numbers.refreshConfiguration = {
						onboarding: state.assets.numbers.refreshConfiguration.onboarding,
						version: state.assets.numbers.refreshConfiguration.version,						
					};
				case 'subscriberAddress':
					state.assets.subscriberAddress.refreshConfiguration.version++;
					state.assets.subscriberAddress.refreshConfiguration = {
						onboarding: state.assets.subscriberAddress.refreshConfiguration.onboarding,
						version: state.assets.subscriberAddress.refreshConfiguration.version				
					};
				break;
			}			
			
			
		}

		const refreshAllComponents = () => {
			refreshConfiguration('devices');	
			refreshConfiguration('numbers');	
			refreshConfiguration('serviceAccountContext');				
			refreshConfiguration('subscriberAddress');	
		}

		const updateOnboarding = (onboarding) => {
			state.onboarding = onboarding;
			state.assets.devices.refreshConfiguration.onboarding = onboarding;		
			state.assets.numbers.refreshConfiguration.onboarding =onboarding;		
			state.assets.serviceAccountContext.refreshConfiguration.onboarding = onboarding;		
			state.assets.subscriberAddress.refreshConfiguration.onboarding = onboarding;		
		}

		onUpdated(() => {	
			if (route.params.voxletInstanceUUID) {
				state.voxletInstanceUUID = route.params.voxletInstanceUUID;
				loadBasicLineDetails();
			} else {
				updateOnboarding('creation');
				refreshAllComponents();
			}
		});


		onMounted(() => {
			if (route.params.voxletInstanceUUID) {
				state.voxletInstanceUUID = route.params.voxletInstanceUUID;
				loadBasicLineDetails();
			} else {
				updateOnboarding('creation');
				refreshAllComponents();
			}
		});


</script>

<style scoped>

.opacityFix {
	opacity: 1;
}

</style>