<script setup>
    import { defineProps, ref, onMounted } from "vue";
    import { useRoute, useRouter } from "vue-router";
    import { useDisplay } from "vuetify";
    import {useMainStore} from '@/stores/MainStore.js';
    import DetailPageHeader from "@/components/DetailPageHeader.vue"
    import NumberComponent from "./diagram/NumberComponent.vue";
    import DeviceComponent from "./diagram/DeviceComponent.vue";
    import BillingComponent from "./diagram/BillingComponent.vue";
    import PstnComponent from "./diagram/PstnComponent.vue";

		const props = defineProps({
        phoneServiceName: {
            type: String,
        }
    });

    const route = useRoute();
    const router = useRouter();
    const mainStore = useMainStore();
    const { mdAndUp } = useDisplay();


    const editMode = (route.query.id != null);

    if (editMode && (mainStore.selectedLine == null || route.query.id != mainStore.selectedLine.id)) {
        router.replace({name: "lines"});
    }

    // references to the cdiagram components
    const scrollWrapper = ref(null);
    const visualLayoutContainer = ref(null);
    const numberComponent = ref(null);
    const deviceComponent = ref(null);
    const billingNumberComponent = ref(null);
    const billingDeviceComponent = ref(null);
    const pstnComponent = ref(null);
    const zIndexOrder = ref([numberComponent, deviceComponent, billingNumberComponent, billingDeviceComponent, pstnComponent]);
    const arrows = ref([]);

    // we collect the height and widths of all the diagram components but only do this once when the component
    // is mounted as we are not expecting the components to change dimensions 
    const componentHeightWidth = ref({});
    onMounted(() => {
        const comps = [numberComponent, deviceComponent, billingNumberComponent, billingDeviceComponent, pstnComponent];
        const compNames = ['numberComponent', 'deviceComponent', 'billingNumberComponent', 'billingDeviceComponent', 'pstnComponent'];
        let compStyle;
        for (let i = 0; i < comps.length; i++) {
            compStyle = getComputedStyle(comps[i].value.$el);
            componentHeightWidth.value[compNames[i]] = {
                height: parseInt(compStyle.height),
                width: parseInt(compStyle.width)
            }
        }

        layoutComponents();
    });

    const layoutComponents = () => {
        // there will be 2 layouts to handle a horizontal layout and one to handle a vertical layout.
        // Essentially the vertical layout is for mobile but if the mobile has been rotated then the
        // horizontal layout can be used
        const verticalSpacing = 50;
        const horizonalSpacing = 50;

        const numberComponentStyle = numberComponent.value.$el.style;
        const deviceComponentStyle = deviceComponent.value.$el.style;
        const pstnComponentStyle = pstnComponent.value.$el.style;
        const billingNumberComponentStyle = billingNumberComponent.value.$el.style;
        const billingDeviceComponentStyle = billingDeviceComponent.value.$el.style;

        let zIndex = 1;

        // get the height of the components

        const scrollWrapperCompStyles = getComputedStyle(scrollWrapper.value);
        if (parseInt(scrollWrapperCompStyles.height) > parseInt(scrollWrapperCompStyles.width)) {
            // vertical layout
            numberComponentStyle.top = "20px";
            numberComponentStyle.left = "20px";

            deviceComponentStyle.top = (parseInt(numberComponentStyle.top) + componentHeightWidth.value['numberComponent'].height + verticalSpacing) + "px";
            deviceComponentStyle.left = numberComponentStyle.left;

            pstnComponentStyle.top = (parseInt(deviceComponentStyle.top) + componentHeightWidth.value['deviceComponent'].height + verticalSpacing) + "px";
            pstnComponentStyle.left = deviceComponentStyle.left;

            billingNumberComponentStyle.top = numberComponentStyle.top;
            billingNumberComponentStyle.left = (parseInt(numberComponentStyle.left) + componentHeightWidth.value['numberComponent'].width + horizonalSpacing) + "px";

            billingDeviceComponentStyle.top = deviceComponentStyle.top;
            billingDeviceComponentStyle.left = (parseInt(deviceComponentStyle.left) + componentHeightWidth.value['deviceComponent'].width + horizonalSpacing) + "px";
            
        } else {
            // horizonal layout
            numberComponentStyle.top = "40px";
            numberComponentStyle.left = "40px";

            deviceComponentStyle.top = numberComponentStyle.top;
            deviceComponentStyle.left = (parseInt(numberComponentStyle.left) + componentHeightWidth.value['numberComponent'].width + horizonalSpacing) + "px";

            pstnComponentStyle.top = deviceComponentStyle.top;
            pstnComponentStyle.left = (parseInt(deviceComponentStyle.left) + componentHeightWidth.value['deviceComponent'].width + horizonalSpacing) + "px";

            billingNumberComponentStyle.top = (parseInt(numberComponentStyle.top) + componentHeightWidth.value['numberComponent'].height + verticalSpacing) + "px";
            billingNumberComponentStyle.left = numberComponentStyle.left;

            billingDeviceComponentStyle.top = (parseInt(deviceComponentStyle.top) + componentHeightWidth.value['deviceComponent'].height + verticalSpacing) + "px";
            billingDeviceComponentStyle.left = deviceComponentStyle.left;
        }

        numberComponentStyle.zIndex = zIndex++;
        deviceComponentStyle.zIndex = zIndex++;
        pstnComponentStyle.zIndex = zIndex++;
        billingNumberComponentStyle.zIndex = zIndex++;
        billingDeviceComponentStyle.zIndex = zIndex++;

        const arrowNumberToDevice = drawLineBetweenComponents(numberComponent.value, deviceComponent.value, "solid");
        arrowNumberToDevice.style.zIndex = numberComponentStyle.zIndex;
        const arrowNumberToNumberBilling = drawLineBetweenComponents(numberComponent.value, billingNumberComponent.value, "solid");
        arrowNumberToNumberBilling.style.zIndex = numberComponentStyle.zIndex;

        const arrowDeviceToPstn = drawLineBetweenComponents(deviceComponent.value, pstnComponent.value, "solid");
        arrowDeviceToPstn.style.zIndex = deviceComponentStyle.zIndex;
        const arrowDeviceToDeviceBilling = drawLineBetweenComponents(deviceComponent.value, billingDeviceComponent.value, "solid");
        arrowDeviceToDeviceBilling.style.zIndex = billingDeviceComponentStyle.zIndex;
    }

    /**
     * This will look at the element supplied and then return the anchor points on that element by specifying
     * positions along the four sides. It will essentially divide up each side with equally space anchor points
     * and the number of anchor points will depend on the length of that side. There will be no anchor points
     * at the corners of the element and parrallel sides will have the same number of anchor points. Anchor points
     * start in the middle of each line and extend out.
     * @param domElement    this the element that is to have the anchor points calculated for
     * @param computedStyle the computed styles for domElement, if not supplied then this will be computed
     */
    const computeAnchorPoints = (domElement, computedStyle) => {
        const maxAnchorSpacing = 30;
        const minimumBufferToEnd = 20;

        const compStyles = (computedStyle) ? computedStyle : getComputedStyle(domElement);
        const width = parseInt(compStyles.width) + parseInt(compStyles.paddingLeft) + parseInt(compStyles.paddingRight);
        const height = parseInt(compStyles.height) + parseInt(compStyles.paddingTop) + parseInt(compStyles.paddingBottom);


        // start with top and bottom
        let topBottomAnchorPoints = [(width / 2)];
        while ((topBottomAnchorPoints[topBottomAnchorPoints.length - 1] + maxAnchorSpacing) < (width - minimumBufferToEnd)) {
            topBottomAnchorPoints.push(topBottomAnchorPoints[topBottomAnchorPoints.length - 1] + maxAnchorSpacing);
            topBottomAnchorPoints.unshift(topBottomAnchorPoints[0] - maxAnchorSpacing);
        }

        // now do left and right
        let leftRightAnchorPoints = [(height / 2)];
        while ((leftRightAnchorPoints[leftRightAnchorPoints.length - 1] + maxAnchorSpacing) < (height - minimumBufferToEnd)) {
            leftRightAnchorPoints.push(leftRightAnchorPoints[leftRightAnchorPoints.length - 1] + maxAnchorSpacing);
            leftRightAnchorPoints.unshift(leftRightAnchorPoints[0] - maxAnchorSpacing);
        }

        return {
            topBottomAnchorPoints,
            leftRightAnchorPoints
        }
    }

    const drawLineBetweenComponents = (component1, component2, lineStyle) => {
        const tolerance = 20;
        const arrowWidth = 20;

        const computedStyle1 = getComputedStyle(component1.$el);
        const computedStyle2 = getComputedStyle(component2.$el);

        const comp1Top = parseInt(computedStyle1.top);
        const comp1Left = parseInt(computedStyle1.left);
        const comp1Width = parseInt(computedStyle1.width) + 
                            ((computedStyle1.boxSizing == "border-box") ? 0 : (parseInt(computedStyle1.paddingLeft) + parseInt(computedStyle1.paddingRight) + 
                                                                                parseInt(computedStyle1.borderLeftWidth) + parseInt(computedStyle1.borderRightWidth)));
        const comp1Height = parseInt(computedStyle1.height) + 
                            ((computedStyle1.boxSizing == "border-box") ? 0 : (parseInt(computedStyle1.paddingTop) + parseInt(computedStyle1.paddingBottom) + 
                                                                                parseInt(computedStyle1.borderTopWidth) + parseInt(computedStyle1.borderBottomWidth)));

        const comp2Top = parseInt(computedStyle2.top);
        const comp2Left = parseInt(computedStyle2.left);
        const comp2Width = parseInt(computedStyle2.width) + 
                            ((computedStyle2.boxSizing == "border-box") ? 0 : (parseInt(computedStyle2.paddingLeft) + parseInt(computedStyle2.paddingRight) + 
                                                                                parseInt(computedStyle2.borderLeftWidth) + parseInt(computedStyle2.borderRightWidth)));
        const comp2Height = parseInt(computedStyle2.height) + 
                            ((computedStyle2.boxSizing == "border-box") ? 0 : (parseInt(computedStyle2.paddingTop) + parseInt(computedStyle2.paddingBottom) + 
                                                                                parseInt(computedStyle2.borderTopWidth) + parseInt(computedStyle2.borderBottomWidth)));

        const compAnchorPoints1 = computeAnchorPoints(component1, computedStyle1);
        const compAnchorPoints2 = computeAnchorPoints(component2, computedStyle2);

        console.log("anchor1: ", compAnchorPoints1, "anchor2:", compAnchorPoints2);

        // the position comparisions are all comparing component1's postition relative to component2's position

        // start looking to see which element is above the other, above comes in 3 variants:
        // - fully above and greater than the spacing tolerance
        // - fully above but less that the spacing tolerance (e.g. might only be 1 or 2 px above)
        // - above but over lapping
        let verticalPos;
        if (comp1Top < comp2Top) {
            // we are above
            if ((comp1Top + comp1Height + tolerance) < comp2Top) {
                verticalPos = "fullyAbove";
            } else if ((comp1Top + comp1Height) < comp2Top) {
                verticalPos = "aboveOutOfTolerance";
            
            } else {
                verticalPos = "overlapping";
            }            
        } else {
            // we are below
            if (comp1Top > (comp2Top + comp2Height + tolerance)) {
                verticalPos = "fullyBelow";
            } else if (comp1Top > (comp2Top + comp2Height)) {
                verticalPos = "belowOutOfTolerance";
            } else {
                verticalPos = "overlapping";
            }
        }

        // now perform the equivalent for horizontal position
        let horizontalPos;
        if (comp1Left < comp2Left) {
            // we are to the left
            if ((comp1Left + comp1Width + tolerance) < comp2Left) {
                horizontalPos = "fullyLeft";
            } else if ((comp1Left + comp1Width) < comp2Left) {
                horizontalPos = "leftOutOfTolerance";
            
            } else {
                horizontalPos = "overlapping";
            }            
        } else {
            // we are to the right
            if (comp1Left > (comp2Left + comp2Width + tolerance)) {
                horizontalPos = "fullyRight";
            } else if (comp1Left > (comp2Left + comp2Width)) {
                horizontalPos = "rightOutOfTolerance";
            } else {
                horizontalPos = "overlapping";
            }
        }

        console.log("verticalPos", verticalPos, "horizontalPos", horizontalPos);

        // this section will calculate where a line connects to a component in terms of which edge and how far along that edge
        let comp1AnchorPosition;
        let comp2AnchorPosition;
        if (horizontalPos == "fullyLeft" || horizontalPos == "fullyRight") {
            comp1AnchorPosition = {
                side: (comp1Left <= comp2Left) ? "right" : "left",
                anchorPos: compAnchorPoints1.leftRightAnchorPoints[parseInt(compAnchorPoints1.leftRightAnchorPoints.length / 2)]
            }

            comp2AnchorPosition = {
                side: (horizontalPos == "fullyLeft") ? "left" : "right",
                anchorPos: compAnchorPoints2.leftRightAnchorPoints[parseInt(compAnchorPoints2.leftRightAnchorPoints.length / 2)]
            }

        } else if (verticalPos == "fullyAbove") {
            // we can use the middle anchor point on one of the sides
            comp1AnchorPosition = {
                side: "bottom",
                anchorPos: compAnchorPoints1.topBottomAnchorPoints[parseInt(compAnchorPoints1.topBottomAnchorPoints.length / 2)]
            }

            comp2AnchorPosition = {
                side: "top",
                anchorPos: compAnchorPoints2.topBottomAnchorPoints[parseInt(compAnchorPoints2.topBottomAnchorPoints.length / 2)]
            }

        } else if (verticalPos == "fullyBelow") {
            // it is not clear on either side but the bottom is clear so we will use that
            comp1AnchorPosition = {
                side: "top",
                anchorPos: compAnchorPoints1.topBottomAnchorPoints[parseInt(compAnchorPoints1.topBottomAnchorPoints.length / 2)]
            }

            comp2AnchorPosition = {
                side: "bottom",
                anchorPos: compAnchorPoints2.topBottomAnchorPoints[parseInt(compAnchorPoints2.topBottomAnchorPoints.length / 2)]
            }

        } else if (verticalPos == "aboveOutOfTolerance" || verticalPos == "belowOutOfTolerance" || verticalPos == "overlapping") {
            // even though the there is a vertical issue it might be that one of the anchor
            // points along the top or bottom might work. Start in the middle and the work to the 
            // edges moving away from the other component
            if (comp1Left <= comp2Left) {
                // comp 1 is to the left, so move to the left on the anchor points
                for (let i = parseInt(compAnchorPoints1.topBottomAnchorPoints.length / 2); i >= 0; i--) {
                    if ((comp1Left + compAnchorPoints1.topBottomAnchorPoints[i] + tolerance) <= comp2Left) {
                        comp1AnchorPosition = {
                            side: (verticalPos == "aboveOutOfTolerance") ? "bottom" : "top",
                            anchorPos: compAnchorPoints1.topBottomAnchorPoints[i]
                        };
                        break;
                    }
                }

                if (comp1AnchorPosition == null) {
                    // since comp1 is to the left and neither the top or bottom are available we must go out the left
                    comp1AnchorPosition = {
                        side: "left",
                        anchorPos: compAnchorPoints1.leftRightAnchorPoints[parseInt(compAnchorPoints1.leftRightAnchorPoints.length / 2)]
                    };

                    // this means that we need to go in either the top or the bottom of comp 2
                    comp2AnchorPosition = {
                        side: (comp1Top <= comp2Top) ? "bottom" : "top",
                        anchorPos: compAnchorPoints2.leftRightAnchorPoints[parseInt(compAnchorPoints2.leftRightAnchorPoints.length / 2)]
                    }
                } else {
                    // we are able to go from somewhere on the top or bottom of comp1 into the left of comp2. We will pick
                    // the highest anchor point by preference when comp2 is below and the lowest when it is below
                    if (verticalPos == "aboveOutOfTolerance") {
                        for (let i = 0; i < compAnchorPoints2.leftRightAnchorPoints.length; i++) {
                            if ((comp1Top + comp1Height + tolerance) <= (comp2Top + compAnchorPoints2.leftRightAnchorPoints[i])) {
                                comp2AnchorPosition = {
                                    side: "left",
                                    anchorPos: compAnchorPoints2.leftRightAnchorPoints[i]
                                }
                                break;
                            }
                        }
                    } else if (verticalPos == "belowOutOfTolerance") {
                        for (let i = (compAnchorPoints2.leftRightAnchorPoints.length - 1); i >= 0; i--) {
                            if (comp1Top > (comp2Top + compAnchorPoints2.leftRightAnchorPoints[i]) + tolerance) {
                                 comp2AnchorPosition = {
                                    side: "left",
                                    anchorPos: compAnchorPoints2.leftRightAnchorPoints[i]
                                }
                                break;
                            }
                        }
                    } else {
                        // we are going out the top or bottom of comp1 but we can not get to the left of comp2 so we will need
                        // to go to the top or bottom of comp2. We will actually choose the same edge for comp2 as compo1 is
                        if (comp1Left <= comp2Left) {
                            comp2AnchorPosition = {
                                side: comp1AnchorPosition.side,
                                anchorPos: compAnchorPoints2.topBottomAnchorPoints[compAnchorPoints2.topBottomAnchorPoints.length - 1]
                            }
                        } else {
                            comp2AnchorPosition = {
                                side: comp1AnchorPosition.side,
                                anchorPos: compAnchorPoints2.topBottomAnchorPoints[0]
                            }
                        }
                    }
                }
            } else {
                // comp 1 is to the right, so move to the right on the anchor points
                for (let i = parseInt(compAnchorPoints1.topBottomAnchorPoints.length / 2); i < compAnchorPoints1.topBottomAnchorPoints.length; i++) {
                    if ((comp1Left + compAnchorPoints1.topBottomAnchorPoints[i]) >= (comp2Left + comp2Width + tolerance)) {
                        comp1AnchorPosition = {
                            side: (verticalPos == "aboveOutOfTolerance") ? "bottom" : "top",
                            anchorPos: compAnchorPoints1.topBottomAnchorPoints[i]
                        };
                        break;
                    }
                }

                if (comp1AnchorPosition == null) {
                    // since comp1 is to the right and neither the top or bottom are available we must go out the right
                    comp1AnchorPosition = {
                        side: "right",
                        anchorPos: compAnchorPoints1.leftRightAnchorPoints[parseInt(compAnchorPoints1.leftRightAnchorPoints.length / 2)]
                    };

                    // this means that we need to go in either the top or the bottom of comp 2
                    comp2AnchorPosition = {
                        side: (comp1Top <= comp2Top) ? "bottom" : "top",
                        anchorPos: compAnchorPoints2.leftRightAnchorPoints[parseInt(compAnchorPoints2.leftRightAnchorPoints.length / 2)]
                    }
                } else {
                    // we are able to go from somewhere on the top or bottom of comp1 into the left of comp2. We will pick
                    // the highest anchor point by preference when comp2 is below and the lowest when it is below
                    if (verticalPos == "aboveOutOfTolerance") {
                        for (let i = 0; i < compAnchorPoints2.leftRightAnchorPoints.length; i++) {
                            if ((comp1Top + comp1Height + tolerance) <= (comp2Top + compAnchorPoints2.leftRightAnchorPoints[i])) {
                                comp2AnchorPosition = {
                                    side: "right",
                                    anchorPos: compAnchorPoints2.leftRightAnchorPoints[i]
                                }
                                break;
                            }
                        }
                    } else if (verticalPos == "belowOutOfTolerance") {
                         for (let i = (compAnchorPoints2.leftRightAnchorPoints.length - 1); i >= 0; i--) {
                             if (comp1Top > (comp2Top + compAnchorPoints2.leftRightAnchorPoints[i] + tolerance)) {
                                 comp2AnchorPosition = {
                                    side: "right",
                                    anchorPos: compAnchorPoints2.leftRightAnchorPoints[i]
                                }
                                break;
                             }
                         }
                    } else {
                        // we are going out the top or bottom of comp1 but we can not get to the right of comp2 so we will need
                        // to go to the top or bottom of comp2. We will actually choose the same edge for comp2 as compo1 is
                        if (comp1Left <= comp2Left) {
                            comp2AnchorPosition = {
                                side: comp1AnchorPosition.side,
                                anchorPos: compAnchorPoints2.topBottomAnchorPoints[compAnchorPoints2.topBottomAnchorPoints.length - 1]
                            }
                        } else {
                            comp2AnchorPosition = {
                                side: comp1AnchorPosition.side,
                                anchorPos: compAnchorPoints2.topBottomAnchorPoints[0]
                            }
                        }
                    }
                }
            }
        }

        
        // set the box dimensions including any additional padding to handle the arrow sizes and room for an arrow
        // line to be able to wrap round from one side of a component to get to another that is higher or lower
        const wrapBuffer = 20;
        let pos1Top = comp1Top + ((comp1AnchorPosition.side == "left" || comp1AnchorPosition.side == "right") ? comp1AnchorPosition.anchorPos : 0);
        let pos1Left = comp1Left + ((comp1AnchorPosition.side == "top" || comp1AnchorPosition.side == "bottom") ? comp1AnchorPosition.anchorPos : 0);
        let pos2Top = comp2Top + ((comp2AnchorPosition.side == "left" || comp2AnchorPosition.side == "right") ? comp2AnchorPosition.anchorPos : 0);
        let pos2Left = comp2Left + ((comp2AnchorPosition.side == "top" || comp2AnchorPosition.side == "bottom") ? comp2AnchorPosition.anchorPos : 0);

        if (comp1AnchorPosition.side == "right") { pos1Left += comp1Width; }
        if (comp2AnchorPosition.side == "right") { pos2Left += comp2Width; }
        if (comp1AnchorPosition.side == "bottom") { pos1Top += comp1Height; }
        if (comp2AnchorPosition.side == "bottom") { pos2Top += comp2Height; }

        if (pos1Top <= pos2Top) {
            if (comp1AnchorPosition.side == "left" || comp1AnchorPosition.side == "right") { pos1Top += -1 * (arrowWidth / 2); }
            if (comp2AnchorPosition.side == "left" || comp2AnchorPosition.side == "right") { pos2Top += (arrowWidth / 2); }
        } else {
            if (comp1AnchorPosition.side == "left" || comp1AnchorPosition.side == "right") { pos1Top += (arrowWidth / 2); }
            if (comp2AnchorPosition.side == "left" || comp2AnchorPosition.side == "right") { pos2Top += -1 * (arrowWidth / 2); }
        }

        if (pos1Left <= pos2Left) {
            if (comp1AnchorPosition.side == "top" || comp1AnchorPosition.side == "bottom") { pos1Left += -1 * (arrowWidth / 2); }
            if (comp2AnchorPosition.side == "top" || comp2AnchorPosition.side == "bottom") { pos2Left += (arrowWidth / 2); }
        } else {
            if (comp1AnchorPosition.side == "top" || comp1AnchorPosition.side == "bottom") { pos1Left += (arrowWidth / 2); }
            if (comp2AnchorPosition.side == "top" || comp2AnchorPosition.side == "bottom") { pos2Left += -1 * (arrowWidth / 2); }
        }

        if (comp1AnchorPosition.side == "left" && comp2AnchorPosition.side == "left") {
            if (pos1Left <= pos2Left) { pos1Left -= wrapBuffer; } else { pos2Left -= wrapBuffer; }
        } else if (comp1AnchorPosition.side == "right" && comp2AnchorPosition.side == "right") {
            if (pos1Left <= pos2Left) { pos2Left += wrapBuffer; } else { pos1Left += wrapBuffer; }
        } else if (comp1AnchorPosition.side == "top" && comp2AnchorPosition.side == "top") {
            if (pos1Top <= pos2Top) { pos1Top -= wrapBuffer; } else { pos2Top -= wrapBuffer; }
        } else if (comp1AnchorPosition.side == "bottom" && comp2AnchorPosition.side == "bottom") {
            if (pos1Top <= pos2Top) { pos2Top += wrapBuffer; } else { pos1Top += wrapBuffer; }
        }

        // handles the wrapping around scenario
        if ((comp1AnchorPosition.side == "right" || comp1AnchorPosition.side == "left") && (comp2AnchorPosition.side == "top" || comp2AnchorPosition.side =="bottom")) {
            pos1Left += (comp1AnchorPosition.side == "right") ? arrowWidth : (-1 * arrowWidth);
            pos2Top += (comp2AnchorPosition.side == "top") ? (-1 * arrowWidth) : arrowWidth;
        }

        console.log("comp1AnchorPosition", comp1AnchorPosition, "comp2AnchorPosition", comp2AnchorPosition);

        var boxTop, boxLeft, boxWidth, boxHeight;

        if (pos1Left < pos2Left) {
            boxLeft = pos1Left;
            boxWidth = pos2Left - pos1Left;
        }  else {
            boxLeft = pos2Left;
            boxWidth = pos1Left - pos2Left;
        }

        if (pos1Top < pos2Top) {
            boxTop = pos1Top;
            boxHeight = pos2Top - pos1Top;
        } else {
            boxTop = pos2Top;
            boxHeight = pos1Top - pos2Top;
        }

        // draw the box
        let arrow = arrows.value.find((arrow) => {
            return (arrow.fromComponent.name == component1.name && arrow.toComponent.name == component2.name);
        });
        let arrowDivWrapper;
        if (arrow == null) {
            arrowDivWrapper = document.createElement("div");
            arrowDivWrapper.style.backgroundColor = "lightblue";
            arrowDivWrapper.style.position = "absolute";
            arrowDivWrapper.style.backgroundPosition = "center center";
            arrowDivWrapper.style.size = "100% 100%, auto";
            arrowDivWrapper.style.repeat = "no-repeat"
        } else {
            arrowDivWrapper = arrow.arrowDiv;
        }

        arrowDivWrapper.style.left = boxLeft + "px";
        arrowDivWrapper.style.width = boxWidth + "px";
        arrowDivWrapper.style.top = boxTop + "px";
        arrowDivWrapper.style.height = boxHeight + "px";
        
        console.log("left:", arrowDivWrapper.style.left, "top:", arrowDivWrapper.style.top, "height:", arrowDivWrapper.style.height, "width:", arrowDivWrapper.style.width);

        if (arrowDivWrapper.parentElement == null) {
            visualLayoutContainer.value.appendChild(arrowDivWrapper);
        }

        const halfArrowWidth = arrowWidth / 2;

        // insert the SVG arrow
        let svg = "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' style='stroke: currentColor; stroke-width: 2px;' version='1.1' preserveAspectRatio='none' viewBox='0 0 " + parseInt(arrowDivWrapper.style.width) + " " + parseInt(arrowDivWrapper.style.height) + "'>";

        // work out the start for the arrow within the bounding box
        let arrowStartLeft, arrowStartTop;
        if (comp1AnchorPosition.side == "left") {
            // start will be on the right of the bounding box but not so it goes past the left edge of the component then
            // just adjust the top or the bottom to allow for the arrow height
            arrowStartLeft = ((boxLeft + boxWidth) > comp1Left) ? (comp1Left - boxLeft) : boxWidth;
            arrowStartTop = (pos1Top <= pos2Top) ? halfArrowWidth : boxHeight - halfArrowWidth;
        } else if (comp1AnchorPosition.side == "right") {
            // start will be on the left of the bounding box but not so it goes past the right edge of the component then
            // just adjust the top or the bottom to allow for the arrow height
            arrowStartLeft = (boxLeft < (comp1Left + comp1Width)) ? (comp1Left + comp1Width - boxLeft) : 0;
            arrowStartTop = (pos1Top <= pos2Top) ? halfArrowWidth : boxHeight - halfArrowWidth;
        } else if (comp1AnchorPosition.side == "top") {
            // start will be on the bottom of the bounding box but not so it goes past the top edge of the component then
            // just adjust the left or the right to allow for the arrow width
            arrowStartTop = ((boxTop + boxHeight) > comp1Top) ? (comp1Top - boxTop) : boxHeight;
            arrowStartLeft = (pos1Left <= pos2Left) ? halfArrowWidth : boxWidth - halfArrowWidth;
        } else {
            // start will be on the top of the bounding box but not so it goes past the bottom edge of the component then
            // just adjust the left or the right to allow for the arrow width
            arrowStartTop = (boxTop < comp1Top) ? (comp1Top - boxTop) : 0;
            arrowStartLeft = (pos1Left <= pos2Left) ? halfArrowWidth : boxWidth - halfArrowWidth;
        }
        
        // work out the end point for the arrow in the bounding box, essentially where the arrow head will go
        let arrowEndTop, arrowEndLeft;
        if (comp2AnchorPosition.side == "left") {
            // start will be on the right of the bounding box but not so it goes past the left edge of the component then
            // just adjust the top or the bottom to allow for the arrow height
            arrowEndLeft = ((boxLeft + boxWidth) > comp2Left) ? (comp2Left - boxLeft) : boxWidth;
            arrowEndTop = (pos1Top > pos2Top) ? halfArrowWidth : boxHeight - halfArrowWidth;
        } else if (comp2AnchorPosition.side == "right") {
            // start will be on the left of the bounding box but not so it goes past the right edge of the component then
            // just adjust the top or the bottom to allow for the arrow height
            arrowEndLeft = (boxLeft < (comp2Left + comp2Width)) ? (comp2Left + comp2Width - boxLeft) : 0;
            arrowEndTop = (pos1Top > pos2Top) ? halfArrowWidth : boxHeight - halfArrowWidth;
        } else if (comp2AnchorPosition.side == "top") {
            // start will be on the bottom of the bounding box but not so it goes past the top edge of the component then
            // just adjust the left or the right to allow for the arrow width
            arrowEndTop = ((boxTop + boxHeight) > comp2Top) ? (comp2Top - boxTop) : boxHeight;
            arrowEndLeft = (pos1Left > pos2Left) ? halfArrowWidth : boxWidth - halfArrowWidth;
        } else {
            // start will be on the top of the bounding box but not so it goes past the bottom edge of the component then
            // just adjust the left or the right to allow for the arrow width
            arrowEndTop = (boxTop < (comp2Top + comp2Height)) ? (comp2Top + comp2Height - boxTop) : 0;
            arrowEndLeft = (pos1Left > pos2Left) ? halfArrowWidth : boxWidth - halfArrowWidth;
        }
        
        // start drawing the line, the key to starting is always to head away from the side the line starts on
        let currentTop = arrowStartTop;
        let currentLeft = arrowStartLeft;
        let nextTop, nextLeft;
        let lines = [];
        let arrowHeadDirection;
        if ((comp1AnchorPosition.side == "left" && comp2AnchorPosition.side == "left") ||
                (comp1AnchorPosition.side == "right" && comp2AnchorPosition.side == "right")) {
            // looping from same side to same side, should be done in 3 lines. 2 horizontal, 1 vertical
            // start with horizonatal
            nextLeft = (comp1AnchorPosition.side == "left") ? (boxWidth - halfArrowWidth) : nextLeft = halfArrowWidth;
            nextTop = currentTop;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;
            currentTop = nextTop;

            // do the one vertical, doesn't matter about direction we know where we are going
            nextTop = arrowEndTop;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentTop = nextTop;

            // do the final horizontal, again we know where we are going to end up
            nextLeft = arrowEndLeft;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;

            arrowHeadDirection = (comp2AnchorPosition.side == "left") ? "right" : "left";

        } else if ((comp1AnchorPosition.side == "left" && comp2AnchorPosition.side == "right") ||
                (comp1AnchorPosition.side == "right" && comp2AnchorPosition.side == "left")) {
            // going from one side to the opposition side of the other, should be done in max 3 lines. 2 horizontal, 1 vertical. can be 1 horizontal
            // start with horizonatal, since going to the midpoint doesn't matter which direction
            nextLeft = boxWidth / 2;
            nextTop = currentTop;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;
            currentTop = nextTop;

            // do the one vertical, doesn't matter about direction we know where we are going
            nextTop = arrowEndTop;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentTop = nextTop;

            // do the final horizontal, again we know where we are going to end up
            nextLeft = arrowEndLeft;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;

            arrowHeadDirection = (comp2AnchorPosition.side == "left") ? "right" : "left";

        } else if ((comp1AnchorPosition.side == "top" && comp2AnchorPosition.side == "bottom") ||
                (comp1AnchorPosition.side == "bottom" && comp2AnchorPosition.side == "top")) {
            // these are one above each other so should be max 3 lines. 2 vertical and 1 horizontal. can be 1 vertical
            // start with vertical, since going to the midpoint doesn't matter which direction
            nextLeft = currentLeft;
            nextTop = boxHeight / 2;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;
            currentTop = nextTop;

            // do the one horizontal, doesn't matter about direction we know where we are going
            nextLeft = arrowEndLeft;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;

            // do the final vertical, again we know where we are going to end up
            nextTop = arrowEndTop;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentTop = nextTop;

            arrowHeadDirection = (comp2AnchorPosition.side == "top") ? "down" : "up";

        } else if (((comp1AnchorPosition.side == "left" || comp1AnchorPosition.side == "right") && 
                        (comp2AnchorPosition.side == "top" || comp2AnchorPosition.side == "bottom"))) {
            // occurs in overlapping where arrow comes out of a side of comp1 and goes into the top or bottom of comp2
            // Done in 4 lines, 2 horizontal, 2 vertical
            // start with short horizontal
            nextLeft = (comp1AnchorPosition.side == "left") ? (currentLeft - halfArrowWidth) : (currentLeft + halfArrowWidth);
            nextTop = currentTop;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;
            currentTop = nextTop;

            // main vertical
            nextTop = (comp2AnchorPosition.side == "top") ? halfArrowWidth : (boxHeight - halfArrowWidth);
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentTop = nextTop;

            // second horizonal
            nextLeft = arrowEndLeft;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;

            // final vertical
            nextTop = arrowEndTop;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentTop = nextTop;

            arrowHeadDirection = (comp2AnchorPosition.side == "top") ? "down" : "up";
        } else if ((comp1AnchorPosition.side == "top" && (comp2AnchorPosition.side == "left" || comp2AnchorPosition.side == "right")) ||
                (comp1AnchorPosition.side == "bottom" && (comp2AnchorPosition.side == "left" || comp2AnchorPosition.side == "right"))) {
            // this will be a 2 line with 1 vertical and 1 horizontal
            // start with the vertical
            nextTop = (comp1AnchorPosition.side == "top") ? halfArrowWidth : (boxHeight - halfArrowWidth);
            nextLeft = currentLeft;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;
            currentTop = nextTop;

            // now the horizontal
            nextLeft = (comp2AnchorPosition.side == "left") ? boxWidth : 0;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;
        
            arrowHeadDirection = (comp2AnchorPosition.side == "left") ? "right" : "left";

        } else if (((comp1AnchorPosition.side == "top" && comp2AnchorPosition.side == "top") || 
                        (comp1AnchorPosition.side == "bottom" || comp2AnchorPosition.side == "bottom"))) {
            // occurs in an overlapping event, will be done in 3 lines, 2 vertical and 1 horizontal
            nextTop = (comp1AnchorPosition.side == "top") ? halfArrowWidth : (boxHeight - halfArrowWidth);
            nextLeft = currentLeft;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;
            currentTop = nextTop;

            // horizontal, doesn't matter about direction we know where we are going
            nextLeft = arrowEndLeft;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentLeft = nextLeft;

            // vertical, doesn't matter about direction we know where we are going
            nextTop = arrowEndTop;
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + nextLeft + "' y2='" + nextTop + "'/>");
            currentTop = nextTop;

            arrowHeadDirection = (comp2AnchorPosition.side == "top") ? "down" : "up";
        }

        // draw arrow head
        const arrowHeadOffset = halfArrowWidth - 2;
        if (arrowHeadDirection == "left") {
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + (currentLeft + arrowHeadOffset) + "' y2='" + (currentTop - arrowHeadOffset) + "'/>");
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + (currentLeft + arrowHeadOffset) + "' y2='" + (currentTop + arrowHeadOffset) + "'/>");
        } else if (arrowHeadDirection == "right") {
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + (currentLeft - arrowHeadOffset) + "' y2='" + (currentTop - arrowHeadOffset) + "'/>");
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + (currentLeft - arrowHeadOffset) + "' y2='" + (currentTop + arrowHeadOffset) + "'/>");
        } else if (arrowHeadDirection == "up") {
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + (currentLeft - arrowHeadOffset) + "' y2='" + (currentTop + arrowHeadOffset) + "'/>");
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + (currentLeft + arrowHeadOffset) + "' y2='" + (currentTop + arrowHeadOffset) + "'/>");
        } else {
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + (currentLeft - arrowHeadOffset) + "' y2='" + (currentTop - arrowHeadOffset) + "'/>");
            lines.push("<line x1='" + currentLeft + "' y1='" + currentTop + "' x2='" + (currentLeft + arrowHeadOffset) + "' y2='" + (currentTop - arrowHeadOffset) + "'/>");
        }
        

        //console.log("lines", lines);

        // the line below will draw a direct line between components, rather than the stepped on calculated above
        // svg += "<line x1='" + arrowStartLeft + "' y1='" + arrowStartTop + "' x2='" + arrowEndLeft + "' y2='" + arrowEndTop + "'/>";
        svg += lines.join();
        svg += "</svg>\")";
        console.log("SVG", svg);
        arrowDivWrapper.style.background = svg;

        // store the line and what it references
        if (arrow == null) {
            console.log("adding arrow to arrow list");
            arrows.value.push({
                fromComponent: component1,
                toComponent: component2,
                lineStyle,
                arrowDiv: arrowDivWrapper
            });
        }

        return arrowDivWrapper;
    }

    const bringToFront = (comp) => {
        const zIndexArray = zIndexOrder.value;
        let compIndex = -1;
        for (let i = 0; i < zIndexArray.length; i++) {
            if (compIndex == -1) {
                if (zIndexArray[i].value.$el == comp) {
                    compIndex = i;
                    comp.style.zIndex = zIndexArray.length;
                }
            } else {
                zIndexArray[i].value.$el.style.zIndex = zIndexArray[i].value.$el.style.zIndex - 1;
            }
        }

        if (compIndex != -1) {
            zIndexArray.push(zIndexArray.splice(compIndex, 1)[0]);
        }
    }

    const dragEnd = (evt, componentName) => {
        bringToFront(evt.target);

        arrows.value.every((arrow) => {
            if (arrow.toComponent.name == componentName || arrow.fromComponent.name == componentName) {
                drawLineBetweenComponents(arrow.fromComponent, arrow.toComponent, arrow.lineStyle);
            }
            return true;
        });
    }

</script>

<template>
    <DetailPageHeader
        :title="(editMode) ? 'Edit line' : 'New line'"
        description="a little description"
        backLinkText="back to lines"
    >
        <div class="scrollWrapper" ref="scrollWrapper">
            <div class="visualLayoutContainer" ref="visualLayoutContainer">
                <NumberComponent name="number" title="number" ref="numberComponent" v-drag @v-drag-end="(evt) => dragEnd(evt, numberComponent.name)"></NumberComponent>
                <DeviceComponent name="device" title="device" ref="deviceComponent" v-drag @v-drag-end="(evt) => dragEnd(evt, deviceComponent.name)"></DeviceComponent>
                <BillingComponent name="billingNumber" title="number billing" ref="billingNumberComponent" v-drag @v-drag-end="(evt) => dragEnd(evt, billingNumberComponent.name)"></BillingComponent>
                <BillingComponent name="billingDevice" title="device billing" ref="billingDeviceComponent" v-drag @v-drag-end="(evt) => dragEnd(evt, billingDeviceComponent.name)"></BillingComponent>
                <PstnComponent name="pstn" title="pstn" ref="pstnComponent" v-drag @v-drag-end="(evt) => dragEnd(evt, pstnComponent.name)"></PstnComponent>
            </div>
        </div>
        <v-btn @click="layoutComponents">Layout</v-btn>
    </DetailPageHeader>

</template>

<style scoped>

    .scrollWrapper {
        width: 100%;
        min-height: 400px;
        height: 50%;
        overflow: auto;
        background-color: #ffeded;
    }

    .visualLayoutContainer {
        position: relative;
        min-width: 600px;
        min-height: 400px;
    }

    .layoutBlock {
        position: absolute;
        height: 58px;
        width: 198px;
        border: 1px solid black;
        background-color: white;
    }

</style>