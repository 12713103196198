<template>
	<v-container fluid class="px-6 py-6 bg-white" v-if="state.contentPage" >		
		<v-row>
			<v-col class="d-flex mb-5">
				<p class="theme-font text-h6">{{ contentPage.pageInfo.contentReference }}</p>
				<v-spacer align="right"><p class="theme-font text-h6 mr-5">{{ contentPage.pageInfo.appName }}</p></v-spacer>
			</v-col>
		</v-row>

			<div v-if="state.contentPage.content && state.contentPage.content.title">					
					<ContentBlockDisplay :pageContext="pageContext" :contentConfiguration="state.contentConfiguration.title" contentFieldName="title" :contentFragments="state.contentPage.content.title" @goToContent="goToContent">
					</ContentBlockDisplay>					
			</div>								
				
			<div v-if="state.contentPage.content && state.contentPage.content.paragraph">					
					<ContentBlockDisplay :pageContext="pageContext" :contentConfiguration="state.contentConfiguration.paragraph" contentFieldName="paragraph" :contentFragments="state.contentPage.content.paragraph" @goToContent="goToContent">
					</ContentBlockDisplay>					
			</div>								
			<div v-if="state.contentPage.content && state.contentPage.content.relatedPages">	
				<p class="text-h6">Related</p>
					<ContentBlockDisplay :pageContext="pageContext" :contentConfiguration="state.contentConfiguration.relatedPages" contentFieldName="relatedPages" :contentFragments="state.contentPage.content.relatedPages" @goToContent="goToContent">
					</ContentBlockDisplay>					
								
			</div>								

		<v-row>	
			<v-col class="d-flex mt-5">
					<p class="font-italic theme-font-other text-caption">Updated:</p> <p class="ml-2 text-caption">{{ contentPage.pageInfo.updateDate }}</p>				
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import { inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ContentBlockDisplay from '../types/ContentBlockDisplay.vue';

const emits = defineEmits(['goToContent']);

const mainStore = useMainStore();

let pageContext = {
		stepCount: 0,		
	};

const initialisePageContext = () => {
	pageContext = {
		stepCount: 0,
	};
}

const state = reactive({	
	contentPage: undefined,
	contentConfiguration: {
		paragraph: {
			multiplicity: "multiple",
			contentTypes: [
				{value: 'textBlock', title:'Paragraph'},		
				{value: 'titleBlock', title:'Title'},
				{value: 'stepBlock', title:'Step'},	
				{value: 'codeBlock', title:'Code Block'},		
				{value: 'imageBlock', title:'Image Block'},
				{value: 'textImageBlock', title:'Text Image'},		
			]
		},
		title: {
			multiplicity: "single",
			contentTypes: [				
				{value: 'titleBlock', title:'Title'},		
			]
		},
		relatedPages: {
			multiplicity: "multiple",
			contentTypes: [				
				{value: 'relatedPages', title:'Related Pages'},		
			]
		}
	}
});

const props = defineProps({		
	contentPage: {
		type: Object,
		required: true,
	}
});

const goToContent = (event) => {
	fireGoToContentEvent(event);
}

const fireGoToContentEvent = (event) => {		
	emits('goToContent',
		{
			primaryUUID: event.primaryUUID,
		}
	);
}

onUpdated(() => {
	console.log('Updated');
	if (props.contentPage) {
		state.contentPage = props.contentPage;
	}
	initialisePageContext();
});

onMounted(() => {
	if (props.contentPage) {
		console.log('Mounted');
		state.contentPage = props.contentPage;
	}
	initialisePageContext();
});

</script>


<style scoped>


</style>