import {useMainStore} from '@/stores/MainStore.js';

export default {			
	isUserInAnyRole(roles) {
		let returnValue = false;					
		const mainStore = useMainStore();
		const phoneServiceName = mainStore.phoneServiceName;
		 mainStore.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
				(domain) => {
					if (domain.phoneServiceName == phoneServiceName) {
						 domain.roles.forEach(
							 (role) => {								
								 roles.forEach(
									(requiredRole) => {
										console.log('role '+role+' '+requiredRole);
										if (role == requiredRole) {
											console.log("match");
											returnValue = true;
										}
									}
								);
							}
						);
					}
				}
		);
		return returnValue;
	},
	isUserPermitted(requiredPermissions) {
		let returnValue = false;					
		const mainStore = useMainStore();
		const phoneServiceName = mainStore.phoneServiceName;
		mainStore.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
			(domain) => {
				if (domain.phoneServiceName == phoneServiceName) {
					 domain.permissions.forEach(
						 (permission) => {			
								requiredPermissions.forEach(
									(requiredPermission) => {
										if (permission == requiredPermission) {
											console.log("match");
											returnValue = true;
										}
									}
								);
							
						}
					);
				}
			}
	);
	return returnValue;
	}

}

