import { defineStore } from "pinia";



import Initialiser from "./initialise-from-server";
import Quota from '@/microServices/quotas';



export const useMainStore = defineStore("mainStore", { 	
		state: () => {
        return {					
					defaultLoginRoute: 'plustag',
					defaultApiSubDomain: 'api.i164.co.uk',
					phoneServiceName: 'plustag',
					phoneServiceDetails: undefined,					
					contextPath: '/i164',
					lastName: undefined,
					lastParams: undefined,
					//contextPath: '/i164',
					initialiserCheckList: {
						fetchServiceDetails: false,
						fetchQuotas: false,
					},
					appLoaded: false,
					loggedIn: false,
					loggedInStatus : null,
					allowedInDomain: true,
					verifiedEmail: -1,
					pollingForLogin: false,						
					loggedOutAutomatically: false,
					initialTargetPath: null,
					initialTargetPhoneServiceName: null,
					initialTargetParams: null,
					showSideMenu: true,
					sideMenuRailMode: false,
					mainMenuViewMode: "full",
					selectedMainMenuNode: ["dashboard"],
					selectedService: {id: 1, serviceName: "Meliometrics"},
					recentServices: [],
					voxletServiceContexts: [],
					selectedLine: null,
					refreshLoggedInStatus: false,
					twoFactorCheckRequired: false,					
					quotaData: {
						userQuotas: [],
						serviceQuotas: []						
					},
					phoneServiceApps: undefined,
					appDataStore:{
						networkHub: {
							i164IndustryHubUUID: undefined,
							i164HubMemberUUID: undefined,
							i164HubMemberIdentityUUID: undefined,
						}
					},
					testEvent: undefined,
					interfaceFeedback: {
						feedbackEvents: [],
						eventLog: [],						
					},
					showContentUUID: undefined,
        }
    },
		getters: {		
			interfaceFeedbackEventsCount() {
				return this.interfaceFeedback.feedbackEvents.length;
			},				
		},
    actions: {
				displaySnackBar(text) {
					this.interfaceFeedback.feedbackEvents.push(
						{
							type: 'snackBar',
							snackBarText: text
						}
					);
				},
				getCommandOutcome(t,outcome,defaultValue) {
					const commandOutcome = {
						outcome: outcome,
						message: defaultValue,
					};					
					const message = t("statics.commandOutcomes."+outcome,defaultValue);
					if (message) {						
						commandOutcome.message = message;
					}
					return commandOutcome;
				},
				getWssUrl(serviceName) {
					if (!serviceName) {
						serviceName = this.phoneServiceName;
					}
					return 'wss://'+serviceName+'.'+this.defaultApiSubDomain+this.contextPath+'/events';
				},
				getBaseUrl(serviceName) {
					if (!serviceName) {
						serviceName = this.phoneServiceName;
					}
					return 'https://'+serviceName+'.'+this.defaultApiSubDomain+this.contextPath;
				},
				getQuartermassAPIUrl(serviceName) {
					if (!serviceName) {
						serviceName = this.phoneServiceName;
					}
					return 'https://'+serviceName+'.'+this.defaultApiSubDomain+this.contextPath+'/commands-q';
				},
				getAPIUrl(serviceName) {
					if (!serviceName) {
						serviceName = this.phoneServiceName;
					}
					return 'https://'+serviceName+'.'+this.defaultApiSubDomain+this.contextPath+'/commands-sso';
				},
				getLogoutUrl() {
					return 'https://plustag.'+this.defaultApiSubDomain+this.contextPath+'/logout';
				},
				getContentAPIUrl(serviceName) {
					if (!serviceName) {
						serviceName = this.phoneServiceName;
					}
					return 'https://'+serviceName+'.'+this.defaultApiSubDomain+this.contextPath+'/publicContent';	
				},
				getContentImageUrl(imageUUID,serviceName) {
					if (!serviceName) {
						serviceName = this.phoneServiceName;
					}
					return 'https://'+serviceName+'.'+this.defaultApiSubDomain+this.contextPath+'/contentFileLoader/'+imageUUID;	
				},
				addRecentService(service) {
            // scan to see if the service is already in the list
            for (let i = 0; i < this.recentServices.length; i++) {
                if (this.recentServices[i].id == service.id) {
                    this.recentServices.splice(i, 1);
                    break;
                }
            }
            this.recentServices.unshift(service);

            // only want to store the last 5 recent services, so trim the list if required
            if (this.recentServices.length > 5) {
                this.recentServices.pop();
            }
        },isUserInDomainUIUD(phoneServiceUUID) {
					if (!this.loggedInStatus) {return false;}
					let inDomain = false;
					this.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
						(domain) => {								
							if (domain.phoneServiceUUID == phoneServiceUUID) {
								inDomain = true;								
							}
						}
					)
					return inDomain;
				},isUserInDomain(phoneServiceName) {
					if (!this.loggedInStatus) {return false;}
					let inDomain = false;
					this.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
						(domain) => {								
							if (domain.phoneServiceName == phoneServiceName) {
								inDomain = true;								
							}
						}
					)
					return inDomain;
				},getDomainDetails(phoneServiceName) {
					let resultDomain = undefined;
					if (!this.loggedInStatus) {return roles;}
					this.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
						(domain) => {								
							if (domain.phoneServiceName == phoneServiceName) {
								resultDomain = domain;
							}
						}
					)
					return resultDomain;
			},getUserRolesForDomain(phoneServiceName) {
						let roles = [];
						if (!this.loggedInStatus) {return roles;}
						this.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
							(domain) => {								
								if (domain.phoneServiceName == phoneServiceName) {
									roles = domain.roles;
								}
							}
						)
						return roles;
				},
				isUserInAnyRole(roles) {
					let returnValue = false;					
					const phoneServiceName = this.phoneServiceName;
					 this.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
							(domain) => {
								if (domain.phoneServiceName == phoneServiceName) {
									 domain.roles.forEach(
										 (role) => {								
											 roles.forEach(
												(requiredRole) => {
													console.log('role '+role+' '+requiredRole);
													if (role == requiredRole) {
														console.log("match");
														returnValue = true;
													}
												}
											);
										}
									);
								}
							}
					);
					return returnValue;
				},
				isUserPermitted(requiredPermissions) {
					let returnValue = false;					
					const phoneServiceName = this.phoneServiceName;
					this.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
						(domain) => {
							if (domain.phoneServiceName == phoneServiceName) {
								 domain.permissions.forEach(
									 (permission) => {			
											requiredPermissions.forEach(
												(requiredPermission) => {
													if (permission == requiredPermission) {
														console.log("match");
														returnValue = true;
													}
												}
											);
										
									}
								);
							}
						}
					);
					return returnValue;
				},
				isUserLicensed(license) {
					let returnValue = false;
					if (license == 'entitlement.browser.app.servicePassport') {
						returnValue = true;
					} else if (license == 'entitlement.browser.app.basicLines') {
						returnValue = true;
					} else if (license == 'entitlement.browser.app.networkHub') {
						returnValue = true;					
					} else if (license == 'entitlement.browser.app.microsoftTeams') {
						returnValue = false;
					}  
					return returnValue;
				},
				getRoleText(roleName) {
					let appText = '';
					let roleText = undefined;
					this.phoneServiceApps.forEach(
						app => {
							appText = app.appText;
							app.appRoles.forEach(
								role => {
									if (role.roleName == roleName) {
										roleText = role.roleText;										
									}
								}
							)
						}
					)
					return roleText;
				},				
				loadInitialDataFromServer()  {
					Initialiser.useStore(this);
					Initialiser.initialise();
					Quota.initialiseStore(this);
				},
				isAppLoaded() {
					if (!this.appLoaded) {
						if (this.initialiserCheckList.fetchQuotas && this.initialiserCheckList.fetchQuotas) {
							this.appLoaded = true;
							console.log("App Loaded")
						}
					}
					return this.appLoaded;
				},
				getQuota() {
					return Quota;
				}
    }
});

