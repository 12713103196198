<template>
	<v-container fluid class="px-6 h-100">
			<v-row>
				<v-col class="pa-0 ma-0">
					<v-row>
						<v-col cols="4">
							<v-card>
								<BoxHeader headerText="Service News"/>
							
								<v-card-text>								
									<p class="theme-font-other font-italic text-caption mt-2">20-06-23</p>
									<p class="theme-font text-body-1 font-weight-bold ml-1">Service Passport Launch Date Announced</p>
									<p class="theme-font text-body-2 ml-1">With TOTSCo going live in 2026, we have announced that Service Passport will be ready by 2029 and will be net-zero.</p>
	
									<p class="theme-font-other font-italic text-caption mt-2">19-06-23</p>
									<p class="theme-font text-body-1 font-weight-bold ml-1">Confusion Reigns in GPLB-SG</p>
									<p class="theme-font text-body-2 ml-1">With no launch plan visibile, how many days will it be before the team reach breaking point and throw in the towel?</p>
								</v-card-text>
							</v-card>							
						</v-col>
						<v-col cols="4">
							<v-hover>
								<template v-slot:default="{ isHovering, props }">
									<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
										<BoxHeader headerText="OTS Match Requests" >
											<template v-slot:action>
												<v-spacer align="right" class="mt-2 mr-2"><p class="text-caption theme-font-other text-capitalize">{{ state.filterValues.timePeriod }}</p></v-spacer>	
											</template>
										</BoxHeader>
										<v-spacer class="ma-4 ">
											<v-row>
												<v-col></v-col>
												<v-col class="text-center">
													<p class="text-caption theme-font-other">MATCHES / TOTAL</p>
												</v-col>									
											</v-row>
											<v-row>
												<v-col class="theme-font text-h6">Made</v-col>
												<v-col class="text-center text-h5">10 / 80</v-col>
												
											</v-row>
											<v-row>
												<v-col class="theme-font text-h6">Received</v-col>
												<v-col class="text-center text-h5">6 / 10</v-col>
												
											</v-row>
										</v-spacer>

									</v-card>
								</template>
							</v-hover>
							<v-row class="ma-0 pa-0">
								<v-col class="ma-0 pa-0 mt-0 mr-1"><v-spacer align="right">
									<p class="text-disabled theme-help-format ">help</p>						
									</v-spacer></v-col>
							</v-row>
						</v-col>
						<v-col cols="4">
							<v-hover>
								<template v-slot:default="{ isHovering, props }">

									<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
										<BoxHeader headerText="OTS Switch Orders" >
											<template v-slot:action>
												<v-spacer align="right" class="mt-2 mr-2"><p class="text-caption theme-font-other text-capitalize">{{ state.filterValues.timePeriod }}</p></v-spacer>	
											</template>
										</BoxHeader>
										<v-spacer class="ma-4 ">
											<v-row>
												<v-col></v-col>
												<v-col class="text-center">
													<p class="text-caption theme-font-other">RECEIVED</p>
												</v-col>
												<v-col class="text-center">
													<p class="text-caption theme-font-other">PROCCESSING</p>
												</v-col>
											</v-row>
											<v-row>
												<v-col class="theme-font text-h6">Gaining</v-col>
												<v-col class="text-center text-h5">10</v-col>
												<v-col class="text-center text-h5">80</v-col>
											</v-row>
											<v-row>
												<v-col class="theme-font text-h6">Losing</v-col>
												<v-col class="text-center text-h5">6</v-col>
												<v-col class="text-center text-h5">10</v-col>
											</v-row>
										</v-spacer>

									</v-card>
								</template>
							</v-hover>
							<v-row class="ma-0 pa-0">
								<v-col class="ma-0 pa-0 mt-0 mr-1">
									<v-spacer align="right">
										<p class="text-disabled theme-help-format " @click="showPermaLinkNameHelp('servicePassport.stats.one');">help</p>
										
										
									</v-spacer>
								</v-col>
							</v-row>
							
						</v-col>
						
					</v-row>
				</v-col>
			</v-row>
		</v-container>
		<ContextHelpDialogue :commandPageInfoUUID="state.help.commandPageInfoUUID" :permaLinkName="state.help.permaLinkName" :show="state.help.show" @close="helpCloseEvent"></ContextHelpDialogue>
	</template>
	
	<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import BoxHeader from '@/components/BoxHeader.vue';
	import ContextHelpDialogue from "@/components/common/ContextHelpDialogue.vue";

	const { t }  = useI18n();
		
	const router = useRouter();
	
	const mainStore = useMainStore();
	
	const state = reactive({	
		tabsTop: 'home',	
		help: {
			commandPageInfoUUID: undefined,
			permaLinkName: undefined,
			show: false,
		},
		filterValues: {
			timePeriod: 'day'
		},
		pagination: {
					page: 2,
					length: 4,
					totalVisible: 7,
				},	
		filters: {		
			timePeriod: [
				{value: 'day', title:'Day View'},
				{value: 'week', title:'Week View'},
				{value: 'month', title:'Month View'}				
			],			
		},	
	});


	const showHelp = (commandPageInfoUUID) => {
		state.help.commandPageInfoUUID = commandPageInfoUUID;
		state.help.show = true;
	}

	const showPermaLinkNameHelp = (permaLinkName) => {
		state.help.permaLinkName = permaLinkName;
		state.help.show = true;
	}

	const helpCloseEvent = (event) => {
		state.help.show = false;
		state.help.articleId = undefined;
	}
	
	
	onMounted(() => {

	});
	
	</script>
	
	<style>
	
	</style>
	