<template >
	<NoAccess v-if="!mainStore.allowedInDomain"/>
	<VerifyYourAccount v-else-if="mainStore.verifiedEmail != 1"/>
	<v-layout :full-height="true" v-else>
			<v-app-bar :flat="true" v-if="mainStore.phoneServiceName != 'plustag'">
				 <div class="app_bar_container theme-dark-background-gradient">
					<v-app-bar-nav-icon  class="theme-text-highlight"
							@click.stop="toggleSideMenu"
					></v-app-bar-nav-icon>
					<v-app-bar-title  class="theme-text-highlight">
						<v-spacer class="ml-5"><v-img :src="i164Transparent" width="67" height="30" ></v-img></v-spacer>
					</v-app-bar-title>
					<v-icon class="theme-text-highlight">domain</v-icon>
					<p class="mr-2 ml-2 text-white" v-if="mainStore.phoneServiceDetails">{{ mainStore.phoneServiceDetails.accountHolderName }}</p>
					<p class="mr-2 ml-0 text-white text-caption d-none d-md-flex">{{ mainStore.phoneServiceName }}</p>
					<v-icon class="theme-text-highlight" @click="refreshUser(this)">account_circle</v-icon>
					<p class="mr-2 ml-2 text-white">{{ mainStore.loggedInStatus.loggedInUserDomains.user.firstName}} {{ mainStore.loggedInStatus.loggedInUserDomains.user.lastName}}</p>					
					<p class="mr-2 ml-0 text-white text-caption d-none d-md-flex">{{ mainStore.loggedInStatus.loggedInUserDomains.user.email}}</p>
					<v-icon class="theme-text-highlight theme-pointer" @click="showHelp(this)">help</v-icon>
					<p class="mr-0 ml-2 text-white d-none d-md-flex theme-pointer" @click="showHelp(this)">Help</p>
					<v-btn @click="state.logout = true;"  class="align-right text-white theme-text-highlight">LOGOUT <v-icon class="ml-2 theme-text-highlight" size="14">logout</v-icon></v-btn>          
				</div>
			</v-app-bar>

			<v-footer app>					
			
			</v-footer>

			<v-navigation-drawer									
					v-model="mainStore.showSideMenu"
					:rail="mainStore.sideMenuRailMode"
					v-if="mainStore.phoneServiceName != 'plustag' && state.menuConfig"
			>
					<SideMenu :menuConfig="state.menuConfig" :railView="mainStore.sideMenuRailMode" :mainStore="mainStore" ref="sideMenu"></SideMenu>					
			</v-navigation-drawer>
		
			<v-main>
				<router-view v-if="route.meta.allowedToView"></router-view> 
				<NoPermission v-else></NoPermission>   
			</v-main>
			
			<v-snackbar v-model="state.snackBar.show">
				{{state.snackBar.snackBarText }}
			</v-snackbar>
	</v-layout>
 	<LogoutDialog :logout="state.logout" logoutPath="login" @close="closeLogout()"/>
	<v-dialog v-model="state.showHelp" persistent fullscreen
      :scrim="false"
      transition="dialog-bottom-transition">
		<v-card>		
        <v-toolbar
          dark
          class="theme-dark-background-gradient"
        >          
          <v-toolbar-title class="theme-font text-white">
						<div class="d-flex">
							<v-icon class="vx-text-dark theme-avatar-color mt-2" size="32">article</v-icon>					
							<p class="mt-2 ml-3 font-weight-bold text-h4 vx-text-dark theme-font-bold">Help Center</p>
						</div>
					</v-toolbar-title>
						
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
            icon
            dark
            @click="state.showHelp = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
          </v-toolbar-items>
        </v-toolbar>
				<v-container fluid class="px-6 py-6 h-100">
				<v-tabs v-model="state.helpCenterTabs" >
						<!--<v-tab value="home" :class="(state.helpCenterTabs == 'home') ? 'bg-active_tab_color' : ''" >					
							<v-avatar
								class="shadow border-radius-xl"
								size="32"  color="avatar_background"
							><v-icon class="vx-text-dark theme-avatar-color" size="24">dialer_sip</v-icon></v-avatar>							
								<p class="ml-2 text-h6 theme-font">Lines</p>
						</v-tab>		-->
						<v-tab  value="documentation" :class="(state.helpCenterTabs== 'documentation') ? 'bg-active_tab_color':''">Documentation</v-tab>
						<v-tab  value="support" :class="(state.helpCenterTabs== 'support') ? 'bg-active_tab_color':''">Support</v-tab>					
				</v-tabs>
				<v-divider/>
				<!--<v-tabs v-model="state.helpCenterTabs" density="compact">
					<v-tab :rounded="0" value="documentation" :class="(state.helpCenterTabs == 'documentation') ? 'bg-primary_100 text-white' : ''" >Documentation</v-tab>
					<v-tab :rounded="0" value="support" :class="(state.helpCenterTabs == 'support') ? 'bg-primary_100 text-white' : ''" >Support</v-tab>
				</v-tabs>-->
				<v-window v-model="state.helpCenterTabs">
					<v-window-item value="documentation">
						<DocumentationFrontPage/>
					</v-window-item>
					<v-window-item value="support">
							<SupportHome/>
					</v-window-item>
				</v-window>
			</v-container>
		</v-card>		
	</v-dialog>
</template>

<script setup>
    import { ref,reactive, watch, onMounted } from 'vue';
    import {useMainStore} from '../stores/MainStore.js';
    import { useDisplay } from 'vuetify';
    import { useI18n } from 'vue-i18n';
		import { useRoute, useRouter } from "vue-router";
    import SideMenu from '../components/sidemenu/SideMenu.vue'
    import ServiceSelector from "./ServiceSelector.vue";
		import VerifyYourAccount from '../components/VerifyYourAccount.vue';
		import NoAccess from '../components/NoAccess.vue';
		import NoPermission from '../components/NoPermission.vue';
		import LogoutDialog from '../components/LogoutDialog.vue';
		import Quota from '@/microServices/quotas';
		import { storeToRefs } from 'pinia';
		import DocumentationFrontPage from './content/DocumentationFrontPage.vue';
		import SupportHome from '@/components/support/SupportHome.vue';
		import i164Transparent from '@/assets/images/i164_transparent.png';
		const route = useRoute();
    const { mdAndUp } = useDisplay();
    const mainStore = useMainStore();
		Quota.initialiseStore(mainStore);
    const { t } = useI18n();

    const state = reactive({
        showHelp: false,
				logout: false,
				menuConfig: undefined,
				helpCenterTabs: 'documentation',
				snackBar: {
					show: false,
					snackBarText: '',
					snackBarQueue: [],
					processing: false,
				}
				

    });
		
    const sideMenu = ref(null);

    const fullMenuConfig = [ 				
			
        {		
            name: "account",
            label: "account",
            icon: "account_balance",
            routeName: "account",
						hasPermission: ['serviceBilling.invoices.view'],
        },
        {		
            name: "users",
            label: "userParticipation",
            icon: "groups",
            routeName: "users",
						hasRole: [],						
        },{		
            name: "content",
            label: "content",
            icon: "article",
            routeName: "contentManager",						
						hasPermission: ['content.updateContent'],
						hasAnyQuota: ['service:content:access']								
        },{		
            name: "developer",
            label: "developer",
            icon: "code",
            routeName: "developer",						
						//hasPermission: ['content.updateContent'],
						//hasAnyQuota: ['service:content:access']								
        },
				{
					title: "Service Offerings",
				},
        {        
						name: "lines",
            label: "lines",
            icon: "dialer_sip",
            routeName: "lines",
						hasAnyQuota: ['service:lines:access'],
						hasSpareQuota: []						
        },
        {
            name: "networkHub",
            label: "networkHub",
            icon: "hub",
            routeName: "networkHubPanel",
						hasAnyQuota: ['service:networkHub:telecoms:createHub'],
						hasSpareQuota: [],
						/**children: [
							{
								name: "networkHubDashboard",
								label: "networkHubDashboard",
								icon: "dashboard",
								routeName: "networkHubDashboard",
							},
							{
								name: "networkHubPanel",
								label: "networkHubPanel",
								icon: "settings",
								routeName: "networkHubPanel",
							}
						]*/
        },
        {
            name: "servicePassport",
            label: "servicePassport",
            icon: "summarize",
            routeName: "servicePassport",
						hasAnyQuota: ['service:servicePassport:consumer:access','service:servicePassport:business:access'],
						hasSpareQuota: [],
						childQuotas: true,						
						/**children: [
						{
								name: "servicePassportDashboard",
								label: "servicePassportDashboard",
								icon: "dashboard",
								routeName: "servicePassportDashboard",
							},
							{
								name: "gainCustomer",
								label: "gainCustomer",
								icon: "arrow_circle_right",
								routeName: "gainCustomer",
							},
							{
								name: "listServicePassports",
								label: "listServicePassports",
								icon: "summarize",
								routeName: "listServicePassports",
								hasAnyQuota: ['service:servicePassport:consumer:access']
							}
						]*/
        },
				{
					title: "Micro Services",
				},
        {	
            name: "voxletServiceContexts",
            label: "voxletServiceContexts",
            icon: "account_tree",
            routeName: "voxletServiceContexts",																		
						hasAnyQuota: ['service:lines:access'],
						hasSpareQuota: [],
						hasActiveQuota: ['service:servicePassport:consumer:passportCount'],
        },
        {		
            name: "devices",
            label: "devices",
            icon: "device_hub",
            routeName: "devices",
						hasAnyQuota: ['service:lines:access'],
						hasSpareQuota: []
        },
        {
            name: "numbers",
            label: "numbers",
            icon: "numbers",
            routeName: "numbers",
						hasAnyQuota: ['service:lines:access'],
						hasSpareQuota: []
        },
        {
            name: "billing",
            label: "billing",
            icon: "currency_pound",
            routeName: "billing",
						hasAnyQuota: ['service:lines:access'],
						hasSpareQuota: []						
        },
        {
            name: "pstn",
            label: "pstn",
            icon: "sip",
            routeName: "pstn",
						hasAnyQuota: ['service:lines:access'],
						hasSpareQuota: []
        },
        {
            name: "branding",
            label: "branding",
            icon: "design_services",
            routeName: "branding",
						hasAnyQuota: [],
						hasSpareQuota: []						
        },
				{
					blanker: "mt-10"
				},
        {					
            name: "userProfile",
            label: "userProfile",
            icon: "account_circle",
						postIcon: 'open_in_new',
						plustagRoute: true,
            routeName: "plustagAccount"
        }
    ]

	const showHelp = (event) => {
		if (route.meta.disableHelp) {
		} else {
			state.showHelp = true;
		}		
	}

	const closeLogout = () => {
		state.logout = false;
	}
	const toggleSideMenu = () => {
			if (mdAndUp.value) {
					//mainStore.sideMenuRailMode = !mainStore.sideMenuRailMode;
			} else {
					mainStore.sideMenuRailMode = false;            
			}
			mainStore.showSideMenu = !mainStore.showSideMenu;
			sideMenu.value.toggleSideMenu();
	}


	watch(route, (to,from) => {
		console.log("to and from "+to.params.phoneServiceName);
		mainStore.allowedInDomain = mainStore.isUserInDomain(to.params.phoneServiceName);
	});


	// watching the change in screen size
	watch(mdAndUp, () => {
			
	});

	const {interfaceFeedbackEventsCount } = storeToRefs(mainStore);

watch(interfaceFeedbackEventsCount, (to,from) => {
	if (to > 0) {
		processFeedbackEvent(mainStore.interfaceFeedback.feedbackEvents.pop());
	}
})

const processFeedbackEvent = (feedbackEvent) => {
	console.log(feedbackEvent);
	switch (feedbackEvent.type) {
		case 'snackBar':
			addToQueue(feedbackEvent);
			break;
		case 'log':
			mainStore.interfaceFeedback.eventLog.push(feedbackEvent);
	}
}

const refreshUser = (event) => {
	mainStore.refreshLoggedInStatus = true;
}
const showSnackBar = (event) => {		
			state.snackBar.snackBarText = event.snackBarText;
			state.snackBar.show = true;
			const interval = setInterval(async () => {			
					state.snackBar.snackBarText = event.snackBarText;						
					showNext();
					clearInterval(interval);					
			}, 3000);		
	}

	const showNext = () => {
		if (state.snackBar.snackBarQueue.length > 0) {
			showSnackBar(state.snackBar.snackBarQueue.shift());
		} else {
			state.snackBar.show = false;
		}
	}

	const addToQueue = (event)  => {
		if (state.snackBar.show) {
			state.snackBar.snackBarQueue.push(event);
		} else {
			showSnackBar(event);
		}
	}

	const buildMenu = () => {	
		let menuItem;
		const menuConfig = [];
		let resourceName;	
		let permission;
		let role;	
		let noChecks = true;
		for (let i = 0; i < fullMenuConfig.length; i++) {		
			noChecks = true;	
			menuItem = fullMenuConfig[i];	
			if (menuItem.hasPermission && menuItem.hasPermission.length > 0) {			
				if (!mainStore.isUserPermitted(menuItem.hasPermission)) {				
					continue;
				}				
			}
			if (menuItem.hasRole && menuItem.hasRole.length > 0) {			
				if (!mainStore.isUserInAnyRole(menuItem.hasRole)) {				
					continue;
				}			
			}		
			if (menuItem.hasAnyQuota && menuItem.hasAnyQuota.length > 0) {
				noChecks = false;
				for (let i = 0; i < menuItem.hasAnyQuota.length;i++) {
					resourceName = menuItem.hasAnyQuota[i];
					if (Quota.hasAnyQuota(resourceName)) {
						if (menuConfig.childQuotas) {

						}
						menuConfig.push(menuItem);
						break;
					}
				}				
			}	
			if (menuItem.hasActiveQuota && menuItem.hasActiveQuota.length > 0) {
				noChecks = false;
				for (let i = 0; i < menuItem.hasActiveQuota.length;i++) {
					resourceName = menuItem.hasActiveQuota[i];
					if (Quota.hasActiveQuota(resourceName)) {
						menuConfig.push(menuItem);
						break;
					}
				}
			} 			
			if (noChecks) {
				menuConfig.push(menuItem);
			}				
		}
		console.log("Menu Built");
		state.menuConfig = menuConfig;
	}

	const showMenuItem = () => {

	}
	

	onMounted(() => {
		buildMenu();
	});

</script>



<style >
nav  {		
		border-right-color: rgb(var(--v-theme-menu_background)) !important; /** Remove weird line */
		background-color:  rgb(var(--v-theme-menu_background)) !important;
	}

header {		
		background-color:  rgb(var(--v-theme-menu_background)) !important;
	}

footer {		

		background-color:  rgb(var(--v-theme-menu_background)) !important;
	}

</style>


<style scoped>
		

		.app_bar_container {
			align-items: center;
			display: flex;
			flex: 0 0 auto;
			position: relative;
			transition: inherit;
			width: calc(100% - 15px);
			background-color:  rgb(var(--v-theme-menu_background)) !important;
			margin-top: 2px !important;
			margin-bottom: 2px !important;
			margin-left: 8px !important;
			margin-right: 8px !important;
			border-radius: 4px !important;
			color: rgb(var(--v-theme-menu_selected_color)) !important
		}

    .serviceIndicator {
        display: flex;
        margin-right: 30px;
        padding: 5px 10px 5px 10px;
        border-radius: 4px;
        cursor: pointer;
    }

    .serviceIndicator:hover {
        background-color: rgb(200, 229, 239);
    }

    .serviceIndicator_mobile {
        display: flex;
        border: 1px solid lightgrey;
        margin: 10px;
        padding: 5px;
        border-radius: 4px;
    }

    .serviceTitle {
        color: grey;
        font-size: smaller;
    }

    .serviceSelectionButton {
        display: flex;
        align-items: center;
        margin-right: 20px;
    }

    .mobileServiceSearchWrapper {
        border: 1px solid lightgrey;
        margin: 10px;
        padding: 5px;
        border-radius: 4px;
        max-height: calc(80% - 20px);
    }

</style>