<template>

</template>
<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';


const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const state = reactive({	
	
});

</script>

<style>

</style>