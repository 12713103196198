<template>
	<OuterCard>
		<v-row>
			<v-col v-if="state.i164HubCommunityMemberships" class="pa-0">
				<BoxHeader headerText="Industry Communities" :dataReady="(true)" readyIcon="check_circle"/>				
				<v-row>
					<v-col class="ma-5 mt-0">
							<v-row v-for="i164HubCommunityMembership,index in state.i164HubCommunityMemberships" :key="index">
								<v-col>
										<v-row>
											<v-col>
												<p class="theme-font text-h6">{{ i164HubCommunityMembership.communityName }}</p>
											</v-col>
										</v-row>
										<v-row v-for="communityWorkflow,index in i164HubCommunityMembership.communityWorkflows">
											<v-col>
														<v-icon class="text-lifecycle_timeline_active_color mr-2">check_circle_outline</v-icon> {{ communityWorkflow.workflowName }}											
											</v-col>
										</v-row>										
								</v-col>
							</v-row>
					</v-col>
				</v-row>				
			</v-col>
			<v-col v-else>
				<v-row>
					<p class="theme-font-other"><v-progress-circular
										indeterminate
										color="rgb(var(--v-theme-loading_spinner_color))"									
									></v-progress-circular></p>
				</v-row>
			</v-col>
		</v-row>
				
			
	</OuterCard>
	
</template>
<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import OuterCard from '@/components/common/OuterPopCard.vue';
import BoxHeader from '@/components/BoxHeader.vue';

	const mainStore = useMainStore();

	const props = defineProps({
		refreshConfiguration: {
			type: Object,
			required: true,
		},	
		configuration: {
			type: Object,
			required: true
		},
		assetData: {
			type: Array,			
		},		
		readOnly: Boolean,
	});

	const state = reactive({
		onboarding: undefined,
		refreshVersion: 0,
		readOnly: false,
		dataReady: false,
		i164HubCommunityMemberships: undefined,	
		processes: {
			businessTelecomsMessaging: true,
			businessTelecomsSwitching: true,
			consumerTelecomsSwitching: false,
		}	
	});


	const refreshState = () => {		
		if (props.assetData) {
			state.i164HubCommunityMemberships = props.assetData;	
			state.dataReady = true;		
		}
	}

	onUpdated(() => {				
		console.log('onUpdated');		
		if (props.refreshConfiguration.version > state.refreshVersion) {
			state.refreshVersion = props.refreshConfiguration.version;
			if (props.refreshConfiguration.onboarding) {				
				state.onboarding = props.refreshConfiguration.onboarding;
			}				
			refreshState();
		}
		if (!state.i164HubCommunityMemberships && props.assetData) {
			refreshState();
		}
	});

	onMounted(() => {
		refreshState();
	});


</script>

<style scoped>

</style>
