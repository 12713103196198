<template>
	<v-container  class="px-6 py-6 h-100"  v-if="state.phoneServiceUserParticipation">
		<v-row>
			<v-col class="h-100">
				<v-row v-if="state.showHeader">
					<v-col class="d-flex mt-1" cols="4">
						<v-tabs v-model="state.selectedTab">
							<v-tab value="userDetails" :class="(state.selectedTab == 'userDetails') ? 'bg-active_tab_color':''">
								<v-avatar
							class="shadow border-radius-xl"
							size="32"  color="avatar_background"
								><v-icon class="vx-text-dark theme-avatar-color" size="24">person</v-icon></v-avatar>							
							<p class="ml-2 text-h6 theme-font">User Account</p>								
							</v-tab>												
						</v-tabs>										
					</v-col>						
				</v-row>			
				<v-row>
					<v-col cols="12" class="ma-0 pd-0">								
						<v-window v-model="state.selectedTab" class="fill-height w-100">
							<v-window-item value="userDetails" class="ma-5">
								<v-row>
									<v-col cols="12" md="4">
											<UserParticipantDetailsPanel :phoneServiceUserParticipation="state.phoneServiceUserParticipation"/>
									</v-col>
									<v-col cols="12" md="4">
										<UserParticipantRolesPanel :phoneServiceUserParticipation="state.phoneServiceUserParticipation"/>
									</v-col>
									<v-col cols="12" md="4">
										<UserParticipationActions :phoneServiceUserParticipation="state.phoneServiceUserParticipation"/>
									</v-col>
								</v-row>
							</v-window-item>
						</v-window>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
	<div v-else>
		<p class="theme-font-other"><v-progress-circular
										indeterminate
										color="rgb(var(--v-theme-loading_spinner_color))"									
									></v-progress-circular></p>
	</div>
</template>

<script setup>
  import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import { useRoute, useRouter } from "vue-router";
	import UserParticipantDetailsPanel from './components/UserParticipantDetailsPanel.vue';
	import UserParticipantRolesPanel from './components/UserParticipantRolesPanel.vue';
	import UserParticipationActions from './components/UserParticipationActions.vue';
	

	const router = useRouter();
	const route= useRoute();
	const { t }  = useI18n();
	const mainStore = useMainStore();


	const state = reactive({
		phoneServiceUserParticipation : undefined,	
		serviceUserUUID: undefined,
		showHeader: false,
		tabs: {
			selectedTab: 'userDetails',
		},
	});


const props = defineProps({
	serviceUserUUID: String,	
	});


const loadPhoneServiceUserParticpation = async () => {
			const apiUrl = mainStore.getAPIUrl();			
			const data = new URLSearchParams();
			data.set("serviceUserUUID",state.serviceUserUUID);
			await fetch(apiUrl+'/phoneServices/loadPhoneServiceUserParticipation', {
				body: data,
				method: 'post',				
				credentials: 'include',
				redirect: 'follow',		
			}).then((response) => {
				response.json().then( 
					d => {
						console.log(d)							
						if (d.outcome == 1) {	
							mainStore.displaySnackBar('Loading Complete');																
							state.phoneServiceUserParticipation = d.phoneServiceUserParticipation;							
						}	else  {
							
						}						
					}									
				)
				return response;
			}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
		});		
	}

const bootServiceUser = () => {
	if (!state.serviceUserUUID) {
		if (route.params.serviceUserUUID) {		
					state.serviceUserUUID = props.serviceUserUUID;
					loadPhoneServiceUserParticpation();	
		}
		if (props.serviceUserUUID) {
			state.serviceUserUUID = props.serviceUserUUID;
			loadPhoneServiceUserParticpation();
		}	
	}
}

	onUpdated(() => {
				bootServiceUser();
		});


	onMounted(() => {
	bootServiceUser();
	});

</script>

<style scoped>



</style>