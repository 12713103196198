<template>
	<v-row :class="backgroundChoice()" >
		<v-col class="d-flex ma-2 pt-0 pb-0 pr-0" >
			<v-icon v-if="readyIcon != ''" :class="dataReady ? 'text-white mt-3 mr-3' : 'text-warning mt-3 mr-3'" >{{iconChoice()}}</v-icon>
			<p class="theme-font text-h6 mt-2 " :class="textChoice()">{{headerText}}</p>
			<v-spacer align="right" @click="close(this);" v-if="closeButton" class="mt-2 mr-2"><v-icon size="x-small">close</v-icon></v-spacer>
			<slot name="action"></slot>
		</v-col>		
	</v-row>	
</template>
<!--'text-success':'text-failure'-->
<script setup>



const emits = defineEmits(['close']);

const close = (event) => {	
	emits('close');	
}

const backgroundChoice = () => {
	if (props.readyIcon == '') {
		return 'theme-box-header-background';
	} 
	if (props.dataReady) {
		return 'theme-box-header-background';
	} else {
		return 'theme-box-header-background';
	}

}

const textChoice = () => {
	if (props.readyIcon == '') {
		return 'text-white';
	} 
	if (props.dataReady) {
		return 'text-white';
	} else {
		return 'theme-box-header-text-failure';
	}

}

const iconChoice = () => {
	if (props.readyIcon == '') {
		return 'check_circle';
	} 
	if (props.dataReady) {
		return props.readyIcon;
	} else {
		if (props.notReadyIcon) {
			return props.notReadyIcon;
		} else {
			return 'cancel';
		}		
	}
}

const props = defineProps({		
	headerText: {
		type: String,
		required: true,
	},
	readyIcon: {
		type: String,
		default: ''
	},
	notReadyIcon: {
		type: String,
		default: ''
	},
	dataReady: {
		type: Boolean,
		required: false,		
	},
	closeButton: Boolean
	
});


</script>

<style>

</style>