<template>
	<VerifyYourAccount v-if="mainStore.verifiedEmail != 1"/>
	<v-layout :full-height="true" class="theme-mainPanel" v-else>


			 <router-view></router-view>        
		
		
	</v-layout>
	<v-snackbar v-model="state.snackBar.show">
			{{state.snackBar.snackBarText }}
		</v-snackbar>
  <LogoutDialog :logout="state.logout"  logoutPath="login"  @close="closeLogout()"/>

</template>

<script setup>
import { ref,reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '../../stores/MainStore.js';
import VerifyYourAccount from '../../components/VerifyYourAccount.vue';
import LogoutDialog from '../../components/LogoutDialog.vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';

const { t }  = useI18n();

const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();

const closeLogout = () => {
	state.logout = false;
}

const state = reactive({
	logout: false,
	snackBar: {
		show: false,
		snackBarText: '',
		snackBarQueue: [],
		processing: false,
	}
});



const {interfaceFeedbackEventsCount } = storeToRefs(mainStore);

watch(interfaceFeedbackEventsCount, (to,from) => {
	if (to > 0) {
		processFeedbackEvent(mainStore.interfaceFeedback.feedbackEvents.pop());
	}
})

const processFeedbackEvent = (feedbackEvent) => {
	console.log(feedbackEvent);
	switch (feedbackEvent.type) {
		case 'snackBar':
			addToQueue(feedbackEvent);
			break;
		case 'log':
			mainStore.interfaceFeedback.eventLog.push(feedbackEvent);
	}
}

const showSnackBar = (event) => {		
			state.snackBar.snackBarText = event.snackBarText;
			state.snackBar.show = true;
			const interval = setInterval(async () => {			
					state.snackBar.snackBarText = event.snackBarText;						
					showNext();
					clearInterval(interval);					
			}, 3000);		
	}

	const showNext = () => {
		if (state.snackBar.snackBarQueue.length > 0) {
			showSnackBar(state.snackBar.snackBarQueue.shift());
		} else {
			state.snackBar.show = false;
		}
	}

	const addToQueue = (event)  => {
		if (state.snackBar.show) {
			state.snackBar.snackBarQueue.push(event);
		} else {
			showSnackBar(event);
		}
	}

onMounted(() => {

});

</script>

<style scoped>


</style>
