<template>
	<v-spacer v-if="state.contentFragment">

		<v-row>
			<v-col>			
		
				<v-row>
					<v-col class="d-flex">				
						<v-text-field  @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)"  @keyup.native.enter="validInput(this)" label="Search Text" variant="outlined" v-model="state.search.searchText"></v-text-field>
								<v-btn icon="search"  :class="state.search.ready ? 'theme-bg-highlight mt-1 ml-5' : 'mt-1 ml-5'" :disabled="!state.search.ready"  @click="searchContent()"></v-btn> 		
					</v-col>
				</v-row>
				<v-row>
					<v-col>

						<v-row v-if="state.contentPages && state.contentPages.length ==0">
							<v-col>None Found</v-col>
						</v-row>
						<v-row v-if="state.selectedContentPage">
							<v-col>
								<v-card>
									<v-card-text>
										<v-row>
											<v-col class="d-flex">
												<v-text-field v-model="state.contentFragment.alternateTitle"  hide-details="auto" variant="outlined" label="Alternate Title" @update:modelValue="valid(this)"></v-text-field>
											</v-col>
										</v-row>
										<v-row>
											<v-col class="d-flex">
												<p>{{ state.contentFragment.contentReference }}</p><v-spacer align="right"><v-btn @click="clearSelection()" size="x-small"><v-icon size="x-small">close</v-icon></v-btn></v-spacer>
											</v-col>
										</v-row>
										<v-row>
											<v-col>
												<p>{{ state.contentFragment.summaryTitle }}</p>
											</v-col>
										</v-row>
										<v-row>
											<v-col>
												<p class="text-caption">{{ state.contentFragment.summaryDescription }}</p>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>							
							</v-col>
						</v-row>
						<v-row v-else v-for="contentPage,index in state.contentPages"  :key="index">
							<v-col @click="selectPage(contentPage);" class="theme-pointer">
								<p>{{ contentPage.summaryTitle }}	{{ contentPage.pageInfo.contentReference }}</p>
								<p class="text-caption">{{ contentPage.summaryDescription }}	</p>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-btn  :disabled="!state.dataReady"  :class="(state.dataReady) ? 'theme-bg-highlight' :''" @click="completed(this)"><p v-if="state.action == 'add'">Create</p><p v-if="state.action == 'update'">Update</p></v-btn>
					</v-col>
				</v-row>
			</v-col>
		</v-row>						
					
	</v-spacer>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';

const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const props = defineProps({		
	contentConfiguration: {
		type: Object,
		required: true,
	},
	action: {
		type: String,
		required: true,
	},
	contentFieldName: {
		type: String,
		required: true,
	},
	contentFragment: {
		type: Object
	},
	position: {
		type: Number
	}
});

const state = reactive({	
	dataReady: false,	
	action: undefined,	
	contentConfiguration: undefined,			
	contentFragment: undefined,
	position: 0,
	contentPages: undefined,
	search : { 
		ready: false,
		searchText: undefined,
		appName: [],
		pageType: [],
		audience: [],
	},
	selectedContentPage: undefined,
	searching: false,
	values: {					
		size: [
			{value: 1, title:'H1'},		
			{value: 2, title:'H2'},	
			{value: 3, title:'H3'},	
			{value: 4, title:'H4 (default)'},	
			{value: 5, title:'H5'},	
			{value: 6, title:'H6'},				
		]
	}
});

const emits = defineEmits(['close','dataEvents']);

const fireDataReadyEvent = () => {
		emits('dataEvents',
			{
				state: 'ready',
				action: state.action,
				position: state.position,
				contentFieldName: state.contentFieldName,
				contentFragment: state.contentFragment,				
			}
		);
}

const clearSelection = () => {
	state.selectedContentPage = undefined;
	valid();
}

const close = (a) => {	
	emits('close');
}

const valid = (event) => {
	state.search.ready = true;
	
	state.dataReady = (state.selectedContentPage);
	return state.dataReady;
}

const validInput = (event) => {
	valid(event);
	if (state.search.ready) {
		searchContent();
  }
}

const completed = (event) => {
	if (valid(event)) {
		fireDataReadyEvent();
	}	
}

const selectPage = (selectedContentPage) => {
	state.selectedContentPage = selectedContentPage;
	console.log(JSON.stringify(selectedContentPage));
	state.contentFragment.primaryUUID = selectedContentPage.primaryUUID;
	state.contentFragment.pageType = selectedContentPage.pageInfo.pageType;	
	state.contentFragment.summaryTitle = selectedContentPage.summaryTitle;
	state.contentFragment.summaryDescription = selectedContentPage.summaryDescription;
	state.contentFragment.contentReference = selectedContentPage.pageInfo.contentReference;	
	console.log(selectedContentPage.pageInfo.contentReference);	
	valid();
}

const searchContent = async () => {
	state.searching = true;
	const data = new URLSearchParams();		
	if (state.search.searchText) {
		data.set('searchText', state.search.searchText);			
	}
	if (state.search.audience && state.search.audience.length > 0) {
		data.set('audience', state.search.audience);	
	}
	if (state.search.appName && state.search.appName.length > 0) {
		data.set('appName', state.search.appName);	
	}
	if (state.search.pageType && state.search.pageType.length > 0) {
		data.set('pageType', state.search.pageType);	
	}
	const apiUrl = mainStore.getAPIUrl();				

	await fetch(apiUrl+'/documentation/searchDocumentation', {
		method: 'post',			
		body: data,	
		credentials: 'include',
		redirect: 'follow',		
	}).then((response) => {
		response.json().then( 
			d => {			
				if (d.outcome == 1) {															
					state.contentPages = d.contentPages;
				}	else {
					mainStore.displaySnackBar('OOps! '+d.message);
				}						
				state.searching = false;
			}									
		)
		return response;
	}).catch((error) => {
	console.log('Looks like there was a problem: \n', error);
});		

}

onUpdated(() => {

});

onMounted(() => {
	if (props.contentConfiguration) {
		state.contentConfiguration = props.contentConfiguration;
		console.log(" contentConfiguration is "+state.contentConfiguration);
	} 
	if (props.contentFieldName) {
		state.contentFieldName = props.contentFieldName;
		console.log(" contentFieldName is "+state.contentFieldName);
	} 
	if (props.action) {
		state.action = props.action;
	} 
	if (props.contentFragment) {
		state.contentFragment =props.contentFragment;
	} else {
		state.contentFragment = {
			type: 'relatedPages',
		}
	}
	if (props.position) {
		state.position = props.position;
	} else {
		state.position = 0;
	}
});
</script>

<style>

</style>