<script setup>
    import { ref, provide } from 'vue';
    import {useMainStore} from '../../stores/MainStore.js';
    import SideMenuItem from './SideMenuItem.vue';

    const mainStore = useMainStore();

    const props = defineProps({
        menuConfig: {
            type: Object,
            default: []
        },
        railView: {
            type: Boolean,
            default: false
        }
    });

    // controls the display of the menu for the more compact view
    const railView = ref(props.railView);

    // expose the display mode to the child elements
    provide("railView", railView);


    // **** public functions below ****

    const toggleSideMenu = () => {
        railView.value = mainStore.sideMenuRailMode;
        mainStore.mainMenuViewMode = (mainStore.mainMenuViewMode == "full") ? "compact" : "full";
				console.log('rails'+railView.value);
    }

    defineExpose({
        toggleSideMenu
    });
</script>

<template>
    <div class="mm_side_menu_containter theme-menu-background-gradient">
				<div v-for="menuItem,index in props.menuConfig" :key="index" >
						<div v-if="menuItem.title" class="menuTitle">{{menuItem.title}}</div>
						<div v-else-if="menuItem.blanker" :class="menuItem.blanker" class="blanker"></div>
        		<SideMenuItem :menuItem="menuItem"  v-else></SideMenuItem>
				</div>
    </div>
</template>

<style>
    .mm_side_menu_containter {
				
        height: 100%;
				margin-top: 2px x!important;
    		margin-bottom: 4px !important;
				margin-left: 12px !important;
				margin-right: 12px !important;
				border-radius: 4px !important;
    }

    .mm_side_menu_containter a {
        text-decoration: none;
    }

		.menuTitle {
			margin-top: 5px;			
			font-size: 12px;
			padding-left:20px;
			padding-bottom: 5px;
			color: white;
			text-transform: uppercase;
			border-bottom-color: rgb(var(--v-theme-menu_separator));
			border-bottom-style: solid;
      border-bottom-width: 1px;
		}

		.blanker {
			border-bottom-color: rgb(var(--v-theme-menu_separator));
			border-bottom-style: solid;
      border-bottom-width: 1px;
		}
</style>