<template>
	<v-container fluid class="px-6 py-6" v-if="state.contentPage" :key="state.version" >
		<v-row>
			<v-col cols="12">
				<v-row>
					<v-col class="d-flex">
						<v-spacer class="elevation-2"><PageControls :edit="state.edit" :contentPage="state.contentPage" @editEvent="editControl" @dataEvents="pageInfoDataEventFired" /></v-spacer>
					</v-col>
				</v-row>	
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="9">
					
				<v-row>
					<v-col>
						<div v-if="state.contentConfiguration.title.multiplicity == 'multiple' || !state.contentPage.content.title  || state.contentPage.content.title.length == 0 ">
							<ContentCreator :contentConfiguration="state.contentConfiguration.title" :position="(0)" contentFieldName="title" @dataEvents="dataEventFired" :edit="state.edit"/>
						</div>
						<div v-if="state.contentPage.content && state.contentPage.content.title">					
								<ContentBlock  :pageContext="pageContext" :contentConfiguration="state.contentConfiguration.title" contentFieldName="title" @dataEvents="dataEventFired"  :contentFragments="state.contentPage.content.title"  :edit="state.edit">
								</ContentBlock>					
						</div>								
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<ContentCreator :contentConfiguration="state.contentConfiguration.paragraph" :position="(0)" contentFieldName="paragraph" @dataEvents="dataEventFired" :edit="state.edit"/>
						<div v-if="state.contentPage.content && state.contentPage.content.paragraph">					
								<ContentBlock :pageContext="pageContext"  :contentConfiguration="state.contentConfiguration.paragraph" contentFieldName="paragraph" @dataEvents="dataEventFired"  :contentFragments="state.contentPage.content.paragraph"  :edit="state.edit">
								</ContentBlock>					
						</div>								
					</v-col>
				</v-row>		
			</v-col>
			<v-col cols="3">
				<v-row>
					<v-col>
						<p class="text-h6">Related Links</p>
						<ContentCreator :contentConfiguration="state.contentConfiguration.relatedPages" :position="(0)" contentFieldName="relatedPages" @dataEvents="dataEventFired" :edit="state.edit"/>
						<div v-if="state.contentPage.content && state.contentPage.content.relatedPages">					
								<ContentBlock :pageContext="pageContext"  :contentConfiguration="state.contentConfiguration.relatedPages" contentFieldName="relatedPages" @dataEvents="dataEventFired"  :contentFragments="state.contentPage.content.relatedPages"  :edit="state.edit">
								</ContentBlock>					
						</div>								
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ContentCreator from '../pages/components/ContentCreator.vue';
import content from '../content.js';
import ContentBlock from '../types/ContentBlock.vue';
import PageControls from '../pages/components/PageControls.vue';

const { t }  = useI18n();
	
const router = useRouter();
const route = useRoute();

const mainStore = useMainStore();
content.initialiseStore(mainStore);

let pageContext = {
		stepCount: 0,
	};

const initialisePageContext = () => {
	pageContext = {
		stepCount: 0,
	};
}

const state = reactive({	
	version: 0,
	edit: true,
	contentPageInfoUUID: undefined,
	contentPage: undefined,
	contentConfiguration: {
		paragraph: {
			multiplicity: "multiple",
			contentTypes: [
				{value: 'textBlock', title:'Paragraph'},		
				{value: 'titleBlock', title:'Title'},
				{value: 'stepBlock', title:'Step'},		
				{value: 'codeBlock', title:'Code'},			
				{value: 'imageBlock', title:'Image'},
				{value: 'textImageBlock', title:'Text Image'},		
				{value: 'splashTemplate', title:'Splash Template'},				
			]
		},
		title: {
			multiplicity: "single",
			contentTypes: [				
				{value: 'titleBlock', title:'Title'},		
			]
		},
		relatedPages: {
			multiplicity: "multiple",
			contentTypes: [				
				{value: 'relatedPages', title:'Related Pages'},		
			]
		}
	}
});

const pageInfoDataEventFired = (pageInfoEvent) => {
	console.log('Web Page '+JSON.stringify(pageInfoEvent));	
	state.contentPage.pageInfo = pageInfoEvent.pageInfo;
	content.processPageInfoEvent(pageInfoEvent,
		(data) => {
			state.version = data.version;
			state.contentPage.pageInfo.version = data.version;
		}
	);	
	console.log('Content Now '+JSON.stringify(state.contentPage));
}

const dataEventFired = (contentEvent) => {
	console.log('Web Page '+JSON.stringify(contentEvent));	
	content.processContentEvent(contentEvent,
		(data) => {
			state.version = data.version;
			state.contentPage.pageInfo.version = data.version;
			content.setContentPage(state.contentPage);
		}
	);	
	console.log('Content Now '+JSON.stringify(state.contentPage));
}

const editControl = (event) => {
	console.log(event.edit);
	state.edit = event.edit;
}

onUpdated(() => {
	console.log('Updated');
	initialisePageContext();
});

onMounted(() => {
			if (route.params.contentPageInfoUUID) {
				state.contentPageInfoUUID = route.params.contentPageInfoUUID;
				content.setContentPageInfoUUID(state.contentPageInfoUUID);
				content.loadContent(
					(data) => {
						state.contentPage = data.contentPage;
						state.version = data.contentPage.pageInfo.version;
						console.log(JSON.stringify(state.contentPage));
					}
				);
			}
			initialisePageContext();			
	});


</script>

<style>


</style>