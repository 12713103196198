<template>
			<v-spacer v-if="state.contentPage">
				<v-row>
					<v-col md="3" sm="2" lg="4">
					</v-col>
					<v-col cols="12" sm="8" md="6" lg="4">
						<v-card>
							<v-card-text>
									<v-row>
										<v-col class="pa-0">									
											<v-row class="theme-box-header-background">
												<v-col class="d-flex ma-2 mb-0">
													<p class="theme-font text-h5 text-white">Page Info</p>
													<v-spacer align="right" @click="close(this);"><v-icon>close</v-icon></v-spacer>
												</v-col>		
											</v-row>
										<v-row>
												<v-col>
													<v-tabs v-model="state.selectedTab">	
														<v-tab value="properties" :class="(state.selectedTab == 'properties') ? 'bg-active_tab_color':''">
															<p class="text-h6 theme-font">Properties</p>
														</v-tab>					
														<v-tab value="classification" :class="(state.selectedTab  == 'classification') ? 'bg-active_tab_color':''">
															<p class="text-h6 theme-font">Classify</p>
														</v-tab>	
														<v-tab value="search" :class="(state.selectedTab  == 'search') ? 'bg-active_tab_color':''">
															<p class="text-h6 theme-font">Search</p>
														</v-tab>									
													</v-tabs>	
												</v-col>
											</v-row>
											<v-row>
												<v-col>
													<v-window v-model="state.selectedTab" class="fill-height bg-white w-100" style="min-height:550px;">
														<v-window-item value="properties" class="ma-5 mt-0 pt-0" >
															<v-row>
																<v-col class="mt-5 ml-5 mr-5  pb-0">
																	<v-select
																				:items="state.values.status"				
																				label="Status"
																				variant="outlined"
																				item-title="title"
																				item-value="value"																	
																				v-model="state.contentPage.pageInfo.status"
																				@update:modelValue="valid(this)"								
																			></v-select>
																</v-col>
															</v-row>
															<v-row>
																<v-col class="ml-5 mr-5  pb-0">
																	<v-text-field  @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)" label="Page Name *" variant="outlined" v-model="state.contentPage.pageInfo.pageName"></v-text-field>
																</v-col>
															</v-row>
															<v-row>
																<v-col class="ml-5 mr-5 pb-0">
																	<v-select
																				:items="state.values.permissions"				
																				label="Permissions"
																				variant="outlined"
																				item-title="title"
																				item-value="value"																	
																				v-model="state.contentPage.pageInfo.permissions"		
																				@update:modelValue="valid(this)"								
																			></v-select>
																</v-col>
															</v-row>
															
															<v-row class="ml-5 mr-5 pb-0">
																<v-col>
																	<v-textarea variant="outlined" label="Description" hide-details="auto" v-model="state.contentPage.pageInfo.description" @update:modelValue="valid(this)"></v-textarea>								
																</v-col>
															</v-row>
														</v-window-item>
														<v-window-item value="classification" class="ma-5">
															<v-row>
																<v-col class="ml-5 mr-5 pb-0">
																	<v-select
																				:items="state.values.apps"				
																				label="App"
																				variant="outlined"
																				item-title="title"
																				item-value="value"																	
																				v-model="state.contentPage.pageInfo.appName"
																				@update:modelValue="valid(this)"								
																			></v-select>
																</v-col>
															</v-row>
															<v-row>
																<v-col class="ml-5 mr-5 pb-0">
																	<v-select
																				:items="state.values.pageTypes"				
																				label="Page Type"
																				variant="outlined"
																				item-title="title"
																				item-value="value"																	
																				v-model="state.contentPage.pageInfo.pageType"
																				disabled="true"
																				@update:modelValue="valid(this)"								
																			></v-select>
																</v-col>
															</v-row>		
															<v-row>
																<v-col class="ml-5 mr-5 pb-0">
																	<v-combobox
																		v-model="state.contentPage.pageInfo.categories"
																		:items="state.values.categories"
																		variant="outlined"
																		label="Categories"
																		multiple
																		chips
																		closable-chips
																	></v-combobox>														
																</v-col>
															</v-row>														
															<v-row>
																<v-col class="ml-5 mr-5 pb-0">
																	<v-select
																			v-model="state.contentPage.pageInfo.audience"
																			:items="state.values.audience"
																			variant="outlined"
																			label="Audience"
																			item-title="title"
																			item-value="value"	
																			multiple
																		>
																			<template v-slot:selection="{ item, index }">
																				<v-chip v-if="index < 2">
																					<span>{{ item.title }}</span>
																				</v-chip>
																				<span
																					v-if="index === 2"
																					class="text-grey text-caption align-self-center"
																				>
																					(+{{ state.contentPage.pageInfo.audience.length - 2 }} others)
																				</span>
																			</template>
																		</v-select>
																</v-col>
															</v-row>
															<v-row>
																<v-col class="ml-5 mr-5  pb-0">
																	<v-text-field  @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)" label="Perma Link Name" variant="outlined" v-model="state.contentPage.pageInfo.permaLinkName"></v-text-field>
																</v-col>
															</v-row>
														</v-window-item>
														<v-window-item value="search" class="ma-5">
															<v-row>
																<v-col class="ml-5 mr-5 pb-0">
																	<v-select
																				:items="state.values.showInSearch"				
																				label="Show In Search"
																				variant="outlined"
																				item-title="title"
																				item-value="value"																	
																				v-model="state.contentPage.pageInfo.showInSearch"
																				@update:modelValue="valid(this)"								
																			></v-select>
																</v-col>
															</v-row>
															<v-row>
																<v-col class="ml-5 mr-5 pb-0">
																	<v-combobox
																		v-model="state.contentPage.pageInfo.labels"
																		:items="state.values.labels"
																		variant="outlined"
																		label="Labels"
																		multiple
																		chips
																		closable-chips
																	></v-combobox>														
																</v-col>
															</v-row>
															<v-row>
																<v-col class="ml-5 mr-5 pb-0">
																	<v-combobox
																		v-model="state.contentPage.pageInfo.keywords"
																		:items="state.values.keywords"
																		variant="outlined"
																		label="Keywords"
																		multiple
																		chips
																		closable-chips
																	></v-combobox>														
																</v-col>
															</v-row>
														</v-window-item>
													</v-window>
												</v-col>
											</v-row>										
												
												<v-row>
													<v-col>
														<v-btn  :disabled="!state.dataReady"  :class="(state.dataReady) ? 'theme-bg-highlight' :''" @click="savePageInfo(this)">Save</v-btn>
													</v-col>
												</v-row>		
										</v-col>
									</v-row>

								</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-spacer>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';

const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const emits = defineEmits(['dataEvents','close']);

const props = defineProps({		
	contentPage: {
		type: Object,
		required: true,
	}
});

const state = reactive({	
	dataReady: false,	
	contentPage: undefined,
	selectedTab: 'properties',
	values: {		
		pageTypes: [
			{value: 'article', title:'Article'},
			{value: 'webPage', title:'Web Page'},
			{value: 'faq', title:'FAQ'},							
			{value: 'command' , title:'Command'},
			{value: 'helpButton' , title:'Help Button'},
			{value: 'splash' , title:'Splash Page'},						
		],	
		apps: [
			{value: 'servicePassport' , title:'Service Passport'},
			{value: 'lines', title:'Lines'},
			{value: 'developer', title:'Developer'},					
		],
		labels: [
			
		],
		status: [
			{value: 'live' , title:'Live'},
			{value: 'draft', title:'Draft'},		
			{value: 'todo', title:'To Do'},
			{value: 'queue', title:'Queue'},
			{value: 'archived', title:'Archived'},
		],
		audience: [
			{value: 'general' , title:'General'},
			{value: 'technical', title:'Technical'},		
			{value: 'developer', title:'Developer'},
			{value: 'financial', title:'Financial'},
			{value: 'business', title:'Business'},
		],
		permissions: [
			{value: 'ownDomainOnly', title:'Own Domain Only'},	
			{value: 'loggedInUser' , title:'Logged In User'},
			{value: 'guest', title:'Public'},	
		],
		categories: [
		
		],
		keywords: [
			
		]		,
		showInSearch: [
			{value: 'true' , title:'Show In Search Results'},
			{value: 'false', title:'Hide From Search'},					
		]				
	},
});

const savePageInfo = () => {
	emits('dataEvents',
			{
				state: 'ready',
				action: 'update',
				pageInfo: state.contentPage.pageInfo,
			}
		);
}

const close = (a) => {	
	emits('close');
}

const valid = (event) => {
	state.dataReady = (state.contentPage.pageInfo.appName && state.contentPage.pageInfo.pageType && state.contentPage.pageInfo.pageName);
}

onUpdated(() => {
	if (props.contentPage) {
		state.contentPage = props.contentPage;
	}
});

onMounted(() => {
	if (props.contentPage) {
				state.contentPage = props.contentPage;
			}
	valid();
	});
</script>

<style>
</style>