<template>
	<v-container fluid class="px-6 py-6">
		<v-row>
			<v-col>
				<v-card>
					<v-card-text>
						<v-row v-if="!state.selectedDirectoryMember">
							<v-col>
								<v-row>
									<v-col class="d-flex">
										<v-text-field label="Search For Retailer" variant="outlined" v-model="state.searchBox.value" class="mr-5" @keydown.enter="fetchDirectory()" ></v-text-field>
										<v-btn icon="search" :class="state.searchBox.value ? 'theme-bg-highlight mt-1' : 'mt-1'"
										@click="fetchDirectory()"></v-btn>		
									</v-col>
								</v-row>
								<v-row v-if="state.directoryMembers">
									<v-col>
										<div v-for="directoryMember,index in state.directoryMembers" :key="index" >
											<v-row v-if="!directoryMember.you">												
												<v-col>
														{{ directoryMember.memberName }}
												</v-col>
												<v-col>								
													<v-row v-for="directoryIdentity,position in directoryMember.identities" :key="position">
														<v-col>
															Trading As
														</v-col>
														<v-col>
															{{ directoryIdentity.gainingProviderTradingName }}
														</v-col>
														<v-col>
															<v-btn @click="selectDestination(directoryMember,directoryIdentity)">Message</v-btn>
														</v-col>
													</v-row>
												</v-col>

											</v-row>
										</div>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
						<v-row v-if="state.selectedDirectoryMember">
								<v-col>To: {{ state.selectedDirectoryMember.memberName }}</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="state.selectedDirectoryMember">
			<v-col>
				<v-card>
					<v-card-text>
						<v-row>
							<v-col>
								<v-select :items="getYourIdentities()" label="Your Identity" variant="solo"
									item-title="gainingProviderTradingName"
									item-value="i164HubMemberIdentityUUID" 
									v-model="state.message.sourceMemberIdentityUUID"
									>
									
								</v-select>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-text-field label="Subject" v-model="state.message.subject"/>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-textarea label="Message" v-model="state.message.message" ></v-textarea>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-btn @click="sendMessage()">Send</v-btn>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		
	</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';

	const { t }  = useI18n();
	
	const mainStore = useMainStore();

	const state = reactive({
		directoryMembers: undefined,
		destinationMemberUUID: undefined,
		selectedDirectoryMember : undefined,
		selectedDirectoryIdentity: undefined,
		message: {
			sourceMemberIdentityUUID: undefined,
			subject: undefined,
			body: undefined,
		},
		searchBox: {
			value: undefined,
		}
	});

	const getYourIdentities = () => {
		for (let i = 0;i < state.directoryMembers.length;i++) {
			if (state.directoryMembers[i].you) {
					console.log("found you");
					return state.directoryMembers[i].identities;
			}
		}
	}

	const selectDestination = (directoryMember,directoryIdentity) => {
		state.selectedDirectoryMember = directoryMember;
		state.selectedDirectoryIdentity = directoryIdentity;

	}
	
	const  fetchDirectory = async () => {
		const data = new URLSearchParams();
			data.set('postcode', state.searchBox.value);	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/searchHubDirectory', {
					method: 'get',			
					//body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								state.directoryMembers = d.directoryMembers;		
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});
	}

	const  sendMessage = async () => {
		const data = new URLSearchParams();
			data.set('sourceMemberIdentityUUID', state.message.sourceMemberIdentityUUID);
			data.set('destinationIdentityKey', state.selectedDirectoryIdentity.bcpid);
			data.set('destinationIdentityKeyType', 'BCPID');	
			data.set('messageSubject', state.message.subject);
			data.set('messageBody', state.message.message);
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/sendGenericMessage', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								console.log("Message Posted")	
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});
	}

	onUpdated(() => {
		
	});

	onMounted(() => {
		//fetchDirectory();
	});

</script>

<style scoped>
</style>