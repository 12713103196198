<template>
	<v-hover v-if="state.onboarding" >
 <template v-slot:default="{ isHovering, props }">
	<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
		<BoxHeader headerText="Telephone Number" :dataReady="state.dataReady" readyIcon="check_circle"/>
		<v-card-text>
			<v-tabs density="compact" v-model="state.tabsTop">
				<v-tab value="allocate" :class="(state.tabsTop == 'allocate') ? 'bg-active_tab_color' : ''" >Allocate</v-tab>		
				<v-tab value="port" :class="(state.tabsTop == 'port') ? 'bg-active_tab_color' : ''" >Port</v-tab>
			</v-tabs>		
			<v-divider/>
			<v-window v-model="state.tabsTop">
				<v-window-item value="allocate">
					<v-row v-if="state.numberConfiguration.numberRequests.length == 0 || (state.selectionMode =='multiple')">
						<v-col class="ma-5">
							<v-row>
								<v-col class="d-flex">
									<v-text-field label="Search By Area" variant="outlined" v-model="state.areaCodeSearchText" class="mr-5"
										@keydown.enter="searchForAreaCodes()"></v-text-field>
									<v-btn icon="search" :class="state.areaCodeSearchText ? 'theme-bg-highlight mt-1' : 'mt-1'"
										:disabled="!state.areaCodeSearchText" @click="searchForAreaCodes()"></v-btn>
								</v-col>
							</v-row>
							<v-row v-if="state.areaCodeSearchResults && state.areaCodeSearchResults.length > 0">
								<v-col>
									<p class="theme-font">Select an Area</p>
									<v-list>
										<v-list-item v-for="areaCodeResult in state.areaCodeSearchResults" :key="areaCodeResult"  active-color="primary">
											<template v-slot:prepend>
												<v-icon icon="numbers" size="16" class="mr-3"></v-icon>
											</template>
											<v-list-item-title class="theme-pointer theme-font-other" @click="selectAreaCode(this,areaCodeResult)">0{{
												areaCodeResult.areaCode}} <b>{{ t('statics.numbers.areaCodes.'+areaCodeResult.areaCode)}} (+{{ areaCodeResult.countryCode}} {{t('statics.numbers.countryCodes.'+areaCodeResult.countryCode)}})</b> </v-list-item-title>
										</v-list-item>
									</v-list>			
								</v-col>
							</v-row>
						</v-col>
					</v-row>			
					<v-row v-if="state.numberConfiguration.numberRequests.length > 0">
						<v-col class="ma-5">
							<p class="theme-font-other font-weight-bold">Requested Numbers</p>
							<v-list>
								<v-list-item v-for="numberRequest, index in state.numberConfiguration.numberRequests" :key="index">
									<v-list-item-title class="theme-pointer theme-font-other">{{ numberRequest.quantity}} x 0{{ numberRequest.areaCode}} <b>{{ t('statics.numbers.areaCodes.'+numberRequest.areaCode)}} (+{{ numberRequest.countryCode}} {{ t('statics.numbers.countryCodes.'+numberRequest.countryCode)}})</b> <span class="text-caption text-decoration-underline ml-3 theme-pointer" @click="removeAreaCode(this,index,numberSelected)" v-if="!state.readOnly">REMOVE</span></v-list-item-title>
								</v-list-item>
							</v-list>					
						</v-col>
					</v-row>
					<v-row v-if="state.numberAssets">
						<v-col class="ma-5">
							<p class="theme-font-other font-weight-bold">Number(s) Allocated</p>
								<v-list>
									<v-list-item v-for="allocatedNumber, index in state.numberAssets" :key="index">
										<v-list-item-title class="theme-pointer theme-font-other">{{ allocatedNumber.telephoneNumber}} </v-list-item-title>
									</v-list-item>
								</v-list>						
							</v-col>
					</v-row>
				</v-window-item>
				<v-window-item value="port">
					<v-row>
						<v-col class="ma-5">
							<v-row>
								<v-col>
									<v-text-field label="Number to Port" variant="outlined"></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-checkbox label="Same Address as Subsciriber"></v-checkbox>
								</v-col>
							</v-row>		
						</v-col>
					</v-row>								
				</v-window-item>
			</v-window>
			
		</v-card-text>
	</v-card>
	</template>
	</v-hover>
	<v-card v-else>
		<v-card-text>Initialising</v-card-text>
	</v-card>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import BoxHeader from '@/components/BoxHeader.vue';


	const { t }  = useI18n();
	
	const route = useRoute();

	const mainStore = useMainStore();
	
	const state = reactive({
		onboarding: undefined,
		refreshVersion: 0,
		readOnly: false,
		dataReady: false,
		selectionMode: 'single',
		areaCodeSearchText: undefined,
		areaCodeSearchResults: undefined,
		numberConfiguration: {
			numberRequests: [],
		},
		numberAssets: undefined,	
		tabsTop: 'allocate',	
	});

	const emits = defineEmits(['dataEvents']);

	const fireDataReadyEvent = () => {			
			emits('dataEvents',
				{
					state: 'ready',
					numberRequests: state.numberConfiguration.numberRequests,
				}
			);
	}

	const fireDataNotReadyEvent = () => {
		emits('dataEvents',
			{
				state: 'notReady',
				numberRequests: state.numberConfiguration.numberRequests,
			}
		);
	}


	const props = defineProps({
		refreshConfiguration: {
			type: Object,
			required: true,
		},
		selectionMode: String,		
		numberConfiguration: {
			type: Object,
			required: true
		},
		assetData: {
			type: Array,			
		},		
		readOnly: Boolean,
	});

	const searchForAreaCodes = () => {
		let areaCodeSearchResults	= [
			{			
				countryCode: 44,
				areaCode: 1582,				
			}
		]
		state.areaCodeSearchResults = areaCodeSearchResults;
	}

	const removeAreaCode = (event,index,numberSelected) => {
		if (!state.readOnly) {
			state.numberConfiguration.numberRequests.splice(index, 1);
			state.dataReady = false;	
		}
	}

	const selectAreaCode = (event,areaCodeResult) => {
		if (!state.readOnly) {
			var numberRequest = {
						quantity: 1,
						countryCode: areaCodeResult.countryCode,
						areaCode: areaCodeResult.areaCode,
						subscriberAddress: undefined,
					}
			state.numberConfiguration.numberRequests.push(numberRequest);
			state.dataReady = true;
			fireDataReadyEvent();
		}
	}

	const refreshState = (data) => {
		if (props.readOnly) {
				state.readOnly = props.readOnly;
		}
		if (props.selectionMode) {
			state.selectionMode = props.selectionMode;
		}
		
		if (props.numberConfiguration.numberRequests) {
			state.numberConfiguration.numberRequests = props.numberConfiguration.numberRequests;
			if (state.numberConfiguration.numberRequests.length > 0) {
				state.dataReady = true;
				fireDataReadyEvent();
			}
			
		} 	

		if (props.assetData) {
			console.log('Found Numbers');
			state.readOnly = true;
			state.numberAssets = props.assetData;
		}
			
	}

	onUpdated(() => {
		console.log("onUpdated Numbers Called")
		if (props.refreshConfiguration.version > state.refreshVersion) {
			state.refreshVersion = props.refreshConfiguration.version;
			console.log(props.refreshConfiguration.onboarding)
			if (props.refreshConfiguration.onboarding) {				
				state.onboarding = props.refreshConfiguration.onboarding;
			}			
			console.log("onUpdated Numbers")
			refreshState();
		}
	});

	onMounted(() => {
		refreshState();
	});

	
</script>

<style scoped>


</style>