<template>

	<div class="ma-5 w-100">
			<v-card class="card-shadow border-radius-md h-100">	
				<v-row  class="h-100">
					<v-col class="">
						<v-row>
							<v-col class="theme-dark-background-gradient ma-3">
								<v-row>
									<v-col class="d-flex mt-1" cols="12" sm="4">
										<v-avatar class="bg-white shadow border-radius-xl mt-1" size="32" color="account_circle"><v-icon class="bg-white  text-primary_100" size="22">person</v-icon></v-avatar>
										<p class="mt-1 ml-3 text-h5 text-white theme-font text-no-wrap">Your Home</p>
									</v-col>
									<v-col cols="12" sm="6" class="d-flex">
										<v-divider color="#ffffff" vertical="vertical" class="mr-2"></v-divider>
										<div>
											<div class="d-flex">											
												<v-icon  class="mr-3 text-white" size="20">person</v-icon>												
												<p class="text-white font-italic">{{ mainStore.loggedInStatus.loggedInUserDomains.user.firstName}} {{ mainStore.loggedInStatus.loggedInUserDomains.user.lastName}}</p>
											</div>
											<div class="d-flex mt-1"><v-icon  class="mr-3 text-white"  size="20">mail</v-icon>
												<p class="text-white font-italic">{{ mainStore.loggedInStatus.loggedInUserDomains.user.email}}</p>
											</div>
								</div>
									</v-col>									
									<v-col cols="12" sm="2" >
										<v-spacer  class="d-flex" align="right" >
											<v-btn class="theme-list-action-button-background text-action_button_text" @click="state.logout = true;" size="x-small">logout <v-icon class="ml-3" size="12">logout</v-icon></v-btn>
										</v-spacer>
										
									</v-col>
								</v-row>

							</v-col>
						</v-row>
						<v-row>
							<v-col class="d-flex ma-5 mt-1" cols="4">
								<v-tabs v-model="state.selectedTab">	
									<v-tab value="serviceList" :class="(state.selectedTab == 'serviceList') ? 'bg-active_tab_color':''">
										<p class="text-h6 theme-font">Services</p>
									</v-tab>					
									<v-tab value="account" :class="(state.selectedTab  == 'account') ? 'bg-active_tab_color':''">
										<p class="text-h6 theme-font">Account</p>
									</v-tab>									
								</v-tabs>										
							</v-col>						
						</v-row>			
						<v-row>
							<v-col class=" " cols="12">								
								<v-window v-model="state.selectedTab" class="fill-height bg-white w-100">
									<v-window-item value="serviceList" class="ma-5">
										<v-row>
										<v-col align="right">
											
											<v-btn class="pl-2 theme-list-action-button-background  text-action_button_text" @click="createNewService()" size="small"><v-icon class="theme-list-action-icon">add</v-icon>Service</v-btn>
											<v-btn class="ml-2 theme-list-action-button-background   text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
										</v-col>
									</v-row>
									<v-row>
										<v-col>
											<v-table class="listTable" density="compact">
												<thead class="theme-list-header-background">
													<tr>
														<th class="text-center text-h6  theme-listing-header-text">Account Holder</th>	
														<th class="text-center text-h6  theme-listing-header-text">Type</th>
														<th class="text-center text-h6  theme-listing-header-text">Service Account Name</th>	
														<th class="text-center text-h6  theme-listing-header-text d-none d-md-table-cell" >Your Roles</th>
														<th class="text-center text-h6  theme-listing-header-text">Status</th>
														<th class="text-center text-h6  theme-listing-header-text">Actions</th>						
													</tr>							
												</thead>
												<tbody>
													<tr v-for="domain in state.phoneServices"  :key="domain.phoneServiceUUID">									
														<td class="text-center">{{ domain.phoneServiceDetails.accountHolderName }}</td>		
														<td class="text-center">{{ t("statics.phoneService.phoneServiceType."+domain.phoneServiceType) }} </td>
														<td class="text-center">{{ domain.phoneServiceName }}</td>		
														<td class="text-center d-none d-md-table-cell "><span v-for="role in mainStore.getUserRolesForDomain(domain.phoneServiceName)" :key="role">{{ t("statics.roles."+role.replaceAll('.','_')) }}</span></td>	
														<td class="text-center">{{ t("statics.phoneService.status."+domain.status) }} </td>
														<td class="text-center"> 
															<v-menu location="center">
																<template v-slot:activator="{ props }">
																	<v-icon 	v-bind="props">menu</v-icon>
																</template>

																<v-list v-if="domain.status == 1">
																	<v-list-item>
																		<v-list-item-title class="theme-pointer" @click="openAssembler(event,domain)">Go To Account</v-list-item-title>
																	</v-list-item>
																</v-list>
																<v-list v-else-if="domain.status == 5">
																	<v-list-item>
																		<v-list-item-title class="theme-pointer" @click="loadServiceForm(domain.phoneServiceName)" >Edit Application</v-list-item-title>													
																	</v-list-item>
																	<v-list-item>
																		<v-list-item-title class="theme-pointer" @click="state.deleteDomain = domain;state.showDelete = true;">Delete Application</v-list-item-title>
																	</v-list-item>
																</v-list>
															</v-menu>
														</td>									
													</tr>								
												</tbody>

											</v-table>
										</v-col>
										</v-row>
									</v-window-item>
									<v-window-item value="account" class="ma-5">
										<v-row>
											<v-col cols="12" md="4">
												<AvatarComponent/>	
												<div class="ma-5"> </div>
												<AccountActions/>		
											</v-col>						
											<v-col cols="12" md="4">
												<UserCreditCardDisplay/>
											</v-col>					
											<v-col cols="12" md="4">
											</v-col>
										</v-row>
										
										
									</v-window-item>
								</v-window>							
							</v-col>						
						</v-row>
							
			
						
					</v-col>
				</v-row>
				
			</v-card>
		</div>
		<v-dialog v-model="state.showDelete">
			<v-row>
				<v-col md="4" sm="2">					
				</v-col>
				<v-col cols="12" sm="8" md="4">
					<v-card v-if="state.showDelete">
						<v-card-title class="theme-font">Delete New Service Application?</v-card-title>
						<v-card-text v-if="state.deleteDomain">
							<p>Are you sure you want to delete this application for a new service?</p>
							<p class="mt-5"><b>Account Holder Name:</b> {{ state.deleteDomain.phoneServiceDetails.accountHolderName }}</p>
						</v-card-text>
						<v-card-actions class="d-flex">
							<div class="" ><v-btn @click="deletePhoneServiceApplication(state.deleteDomain.phoneServiceName,state.deleteDomain.phoneServiceUUID);">Yes</v-btn></div><div align="right"><v-btn @click="closeDelete()">No</v-btn></div>
						</v-card-actions>
					</v-card>
				</v-col>
				<v-col md="4" sm="2">					
				</v-col>
			</v-row>

		</v-dialog>
		<LogoutDialog :logout="state.logout"  logoutPath="login"  @close="closeLogout()"/>
</template>

<script setup>
import { ref,reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '../../stores/MainStore.js';
import VerifyYourAccount from '../../components/VerifyYourAccount.vue';
import LogoutDialog from '../../components/LogoutDialog.vue';
import AvatarComponent from './components/AvatarComponent.vue';

import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import UserCreditCardDisplay from './cards/UserCreditCardDisplay.vue';
import AccountActions from './components/AccountActions.vue';

const { t }  = useI18n();

const mainStore = useMainStore();
const router = useRouter();
const route = useRoute();

const closeLogout = () => {
	state.logout = false;
}

const state = reactive({
	logout: false,
	phoneServices: undefined,
	selectedTab: 'serviceList',
	browserTabs: [],
	tabs: {
		selectedTab: undefined,
	},
	showDelete: false,
	deleteDomain: undefined,
});

const refreshList = () => {
		getServiceParticipation(true);
	}


const createNewService = () => {
	router.push({name: 'plustagNewServiceForm'});
}


const loadServiceForm = (serviceName) => {
			console.log(serviceName);
			router.push({name: 'plustagEditServiceForm', params:{phoneServiceName: serviceName,selectedStage: 1}});
		}


const getServiceParticipation = async (show) => {
	const apiUrl = mainStore.getAPIUrl('plustag');				
	await fetch(apiUrl+'/plustag/listServiceParticipation', {
		method: 'get',				
		credentials: 'include',
		redirect: 'follow',		
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)							
				if (d.outcome == 1) {			
					mainStore.displaySnackBar('Services Reloaded');									
					state.phoneServices = d.phoneServices;
				}							
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);
	});		
}


const closeDelete = () => {
			state.showDelete = false;	
			state.deleteDomain = undefined;
		
		}

		const deletePhoneServiceApplication = async (phoneServiceName,phoneServiceUUID) => {		
				const apiUrl = mainStore.getAPIUrl(phoneServiceName);			
				const data = new URLSearchParams();
				data.set('phoneServiceUUID', phoneServiceUUID);		
				await fetch(apiUrl+'/phoneServices/deletePhoneServiceApplication', {
					method: 'post',
					body: data,
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {
								mainStore.displaySnackBar('Application Deleted');									
								closeDelete();
								getServiceParticipation();																						
							} else {									
									console.log('Failed '+d.outcome);
									mainStore.displaySnackBar('Failed to Delete '+d.message);																										
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
		}


const openAssembler =  (event,domainDetails) => {
	var tab = null;
	state.browserTabs.forEach(
		(_tab) => {
			if ( _tab.name == domainDetails.phoneServiceName) {
				console.log('tab exists');
				tab = _tab;
			}
		}
	);
	if (tab == undefined) {
		const ref = Math.floor(Math.random() * 1000);
		tab = window.open('/#/assembler/'+domainDetails.phoneServiceName+"/account?ref="+ref,domainDetails.phoneServiceName);
		//tab = window.open('/#/assembler/'+domainDetails.phoneServiceName+"/account?",domainDetails.phoneServiceName);
		state.browserTabs.push(tab);
	} else {
		tab.focus();
	}
	return ;
};


onMounted(() => {
	getServiceParticipation();
	state.selectedTab = 'serviceList';
});

</script>

<style scoped>


</style>
