<template>
	<v-container fluid class="px-6 py-6 h-100">
			<v-row>
								<v-col class="d-flex ml-1">
									<v-card>
										<v-card-text class="pa-1">
											<p class="font-weight-bold">
												Show
											</p>
											<v-btn size="x-small" :class="(state.showMargins) ? 'theme-bg-highlight':''" class="mr-2 mt-1" @click="toggleMargins()">Margins</v-btn>												
										</v-card-text>
									</v-card>
									
									 <p v-if="state.selectedDestinations" class="theme-font text-body-1 mt-3 ml-2">{{state.selectedDestinations.length}} destinations selected</p>
								</v-col>							
								<v-col>
									<v-combobox
										density="compact"
										v-model="state.search.countries"
										:items="state.filters.countries"
										variant="outlined"
										label="Filter By Country"
										multiple
										chips
										closable-chips
										@update:modelValue="doFilter(this)"
									></v-combobox>
								</v-col>
							</v-row>			
				<v-data-table					
						v-model="state.selectedDestinations"									
								:items-per-page="state.dataTableConfig.itemsPerPage"
								:headers="state.dataTableConfig.headers"
								:items="state.data"
								:item-value="item => item.destinationCode"	
								item-key="destinationCode"							
								class="elevation-1"
								no-data-text="No Destinations"
								show-select								
							>						
								<template v-slot:headers="{ columns, isSorted, getSortIcon, toggleSort }">
									<tr class="theme-box-header-background">
										<template v-for="column in columns" :key="column.key">
											<td style="background-color:transparent" :class="'text-'+column.align">
												<p class="mr-2 cursor-pointer text-body-1 theme-listing-header-text" @click="() => toggleSort(column)">{{ column.title }} <span v-if="state.showMargins && column.key != 'd'" class="text-caption"><br/>Rate / Cost / Margin</span></p>
												<template v-if="isSorted(column)">
													<v-icon :icon="getSortIcon(column)"></v-icon>
												</template>
												<v-icon v-if="column.removable" icon="$close" @click="() => remove(column.key)"></v-icon>
											</td>
										</template>
									</tr>
								</template>	
								<template v-slot:item.d="{ item }">								
									<p v-if="item.selectable.label">{{ item.selectable.label }}</p><p v-else>{{ item.selectable.d }}</p>
								</template>
								<template v-slot:item.Rp="{ item }">								
									<p :class="(item.selectable.country == 'AZ') ? 'text-warning':''">{{ item.selectable.Rp }}p <span v-if="state.showMargins">/ {{ item.selectable.p }}p</span> <span v-if="state.showMargins">/ {{ calculateMargin(item.selectable.p,item.selectable.Rp) }}%</span></p>									
								</template>	
								<template v-slot:item.Rop="{ item }">								
									<p :class="(item.selectable.country == 'AZ') ? 'text-warning':''">{{ item.selectable.Rop }}p <span v-if="state.showMargins">/ {{ item.selectable.op }}p</span> <span v-if="state.showMargins">/ {{ calculateMargin(item.selectable.op,item.selectable.Rop) }}%</span></p>
								</template>	
								<template v-slot:item.Rw="{ item }">								
									<p :class="(item.selectable.country == 'AZ') ? 'text-warning':''">{{ item.selectable.Rw }}p <span v-if="state.showMargins">/ {{ item.selectable.w }}p</span> <span v-if="state.showMargins">/ {{ calculateMargin(item.selectable.w,item.selectable.Rw) }}%</span></p>
								</template>															
							</v-data-table>
							<v-btn @click="selectDestinations">Select</v-btn>
			</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import { VDataTable } from 'vuetify/labs/VDataTable';

	import * as fullDestinations from "./full-destinations.json";

	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const emits = defineEmits(['dataEvents','close']);

	const fireDataReadyEvent = (event) => {				
		emits('dataEvents',
			{
				state: 'selectedDestinations',
				destinations: state.selectedDestinations,
			}
		);
	
	}

	const rateHeaders =  [
			{
				title: 'Destination',
				align: 'start',
				sortable: false,
				key: 'd',
			},		
			{ title: 'Peak', align: 'center', key: 'Rp' },
			{ title: 'Off Peak', align: 'center', key: 'Rop' },
			{ title: 'Weekend', align: 'center', key: 'Rw' },
		];

		const marginHeaders =  [
		{
				title: 'Destination',
				align: 'start',
				sortable: false,
				key: 'd',
			},		
			{ title: 'Peak', align: 'center', key: 'Rp' },
			{ title: 'Off Peak', align: 'center', key: 'Rop' },
			{ title: 'Weekend', align: 'center', key: 'Rw' },	
		];


	const state = reactive({
		warnings: false,
		showMargins: false,
		selectedDestinations: [],
		search: {
			countries: undefined,
			countries2: undefined,
		},
		data: undefined,
		filters: {
			countries: [
				{value: 'AT' , code:"43", title:'Austria +43'},
				{value: 'AZ' , code:"994", title:'Azerbaijan +994'},
				{value: 'US' , code:"1", title:'USA +1'},
				{value: 'GB' , code:"44", title:'United Kingdom +44'},
				{value: 'FR' , code:"33", title:'France +33'},
			]
		},
		dataTableConfig : {
			headers: rateHeaders,
			page: 1,
			pageCount: 10,
			itemsPerPage: 10,
		},		
	});

	const updateModelValue = (a) => {
		console.log(a);
	}

const selectDestinations = (event) => {
	fireDataReadyEvent(event);
	emits('close');
}

	const toggleMargins = () => {
		state.showMargins = !state.showMargins;
	}

const calculateMargin = (cost,rate) => {
		return Math.round( ((rate/cost)*100) -100 );
	}

	const processRates = () => {
		const markup = 1.3;
		const lowMarkup = 1.1;
		for (let i = 0; i < state.data.length;i++) {			
			if (state.data[i].country == 'AZ') {
				state.data[i].Rp = Math.ceil(100*state.data[i].p*lowMarkup)/100;
				state.data[i].Rop =Math.ceil(100* state.data[i].op*lowMarkup)/100;
				state.data[i].Rw = Math.ceil(100*state.data[i].w*lowMarkup)/100;
			} else {
				state.data[i].Rp = Math.ceil(100*state.data[i].p*markup)/100;
				state.data[i].Rop =Math.ceil(100* state.data[i].op*markup)/100;
				state.data[i].Rw = Math.ceil(100*state.data[i].w*markup)/100;
			}		
		}
	}

	const doFilter = (event) => {
		if (state.warnings) {
			const newData = [];
			for (let i = 0; i < fullDestinations.rates.length;i++) {
			if (fullDestinations.rates[i].country == 'AZ') {
						newData.push(fullDestinations.rates[i]);
				}
			}						
			state.data = newData;
		} else if (state.search.countries && state.search.countries.length > 0) {
			const newData = [];
			for (let i = 0; i < fullDestinations.rates.length;i++) {
				if (fullDestinations.rates[i].country) {
					for (let j = 0; j < state.search.countries.length; j++) {						
						if (fullDestinations.rates[i].country == state.search.countries[j].value||fullDestinations.rates[i].code == state.search.countries[j].value) {
							newData.push(fullDestinations.rates[i]);
						}
					}
				}			
			}
			state.data = newData;
		} else  {
			state.data = fullDestinations.rates;
		}
		processRates();
	}

	onUpdated(() => {	
		
	});


	onMounted(() => {		
		state.data = fullDestinations.rates;
		processRates();		
	});
</script>

<style>

</style>