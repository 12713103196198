<template>
	<v-container>
		<v-row v-if="headerText">
			<v-col>
				<BoxHeader :headerText="headerText"/>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-spacer align="right">
					<v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text" @click="addCard()"><v-icon class="theme-list-action-icon">add</v-icon>Card</v-btn>
				</v-spacer>				
			</v-col>	
		</v-row>
		<v-row>
			<v-col>
				<v-row class="" v-if="state.paymentCardMethods && state.paymentCardMethods.length > 0">
					<v-col></v-col>
					<v-col class="d-sm-none"></v-col>
					<v-col class="theme-font font-weight-bold text-uppercase text-body-2">Name</v-col>
					<v-col class="theme-font font-weight-bold text-uppercase text-body-2">Number</v-col>
					<v-col class="theme-font font-weight-bold text-uppercase text-body-2">Expiry</v-col>		
					<v-col></v-col>			
				</v-row>
				<v-row v-for="paymentCard in state.paymentCardMethods"  :key="paymentCard.primaryUUID">					
					<v-col class="text-no-wrap"><v-img :src="getCardIcon(paymentCard.cardBrand)" min-width="40" max-width="40" ></v-img></v-col>
					<v-col  class="d-sm-none">{{ t( 'statics.cardTypes.'+paymentCard.cardFunding) }}</v-col>
					<v-col>{{ paymentCard.cardName }}</v-col>
					<v-col>**** {{ paymentCard.cardNumberLastFour }}</v-col>
					<v-col>{{ paymentCard.cardExpiryMonth }}/{{ paymentCard.cardExpiryYear }}</v-col>
					<v-col><v-icon @click="removeCardFromService(paymentCard.primaryUUID)">delete</v-icon></v-col>
				</v-row>
			</v-col>	
		</v-row>		
	</v-container>	
	
	<CreditCardSelectionDialog :userPaymentCardMethods="state.userPaymentCardMethods" :paymentCardMethods="state.paymentCardMethods" :show="state.showSelection"  :phoneService="phoneService" @close="closeDialog()" @selectedCard="selectedCard()" @startPolling="startPolling()"/>
	
	
</template>

<script setup>
import { defineProps,reactive,onMounted } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import amex from '@/assets/images/Payment_Methods/Amex/48.png';
import applePay from '@/assets/images/Payment_Methods/ApplePay/48.png';
import dinersClub from '@/assets/images/Payment_Methods/DinersClub/48.png';
import discover from '@/assets/images/Payment_Methods/Discover/48.png';
import googlePay from '@/assets/images/Payment_Methods/GooglePay/48.png';
import maestro from '@/assets/images/Payment_Methods/Maestro/48.png';
import mastercard from '@/assets/images/Payment_Methods/Mastercard/48.png';
import payPal from '@/assets/images/Payment_Methods/PayPal/48.png';
import stripe from '@/assets/images/Payment_Methods/Stripe/48.png';
import unionPay from '@/assets/images/Payment_Methods/UnionPay/48.png';
import verifone from '@/assets/images/Payment_Methods/Verifone/48.png';
import visa from '@/assets/images/Payment_Methods/Visa/48.png';
import CreditCardSelectionDialog from './CreditCardSelectionDialog.vue';
import BoxHeader from '@/components/BoxHeader.vue';



const { t }  = useI18n();
const mainStore = useMainStore();

const MODE_SELECTION = 'selection';
const MODE_list = 'list';

const LIST_USER = "user";
const LIST_SERVICE = "service";

const state = reactive({		
	paymentCardMethods: [],
	userPaymentCardMethods: [],
	showSelection: false,
	polling: undefined,
	pollingNow : false,
	headerText : undefined,
});

const getCardIcon = (cardType) => {
		let icon = undefined;
		switch (cardType) {
			case 'visa':
				icon = visa;
				break;
			case 'visa_debit':
				icon = visa;
				break;
			case 'amex':
				icon = amex;
				break;
			case 'amex2':
				icon = amex;
				break;
			case 'diners':
				icon = dinersClub;				
				break;
			case 'diners_14digits':
				icon = dinersClub;				
				break;
			case 'discover':
				icon = discover;				
				break;
			case 'discover2':
				icon = discover;				
				break;
			case 'mastercard_debit':
				icon = mastercard;				
				break;
			case 'mastercard_debit':
				icon = mastercard;				
				break;
			case 'mastercard_prepaid':
				icon = mastercard;				
				break;
			case 'unionpay':
				icon = unionPay;				
				break;
			case 'paypal':
				icon = payPal;				
				break;
			case 'googlepay':
				icon = googlePay;				
				break;
			case 'applepay':
				icon = applePay;				
				break;
			case 'verifone':
				icon = verifone;				
				break;
			case 'stripe':
				icon = stripe;				
				break;
			case 'maestro':
				icon = maestro;				
				break;
			default:
				icon = visa;
		}
		return icon;
	}

const closeDialog = () => {
	state.showSelection = false;
	stopPolling();
}

const selectedCard = () => {
	state.showSelection = false;
	listServicePaymentCards();
	listUserPaymentCards();
}

const startPolling = () => {
	state.polling = setInterval(async () => {
		if (!state.pollingNow) {							
				listUserPaymentCards();						
		}			
	}, 3000);
};

const stopPolling = () => {
	if (state.polling) {
		clearInterval(state.polling);
		state.polling = undefined;
	}
}


onMounted(() => {
	listServicePaymentCards();
	listUserPaymentCards();
});



const removeCardFromService = async (stripePaymentMethodUUID)  => {
	console.log('listing cards for '+props.phoneService.phoneServiceName);
		const apiUrl = mainStore.getAPIUrl(props.phoneService.phoneServiceName);	
		const data = new URLSearchParams();
		data.set('stripePaymentCardMethodUUID', stripePaymentMethodUUID);			
		await fetch(apiUrl+'/serviceBilling/removeCardFromService', {
			method: 'post',			
			body: data,	
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {						
						listServicePaymentCards();
					}							
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});	
}


const addCard = () => {

		state.showSelection = true;

}

const props = defineProps({
		creditCardConfig: {
			type: Object,
			required: true
		},
		phoneService: {
			type: Object,
			required: true
		},
		headerText: String,
	});

	const listServicePaymentCards = async () => {
		console.log('listing cards for '+props.phoneService.phoneServiceName);
		const apiUrl = mainStore.getAPIUrl(props.phoneService.phoneServiceName);				
		await fetch(apiUrl+'/serviceBilling/listServiceCardPaymentMethods', {
			method: 'get',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {			
						if (d.stripeCardPaymentMethods) {
							state.paymentCardMethods = d.stripeCardPaymentMethods;
						}	else {
							state.paymentCardMethods = [];
						}		
						
					}							
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});	
	}

	const listUserPaymentCards = async () => {
		const apiUrl = mainStore.getAPIUrl('plustag');	
		state.pollingNow = true;			
		await fetch(apiUrl+'/serviceBilling/listUserCardPaymentMethods', {
			method: 'get',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d);						
					if (d.outcome == 1) {				
						if (d.stripeCardPaymentMethods) {
							state.userPaymentCardMethods = d.stripeCardPaymentMethods;
						}	else {
							state.userPaymentCardMethods = [];
						}	
					}							
					state.pollingNow = false;
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
			state.pollingNow = false;
		});	
	}
	
	const createCheckoutSession = async () => {
		console.log('creating checkout for '+props.phoneService.phoneServiceName);
		const apiUrl = mainStore.getAPIUrl(props.phoneService.phoneServiceName);	
		const popup = openCardWindow();			
		await fetch(apiUrl+'/serviceBilling/createCheckoutSession', {
			method: 'get',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == -3) {
					} else {
						popup.location = d.url;
					}							
				}									
			)
			return response;
		}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);

		});		
		}


	

		
		



</script>