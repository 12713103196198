<script setup>
    import { inject } from 'vue';
    import {useMainStore} from '../../stores/MainStore.js';
		import { useI18n } from 'vue-i18n';


		const { t } = useI18n();
    const mainStore = useMainStore();

    const props = defineProps({
        menuItem: {
            type: Object,
            required: true
        },
        subMenuItem: {
            type: Object,
            required: true
        }
    });

    const railView = inject("railView");

</script>

<template>
<v-defaults-provider
        :defaults="{
            VTooltip: {}
        }"
    >
     <v-tooltip bottom>
        <template v-slot:activator="{props}">
            <router-link :to="{name: subMenuItem.routeName , params:{phoneServiceName: mainStore.phoneServiceName}}">
                <div :class="(railView) ? 'subLevelItem subLevelItem_compact' : 'subLevelItem'" v-bind="props">
                    <v-icon small :color="(mainStore.selectedMainMenuNode[0] == menuItem.name && mainStore.selectedMainMenuNode[1] == subMenuItem.name) ? 'green' : 'white'" :class="(railView == true) ? 'menuIcon_compactView' : 'menuIcon_fullView'">{{ subMenuItem.icon }}</v-icon>
                    <div v-if="railView == false" :class="(mainStore.selectedMainMenuNode[0] == menuItem.name && mainStore.selectedMainMenuNode[1] == subMenuItem.name) ?  'menulabel text-green' : 'menulabel'">{{ t("sectionHeadings." + subMenuItem.label) }}</div>
                </div>
            </router-link>
        </template>
        <span>
            <v-icon small color="white" class="tooltipIcon">{{ subMenuItem.icon }}</v-icon>
            {{ t("sectionHeadings." + subMenuItem.label) }}
        </span>
    </v-tooltip>
    </v-defaults-provider>
</template>

<style scoped>
    .subLevelItem {
        height: 40px;
        display: flex;
        align-items: center;
    }
    .subLevelItem_compact {
        width: 100%;
        justify-content: center;
    }

    .menuIcon_fullView {
        margin-left: 20px;
        margin-right: 10px;
        font-size: 1.1em;
        cursor: pointer;
    }
		
    .menuIcon_compactView {
        margin: 0;
        font-size: 1.1em;
        cursor: pointer;
    }

    .menulabel {
        color: white;
        font-size: 1em;
        cursor: pointer;
    }

    .tooltipIcon {
        margin-right: 10px;
        font-size: 1.1em;
    }
</style>