import Quota from '@/microServices/quotas';

const configuration =  {
	store: undefined,
}


const  fetchServiceDetails = async () => {
	const apiUrl = configuration.store.getAPIUrl();			
				await fetch(apiUrl+'/phoneServices/loadPhoneServiceDetails', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {							
								configuration.store.initialiserCheckList.fetchServiceDetails = true;
								configuration.store.phoneServiceDetails = d.phoneServiceDetails;
								configuration.store.pollingForLogin = false;
								configuration.store.isAppLoaded();
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});
}

const loadAppsAndRoles = async () => {
	if (configuration.store.phoneServiceName != 'plustag') {
		const apiUrl = configuration.store.getAPIUrl();			
			await fetch(apiUrl+'/phoneServices/listAppsAndRoles', {
				method: 'get',				
				credentials: 'include',
				redirect: 'follow',		
			}).then((response) => {
				response.json().then( 
					d => {
						console.log(d)							
						if (d.outcome == 1) {
							configuration.store.phoneServiceApps = d.phoneServiceApps;
						}	
					}									
				)
				return response;
			}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});					
		}
}

const fetchQuotas = () => {
	Quota.initialiseStore(configuration.store);
	Quota.refreshQuotas();
}


export default {
	useStore: (store) => {
		configuration.store = store;
		
	},
	initialise: (callback) => {		
		fetchQuotas();
		fetchServiceDetails(callback);
		loadAppsAndRoles();
	}
};