<template>
	<v-container>
		<v-row>
			<v-col class="mt-5">
				<div class="text-h5">Accept the Terms and Conditions</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="mt-5">
				<v-checkbox v-model="termsAndConditions.acceptedTerms" :rules="[state.rules.required]" color="deep-purple">
					<template v-slot:label>
						I agree to the&nbsp;
						<a href="#" @click.stop.prevent="dialog = true">Terms of Service</a>
						&nbsp;and&nbsp;
						<a href="#" @click.stop.prevent="dialog = true">Privacy Policy</a>*
					</template>
				</v-checkbox>
			</v-col>
		</v-row>
	</v-container>
</template>
	


<script setup>
import { defineProps,reactive } from 'vue';

	const state = reactive({		
		rules: {
        required: v => !!v || 'This field is required',
      },
	});

const props = defineProps({
		termsAndConditions: {
            type: Object,
            required: true
        }
    });

	
</script>