<template>
	<v-container>
		<v-row>
			<v-col class="ma-5">
				<v-card>
					<BoxHeader headerText="Pricing Query"/>
					<v-card-text>	
						<v-row>
							<v-col>
								<v-row>
									<v-col class="d-flex ml-5" @click="showDatePicker">
										<p class="theme-font text-h5 mr-5">Date</p> <v-icon class="mt-1">calendar_month</v-icon> <p class="ml-2 mr-2 theme-font-other text-h5" v-if="state.pricingQuery.startDate">{{formatDate()}}</p><p v-else>Select Date</p> 
									</v-col>											
								</v-row>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<v-row>
									<v-col class="d-flex ml-5">
										<p class="theme-font text-h5 mr-5">Time</p> <p class="mr-2 theme-font-other text-h5">{{state.pricingQuery.startTime}}</p>
									</v-col>											
								</v-row>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="6" class="d-flex ml-5">
									<v-text-field label="Duration" variant="outlined" v-model="state.pricingQuery.duration"/> <p class="ml-4 theme-font text-h6 mt-3">Seconds</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
									<v-text-field label="Number Dialled" variant="outlined" v-model="state.pricingQuery.numberDialled"  @focusin="checkForm(this)" @focusout="checkForm(this)" @input="checkForm(this)" @keydown.enter="priceCall"/> 
							</v-col>
						</v-row>
						<v-row>
							<v-col align="right" class="ml-5 mr-5">
									<v-btn size="small"  :disabled="(!state.dataReady)" :class="(state.dataReady) ? 'theme-bg-highlight':''" @click="priceCall">Price Call</v-btn>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="mt-6" v-if="state.pricingQuery.rate">
								<p>Rate</p>: <p>{{ state.pricingQuery.rate }}</p>
								<p>Price</p>: <p>{{ state.pricingQuery.price }}</p>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col class="ma-5">
				<v-row v-if="state.rate">
					<v-col>
						<p class="theme-font text-h5"> Results</p>
						<p class="theme-font-other text-body-1">UK Mobile 3</p>
						<p class="theme-font-other text-body-1">7ppm</p>
						<p class="theme-font-other text-body-1">Price: {{ Math.round(10*7 * (state.pricingQuery.duration/60))/10 }} pence</p>
					</v-col>
				</v-row>
				<p></p>
			</v-col>
		</v-row>
	
</v-container>
<v-dialog v-model="state.showDatePicker">
	<v-row>
		<v-col md="4" sm="2">					
		</v-col>
		<v-col cols="12" sm="8" md="4">
		<v-date-picker elevation="24" :multiple="(false)" v-model="state.pricingQuery.startDate" @click:save="closeDatePicker"></v-date-picker>
	</v-col>
		<v-col md="4" sm="2">					
		</v-col>
	</v-row>
</v-dialog>
	<!--<v-row>
		<v-col>
				<v-select
				:items="state.values.tariffSelect"				
				label="Select Tariff"
				variant="outlined"
				item-title="title"
				item-value="value"																		
				v-model="state.pricingQuery.tariffUUID"
		></v-select>
		</v-col>
	</v-row>-->

</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import BoxHeader from '@/components/BoxHeader.vue';
	import { VDatePicker } from 'vuetify/labs/VDatePicker';
	import { useDate } from 'vuetify/labs/date'

	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();
	
	const state = reactive({
		dataReady: false,
		showDatePicker:  false,
		rate: undefined,
		price: undefined,
		pricingQuery: {
			tariffUUID: undefined,
			startDate: undefined,				
			startTime: '00:00',
			duration: 60,
			numberDialled: undefined,
			rate: undefined,
			price: undefined,
		},
	});

	const checkForm = (event) => {
		if (state.pricingQuery.numberDialled) {
			if (state.pricingQuery.numberDialled.match("^\\+?[1-9]\\d{1,14}$")) {
				state.dataReady = true;
				return true;
			}
		}
		state.dataReady = false;
	}

	const priceCall = () => {
		mainStore.displaySnackBar('Pricing Call');
		state.rate = '12ppm';
		state.price = '&pound; 1.47';
	}

	const showDatePicker = () => {
		state.showDatePicker = true;
	}

	const formatDate = () => {		
		const date = useDate();
		return date.format(new Date(state.pricingQuery.startDate),'fullDateWithWeekday');
	}


	const closeDatePicker = () => {
		state.showDatePicker = false;
	}

	onMounted(() => {		
		const date = useDate()

		state.pricingQuery.startDate = [];
	});


</script>

<style>
</style>