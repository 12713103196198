<template>

<div class="theme-mainPanel fill-height" >					
			<v-row class="mb-5 fill-height" >
				<v-col md="2" ></v-col>
				<v-col md="8" class="fill-height d-flex align-center justify-center" >
					<v-container v-if="!state.completed">
						<v-row class="mb-10">
							<v-col>
						<v-card class="card-floater card-shadow border-radius-md loadingBox" >
							<div class="px-2 pt-4 pb-0 d-flex">
							<v-avatar
									color="success border-radius-xl mt-n8"
									class="shadow"
									size="48"
								>
									<v-icon class="text-white" size="30">mail</v-icon
									>
								</v-avatar>	
								<p class="theme-font text-h4 ml-5" v-if="state.verificationOutcome != 909">You have an Invite to Join a Service</p>								
								<p class="theme-font text-h4 ml-5" v-else>Invite Expired</p>														
							</div>
						<v-card-text class="align-center" v-if="state.verified && state.phoneServiceUserParticipationInvite">
							<div class="vx-green-lighter-background">
								<p class="text-body-1 theme-font-other ma-2"><b>To:</b> {{ state.phoneServiceUserParticipationInvite.toFirstName }} {{ state.phoneServiceUserParticipationInvite.toLastName }} ({{ state.phoneServiceUserParticipationInvite.toEmailAddress }}) </p>							
								<p class="text-body-1 theme-font-other ma-2"><b>From:</b> {{ state.phoneServiceUserParticipationInvite.fromFirstName }} {{ state.phoneServiceUserParticipationInvite.fromLastName }}  ({{ state.phoneServiceUserParticipationInvite.fromEmailAddress }})  on behalf of {{ state.phoneServiceUserParticipationInvite.fromServiceName}}</p>
							</div>
							<p class="text-body-1 theme-font-other ma-2">To join <b>{{ state.phoneServiceUserParticipationInvite.fromServiceName}}</b> as:-</p>
							<div v-for="grant in state.phoneServiceUserParticipationInvite.phoneServiceUserGrants" :key="grant.primaryUUID">
								<p class="text-body-1 theme-font-other ml-5">{{ t("statics.roles."+grant.roleName.replaceAll('.','_')) }}</p>
							</div>	
							
							<div v-if="state.phoneServiceUserParticipationInvite.extraMessageToUser">
								<p class="text-body-1 theme-font-other ma-2">They included the following message: -</p>	
								<p class="text-body-1 theme-font-other ml-5 ma-2 mr-5"><i>{{ state.phoneServiceUserParticipationInvite.extraMessageToUser }}</i></p>
							</div>
						
							

							<div v-if="state.processType == 'userLoggedIn' && state.loggedInStatus">	
								<v-divider class="mt-5 mb-5"></v-divider>
								<div v-if="state.isInTargetAlready">
									<v-row>
										<v-col>
											<p class="text-body-1 theme-font-other ma-2">You are already a User in the service they are inviting you to.</p>
											<p class="text-body-1 theme-font-other ma-2">If this was a mistake or you are sharing browsers, you need to logout of this account to continue. </p>
										</v-col>
								</v-row>							
								<v-row>
										<v-col align="center">
											<v-btn :class="(state.displayUserForm ) ? 'theme-bg-highlight': ''" @click="state.logout = true;">LOGOUT TO CONTINUE</v-btn>
										</v-col>
								</v-row>
								</div>		
								<div v-else>
									<p class="text-body-1 theme-font-other ma-2">You are logged in as <b>{{ state.loggedInStatus.loggedInUserDomains.user.email  }}</b></p>
									<p class="text-body-1 theme-font-other ma-2">Add the <b>{{ state.phoneServiceUserParticipationInvite.fromServiceName}}</b> you service list on this account.</p>
									<v-container>

											<v-row>
													<v-col align="center"><v-btn :class="(!state.displayUserForm) ? 'theme-bg-highlight': ''" @click="state.showLinkInviteDialog = true;">LINK TO THIS ACCOUNT</v-btn></v-col>
											</v-row>
											<v-row class="">
													<v-col class="d-flex align-center justify-center"><v-divider class="mr-5 mt-5 mb-5"></v-divider> OR <v-divider class="ml-5 mt-5 mb-5"></v-divider></v-col>
											</v-row>										
											<v-row class="mt-5">
													<v-col align="center"><v-btn :class="(state.displayUserForm) ? 'theme-bg-highlight': ''" @click="state.logout = true;">Link to another Account</v-btn>	<p class="align-center theme-font-other  text-caption">You will be logged out to first.</p></v-col>
											</v-row>
											<v-row class="">
													<v-col class="d-flex align-center justify-center"><v-divider class="mr-5 mt-5 mb-5"></v-divider> OR <v-divider class="ml-5 mt-5 mb-5"></v-divider></v-col>
											</v-row>
											<v-row class="">
													<v-col>
														<p class="text-body-1 theme-font-other ma-2">Create a new account for {{ state.phoneServiceUserParticipationInvite.toEmailAddress }}.</p>												
													</v-col>
											</v-row>
											<v-row class="">
													<v-col align="center"><v-btn :class="(state.displayUserForm) ? 'theme-bg-highlight': ''" @click="state.logout = true;">CREATE NEW ACCOUNT</v-btn>	<p class="align-center theme-font-other  text-caption">You will be logged out to first.</p></v-col>
											</v-row>
									</v-container>			
								</div>
		
							</div>
							<div v-else>
								<v-divider class="mt-5"></v-divider>
								<v-container>
									<v-row>
												<v-col>
													<p class="text-body-1 theme-font-other ma-2">You can link this to an existing login you have with Voxhub Wholesale.</p>
												</v-col>
										</v-row>
									<v-row>
												<v-col align="center"><v-btn  @click="goToLogin(true);" >LINK TO AN EXISTING LOGIN</v-btn><p class="align-center theme-font-other text-caption">You will be asked to login first.</p></v-col>
										</v-row>
										<v-row class="">
												<v-col class="d-flex align-center justify-center"><v-divider class="mr-5 mt-5 mb-5"></v-divider> OR <v-divider class="ml-5 mt-5 mb-5"></v-divider></v-col>
										</v-row>
										<v-row class="">
												<v-col><p class="text-body-1 theme-font-other ma-2">Create a new account for the <b>{{ state.phoneServiceUserParticipationInvite.toEmailAddress }}</b> address.</p></v-col>
										</v-row>
										<v-row class="">
												<v-col align="center"><v-btn :class="(state.displayUserForm || state.processType == 'newUser') ? 'theme-bg-highlight': ''" @click="displayNewUserForm()">CREATE NEW ACCOUNT</v-btn></v-col>
										</v-row>
										<v-row class="">
														<v-col class="d-flex align-center justify-center"><v-divider class="mr-5 mt-5 mb-5"></v-divider> OR <v-divider class="ml-5 mt-5 mb-5"></v-divider></v-col>
												</v-row>
												<v-row class="">
													<v-col>
															<p class="text-body-1 theme-font-other ma-2"><v-textarea
														name="rejectionMessage"
														label="Reason For Rejection"
														v-model="state.rejectionMessage"
														hint="Leave this emtpy if you don't wish to give a reason!"
													></v-textarea></p>												
														</v-col>											
												</v-row>
												<v-row class="">
														<v-col align="center"><v-btn  @click="rejectInvitation()">REJECT INVITE</v-btn></v-col>
												</v-row>
								</v-container>
							</div>	
						</v-card-text>
						<v-form @keyup.enter="createNewAccount()">
						<v-card-text class="align-center ml-5" v-if="state.displayUserForm ">
							<v-row>
								<v-col>
									<p class="theme-font text-h5">Create a New Account</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="pb-0">
									<v-row >
										<v-col>
											<v-text-field id="firstName" variant="outlined" name="firstName" label="First Name*" type="text" v-model="state.userForm.firstName"  @focusin="isValidNewUserForm(this)" @focusout="isValidNewUserForm(this)" @input="isValidNewUserForm(this)" ></v-text-field>
										</v-col>
										<v-col>
											<v-text-field id="lastName" variant="outlined" name="lastName" label="Last Name*" type="text"  v-model="state.userForm.lastName"  @focusin="isValidNewUserForm(this)" @focusout="isValidNewUserForm(this)" @input="isValidNewUserForm(this)" ></v-text-field>
										</v-col>
									</v-row>									
								</v-col>
							</v-row>
							<v-row>
								<v-col class="pt-0">
									<v-text-field id="emailAddress" variant="outlined" label="Email Address*" readonly hide-details="auto" autocomplete="username" v-model="state.userForm.emailAddress"  @focusin="isValidNewUserForm(this)" @focusout="isValidNewUserForm(this)" @input="isValidNewUserForm(this)" ></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field id="password" variant="outlined" name="password" label="Password*" type="password" autocomplete="current-password"  v-model="state.userForm.password"></v-text-field>
									<PasswordBar :password="state.userForm.password" @dataEvents="passwordReady"/>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-text-field id="passwordCheck" variant="outlined" name="passwordCheck" label="Confirm Password*" type="password" autocomplete="current-password"  v-model="state.userForm.passwordCheck"   @focusin="isValidNewUserForm(this)" @focusout="isValidNewUserForm(this)" @input="isValidNewUserForm(this)" ></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="text-center">
									<v-btn @click="createNewAccount()" :disabled="!state.newUserDataReady" :class="(state.newUserDataReady) ? 'theme-bg-highlight' :''">Create New Account</v-btn>
								</v-col>
							</v-row>

						</v-card-text>
					</v-form>
						<v-card-text v-if="state.verificationOutcome == 909">

								<p class="text-body-1 theme-font-other ma-2">This Invite has Expired</p>
									<p class="text-body-1 theme-font-other ma-2">It was sent by {{ state.phoneServiceUserParticipationInvite.fromFirstName }} {{ state.phoneServiceUserParticipationInvite.fromLastName }} asking you to join the service operated by <b>{{ state.phoneServiceUserParticipationInvite.fromServiceName}}</b> using your <b>{{ state.phoneServiceUserParticipationInvite.toEmailAddress }}</b> address.</p>
									<p class="text-body-1 theme-font-other ma-2">Please contact them to request that the re-send this invite.</p>

						</v-card-text>
						<v-card-text v-if="state.verificationOutcome == 919">
							<v-row>
										<v-col>
											<p class="text-body-1 theme-font-other ma-2">You sent this invite! You cannot invite yourself to a service.</p>
											<p class="text-body-1 theme-font-other ma-2">If this was a mistake or you are sharing browsers, you need to logout of this account to continue. </p>
										</v-col>
								</v-row>							
								<v-row>
										<v-col align="center">
											<v-btn :class="(state.displayUserForm ) ? 'theme-bg-highlight': ''" @click="state.logout = true;">LOGOUT TO CONTINUE</v-btn>
										</v-col>
								</v-row>
							</v-card-text>
							<v-card-text v-if="state.verificationOutcome == -1">
								<v-row>
										<v-col>
											<p class="text-body-1 theme-font-other ma-2">An Error has Occured.</p>											
										</v-col>
								</v-row>		
							</v-card-text>
							<v-card-text  class="d-flex align-center ml-5" v-else-if="!state.verificationOutcome">
									<v-progress-circular
										indeterminate
										color="#A4B122"							
									></v-progress-circular><p class="text-h4 theme-font ml-10">Loading</p>
								</v-card-text>							
					</v-card>
					</v-col>
				</v-row>			
			</v-container>
			<v-container v-else>
				<v-row class="mb-10">
					<v-col>
						<v-card class="card-floater card-shadow border-radius-md loadingBox" >
							<div class="px-2 pt-4 pb-0 d-flex">
							<v-avatar
									color="success border-radius-xl mt-n8"
									class="shadow"
									size="48"
								>
									<v-icon class="text-white" size="32">mail</v-icon
									>
								</v-avatar>	
								<p class="theme-font text-h4 ml-5" v-if="state.inviteAccepted">Invite Accepted</p>	
								<p class="theme-font text-h4 ml-5" v-if="state.inviteRejected">Invite Rejected</p>	
							</div>
							<v-card-text class="align-center">
								<div v-if="state.inviteRejected">
									<p>You have rejected this invite to join the {{ state.phoneServiceUserParticipationInvite.fromServiceName}} service.</p>
								</div>
								<div v-if="state.inviteAccepted">
									<p>You have accepted this invite to join the {{ state.phoneServiceUserParticipationInvite.fromServiceName}} service.</p>
								</div>								
								<v-row>
								<v-col class="text-center mt-5">
									<v-btn @click="quit(this)">Go To Login</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
			</v-col>
			<v-col md="2" ></v-col>
		</v-row>		
			
	</div>
	
		<LogoutDialog :logout="state.logout" @close="closeLogout()" @logout="loggedOut()"/>
		<v-dialog v-model="state.showLinkInviteDialog" max-width="500px">
			<v-card>
				<v-card-text>				
					<v-row>
						<v-col class="ma-5"><v-img :src="servedUpLogo" min-width="150" ></v-img></v-col>
					</v-row>				
					<v-row>
						<v-col class=""><p class="text-h5 theme-font">Logout of Voxhub?</p></v-col>
					</v-row>
					<v-row>
						<v-col class=""><p class="text-body-2 theme-font-other">This will log you out of all the Voxhub windows you are currently logged into.</p></v-col>
					</v-row>
					<v-row>
						<v-col class="text-center mt-5">
							<v-btn @click="doLogoutRequest(this)">LOGOUT OF VOXHUB</v-btn>
							</v-col>
					</v-row>
					<v-row>
						<v-col class="text-center">
							<p class="mt-5 text-caption text-decoration-underline bg-white theme-pointer" @click="close(this)">Close</p>
							</v-col>
					</v-row>
				</v-card-text>
				<v-card-actions>
					
				</v-card-actions>			
			</v-card>
		</v-dialog>
		<v-dialog v-model="state.showAccountCreatedDialog" max-width="500px">
			<v-card>
				<v-card-text>				
					<v-row>
						<v-col class="ma-5"><v-img :src="servedUpLogo" min-width="150" ></v-img></v-col>
					</v-row>				
					<v-row>
						<v-col class=""><p class="text-h5 theme-font">Your Acccount Has Been Created</p></v-col>
					</v-row>
					<v-row>
						<v-col class=""><p class="text-body-2 theme-font-other">You will shortly receive a confirmation email. The person that invited you will also be notified.</p></v-col>
					</v-row>
					<v-row>
						<v-col class="text-center mt-5">
							<v-btn @click="quit(this)">Go To Login</v-btn>
							</v-col>
					</v-row>					
				</v-card-text>
				<v-card-actions>
					
				</v-card-actions>			
			</v-card>
		</v-dialog>
</template>

<script setup>
import LogoutDialog from '@/components/LogoutDialog.vue';
import { reactive, onMounted,onUnmounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import voxhubLogo from '@/assets/images/voxhub_greenOnWhite.svg';
import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
import i164Transparent from '@/assets/images/i164_transparent.png';
import PasswordBar from '@/components/plustag/components/PasswordBar.vue';

const { t }  = useI18n();
const route = useRoute();
const router = useRouter();

const mainStore = useMainStore();


const state = reactive({
	newUserDataReady: false,
	logout: false,
	logoutPath: 'login',
	loggedIn: false,
	loggedInStatus: undefined,
	accessCode: undefined,
	verificationType: 'inviteUserToService',
	quartermass: true,
	processType: 'newUser',
	verified:  false,
	verificationOutcome: undefined,
	verificationDetails: {
		emailAddress: undefined,
	},
	displayUserForm: false,
	userForm: {	
		firstName: "",
		lastName: "",				
		emailAddress: "",
		password: "",
		passwordCheck: "",
	},
	verificationData: undefined,
	phoneServiceUserParticipation: undefined,
	phoneServiceUserParticipationInvite: undefined,
	showLinkInviteDialog: false,
	showAccountCreatedDialog: false,
	inviteAccepted: false,
	inviteRejected: false,
	rejectionMessage: undefined,
	completed: false,
	isInTargetAlready: false,
	passwordPassed: false,
});

if (route.params.accessCode) {
	state.accessCode = route.params.accessCode;
}

const displayNewUserForm = () => {
	state.displayUserForm = true;
}

const quit = () => {
	goToLogin(false);
}

const closeLogout = () => {
	state.logout = false;
}


const loggedOut = () => {
	state.processType = 'newUser';
	verifyAccessCode();
}

const goToLogin = async (comeBack) => {
		
	let apiUrl = mainStore.getBaseUrl('plustag')+'/logout';	
	await fetch(apiUrl, {
		method: 'POST',
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {
					if (comeBack) {
						mainStore.lastName = 'inviteUserToService';					
						mainStore.lastParams = {accessCode: state.accessCode};
					}
					router.push({name: 'login'});		
				}
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);			
	});			
}


const verifyAccessCode = async (accessCode,verificationType) => {	
	const data = new URLSearchParams();
		
		await fetch(mainStore.getBaseUrl('plustag')+'/quartermass/accessCodeVerification/'+state.accessCode+"/"+state.verificationType, {
			method: 'POST',
			body: data,
			credentials: 'include',
			redirect: 'follow',																									
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)
											
					if (d.outcome == 1) {						
						state.verificationDetails = d;
						state.userForm.emailAddress = d.emailAddress;
						state.verified = true;
						fetchUserInviteDetails();
					} else if (d.outcome == 908) {//Already Logged In
						state.processType = 'userLoggedIn';
						state.quartermass = false;
						state.verified = true;//accessCode verified but user is different
						fetchUserInviteDetails();
						getLoggedInDetails();
						mainStore.displaySnackBar(d.outcome);
					} else if (d.outcome == 909) {//Expired, so not verified
						mainStore.displaySnackBar(d.outcome+' expired ');
						state.verified = false;
						state.phoneServiceUserParticipationInvite = d.phoneServiceUserParticipationInvite;
					} else if (d.outcome == 919) {//Expired, so not verified						
						state.verified = false;
						state.phoneServiceUserParticipationInvite = d.phoneServiceUserParticipationInvite;
					} else {
						mainStore.displaySnackBar(d.outcome);
					}
					state.verificationOutcome = d.outcome;	
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
			
	});		
}

const fetchUserInviteDetails = async () => {	
	const data = new URLSearchParams();
	data.set('accessCode',state.accessCode);
	let apiUrl = mainStore.getQuartermassAPIUrl('plustag');
	let command = 'fetchUserInviteDetails';
	if (state.processType == 'userLoggedIn') {
		apiUrl = mainStore.getAPIUrl('plustag');
		command = 'fetchLoggedInUserInviteDetails';
	}
	await fetch(apiUrl+'/phoneServices/'+command, {
		method: 'POST',
		body: data,
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {
					state.verificationData = d.verificationData;
					if (!d.phoneServiceUserParticipation) {
						state.verificationOutcome = 909;
						state.verified = false;
					} else {
						state.isInTargetAlready = isUserInDomainUIUD(d.phoneServiceUserParticipation.phoneServiceUUID);	
						state.phoneServiceUserParticipation = d.phoneServiceUserParticipation;	
					}
					
					state.phoneServiceUserParticipationInvite = d.phoneServiceUserParticipationInvite;
					state.userForm.firstName = d.phoneServiceUserParticipationInvite.toFirstName;
					state.userForm.lastName = d.phoneServiceUserParticipationInvite.toLastName;
		
				} 
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);			
	});		
}

const isUserInDomainUIUD = (phoneServiceUUID) => {
		if (!state.loggedInStatus) {return false;}
		let inDomain = false;
		state.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
			(domain) => {								
				if (domain.phoneServiceUUID == phoneServiceUUID) {
					inDomain = true;								
				}
			}
		)
		return inDomain;
	}


const createNewAccount = async () => {	
	const data = new URLSearchParams();
	data.set('accessCode',state.accessCode);
	data.set('firstName',state.userForm.firstName);
	data.set('lastName',state.userForm.lastName);
	data.set('password',state.userForm.password);
	data.set('choice','newAccount');
	let apiUrl = mainStore.getQuartermassAPIUrl('plustag');
	let command = 'acceptRejectUserInvite';
	await fetch(apiUrl+'/phoneServices/'+command, {
		method: 'POST',
		body: data,
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {
					state.showAccountCreatedDialog = true;
					state.inviteAccepted = true;
					state.completed = true;
				} 
				mainStore.displaySnackBar(d.message);
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);			
	});		
}

const acceptInvitation = async () => {	
	const data = new URLSearchParams();
	data.set('accessCode',state.accessCode);
	data.set('choice','existingAccount');
	let apiUrl = mainStore.getAPIUrl('plustag');
	let command = 'acceptRejectLoggedInUserInvite';
	await fetch(apiUrl+'/phoneServices/'+command, {
		method: 'POST',
		body: data,
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {					
					state.inviteAccepted = true;
					state.completed = true;
				} 
				mainStore.displaySnackBar(d.message);
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);			
	});		
}

const rejectInvitation = async () => {	
	const data = new URLSearchParams();
	data.set('accessCode',state.accessCode);
	data.set('rejectionMessage',state.rejectionMessage);
	data.set('choice','rejectRequest');
	let apiUrl = mainStore.getQuartermassAPIUrl('plustag');
	let command = 'acceptRejectUserInvite';
	await fetch(apiUrl+'/phoneServices/'+command, {
		method: 'POST',
		body: data,
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {					
					state.inviteRejected= true;
					state.completed = true;
				} 
				mainStore.displaySnackBar(d.message);
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);			
	});		
}


const getLoggedInDetails = async () => {	
	let apiUrl = mainStore.getAPIUrl('plustag')+'/plustag/loggedInStatus';	
	await fetch(apiUrl, {
		method: 'POST',
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {
					state.loggedInStatus = d;					
				}
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);
			
	});		
}


const passwordReady = (event) => {
	if (event) {
		state.passwordPassed = (event.state == 'ready');
	} else {
		state.passwordPassed = false;
	}
	
}

const isValidNewUserForm = (event) => {
	
	if (state.userForm.firstName && state.userForm.lastName && state.userForm.emailAddress && state.userForm.password) {
		if (state.passwordPassed && (state.userForm.password == state.userForm.passwordCheck)) {			
			state.newUserDataReady = true;
			return true;
		}				
	}			
	state.newUserDataReady = false;
	return false;
}

onMounted(() => {
	verifyAccessCode();
});

</script>

<style scoped>


	.card-floater {
		overflow: visible;
	}
</style>