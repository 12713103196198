<script setup>
    import { defineProps } from "vue";
    import { useRouter } from "vue-router";
    import PageInfoHeader from '@/components/PageInfoHeader.vue';

    const router = useRouter();

    const props = defineProps({
        title: {
            type: String,
            default: "title not set"
        },
        description: {
            type: String,
            default: null
        },
        backLinkText: {
            type: String,
            default: "back"
        }
    });

</script>

<template>
    <div class="backContainer">
        <a @click="router.back()">
            <v-icon
                size="small"
                style="top: -1px"
            >mdi-arrow-left-circle</v-icon>
            {{ backLinkText }}
        </a>
    </div>

    <div class="pageContainter">
        <PageInfoHeader
            :title=title
            :description=description
        ></PageInfoHeader>
    </div>
    <div class="pageBody">
        <slot></slot>
    </div>
</template>

<style scoped>

    .backContainer {
        margin: 10px 10px 10px 10px;
        padding: 5px 0px 5px 10px;
        border: 1px solid rgb(87, 66, 66);
        border-radius: 4px;
    }

    .backContainer:hover {
        color: green;
    }

    .pageContainter {
        margin: 10px 10px 10px 10px;
    }

    .pageBody {
        margin: 0px 10px 10px 10px;
    }

</style>