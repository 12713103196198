<template>
	<OuterCard>
			<v-row v-if="state.phoneServiceUserParticipation">
				<v-col class="pa-0" >
					<BoxHeader headerText="User Actions"/>
					<v-row>
						<v-col  class="d-flex ma-2 theme-pointer" @click="manageRoles(this)">
							<v-icon  class="mr-3  text-primary_100" size="20">chevron_right</v-icon><p>Manage Roles</p>
						</v-col>
					</v-row>
				<!--<v-row>
						<v-col><v-divider></v-divider></v-col>
					</v-row>
					<v-row>
						<v-col  class="d-flex ma-2 theme-pointer" @click="twoFactorSetup()">
							<v-icon  class="mr-3  text-primary_100" size="20" >chevron_right</v-icon><p v-if="mainStore.loggedInStatus.twoFactorRequired">Two Factor Enabled</p><p v-else>Enable Two Factor</p>
						</v-col>
					</v-row>-->	
					<v-row>
						<v-col><v-divider></v-divider></v-col>
					</v-row>
					<v-row v-if="state.phoneServiceUserParticipation.serviceUserUUID != mainStore.loggedInStatus.userRef.primaryUUID">
						<v-col  class="d-flex ma-2 theme-pointer" @click="showRemoveUser()">
							<v-icon  class="mr-3  text-primary_100" size="20" >chevron_right</v-icon><p>Remove Access</p>
						</v-col>
					</v-row>
				</v-col>
			</v-row>	
			<v-row v-else>
				<v-col>
					<p class="theme-font-other"><v-progress-circular
									indeterminate
									color="rgb(var(--v-theme-loading_spinner_color))"									
								></v-progress-circular></p>
							</v-col>
			</v-row>		
		</OuterCard>
		<v-dialog v-model="state.showRemoveUserDialog">
			<v-row>
				<v-col cols="12" sm="4">					
				</v-col>
				<v-col cols="12" sm="4">
					<v-card>
						<BoxHeader headerText="Remove Access" closeButton="true" @close="closeRemoveUser(this);"/>
						<v-card-text>
						
						<v-row v-if="state.phoneServiceUserParticipation && (state.removeUserErrorOutcome == 926 || state.ownerCount ==1)" class="vx-green-lighter-background">
							<v-col class="pt-5">
								<p class="theme-font-other"><v-icon class="mr-2" color="red">warning</v-icon> You cannot remove this user. There must always be an owner on the account.</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-5"><v-icon class="mr-2">person</v-icon>{{state.phoneServiceUserParticipation.firstName}} {{state.phoneServiceUserParticipation.lastName}}</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-5"><v-icon class="mr-2">email</v-icon>{{state.phoneServiceUserParticipation.emailAddress}}</v-col>
						</v-row>										
						<v-row>
							<v-col>
								<p class="theme-font-other ma-3">This will remove the user from this account.</p>
								<p class="theme-font-other ma-3">This does not delete the user, it simply removes permissions they have to access this account.</p>		
								<p class="theme-font-other ma-3">The user will be sent an email notifying them of this change.</p>								
							</v-col>
						</v-row>
						<v-row>
							<v-col align="center">
								<v-btn class="theme-bg-highlight" @click="removeUserFromPhoneService(true,true);">Remove</v-btn>
							</v-col>
						</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" sm="4">					
				</v-col>
			</v-row>		
		</v-dialog>
		<div v-if="state.phoneServiceUserParticipation">
		<PlatformRolesPanelDialog :show="state.showPlatformRolesPanel" :phoneServiceUserParticipation="state.phoneServiceUserParticipation" @close="closeManageRolesDialog(this)"/>
	</div>
	</template>
	
	<script setup>
	import { defineProps,reactive,onMounted,onUpdated } from 'vue';
	import OuterCard from '@/components/common/OuterPopCard.vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import PlatformRolesPanelDialog from './PlatformRolesPanelDialog.vue';
	import BoxHeader from '@/components/BoxHeader.vue';
	
	const mainStore = useMainStore();

	const router = useRouter();		

	const props = defineProps({
		phoneServiceUserParticipation: {
			type: Object,
			required: true,
		}
	});


	const state = reactive({		
		phoneServiceUserParticipation: undefined,
		showRemoveUserDialog: false,
		removeUserErrorOutcome: undefined,
		ownerCount: undefined,
		showPlatformRolesPanel: false,
	});

	const manageRoles = (a) => {
		state.showPlatformRolesPanel = true;
	}

	const closeManageRolesDialog = () => {
			state.showPlatformRolesPanel = false;
		}

	const closeRemoveUser = (a) => {	
		state.showRemoveUserDialog = false;
	}

	const showRemoveUser = () => {
		state.showRemoveUserDialog = true;
	}

	const removeUserFromPhoneService = async (removeUser,sendEmail) => {
		if (!state.phoneServiceUserParticipation) {return;}
		if (state.phoneServiceUserParticipation.serviceUserUUID == mainStore.loggedInStatus.userRef.primaryUUID) {
			console.log('Cannot remove self')
			return;
		}
		const apiUrl = mainStore.getAPIUrl();			
		const data = new URLSearchParams();
		data.set("serviceUserUUID",state.phoneServiceUserParticipation.serviceUserUUID);
		data.set("removeUser",removeUser);		
		data.set("sendEmail",sendEmail);				
		await fetch(apiUrl+'/phoneServices/revokeUserRole', {
			body: data,
			method: 'post',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {
						mainStore.displaySnackBar('User Removed');						
						state.showRemoveUserDialog = false;
						router.push({name: 'users'});
					}	else {//last owner
						state.removeUserErrorOutcome = d.outcome
						state.removeUserErrorMessage = d.message
					}		
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});		
	}


	onMounted(() => {
		if (props.phoneServiceUserParticipation) {
			state.phoneServiceUserParticipation = props.phoneServiceUserParticipation;
		}		
	});

	

	
	</script>
	
	<style scoped>
	
	</style>