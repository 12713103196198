<script setup>
    import { defineProps, ref } from "vue";

    defineProps({
        title: {
            type: String,
            default: "title not set"
        },
        description: {
            type: String,
            default: null
        }
    });

    const descriptionContainer = ref(null);
    const showMoreLessLink = ref(null);
    const initialHeight = ref(null);

    const expandContractDescription = () => {
        const descriptionContainterDom = descriptionContainer.value;

        if (initialHeight.value == null) {
            initialHeight.value = parseInt(getComputedStyle(descriptionContainterDom).height);
        }
        
        if (descriptionContainterDom.style.height != "") {
            descriptionContainterDom.style.removeProperty("height");
            showMoreLessLink.value.innerHTML = "show more";
        } else {
            // calculate the height of the description box
            const compStyle = getComputedStyle(descriptionContainterDom);
            const dummyElement = document.createElement("div");
            dummyElement.style.position = "absolute";
            dummyElement.style.top = "-1000px";
            dummyElement.style.left = "-1000px";
            dummyElement.style.width = compStyle.width;
            dummyElement.style.padding = compStyle.padding;
            dummyElement.style.fontSize = compStyle.fontSize;
            dummyElement.innerHTML = descriptionContainterDom.innerHTML;
            document.body.appendChild(dummyElement);
            const dummyCompStyle = getComputedStyle(dummyElement);


            // descriptionContainterDom.style.height = "auto";
            // if the current height/ minimum height is less then use the greater of the two heights
            descriptionContainterDom.style.height = (initialHeight.value > parseInt(dummyCompStyle.height)) ? initialHeight.value + "px" : dummyCompStyle.height;
            showMoreLessLink.value.innerHTML = "show less";

            document.body.removeChild(dummyElement);
        }
    }

</script>

<template>
    <div class="infoHeaderContainer">
        <div class="infoHeader">
            <div class="infoHeader_title">
                {{title}}
            </div>
            <div class="infoHeader_description" ref="descriptionContainer">
                {{description}}
            </div>
            <div class="moreLess">
                <a @click="expandContractDescription" ref="showMoreLessLink">show more</a>
            </div>
        </div>
    </div>
</template>

<style scoped>

    .infoHeaderContainer {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
    }

    .infoHeader {
        width: 100%;
        border: 1px solid rgb(87, 66, 66);;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
    }

    .infoHeader_title {
        padding: 5px 10px 5px 10px;
        background-color: rgb(87, 66, 66);
        color: white;
        font-size: 18px;
        font-weight: bold;
    }

    .infoHeader_description {
        height: 40px;
        margin: 5px 15px;
        transition: height 0.5s;
        overflow: hidden;
    }

    .moreLess {
        text-align: right;
        font-size: 14px;
        padding-right: 10px
    }
</style>