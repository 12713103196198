<template>
	<v-container fluid class="px-6 py-0 h-100">
		
			<v-row>
				<v-col align="right">		
					<v-btn  @click="createNewBasicLine()" size="small" class="pl-2 theme-list-action-button-background  text-action_button_text"><v-icon class="theme-list-action-icon">add</v-icon>Basic Line</v-btn>
									<v-btn class="ml-2 theme-list-action-button-background   text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
				</v-col>
			</v-row>		
			<v-row>			
				<v-col md="0">	
					<v-card class="card-shadow border-radius-md listDisplay d-flex flex-column">						
						
						<v-table   density="compact">														
							<thead class="theme-list-header-background">
								<tr>															
									<th class="text-center text-h6 theme-listing-header-text">Status</th>									
									<th class="text-center text-h6 theme-listing-header-text">Telephone Number</th>
									<th class="text-center text-h6 theme-listing-header-text">Subscriber</th>
									<th class="text-center text-h6 theme-listing-header-text"></th>
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="basicLineDetail in state.basicLineDetailsList"  :key="basicLineDetail.primaryUUID">						
									<td class="text-center">{{ t('statics.lifecyclePhases.basicLine.'+basicLineDetail.onboarding) }}</td>
									<td class="text-center">{{ basicLineDetail.telephoneNumber }}</td>
									<td class="text-center">{{ basicLineDetail.postcode}}</td>	
									<td class="text-center"><v-menu location="center">
											<template v-slot:activator="{ props }">
												<v-icon v-bind="props">menu</v-icon>
											</template>

											<v-list>
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="basicLine(basicLineDetail.primaryUUID);">View Basic Line</v-list-item-title>
												</v-list-item>
												<v-list-item>
													<v-list-item-title class="theme-pointer">Diagnostics</v-list-item-title>
												</v-list-item>
											</v-list>
										
										</v-menu></td>
								</tr>								
								<tr v-if="state.basicLineDetailsList.length == 0">
									<td colspan="4" class="theme-font text-center text-h6">No Records Found</td>
								</tr>	
								
							</tbody>

						</v-table>

						 <v-spacer></v-spacer>
							<!--<v-pagination
										v-model="state.pagination.page"
										:length="state.pagination.length"
										:total-visible="state.pagination.totalVisible"
										></v-pagination>-->
					</v-card>	
				</v-col>
			</v-row>
			<v-row class="pd-10">
				&nbsp;
			</v-row>			
		</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';

	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const state = reactive({
		basicLineDetailsList: [],			
		filterValues: {
			filterByPhase: 'all',
			recordCount: 25,
		},
		pagination: {
					page: 2,
					length: 4,
					totalVisible: 7,
				},	
		filters: {		
			recordCount: [
				{value: 10, title:'10 Lines'},
				{value: 25, title:'25 Lines'},
				{value: 50, title:'50 Lines'},
				{value: 100, title:'100 Lines'},					
			],			
		},
		basicLineConfiguration : {	
			serviceAccountContext: {
				createNewContext: true,
				serviceAccountContextDisplayName: "Customer X"
			},
			numberRequest: {
				countryCode: 44,
				areaCode: 1582
			},
			deviceRequest: {
				deviceType: 1000
			}
		}
	});

	const basicLine = async (voxletInstanceUUID) => {
		router.push({name: 'basicLine',params: {voxletInstanceUUID: voxletInstanceUUID }});
	}

	const createNewBasicLine = async () => {
		router.push({name: 'createBasicLine'});
	}

	const changeRecordCount = ()  => {

	}
	
	const listBasicLines = async () => {
				const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/voxletLines/listBasicLines', {
					method: 'get',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == -3) {
							} else {
									state.basicLineDetailsList = d.basicLineDetails;
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);

			});		
		}

		const refreshList = async () => {
			listBasicLines();
		}

		listBasicLines();
</script>

<style scoped>

    
</style>