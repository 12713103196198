<template>
<OuterCard>
		<v-row>
			<v-col class="pa-0">
				<v-row class="theme-box-header-background">
				<v-col class="d-flex ma-2 mb-0">
					<p class="theme-font text-h5 text-white">Your Details</p>
				</v-col>		
				</v-row>		
				<v-row>
					<v-col class="ma-2 pt-0 pb-0">		
						<v-spacer class="d-flex mt-3 mb-3">
							<v-icon  class="mr-3  text-primary_100" size="20">person</v-icon>												
							<p class="theme-font-other text-body-2">{{ mainStore.loggedInStatus.loggedInUserDomains.user.firstName}} {{ mainStore.loggedInStatus.loggedInUserDomains.user.lastName}}</p>
						</v-spacer>											
					</v-col>
				</v-row>							
				<v-row>
					<v-col class="ma-2 pt-0 pb-0">		
						<div>
							<v-divider></v-divider>
						</div>	
						<v-spacer class="d-flex mt-3 mb-3">
							<v-icon  class="mr-3  text-primary_100" size="20" v-if="mainStore.loggedInStatus.twoFactorRequired">key</v-icon>
							<v-icon  class="mr-3  text-primary_100" size="20" v-else>key_off</v-icon>
							<p v-if="mainStore.loggedInStatus.twoFactorRequired"  class="theme-font-other text-body-2"> Two Factor Enabled</p>
							<p v-else  class="theme-font-other text-body-2">Two Factor Disabled</p>
						</v-spacer>											
					</v-col>
				</v-row>			
				<v-row>
					<v-col class="ma-2 pt-0 pb-0 mb-3">		
						<div>
							<v-divider></v-divider>
						</div>	
						<v-spacer class="d-flex mt-3 mb-3">
							<v-icon  class="mr-3 text-primary_100" size="20">mail</v-icon>
							<p class="theme-font-other text-body-2">{{ mainStore.loggedInStatus.loggedInUserDomains.user.email}}</p>	<v-spacer align="right"><v-icon class="ml-5 theme-icon-verified">verified</v-icon></v-spacer>
						</v-spacer>											
					</v-col>
				</v-row>
				<div v-for="mobileNumber,index in state.userMobileNumbers" :key="index">
					<v-row>
					<v-col class="ma-2 pt-0 pb-0 mb-3">		
						<div>
							<v-divider></v-divider>
						</div>	
						<v-spacer class="d-flex mt-3 mb-3">
							<v-icon  class="mr-3 text-primary_100" size="20">phone</v-icon>
							<p class="theme-font-other text-body-2">{{ formatMobileNumber(mobileNumber.data) }}</p>	<v-spacer align="right"><v-icon class="ml-5 theme-icon-verified">verified</v-icon></v-spacer>
						</v-spacer>											
					</v-col>
				</v-row>
			</div>				
			</v-col>
		</v-row>		
	</OuterCard>
</template>

<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
	import OuterCard from '@/components/common/OuterPopCard.vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';

	const mainStore = useMainStore();

	const router = useRouter();
	
	const state = reactive({
		userMobileNumbers: undefined,
	});

	const formatMobileNumber = (number) => {
		if (number.startsWith("44")) {
			return "0"+number.substring(2);
		}
		return number;
	}

const listValidataUserMobileNumbers = async () => {
	const apiUrl = mainStore.getAPIUrl('plustag');			
		await fetch(apiUrl+'/plustag/listValidataUserMobileNumbers', {
			method: 'get',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {				
					if (d.outcome == 1) {
						console.log(d);
						state.userMobileNumbers = d.userMobileNumbers;											
					}	else {
						const commandOutcome = mainStore.getCommandOutcome(t,d.outcome,d.message);
						state.commandOutcome = commandOutcome;
					}		
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});		
			
}

onMounted(() => {
	listValidataUserMobileNumbers();
	});

</script>

<style scoped>

</style>