<template>
	<v-container  class="h-100">
			<v-row>
				<v-col class="ma-0 pa-0">
					<v-spacer align="right">
						<v-row>
							<v-col cols="3" sm="7" md="9">
							</v-col>
							<v-col cols="5" sm="3" md="2">			
								<v-select
									:items="state.filters.recordCount"				
									label="Showing"
									variant="outlined"
									item-title="title"
									item-value="value"	
									@update:modelValue="changeRecordCount"								
									v-model="state.filterValues.recordCount"
								></v-select>
							</v-col>
							<v-col cols="2" sm="1" md="1">
								<v-btn class="mt-3 ml-2 theme-list-action-button-background   text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
							</v-col>
						</v-row>
					</v-spacer>
				</v-col>	
			</v-row>			
			<v-row>			
				<v-col class="mt-0" >	
					<v-card class="card-shadow border-radius-md listDisplay d-flex flex-column">						
						
						<v-table  density="compact">														
							<thead class="theme-list-header-background ">
								<tr>															
									<th class="text-center text-h6 theme-listing-header-text">Number</th>
									<th class="text-center text-h6 theme-listing-header-text">Date</th>
									<th class="text-center text-h6 theme-listing-header-text">Amount</th>
									<th class="text-center text-h6 theme-listing-header-text">Amount</th>
									<th class="text-center text-h6 theme-listing-header-text"></th>
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="serviceAccountInvoice in state.serviceAccountInvoices"  :key="serviceAccountInvoice.primaryUUID">																							
									<td class="text-center">{{ serviceAccountInvoice.invoiceId }}</td>
									<td class="text-center">{{ serviceAccountInvoice.invoiceDate}}</td>	
									<td class="text-center">{{ calcTotal(serviceAccountInvoice)}}</td>		
									<td class="text-center">{{ calcTotal(serviceAccountInvoice)}}</td>										
									<td class="text-center"><v-menu location="center">
											<template v-slot:activator="{ props }">
												<v-icon v-bind="props">menu</v-icon>
											</template>

											<v-list>
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="viewInvoice(serviceAccountInvoice.primaryUUID);">View Invoice</v-list-item-title>
												</v-list-item>
											</v-list>
										
										</v-menu></td>
								</tr>								
								<tr v-if="state.serviceAccountInvoices.length == 0">
									<td colspan="5" class="theme-font text-center text-h6">No Records Found</td>
								</tr>	
								
							</tbody>

						</v-table>

						 <v-spacer></v-spacer>
						<!--<v-pagination
										v-model="state.pagination.page"
										:length="state.pagination.length"
										:total-visible="state.pagination.totalVisible"
										></v-pagination>-->
					</v-card>	
				</v-col>
			</v-row>
			<v-row class="pd-10">
				&nbsp;
			</v-row>			
		</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';

	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const state = reactive({
		serviceAccountInvoices: [],			
		filterValues: {
			filterByPhase: 'all',
			recordCount: 25,
		},
		pagination: {
					page: 2,
					length: 4,
					totalVisible: 7,
				},	
		filters: {		
			recordCount: [
				{value: 10, title:'10'},
				{value: 25, title:'25'},
				{value: 50, title:'50'},
				{value: 100, title:'100'},					
			],			
		}
	});

	const viewInvoice = async (primaryUUID) => {
		router.push({name: 'invoiceDisplay',params: {serviceAccountInvoiceUUID: primaryUUID }});
	}



	const changeRecordCount = ()  => {

	}
	
	const listServiceInvoices = async () => {
				const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/serviceBilling/listServiceAccountInvoices', {
					method: 'get',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {
									state.serviceAccountInvoices = d.serviceAccountInvoices;
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
		}

		const calcSubTotal = (serviceAccountInvoice) => {
			let subTotal = 0;
			if (serviceAccountInvoice) {
				for (let i = 0; i < serviceAccountInvoice.serviceAccountInvoiceItems.length;i ++) {
					subTotal += serviceAccountInvoice.serviceAccountInvoiceItems[i].amount;
				}
			}
			return subTotal;
		}

		const calcVat = (serviceAccountInvoice) => {
			let subTotal = calcSubTotal(serviceAccountInvoice);
			return (subTotal * (serviceAccountInvoice.vatForThisInvoice/100))			
		}

		const calcTotal = (serviceAccountInvoice) => {
			const total = calcSubTotal(serviceAccountInvoice)+ calcVat(serviceAccountInvoice);
			return total;
		}


		const refreshList = async () => {
			listServiceInvoices();
		}


		onUpdated(() => {	
			listServiceInvoices();
		});


		onMounted(() => {
			listServiceInvoices();
		});

</script>

<style scoped>

    
</style>