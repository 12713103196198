<template>
	<v-container>
		<v-table density="compact">														
			<thead class="theme-list-header-background">
				<tr>															
					<th class="text-center text-h6 theme-listing-header-text">Rate Rule</th>
					<th class="text-center text-h6 theme-listing-header-text">Target %</th>
					<th class="text-center text-h6 theme-listing-header-text">Rounding (p)</th>
					<th class="text-center text-h6 theme-listing-header-text">Monitor</th>
					<th class="text-center text-h6 theme-listing-header-text">Group</th>
					<th class="text-center text-h6 theme-listing-header-text"></th>
				</tr>							
			</thead>						
			<tbody v-if="state.pricingRulesData">
				<tr v-for="pricingRule,index in state.pricingRulesData"  :key="index">						
					<td class="text-center"><p v-if=" pricingRule.upperbound">&le; {{ pricingRule.upperbound }}p</p><div v-else><p v-if="index>0">&gt; {{state.pricingRulesData[index-1].upperbound}}p</p></div></td>
					<td class="text-center">{{ pricingRule.target }}%</td>
					<td class="text-center">{{ pricingRule.rounding }}p</td>	
					<td class="text-center">&lt; {{ pricingRule.monitor.minimum}}% <span class="text-caption">and</span> &gt; {{ pricingRule.monitor.maximum}}%</td>	
					<td class="text-center">{{ pricingRule.groupName }}</td>	
					<td class="text-center"><v-menu location="center">
							<template v-slot:activator="{ props }">
								<v-icon v-bind="props">menu</v-icon>
							</template>
							<v-list>
								<v-list-item>
									<v-list-item-title class="theme-pointer" @click="editRule(pricingRule)">Edit Rule</v-list-item-title>
								</v-list-item>
							</v-list>
						
						</v-menu></td>
				</tr>								
				<tr v-if="state.pricingRulesData.length == 0">
					<td colspan="4" class="theme-font text-center text-h6">No Records Found</td>
				</tr>	
				
			</tbody>

		</v-table>
	</v-container>
	<v-dialog persistent v-model="state.showEditForm">
	
		<v-row>
					<v-col md="3" sm="2">					
					</v-col>
					<v-col cols="12" sm="8" md="6">
						<v-card>
							<BoxHeader headerText="Pricing Rule" :closeButton="(true)" @close="close"/>
							<v-card-text>
								<v-row>
									<v-col class="ma-5">
										<v-row>
											<v-col class="d-flex">
												<p class="theme-font text-h6 mr-10 mt-3">Apply to rates less than</p><v-text-field  @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)" label="" variant="outlined" v-model="state.selectedPricingRule.upperbound"></v-text-field><p class="theme-font text-h6 ml-2 mt-3">pence per minute</p>
											</v-col>
										</v-row>
										<v-row>
											<v-col class="d-flex">
												<p class="theme-font text-h6 mr-10 mt-3">Target Margin</p><v-text-field class="targetMargin" @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)"  variant="outlined" v-model="state.selectedPricingRule.target"></v-text-field><p class="theme-font text-h6 ml-2 mt-3">%</p>
											</v-col>
										</v-row>
										<v-row>
											<v-col class="d-flex">
												<p class="theme-font text-h6 mr-10 mt-3">Round to nearest</p><v-text-field  class="rounding" @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)" variant="outlined" v-model="state.selectedPricingRule.rounding"></v-text-field><p class="theme-font text-h6 ml-2 mt-3">pence</p>
											</v-col>
										</v-row>
										<v-row>
											<v-col class="d-flex">
												<p class="theme-font text-h6 mr-10 mt-3">Warn when margin less than</p><v-text-field class="monitoringMinimum"   @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)" variant="outlined" v-model="state.selectedPricingRule.monitor.minimum"></v-text-field><p class="theme-font text-h6 ml-2 mt-3">%</p>
											</v-col>
										</v-row>
										<v-row>
											<v-col class="d-flex">
												<p class="theme-font text-h6 mr-10 mt-3">Warn when margin more than</p><v-text-field class="monitoringMaximum"  @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)"  variant="outlined" v-model="state.selectedPricingRule.monitor.maximum"></v-text-field><p class="theme-font text-h6 ml-2 mt-3">%</p>
											</v-col>
										</v-row>
										<v-row>
											<v-col class="d-flex">
												<v-spacer class="text-end"><v-btn class="theme-bg-highlight" @click="close">Save</v-btn></v-spacer>
											</v-col>
										</v-row>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
						
					</v-col>
					<v-col md="3" sm="2">					
				</v-col>
			</v-row>	
	</v-dialog>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
import BoxHeader from '@/components/BoxHeader.vue';

	
	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const emits = defineEmits(['dataEvents']);

	const state = reactive({
		showEditForm: false,
		selectedPricingRule: undefined,
		pricingRulesData: [
			{
				upperbound: 1,
				rounding: 0.1,
				target: 30,			
				monitor: {
					minimum: 15,
					maximum: 50,
				},
				groupName: 'A',
			},
			{
				upperbound: 5,
				rounding: 1,
				target: 30,			
				monitor: {
					minimum: 15,
					maximum: 50,
				},
				groupName: 'B',
			},
			{
				upperbound: undefined,
				target: 10,
				rounding: 1,
				monitor: {
					minimum: 15,
					maximum: 50,
				},
				groupName: 'C',
			}
		],
	});

	const props = defineProps({		
	
	});


	const valid = (event) => {

	}

	const editRule = (pricingRule) => {
		state.selectedPricingRule = pricingRule;
		state.showEditForm = true;
	}

	const close = (event) => {
		state.showEditForm = false;
	}

	onUpdated(() => {	
		
	});


	onMounted(() => {
	
	});
</script>

<style scoped>

.targetMargin {
	max-width: 100px;
}

.rounding {
	max-width: 100px;
}

.monitoringMinimum {
	max-width: 100px;
}
.monitoringMaximum {
	max-width: 100px;
}


</style>