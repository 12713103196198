<template>
	<OuterCard>
		<v-row>
			<v-col v-if="state.i164HubMemberEncryptionKeys" class="pa-0">
				<BoxHeader headerText="Encryption" :dataReady="state.dataReady" readyIcon="check_circle">
					<template v-slot:action>
						<v-spacer align="right" class="mt-2"><v-btn  @click="addNewEncryptionKey()" size="small" class="pl-2 theme-list-action-button-background  text-action_button_text mr-5"><v-icon class="theme-list-action-icon">add</v-icon>Key</v-btn></v-spacer>	
					</template>
				</BoxHeader>
				<!--<v-row :class="state.dataReady ? 'bg-vx_green_900' : ''">
				<v-col class="d-flex pa-1 pl-5">
					<p class="theme-font text-h5">Encryption Keys</p><v-spacer align="right"><v-btn  @click="addNewEncryptionKey()" size="small" class="pl-2 theme-list-action-button-background  text-action_button_text mr-5"><v-icon class="theme-list-action-icon">add</v-icon>Key</v-btn></v-spacer>	
				</v-col>		
				</v-row>
				<v-row>
					<v-col><v-divider></v-divider></v-col>
				</v-row>-->
				<v-row>
					<v-col class="ma-5 mt-0 mb-0">
						<v-table  class="listTable">														
							<thead>
								<tr>									
									<th class="text-center text-body-2 theme-mini-listing-header-text">Active</th>
									<th class="text-center text-body-2 theme-mini-listing-header-text" >Name</th>
													
									<th class="text-center text-body theme-mini-listing-header-text"></th>
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="i164HubMemberEncryptionKey,index in state.i164HubMemberEncryptionKeys" :key="index">
									
										<!--<td class="text-center"><v-icon v-if="i164HubMemberEndpoint.active" class="text-success">check_circle</v-icon><v-icon v-if="!i164HubMemberEndpoint.active" class="text-failure">cancel</v-icon></td>-->
										<td class="text-center" ><v-icon :class="i164HubMemberEncryptionKey.active ? 'text-success':'text-failure'" :icon="i164HubMemberEncryptionKey.active ? 'check_circle':'highlight_off'"></v-icon></td>
										<td class="text-center">	{{ i164HubMemberEncryptionKey.rsaKeyName  }}</td>
										
										
										<td class="text-center">
										<v-menu location="center">
												<template v-slot:activator="{ props }">
													<v-icon v-bind="props">menu</v-icon>
												</template>
												
												<v-list>	
													<v-list-item>
														<v-list-item-title class="theme-pointer" @click="viewI164HubMemberEncryptionKey(i164HubMemberEncryptionKey);">View Details</v-list-item-title>
													</v-list-item>											
													<v-list-item>
														<v-list-item-title class="theme-pointer" @click="activate(i164HubMemberEncryptionKey);">Activate</v-list-item-title>
													</v-list-item>
													<v-list-item>
														<v-list-item-title class="theme-pointer" @click="deactivate(i164HubMemberEncryptionKey);">De-Activate</v-list-item-title>
													</v-list-item>
													<v-list-item>
														<v-list-item-title class="theme-pointer" @click="deleteExistingI164HubMemberEncryptionKey(i164HubMemberEncryptionKey.primaryUUID);">Delete</v-list-item-title>
													</v-list-item>

												</v-list>
											
											</v-menu>	
										</td>
									
								</tr>
													
							</tbody>							
						</v-table>
											
					</v-col>
				</v-row>
				<v-row v-if="state.i164HubMemberEncryptionKey && state.mode == 'listOnly'">
					<v-col>
						<v-row>
							<v-col>Name</v-col>
							<v-col>{{state.i164HubMemberEncryptionKey.rsaKeyName}}</v-col>
						</v-row>						
						<v-row>
							<v-col>Public Key</v-col>
							<v-col class="text-truncate">{{state.i164HubMemberEncryptionKey.publicKey}}</v-col>
						</v-row>	
					</v-col>
				</v-row>
				<v-row v-if="state.mode == 'addNew'">
					<v-col class="ma-5 mt-0">
						<v-divider class="mb-5"/>
							<v-row :class="state.i164HubMemberEncryptionKeyDataReady ? 'bg-vx_green_900' : ''">							
								<v-col class="d-flex pa-1 pl-5">								
									<v-icon :class="state.i164HubMemberEncryptionKeyDataReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon>
									<p class="theme-font text-h5" v-if="state.mode == 'addNew'">Create New Encryption Key</p>								
								</v-col>		
							</v-row>
							<v-row>
								<v-col><v-divider/></v-col>
							</v-row>
							<v-row>
								<v-col><v-select variant="outlined" :items="state.values.keyType" label="KeyType" item-title="title"
								item-value="value" 
								v-model="state.i164HubMemberEncryptionKey.keyType"  @update:modelValue="isEncryptionKeyValid()" hide-details="true"></v-select></v-col>												
							</v-row>
							<v-row>
								<v-col><v-text-field variant="outlined" label="Key Name *" hide-details="auto" v-model="state.i164HubMemberEncryptionKey.rsaKeyName" @update:modelValue="isEncryptionKeyValid()"></v-text-field></v-col>
							</v-row>						
							<v-row v-if="state.i164HubMemberEncryptionKey.keyType == 'hubApi'">							
								<v-col><v-textarea variant="outlined" label="Public Key" hide-details="auto" v-model="state.i164HubMemberEncryptionKey.publicKey" @update:modelValue="isEncryptionKeyValid()"></v-textarea></v-col>
							</v-row>
							<v-row v-if="state.i164HubMemberEncryptionKey.keyType == 'servicePassport'">	
								<v-col>
									<p>A new managed keychain will be created for you.</p>
								</v-col>
							</v-row>
							<v-row>
								<v-col align="center">								
									<v-btn @click="resetForm()">Cancel</v-btn>
								</v-col>
								<v-col align="center">								
									<v-btn :class="state.i164HubMemberEncryptionKeyDataReady ? 'theme-bg-highlight' : ''" :disabled="!isEncryptionKeyValid()">
										<p  @click="createNewI164HubMemberEncryptionKey()">Create</p>																
									</v-btn>
								</v-col>							
							</v-row>
							</v-col>
				</v-row>
				<v-spacer align="right"><p class="text-disabled theme-help-format" @click="showHelp('help-servicePassport-switch-orders')">help</p></v-spacer>
			</v-col>

		</v-row>
				
			
	</OuterCard>

	<ContextHelpDialogue :articleId="state.help.articleId" :show="state.help.show" @close="helpCloseEvent"></ContextHelpDialogue>
	
</template>
<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import OuterCard from '@/components/common/OuterPopCard.vue';
	import ContextHelpPopper from "@/components/common/ContextHelpPopper.vue";
	import ContextHelpDialogue from "@/components/common/ContextHelpDialogue.vue";
	import { thisTypeAnnotation } from '@babel/types';
import BoxHeader from '@/components/BoxHeader.vue';

	const mainStore = useMainStore();


	const props = defineProps({
		refreshConfiguration: {
			type: Object,
			required: true,
		},	
		configuration: {
			type: Object,
			required: true
		},
		assetData: {
			type: Object,			
		},		
		readOnly: Boolean,
	});

	/**
	 * mode - listOnly
	 * mode - addNew
	 * mode - edit
	 */
	const state = reactive({
		onboarding: undefined,
		refreshVersion: 0,
		readOnly: false,
		dataReady: false,
		i164HubMemberEncryptionKeys: undefined,	
		i164HubMemberEncryptionKey: undefined,	
		i164HubMemberEncryptionKeyDataReady: false,
		mode: 'listOnly',
		values: {
			keyType: [
				{value: 'servicePassport', title:'Service Passport Encryption'},
				{value: 'hubApi', title:'Hub API Encryption'}			
			],
			active: [
				{value: true, title:'Active'},
				{value: false, title:'Disabled'}		
			]
		},
		help: {
			show: false,
			articleId: undefined,
		}
	});

	const showHelp = (articleId) => {
		state.help.articleId = articleId;
		state.help.show = true;
	}

	const helpCloseEvent = (event) => {
		state.help.show = false;
		state.help.articleId = undefined;
	}

const emits = defineEmits(['dataEvents']);

const fireDataRefreshEvent = (event) => {		
	emits('dataEvents',
		{
			state: 'refreshRequired'
		}
	);
}


	const resetForm = () => {
		state.i164HubMemberEncryptionKey = undefined;
		state.mode = 'listOnly';		
	}

	const addNewEncryptionKey = () => {
		state.i164HubMemberEncryptionKey = {
			keyType: 'servicePassport',
			rsaKeyName: '',
			publicKey: '',
		};
		state.mode = 'addNew';		
	}

	const isEncryptionKeyValid = () => {				
		let valid = true;
		if (state.i164HubMemberEncryptionKey) {
			if (state.i164HubMemberEncryptionKey.rsaKeyName && state.i164HubMemberEncryptionKey.rsaKeyName.length > 0 ) {

			} else {
				valid = false;
			}
			if (state.i164HubMemberEncryptionKey.keyType == 'hubApi') {
				if (state.i164HubMemberEncryptionKey.publicKey && state.i164HubMemberEncryptionKey.publicKey.length > 0) {

				} else {
					valid = false;
				}
			}
		} else {		
			valid = false;
		}
		state.i164HubMemberEncryptionKeyDataReady = valid;
		return valid;	
	}

	const viewI164HubMemberEncryptionKey = (i164HubMemberEncryptionKey) => {
		state.mode = 'listOnly';			
		state.i164HubMemberEncryptionKey = i164HubMemberEncryptionKey;		
		console.log('Here '+state.mode+' '+state.i164HubMemberEncryptionKey);
	}

	const createNewI164HubMemberEncryptionKey = () => {
		if (isEncryptionKeyValid()) {
			console.log("Adding addI164HubMemberEndpoint");
			//addI164HubMemberEndpoint();
		} else {
			console.log("Cannot addI164HubMemberEndpoint");
		}
	}

	const updateExistingI164HubMemberEncryptionKey = () => {		
			console.log("Updating updateI164HubMemberEndpoint");
			//updateI164HubMemberEndpoint();		
	}

	const deleteExistingI164HubMemberEncryptionKey = (i164HubMemberEndpointUUID) => {

			console.log("Deleting deleteI164HubMemberEndpoint");
			//deleteI164HubMemberEndpoint(i164HubMemberEndpointUUID);

	}
	
	

	const refreshState = () => {		
		if (props.assetData) {
			state.i164HubMemberEncryptionKeys = props.assetData;	
			state.dataReady = true;		
		}
	}

	onUpdated(() => {				
		if (props.refreshConfiguration.version > state.refreshVersion) {
			state.refreshVersion = props.refreshConfiguration.version;
			if (props.refreshConfiguration.onboarding) {				
				state.onboarding = props.refreshConfiguration.onboarding;
			}				
			refreshState();
		}
	});

	onMounted(() => {
		refreshState();
	});

</script>

<style scoped>

</style>
