<template>
	<v-layout :full-height="true" class="theme-mainPanel">			
			<v-row class="mb-5 fill-height" >
				<v-col md="2"></v-col>
				<v-col md="8" class="fill-height d-flex align-center justify-center" >
					
					
						<v-card  class="card-shadow border-radius-md ">
							<v-card-text v-if="state.twoFactorRequired">
								<v-row>
								<v-col class="ma-5" align="center">
									<v-card class="theme-dark-background-gradient" width="180" align="center">
										<v-spacer><v-img :src="i164Transparent" width="160" height="72" class="pa-1"></v-img></v-spacer>
									</v-card>
								</v-col>
							</v-row>	
								<v-row>
									<v-col class=""><p class="text-h5 theme-font">Two Factor Required</p></v-col>
								</v-row>
								<v-row v-if="state.twoFactor.outcome != 0">									
									<v-col v-if="state.twoFactor.outcome != 1">
										<v-icon class="text-red">warning</v-icon> {{ state.twoFactor.message }}
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<p class="text-body-1 theme-font-other mb-2">Your account is protected by two factor.</p>
										<p class="text-body-1 theme-font-other">Enter the 6 digit code shown in your App.</p>									
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-row>
											<v-col class="text-align-center">
												<v-spacer align="right"><p class="theme-help-format mr-6" @click="state.twoFactor.verificationCode = undefined;validTwoFactor(this)">RESET</p></v-spacer>	
												<v-otp-input 
												ref="activateInput"
												input-classes="otp-input"
												v-model:value="state.twoFactor.verificationCode"
												separator="-"
												:num-inputs="6"
												:should-auto-focus="true"
												:is-input-num="true"
												value=""
												@on-complete="validTwoFactor(this)"
												@keydown.enter="validTwoFactor(this)"
											></v-otp-input>		
											<div class="d-flex"><v-checkbox label="Remember this device for 30 days" v-model="state.twoFactor.rememberMe" class="shrink" hide-details="true"></v-checkbox><v-spacer class="mt-5"><p class="text-disabled theme-help-format " @click="showHelp('help-login-twoFactor-rememberMe')">help/advice</p></v-spacer></div>
											<p class="text-warning" v-if="state.twoFactor.rememberMe">Only do this, if this device is NOT shared with others!</p>
											</v-col>
											
										</v-row>

									</v-col>
								</v-row>
								<v-spacer align="center" class="mt-5">
									<v-btn @click="doTwoFactor()" :disabled="!state.twoFactor.twoFactorCodeReady"  :class="(state.twoFactor.twoFactorCodeReady) ? 'theme-bg-highlight' :''">Sign In</v-btn>									
								</v-spacer>

							</v-card-text>
							<v-card-text v-else-if="!mainStore.loggedIn && !state.twoFactorRequired">
								<v-form @keyup.enter="doLoginRequest()">
									<v-row>
										<v-col class="ma-5" align="center">
											<v-card class="theme-dark-background-gradient" width="180" align="center">
												<v-spacer><v-img :src="i164Transparent" width="160" height="72" class="pa-1"></v-img></v-spacer>
											</v-card>
										</v-col>
									</v-row>	
								<v-row>
									<v-col class=""><p class="text-h5 theme-font">Sign In to your i164 Account</p></v-col>
								</v-row>
								<v-row v-if="state.loginFailureReason">
									<v-col v-if="state.loginFailureReason == INVALID_CREDENTIALS" align="center" class="mb-4">
										<p><v-icon class="text-red">warning</v-icon> Username or Password Invalid</p>
									</v-col>
									<v-col v-if="state.loginFailureReason == SERVER_UNAVAILABLE" align="center" class="mb-4">
										<p><v-icon class="text-red">warning</v-icon>Login Server Unavailable</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<v-text-field placeholder="Email Address" prepend-inner-icon="email" variant="outlined" hide-details="auto" autocomplete="username" v-model="state.loginForm.username" @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)" ></v-text-field>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<p class="ml-5 pl-2 pr-2 text-right text-caption text-decoration-underline bg-white theme-pointer" @click="goToForgottenPassword()">Forgot your password?</p>
										<v-text-field id="password"  :append-inner-icon="state.loginForm.showHide ? 'eye-off' : 'eye'"  @click:append-inner="state.loginForm.showHide = !state.loginForm.showHide" prepend-inner-icon="key" name="password" placeholder="Password" variant="outlined" type="password" autocomplete="current-password"  v-model="state.loginForm.password"  @focusin="valid(this)" @focusout="valid(this)" @input="valid(this)"></v-text-field>
									</v-col>
								</v-row>
								
								<v-row>
									<v-col class="text-center">
										<v-btn @click="doLoginRequest()" :disabled="!state.dataReady" :class="(state.dataReady) ? 'theme-bg-highlight' :''">LOGIN</v-btn>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="ml-2 mt-2 d-flex">
										<p class="text-caption bg-white">Don't have an account?</p>  <p class="ml-5 pl-2 pr-2 text-caption text-decoration-underline bg-white theme-pointer" @click="goToRegister()">Sign-up</p>
									</v-col>
								</v-row>
								</v-form>	
							</v-card-text>
							<v-card-text v-else>
								<v-row>
									<v-col class="ma-5"><v-img :src="servedUpLogo" min-width="350" ></v-img></v-col>
								</v-row>
								<v-row>
									<v-col class=""><p class="text-h5 theme-font">You are already Logged In.</p></v-col>
								</v-row>
								<v-row>
									<v-col class="text-center">
										<v-btn @click="goToStart()">Start</v-btn>
									</v-col>
								</v-row>
							</v-card-text>

							<v-card-text v-if="!state.loginForm.visible">
								<v-row>
									<v-col class="ma-5" align="center">
										<v-card class="theme-dark-background-gradient" width="180" align="center">
											<v-spacer><v-img :src="i164Transparent" width="160" height="72" class="pa-1"></v-img></v-spacer>
										</v-card>
									</v-col>
								</v-row>	
								<v-row>
									<v-col class="d-flex"><v-progress-circular
									indeterminate
									color="#A4B122"
								></v-progress-circular> <p class="ml-5 text-h5">Contacting Login Server</p>
									</v-col>
								</v-row>
								
							</v-card-text>
						</v-card>										

				</v-col>
				<v-col md="2"></v-col>
			</v-row>					
			<v-footer app class="vx-off-color-background">
				<v-row>					
					<v-col><v-img  :src="servedUpLogo" max-width="150"  class="align-right"></v-img></v-col>
					<v-col><div style="text-align:right;">&copy; {{ getCopyDate() }} Served Up Ltd</div></v-col>
				</v-row>
			</v-footer>
	</v-layout>
	<ContextHelpDialogue :articleId="state.help.articleId" :show="state.help.show" @close="helpCloseEvent"></ContextHelpDialogue>
	<v-dialog v-model="mainStore.loggedOutAutomatically"
    >
        <v-card
            width="50%" min-width="400" max-width="400"
            height="40%" min-height="200" max-height="200"
        >
            <v-card-title>You are Logged Out</v-card-title>
            <v-card-text>
               You have been automatically logged out.
            </v-card-text>
            <v-card-actions>
							<v-col class="text-right">
                <v-btn
                    @click="hideLogoutNotice"
                >Close</v-btn>
							</v-col>
            </v-card-actions>
        </v-card>
    </v-dialog>

</template>

<style scoped>




</style>


<script setup>
	import { inject, ref, reactive, watch, onMounted,onUnmounted} from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import { useMainStore } from "../stores/MainStore.js";
	import { storeToRefs } from 'pinia';
	import voxhubLogo from '@/assets/images/voxhub_greenOnWhite.svg';
	import i164Transparent from '@/assets/images/i164_transparent.png';
	import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';
	import ContextHelpPopper from "@/components/common/ContextHelpPopper.vue";
	import ContextHelpDialogue from "@/components/common/ContextHelpDialogue.vue";
	const mainStore = useMainStore();		
	const router = useRouter();
	const route = useRoute();

	const INVALID_CREDENTIALS = 'invalidCredentials';
	const SERVER_UNAVAILABLE = 'serverUnavailable';

	const { loggedIn } = storeToRefs(mainStore);

	watch(loggedIn, (to,from) => {
		console.log("Logged in changed from "+from+" to "+to);
	})
	
	import { useDate } from 'vuetify/labs/date';
const getCopyDate = () => {
	const date = useDate();
	return date.getYear(new Date());
}

	const showHelp = (articleId) => {
		state.help.articleId = articleId;
		state.help.show = true;
	}

	const helpCloseEvent = (event) => {
		state.help.show = false;
		state.help.articleId = undefined;
	}


	const state = reactive({
		dataReady: false,
		initialMainRouteName: 'dashboard',
		initialPlustagRouteName: 'plustag',
		loginFailureReason: undefined,	
		twoFactorRequired: false,	
		twoFactor: {
			twoFactorCodeReady : false,
			verificationCode: undefined,
			rememberMe: false,
			outcome: 0,
			message: undefined,
		},
		loggedInDetails: undefined,				
		loginForm: {
			visible: true,
			username: "dan-test@netscrape.co.uk",
			password: "",
			showHide: true,
		},
		help: {
			show: false,
			articleId: undefined,
		}
	});


	const valid = (event) => {
		if (state.loginForm.username && state.loginForm.password) {
			const emailValid = state.loginForm.username.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
			state.dataReady = (emailValid && state.loginForm.password.length > 7);
		} else {
			state.dataReady = false;
		}
	}

	const validTwoFactor = (event) => {
		console.log('Here');
		if (state.twoFactor.verificationCode && state.twoFactor.verificationCode.length == 6) {
			state.twoFactor.twoFactorCodeReady = true;
		} else {
			state.twoFactor.twoFactorCodeReady = false;
		}
	}

	const resetErrorMessage = () => {
		state.loginFailureReason = undefined;
	}

	if (route.params.phoneServiceName != undefined) {
		console.log("Setting phoneService via route to "+route.params.phoneServiceName);
		mainStore.phoneServiceName = route.params.phoneServiceName;
	}

	const goToRegister = () => {
		router.push({ name: 'register'});
	}

	const goToStart = () => {
		router.push({ name: 'plustag'});
	}


	const goToForgottenPassword = () => {
		router.push({ name: 'forgottenPassword'});
	}
	
	const hideLogoutNotice = () => {
		mainStore.loggedOutAutomatically = false;
	}

	const doLoginRequest = async () => {	
		mainStore.pollingForLogin = true;
		state.loginFailureReason = undefined;
		const data = new URLSearchParams();
		data.set('username', state.loginForm.username);
		data.set('password', state.loginForm.password);
		data.set('rememberMe', 'true');
		data.set('submit', 'Submit');		
			await fetch(mainStore.getBaseUrl('plustag')+'/callback?client_name=FormClient', {
				method: 'POST',
				body: data,
				credentials: 'include',
				redirect: 'follow',																									
			}).then((response) => {
				response.json().then( 
					d => {						
						if (d.outcome == 1) {
							if (d.twoFactorRequired && d.twoFactorAuthenticated == false){
								console.log("Two Factor Required");
								state.twoFactorRequired = true;
								state.loggedInDetails = d;
							} else {
								console.log("User Logged In");
								mainStore.loggedInStatus = d;
								mainStore.loggedIn = true;
								mainStore.verifiedEmail = d.verifiedEmail;	
								state.loginFailureReason = undefined;
								if (mainStore.lastName) {
									console.log('Sending to lastName '+mainStore.lastName+" with params "+JSON.stringify(mainStore.lastParams));
									if (mainStore.lastParams) {
										if (mainStore.lastParams.phoneServiceName) {
											let phoneServiceName = mainStore.lastParams.phoneServiceName;
											mainStore.phoneServiceName = phoneServiceName;
											mainStore.allowedInDomain = mainStore.isUserInDomain(phoneServiceName);		
										}
										if (mainStore.lastName == 'default') {
											router.push({name:'plustag'});
										} else {
											router.push({name: mainStore.lastName, params: mainStore.lastParams});									
										}														
									} else {
										router.push({name: mainStore.lastName});
									}
								}	else {
									router.push({name:'plustag'});
								}							
								mainStore.pollingForLogin = false;

							}
						} else {
							mainStore.loggedIn = false;
							state.loginFailureReason = INVALID_CREDENTIALS;
							state.loginForm.visible = true;
							mainStore.pollingForLogin = false;
							mainStore.verifiedEmail = -1;	
							//console.log("User Not Logged In");
						}							
					}									
				)
				return response;
			}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
				mainStore.loggedIn = false;
				state.loginFailureReason = SERVER_UNAVAILABLE;
				state.loginForm.visible = true;
				mainStore.pollingForLogin = false;
		});		
	}

	

	const doTwoFactor = async () => {

			const data = new URLSearchParams();
			const apiUrl = mainStore.getAPIUrl('plustag');		
			data.set('verificationCode',state.twoFactor.verificationCode);
			data.set('rememberMe',state.twoFactor.rememberMe);
				await fetch(apiUrl+'/plustag/twoFactorAuthenticateUser', {
					method: 'POST',
					body: data,
					credentials: 'include',
					redirect: 'follow',																									
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)
									
							if (d.outcome == 1) {
								mainStore.displaySnackBar('Login Complete');								
								mainStore.twoFactorCheckRequired = false;
								mainStore.loggedInStatus = state.loggedInDetails;
								mainStore.loggedIn = true;
								mainStore.verifiedEmail = state.loggedInDetails.verifiedEmail;	
								state.loginFailureReason = undefined;
								if (mainStore.lastName) {
									console.log('Sending to lastName '+mainStore.lastName+" with params "+JSON.stringify(mainStore.lastParams));
									if (mainStore.lastParams) {
										if (mainStore.lastParams.phoneServiceName) {
											let phoneServiceName = mainStore.lastParams.phoneServiceName;
											mainStore.phoneServiceName = phoneServiceName;
											mainStore.allowedInDomain = mainStore.isUserInDomain(phoneServiceName);		
										}
										if (mainStore.lastName == 'default') {
											router.push({name:'plustag'});
										} else {
											router.push({name: mainStore.lastName, params: mainStore.lastParams});									
										}														
									} else {
										router.push({name: mainStore.lastName});
									}
								}	else {
									router.push({name:'plustag'});
								}							
								mainStore.pollingForLogin = false;								
							} else {
									state.twoFactor.outcome = d.outcome;
									state.twoFactor.message = d.message;
									state.twoFactor.verificationCode = undefined;
							}
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
					
			});		
			
		
		}

	onMounted(() => {
		console.log('Mounted Login '+route.path);
	
		if (mainStore.twoFactorCheckRequired) {
			console.log("Two Factor Required");
			state.twoFactorRequired = true;
			state.loggedInDetails = mainStore.loggedInStatus;
		}
		
		if (mainStore.loggedIn) {
			console.log("Redirecting to Plustag as User is Logged In");
			router.push({name: mainStore.defaultLoginRoute});
		}
		valid();
	});

	onUnmounted(() => {
		console.log('UnMounted Login');		
	});

</script>
