<template>
		<div class="theme-mainPanel fill-height" >					
			<v-row class="mb-5 fill-height" >
				<v-col md="4" ></v-col>
				<v-col md="4" class="fill-height d-flex align-center justify-center" >
					<v-card  class="card-shadow border-radius-md loadingBox" >
						<v-card-text class="d-flex align-center ml-5">
						<v-progress-circular
									indeterminate
									color="rgb(var(--v-theme-loading_spinner_color))"									
								></v-progress-circular><p class="text-h4 theme-font ml-10">Loading</p>
								</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4" ></v-col>
			</v-row>
				</div>
</template>
<style scoped>



	.loadingBox {
		border: 1px solid rgb(var(--v-theme-loading_box_border_color));
		min-width: 280px;
	}
</style>

<script setup>

	import { useRoute, useRouter } from "vue-router";
	import { reactive,watch,onMounted } from 'vue';
	import { useMainStore } from '/src/stores/MainStore.js';
	import { storeToRefs } from 'pinia';
	import { useBroadcastChannel } from "vue-composable";

	const mainStore = useMainStore();
	
	const route = useRoute();
	const router = useRouter();
	let herenowConnection = undefined;

	const state = reactive({

  });
	
	
	
	const {loggedIn,phoneServiceName,initialTargetPath,initialTargetPhoneServiceName,refreshLoggedInStatus } = storeToRefs(mainStore);

	watch(loggedIn, (to,from) => {
		console.log("Logged in changed from "+from+" to "+to)	;
		mainStore.allowedInDomain = mainStore.isUserInDomain(mainStore.phoneServiceName);		
	})

	watch(phoneServiceName, (to,from) => {
		console.log("phoneServiceName in changed from "+from+" to "+to)
		mainStore.allowedInDomain = mainStore.isUserInDomain(mainStore.phoneServiceName);		
	})


	watch(initialTargetPath, (to,from) => {
		console.log("initialTargetPath changed from "+from+" to "+to)
	})

	watch(initialTargetPhoneServiceName, (to,from) => {
		console.log("initialTargetPhoneServiceName changed from "+from+" to "+to)
	})

	
	watch(refreshLoggedInStatus, (to,from) => {
		console.log("refreshLogedInStatus changed from "+from+" to "+to)
		if (to) {
			checkIfLoggedIn();
		}
	})


	const getPollingUrl = () => {
		let endBit = '/commands-sso/plustag/loggedInStatus';
		if (!mainStore.refreshLoggedInStatus && mainStore.loggedIn && mainStore.loggedInStatus != null && mainStore.verifiedEmail == 1) {
			endBit = '/commands-sso/plustag/loggedInStatus?keepalive=true';
		}
		let args = '';
		if (mainStore.refreshLoggedInStatus) {
			args = '?refresh=true';
			mainStore.refreshLoggedInStatus = false;
		} 			
		return mainStore.getBaseUrl('plustag')+endBit+args;		
	};

	const pollLoggedIn =  () => {
		setInterval(async () => {
		if (!mainStore.pollingForLogin) {
				mainStore.pollingForLogin = true;									
				checkIfLoggedIn();						
		}			
	}, 5000)
	};

	const checkIfLoggedIn  = async () => {
		if (route.path.startsWith('/quartermass')) {
			mainStore.pollingForLogin = false;
			mainStore.appLoaded= true;
		} else {	
			
			await fetch(getPollingUrl(), {
					method: 'get',				
					credentials: 'include',
    			redirect: 'follow',		
					}).then((response) => {
						response.json().then( 
							d => {												
								if (d.outcome == 1) {
									mainStore.verifiedEmail = d.verifiedEmail;
									if (d.twoFactorRequired && d.twoFactorAuthenticated == false) {
										console.log("Two Factor Not Complet");
										mainStore.twoFactorCheckRequired = true;
									} else {
										//console.log("User Logged In");
										if (d.loggedInUserDomains) {
												mainStore.loggedInStatus = d;
											}	
										if (!mainStore.loggedIn) {//Change of Login
											
											mainStore.loggedIn = true;											
										/**
										 * This deal with when a user logs in
										 * We return the user to where they were
										 * router.push({name: item.routeName, params:{phoneServiceName: mainStore.phoneServiceName}});
										 */		
											if (mainStore.lastName) {
												console.log('Sending to lastPath '+mainStore.lastName+" with params "+JSON.stringify(mainStore.lastParams));
												if (mainStore.lastParams) {
													if (mainStore.lastParams.phoneServiceName) {
														let phoneServiceName = mainStore.lastParams.phoneServiceName;
														mainStore.phoneServiceName = phoneServiceName;
														mainStore.allowedInDomain = mainStore.isUserInDomain(phoneServiceName);		
													}
													router.push({name: mainStore.lastName, params: mainStore.lastParams});									
												} else {
													router.push({name: mainStore.lastName});
												}
											}	else {
												router.push({name:'plustag'});
											}																																					
										}
									}																								
								} else {
									console.log("User Not Logged In "+mainStore.initialTargetPath+" in "+mainStore.initialTargetPhoneServiceName);
									mainStore.verifiedEmail = -1;			
									mainStore.loggedIn = false;
									mainStore.loggedInStatus = null;					
									if (route.meta.accessibility == "onlyAuthorised") {										
										mainStore.initialTargetPhoneServiceName = mainStore.phoneServiceName;
										mainStore.initialTargetPath = route.name;
										router.push({name: 'login'});
									}							
								}
								mainStore.pollingForLogin = false;
								if (!mainStore.appLoaded) {								
									completeLoading();
								}								
							
							}									
						)
						return response;						
					}).catch((error) => {
						console.log('Looks like there was a problem: \n', error);
						mainStore.pollingForLogin = false;
				});	
			}
	}

const connectToHerenow = () => {
	const wssUrl = mainStore.getWssUrl('plustag');	
	herenowConnection	= new WebSocket(wssUrl);

	herenowConnection.onmessage = function(event) {
    console.log(event);
  }

	herenowConnection.onopen = function(event) {
		console.log(event)
		console.log("Successfully connected to the echo websocket server...")
	
		var userStatus = {
			action: "command",
			commandGroup: "plustag.loggedInStatus"
		};		
		herenowConnection.send(JSON.stringify(userStatus));
		
	}

}

const completeLoading = () =>{		
	if (mainStore.loggedIn) {
		mainStore.loadInitialDataFromServer();
		connectToHerenow();
	} else {
		mainStore.appLoaded = true;
	}
	
}

onMounted(() => {
	pollLoggedIn();
	addListener(receiveMessage);
});

const {
  supported,
  data,
  addListener,	
} = useBroadcastChannel('voxhub');

const receiveMessage = (message) => {
	console.log(message);
	if (message.data == 'RefreshLoggedInStatus') {
		mainStore.refreshLoggedInStatus = true;
	}
}



	



</script>
