<template>
	<v-container fluid class="px-6 py-6 h-100">
				<v-row class="mb-2">			
					<v-col md="12">			
	
							<v-row>
								<v-col class="d-flex">
									<v-avatar
									class="shadow border-radius-xl mt-1"
									size="48"  color="avatar_background"
								><v-icon class="vx-text-dark theme-avatar-color" size="32">article</v-icon></v-avatar>							
									<p class="mt-3 ml-3 font-weight-bold text-h4 vx-text-dark theme-font-bold">Content</p>
								</v-col>
								<!--<v-col md="4">
									<v-text-field
									label="Search for a Number"
									prepend-inner-icon="search"
									variant="solo"
									@update:modelValue="doSearch"								
								></v-text-field>					
								</v-col>-->
								<v-col md="2" class="d-flex">									
										<v-select
									:items="state.filters.pageType"								
									label="Filter By Type(s)"
									variant="outlined"
									item-title="value"
									item-value="key"
									v-model="state.filterValues.show"
									@update:modelValue="state.filterValues.pageType"									
									></v-select>
								</v-col>
								<v-col align="right">
									
								</v-col>
								
								<!--<v-col md="2" class="">
									<v-select
										:items="state.filters.recordCount"				
										label="Showing"
										variant="solo"
										item-title="title"
										item-value="value"	
										@update:modelValue="changeRecordCount"								
										v-model="state.filterValues.recordCount"
									></v-select>
								</v-col>-->
							</v-row>
					</v-col>				
				</v-row>
				<v-row>
					<v-col align="right">
						<v-btn size="small" class="pl-2 theme-list-action-button-background text-action_button_text" @click="showImageBrowser()"><v-icon class="theme-list-action-icon">image</v-icon>Images</v-btn>
						<v-btn size="small" :class="(checkDocumentQuota()) ? 'theme-list-action-button-background':'theme-quota-warning-background'" class="ml-2 pl-2 text-action_button_text" @click="newContentPage()"><v-icon class="theme-list-action-icon">article</v-icon>New</v-btn>
						<v-btn class="ml-2  theme-list-action-button-background  text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
					</v-col>
				</v-row>
				<v-row>			
					<v-col md="0">
						<v-card class="card-shadow border-radius-md listDisplay d-flex flex-column">						
							
							<v-table  class="listTable" density="compact">														
								<thead class="theme-box-header-background">
									<tr>
										<th class="text-center text-h6 theme-listing-header-text">App</th>		
										<th class="text-center text-h6 theme-listing-header-text">Ref</th>										
										<th class="text-center text-h6 theme-listing-header-text">Status</th>
										<th class="text-center text-h6 theme-listing-header-text">Permissions</th>
										<th class="text-center text-h6 theme-listing-header-text">Title</th>		
										<th class="text-center text-h6 theme-listing-header-text"></th>
									</tr>							
								</thead>						
								<tbody>
									<tr v-for="contentPage,index in state.contentPages"  :key="index">
										<td class="text-center">{{contentPage.pageInfo.appName}}</td>
										<td class="text-center"><p class=" text-caption">{{contentPage.pageInfo.contentReference}}</p><p class=" text-caption" v-if="contentPage.pageInfo.permaLinkName">{{contentPage.pageInfo.permaLinkName}}</p></td>
										<td class="text-center">{{contentPage.pageInfo.status}}</td>
										<td class="text-center">{{contentPage.pageInfo.permissions}}</td>
										<td class="text-center">{{contentPage.summaryTitle}}</td>
										
										<td class="text-center">
											<v-icon @click="jumpToPageType(contentPage);">settings</v-icon>											
										</td>								
									</tr>								
								
								</tbody>
	
							</v-table>
	
							 
							<!--	<v-pagination
											v-model="state.pagination.page"
											:length="state.pagination.length"
											:total-visible="state.pagination.totalVisible"
											></v-pagination>-->
						</v-card>	
					</v-col>
				</v-row>
				<v-row class="pd-10">
					&nbsp;
				</v-row>			
			</v-container>
			<v-dialog v-model="state.showCreateDialog" persistent>
				<v-row>
					<v-col cols="0" md="3" sm="2" lg="4">
					</v-col>
					<v-col cols="12" sm="8" md="6" lg="4">
						<v-card>
							<BoxHeader headerText="Create New Content Page" :closeButton="(true)" @close="closeCreateDialog"></BoxHeader>
							<v-card-text>							
									<ContentPageCreateForm  @dataEvents="pageCreateFormEvents"/>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="0" md="3" sm="2" lg="4">
					</v-col>
				</v-row>
			</v-dialog>		
			<v-dialog v-model="state.showImageBrowser" persistent>
				<v-row>
					<v-col cols="0">
					</v-col>
					<v-col cols="12">
						<v-card class="">
							<BoxHeader headerText="Image Browser" :closeButton="(true)" @close="closeImageBrowser"></BoxHeader>
							<v-card-text>							
									<ImageBrowser @dataEvents="selectImages" :configuration="state.imageBrowserConfiguration"/>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="0">
					</v-col>
				</v-row>
			</v-dialog>		
			
	</template>
	
	
<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import { useRoute, useRouter } from "vue-router";
	import ContentPageCreateForm from '../ContentPageCreateForm.vue';
	import FileUploads from './FileUploads.vue'
	import ImageBrowser from './ImageBrowser.vue'
	import BoxHeader from '@/components/BoxHeader.vue';


	const router = useRouter();
	const { t }  = useI18n();
	const mainStore = useMainStore();

	const state = reactive({	
		contentPages: undefined,
		filterValues: {
			pageType: undefined,
			recordCount: 10,
		},					
		pagination: {
						page: 2,
						length: 4,
						totalVisible: 7,
			},
		filters: {
			pageType: [
			{value: 'article', title:'Article'},
			{value: 'webPage', title:'Web Page'},
			{value: 'faq', title:'FAQ'},							
			{value: 'command' , title:'Command'},
			{value: 'helpButton' , title:'Help Button'},				
			],
		}	,
		imageBrowserConfiguration: {
			browserMode: 'browse',
		},
		showCreateDialog: false,
		showImageBrowser: false,
	});

	const selectImages = (event) => {
		console.log(event.selectedImages);
		closeImageBrowser();
	}
	
	const jumpToPageType = (contentPage) => {
	
		router.push({name: contentPage.pageInfo.pageType,params: {contentPageInfoUUID: contentPage.pageInfo.primaryUUID }});
	
	}

	const checkDocumentQuota = () => {
		//return true;
		return mainStore.getQuota().hasSpareQuota('service:content:documentCount');
	}

	const refreshList = () => {
		listContent();
	}
	
const showImageBrowser = () => {
	state.showImageBrowser = true;
}

const closeImageBrowser = () => {
	state.showImageBrowser = false;
}


	const newContentPage = () => {
		if (checkDocumentQuota()) {
			state.showCreateDialog = true;
		} else {

			mainStore.displaySnackBar('Document Quota Exceeded');	
		}
	}

const closeCreateDialog = () => {
	state.showCreateDialog = false;
}

	const pageCreateFormEvents = () => {
		state.showCreateDialog = false;
		mainStore.getQuota().refreshQuotas();
		refreshList();
	}
	
	const listContent = async (show) => {
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/content/listContentPages', {
					method: 'get',								
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {															
								state.contentPages = d.contentPages;
								mainStore.displaySnackBar('List Loaded');				
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}
	
	
		const refreshData = () => {
			listContent();
		}
	
		onMounted(() => {			
			refreshData();
		});
		
	</script>
	
	<style scoped>
	
	</style>