<template>
		<v-container fluid class="px-6 py-6">
			<v-row>
			<v-col>
				<v-row>
					<v-col class="d-flex" cols="10">
						<v-avatar class="theme-bg-highlight shadow border-radius-xl mt-1" size="48"
							color="avatar_background"><v-icon class="vx-text-dark theme-avatar-color"
								size="32">arrow_circle_right</v-icon></v-avatar>
						<p class="mt-3 ml-3 font-weight-bold text-h4 vx-text-dark theme-font-bold">Gain New Customer</p>
					</v-col>
					<v-col cols="2">					
						<!--<v-btn @click="goToMessageForm()">Message Form</v-btn>-->
					</v-col>
				</v-row>
			</v-col>
		</v-row>
			<v-row>
				<v-col cols="4">
					<v-row>
						<v-col>
							<v-hover >
								<template v-slot:default="{ isHovering, props }">
									<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
										<v-card-text>
											<v-row>
												<v-col class="d-flex center">
													<p class="theme-font text-h5">Customer Details</p>	
												</v-col>
											</v-row>
											<v-row>
												<v-col><v-divider></v-divider></v-col>
											</v-row>
											<v-row>
												<v-col>
													<p>Locate subscribers with a postcode. ID1 1QD</p>
												</v-col>
											</v-row>
											<v-row v-if="state.postCodeError">
												<v-col>
														{{state.postCodeError}}
												</v-col>
											</v-row>
											<v-row>
												<v-col class="d-flex">
													<v-text-field label="Postcode" variant="outlined" v-model="state.postCodeLookup.postCodeToLookup" class="mr-5" @keydown.enter="lookupSubscriberDetails()" :disabled="state.mode != 'postcodeLookup'"></v-text-field>
													<v-btn icon="search" :class="state.postCodeLookup.postCodeToLookup ? 'theme-bg-highlight mt-1' : 'mt-1'"
													:disabled="!state.postCodeLookup.postCodeToLookup || state.mode != 'postcodeLookup'" @click="lookupSubscriberDetails()"></v-btn>		
												</v-col>
											</v-row>
											<v-row v-if="state.postCodeLookup.otsConsumerMatchingRequests != undefined && state.mode == 'postcodeLookup'">
													<v-col v-if="state.postCodeLookup.otsConsumerMatchingRequests.length > 0">
														Located {{ state.postCodeLookup.otsConsumerMatchingRequests.length  }} existing requests.
													</v-col>
													<v-col align="center" v-else>
															No Existing Matching Requests Found
															<v-btn class="mt-5" @click="lookupAddressDetails();">CREATE NEW REQUEST</v-btn>
													</v-col>
											</v-row>			
										</v-card-text>
									</v-card>
								</template>
							</v-hover>
						</v-col>
					</v-row>
					<v-row v-if="state.mode != 'postcodeLookup'">
							<v-col >
								<v-card>
									<v-card-title><p class="theme-font text-h6">Check List</p></v-card-title>
									<v-list>
										<v-list-item>
											<template v-slot:prepend>
												<v-icon :class="state.addressDetails.dataReady ? 'text-check_passed_color opacityFix mt-1 mr-2' : 'text-check_failed_color mt-1 mr-2'">check_circle_outline</v-icon> <v-icon icon="account_tree" size="16" class="mr-3"></v-icon>
											</template>
											<v-list-item-title class="ma-0">Address Details</v-list-item-title>
										</v-list-item>
										<v-list-item>
											<template v-slot:prepend>
												<v-icon :class="state.subscriberDetails.dataReady ? 'text-check_passed_color opacityFix mt-1 mr-2' : 'text-check_failed_color mt-1 mr-2'">check_circle_outline</v-icon> <v-icon icon="contact_page" size="16" class="mr-3"></v-icon>
											</template>
											<v-list-item-title class="ma-0">Customer Details</v-list-item-title>
										</v-list-item>
										<v-list-item>
											<template v-slot:prepend>
												<v-icon :class="state.subscriberServices.dataReady ? 'text-check_passed_color opacityFix mt-1 mr-2' : 'text-check_failed_color mt-1 mr-2'">check_circle_outline</v-icon> <v-icon icon="numbers" size="16" class="mr-3"></v-icon>
											</template>
											<v-list-item-title class="ma-0">Service Details</v-list-item-title>
										</v-list-item>
									
									</v-list>
									
								</v-card>
							</v-col>
						</v-row>
				</v-col>
			
				<v-col cols="4">
					<div v-if="state.postCodeLookup.postCodeLookupResults" >
						<v-hover>
							<template v-slot:default="{ isHovering, props }">
								<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
									<v-card-text>
										<v-row>
											<v-col class="d-flex">
												<v-icon :class="state.addressDetails.dataReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon><p class="theme-font text-h5">Address Details</p>	
											</v-col>
										</v-row>
										<v-row>
											<v-col >											
												<v-divider/>
											</v-col>
										</v-row>
										<v-row v-if="!state.postCodeLookup.otsMatchSelectedAddress">
									
											<v-col v-if="state.postCodeLookup.postCodeLookupResults.length == 0">
												No Addresses Found for that postcode
											</v-col>
											<v-col class="ml-0 pl-0" v-else>
												<p class="theme-font ml-2 pl-2">Select an Address in <b>{{ state.postCodeLookup.postCodeToLookup }}</b></p>
												<v-list class="ml-0 pl-0">
													
														<v-list-item v-for="addressDetails,index in state.postCodeLookup.postCodeLookupResults" :key="index" active-color="vx_green_300"  variant="plain" class="pl-0">
															
															<template v-slot:prepend>
																<v-icon class="ma-2 pl-0" size="20">home</v-icon>
															</template>
															<v-list-item-title class="theme-pointer ml-0" v-if="addressDetails.addressLine2" @click="selectAddress(this,addressDetails)">{{addressDetails.addressLine1 }}, {{addressDetails.addressLine2 }}, {{ addressDetails.postTown }}</v-list-item-title>
															<v-list-item-title  class="theme-pointer" v-else @click="selectAddress(this,addressDetails)">{{addressDetails.addressLine1 }},  {{ addressDetails.postTown }}</v-list-item-title>
														</v-list-item>
											
												</v-list>			
																					
											</v-col>

										</v-row>
										<v-row v-else>
											<v-col v-if="state.addressDetails.dataReady">
													<v-row>													
															<v-col md="4" class="theme-font-other font-weight-bold">Line 1</v-col><v-col md="8" class="theme-font-other">	{{state.postCodeLookup.otsMatchSelectedAddress.addressLine1}}</v-col>
													</v-row>
													<v-row>
														<v-col md="4" class="theme-font-other font-weight-bold">Line 2</v-col><v-col md="8" class="theme-font-other">	{{state.postCodeLookup.otsMatchSelectedAddress.addressLine2}}</v-col>														
													</v-row>
													<v-row>													
															<v-col md="4" class="theme-font-other font-weight-bold">Line 3</v-col><v-col md="8" class="theme-font-other">	{{state.postCodeLookup.otsMatchSelectedAddress.addressLine3}}</v-col>																
													</v-row>
													<v-row>													
															<v-col md="4" class="theme-font-other font-weight-bold">Post Town</v-col><v-col md="8" class="theme-font-other">	{{state.postCodeLookup.otsMatchSelectedAddress.postTown}}</v-col>																
													</v-row>
													<v-row>													
															<v-col md="4" class="theme-font-other font-weight-bold">Postcode</v-col><v-col md="8" class="theme-font-other">	{{state.postCodeLookup.otsMatchSelectedAddress.postcode}}</v-col>																
													</v-row>												
													<v-row>
														<v-col>
															<p class="theme-font-other text-caption theme-pointer" @click="state.addressDetails.dataReady = false">Change Address</p>
														</v-col>
													</v-row>
												</v-col>
												<v-col v-else>
													<v-row>
														<v-col>
															<v-text-field label="Address Line 1" variant="outlined" v-model="state.postCodeLookup.otsMatchSelectedAddress.addressLine1"></v-text-field>
														</v-col>
													</v-row>
													<v-row>
														<v-col>
															<v-text-field label="Address Line 2" variant="outlined" v-model="state.postCodeLookup.otsMatchSelectedAddress.addressLine2"></v-text-field>
														</v-col>
													</v-row>
													<v-row>
														<v-col>
															<v-text-field label="Address Line 3" variant="outlined" v-model="state.postCodeLookup.otsMatchSelectedAddress.addressLine3"></v-text-field>
														</v-col>
													</v-row>
													<v-row>
														<v-col>
															<v-text-field label="PostTown" variant="outlined" v-model="state.postCodeLookup.otsMatchSelectedAddress.postTown"></v-text-field>
														</v-col>
													</v-row>
													<v-row>
														<v-col>
															<v-row>
																<v-col><v-text-field label="Postcode" variant="outlined" v-model="state.postCodeLookup.otsMatchSelectedAddress.postcode" disabled></v-text-field></v-col>
																<v-col></v-col>
															</v-row>
															
														</v-col>
													</v-row>
													<v-row>
														<v-col>
															<v-btn @click="addressReady(this)" size="small" class="">DONE</v-btn>
														</v-col>
													</v-row>
												</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</template>
						</v-hover>
					</div>
				</v-col>
				<v-col cols="4">
					<v-row v-if="state.mode != 'postcodeLookup'">	
						<v-col>
							<v-hover>
								<template v-slot:default="{ isHovering, props }">
									<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
										<v-card-text v-if="state.subscriberDetails.dataReady">
											<v-row>
												<v-col class="d-flex center">
													<v-icon :class="state.subscriberDetails.dataReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon><p class="theme-font text-h5">Customer Details</p>	<v-spacer  align="right"><v-btn size="x-small" @click="changeSubscriberDetails(this)" v-if="!state.readOnly">EDIT</v-btn></v-spacer>
												</v-col>
											</v-row>
											<v-row>
												<v-col><v-divider></v-divider></v-col>
											</v-row>
											<v-row>
												<v-col md="4" class="theme-font-other font-weight-bold">First Name</v-col><v-col md="8" class="theme-font-other">	{{state.subscriberDetails.firstName}}</v-col>
											</v-row>				
											<v-row>
												<v-col md="4" class="theme-font-other font-weight-bold">Last Name</v-col><v-col md="8" class="theme-font-other">	{{state.subscriberDetails.lastName}}</v-col>
											</v-row>												
										</v-card-text>
										<v-card-text v-else>
											<v-row>
												<v-col class="d-flex center">
													<v-icon :class="state.subscriberDetails.dataReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon><p class="theme-font text-h5">Customer Details</p>	
												</v-col>
											</v-row>
											<v-row>
												<v-col><v-divider></v-divider></v-col>
											</v-row>
											<v-row>
												<v-col>
													<v-text-field label="First Name" variant="outlined" v-model="state.subscriberDetails.firstName"></v-text-field>
												</v-col>
											</v-row>
											<v-row>
												<v-col>
													<v-text-field label="Last Name" variant="outlined" v-model="state.subscriberDetails.lastName"></v-text-field>
												</v-col>
											</v-row>
											<v-row>
											<v-col>
												<v-btn @click="saveSubscriberDetails(this)">Done</v-btn>
											</v-col>
										</v-row>		
										</v-card-text>
									</v-card>
								</template>
							</v-hover>
						</v-col>
					</v-row>
					<v-row v-if="state.mode != 'postcodeLookup'">	
						<v-col>
							<v-hover>
								<template v-slot:default="{ isHovering, props }">
									<v-card v-bind="props" :elevation="isHovering ? 12 : 2">
										<v-card-text>
											<!--<v-row>
												<v-col class="d-flex center">
													<v-icon :class="state.subscriberDetails.dataReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon><p class="theme-font text-h5">Service Details</p>	<v-spacer  align="right"><v-btn size="x-small" @click="changeSubscriberDetails(this)" v-if="!state.readOnly">EDIT</v-btn></v-spacer>
												</v-col>
											</v-row>-->
											<v-row>
												<v-col class="d-flex center">
													<v-icon :class="state.subscriberServices.dataReady ? 'text-lifecycle_timeline_active_color mt-1 mr-2' : 'text-lifecycle_timeline_inactive_color mt-1 mr-2'">check_circle_outline</v-icon><p class="theme-font text-h5">Service Details</p>	
												</v-col>
											</v-row>
											<v-row>
												<v-col><v-divider></v-divider></v-col>
											</v-row>
											<v-row v-if="state.subscriberServices.services && state.subscriberServices.services.length > 0">
												<v-col>
													<v-row>
														<v-col>
															<v-row>
																<v-col>
																	<p class="theme-font-other font-weight-bold">Migrating Services</p>
																</v-col>
															</v-row>
															<v-row>
																<v-col>
																	<v-list>
																		<v-list-item v-for="service,index in state.subscriberServices.services" :key="index"  active-color="primary">
																			<template v-slot:prepend>
																				<v-icon icon="phone" size="16" class="mr-3" v-if="service.serviceType == 'NBICS'"></v-icon>
																				<v-icon icon="cloud" size="16" class="mr-3" v-if="service.serviceType == 'IAS'"></v-icon>
																			</template>
																			<v-list-item-title class="theme-pointer theme-font-other" >{{ service.serviceType }} {{ service.identifier }} {{ service.action }} <span class="text-caption text-decoration-underline ml-3 theme-pointer" @click="removeService(this,index,service)" v-if="!state.readOnly">REMOVE</span></v-list-item-title>
																		</v-list-item>
																	</v-list>																
																</v-col>
																
															</v-row>
														</v-col>
													</v-row>
													
												</v-col>
											</v-row>
											<v-row v-if="state.subscriberServices.services.length == 0 || state.subscriberServices.newService.addAnotherService">												
												<v-col>
													<v-row v-if="state.subscriberServices.services.length > 0">
														<v-col>
															<v-divider/>
														</v-col>
													</v-row>
													<v-row>
														<v-col>
															<p class="theme-other-font">Select one or more services to migrate.</p>
														</v-col>
													</v-row>
													<v-row>
														<v-col>
															<v-select :items="state.subscriberServices.serviceTypes" label="Service Type" variant="outlined" item-title="typeDescription"
															item-value="type" 
															v-model="state.subscriberServices.newService.serviceType"
															:hint="subscriberDetailsServiceTypeHint()"></v-select>
														</v-col>
													</v-row>
													<v-row v-if="state.subscriberServices.newService.serviceType == 'NBICS'">
															<v-col>
																<v-text-field label="Telephone Number" variant="outlined" v-model="state.subscriberServices.newService.identifier"></v-text-field>
															</v-col>
													</v-row>
													<v-row v-if="state.subscriberServices.newService.serviceType == 'NBICS'">
														<v-col>
														<v-select :items="state.subscriberServices.actions" label="Action" variant="outlined" item-title="typeDescription"
															item-value="type" 
															v-model="state.subscriberServices.newService.action"
															:hint="actionTypeHint()"></v-select>
														</v-col>
													</v-row>
													<v-row>
														<v-col>
														<v-btn @click="addService(this)" size="small" class="">Add Service</v-btn><v-btn @click="state.subscriberServices.newService.addAnotherService = false;" size="small" class="ml-5" v-if="state.subscriberServices.services.length > 0">Cancel</v-btn>
													</v-col>
													</v-row>	
												</v-col>
											</v-row>				
											<v-row v-else>
												<v-col>
													<p class="theme-font-other text-caption theme-pointer" @click="state.subscriberServices.newService.addAnotherService = true">Add Another Service</p>												
												</v-col>
											</v-row>																		
										</v-card-text>									
									</v-card>
								</template>
							</v-hover>
							<v-btn class="mt-10">Send Match Request</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
		</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import { useRoute, useRouter } from "vue-router";
	const router = useRouter();
	const { t }  = useI18n();
	
	const mainStore = useMainStore();

	const state = reactive({
		readOnly: false,
		dataReady: false,
		onboarding: undefined,
		mode: 'postcodeLookup',
		postCodeLookup: {
			postCodeToLookup: undefined,
			postCodeError: undefined,
			postCodeLookupResults: undefined,			
			otsConsumerMatchingRequests: undefined,
			otsMatchSelectedAddress: undefined,			
		},
		addressDetails: {
			dataReady: false,
		},
		subscriberDetails: {
			dataReady: false,
			firstName: undefined,
			lastName: undefined,
			accountNumber: undefined,
		},
		subscriberServices: {
			actions: [
				{
					type: "cease",
					typeDescription: "Cease",
				},{
					type: "port",
					typeDescription: "Port",
				}
			],
			serviceTypes: [
				{
					type: "IAS",
					typeDescription: "Internet Access",
				},{
					type: "NBICS",
					typeDescription: "Telephone",
				}
			],
			dataReady: false,
			services: [],
			newService: {
				addAnotherService: false,
				serviceType: 'IAS',
				action: 'cease',
				serviceIdentifier: ''
			}
		}

	});

const goToMessageForm = () => {
	router.push({name: 'gainCustomerMessage'});
}

const selectAddress = (event,adddressDetails) => {
	state.postCodeLookup.otsMatchSelectedAddress = adddressDetails;
	console.log(adddressDetails);
}



const addService = (event) => {
	var service = {
		serviceType: state.subscriberServices.newService.serviceType,
		action:  state.subscriberServices.newService.action,
		serviceIdentifier: state.subscriberServices.newService.serviceIdentifier
	};

	state.subscriberServices.services.push(service);
	state.subscriberServices.newService.addAnotherService = false;	
	state.subscriberServices.newService.serviceType = 'IAS';
	state.subscriberServices.newService.action = 'cease';
	state.subscriberServices.newService.serviceIdentifier = '';
	state.subscriberServices.dataReady = true;
}

const addressReady = () => {
	state.addressDetails.dataReady = true;
}


const removeService = (event,index,service) => {
	state.subscriberServices.services.splice(index, 1);
}



const actionTypeHint = () => {
			if (state.subscriberServices.newService.action == 'cease') {
				return 'This Service will cease.';
			} else if (state.subscriberServices.newService.action == 'port') {
				return "This number will port to us.";
			} 
			return '';												
	}

const subscriberDetailsServiceTypeHint = () => {
			if (state.subscriberServices.newService.serviceType == 'IAS') {
				return 'Internet including broadband and fibre.';
			} else if (state.subscriberServices.newService.serviceType == 'NBICS') {
				return "Telephone lines and VoIP Services.";
			} 
			return '';												
	}

const saveSubscriberDetails = (event) => {

	state.subscriberDetails.dataReady = (state.subscriberDetails.firstName && state.subscriberDetails.lastName && state.subscriberDetails.firstName.length > 0 &&  state.subscriberDetails.lastName.length > 0);
	
}

const changeSubscriberDetails = (event) => {
	state.subscriberDetails.dataReady = false;
}

	const lookupSubscriberDetails = () => {
		lookupExistingSubscriberDetails();
	}

	const lookupExistingSubscriberDetails = async () => {
		
		const data = new URLSearchParams();
			data.set('postcode', state.postCodeLookup.postCodeToLookup);	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/servicePassport/locateExistingMatchingRequests', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
									state.postCodeLookup.otsConsumerMatchingRequests = d.otsConsumerMatchingRequests;	
									if (d.otsConsumerMatchingRequests.length > 0) {
										state.mode = "matching results";
									}						
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}

	const lookupAddressDetails = async () => {
			if (!state.postCodeLookup) {
				state.postCodeLookup.postCodeError = 'Need to type a Postcode';
				return;
			}
			const data = new URLSearchParams();
			data.set('postcode', state.postCodeLookup.postCodeToLookup);	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/voxletServices/subscriberAddressLookup', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
									state.postCodeLookup.postCodeLookupResults = d.idealPostcodeResults.postcodeLookupDetails;
									state.mode = 'newMatchingRequest';
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}

		const createMatchRequest = async () => {
			let configuration = {
				grcpBrandName: "",
				foreName: state.subscriberDetails.firstName,
				name: state.subscriberDetails.lastName,
				account: "",
				uprn: state.subscriberDetails.lastName,
				postcode: state.postCodeLookup.otsMatchSelectedAddress.postcode,
				addressLine1: state.postCodeLookup.otsMatchSelectedAddress.addressLine1,
				addressLine2: state.postCodeLookup.otsMatchSelectedAddress.addressLine2,
				addressLine3: state.postCodeLookup.otsMatchSelectedAddress.addressLine3,
				postTown: state.postCodeLookup.otsMatchSelectedAddress.postTown,
				county: state.postCodeLookup.otsMatchSelectedAddress.county,
				services: state.subscriberServices.services	
			};
			
			const data = new URLSearchParams();
			data.set('residentialSwitchMatchRequest',JSON.stringify(configuration));	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/servicePassport/createOTSConsumerMatchingRequest', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
									console.log('match requested');
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}
</script>

<style scoped>
.v-list-item--active {
  background-color: red;
}
</style>