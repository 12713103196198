<template>	
		<v-row class="">
			<v-col cols="12" class="mt-0">	
				<v-list class="overflow-y-auto mt-0" style="height:400px">					
					<div  v-for="paymentCard in state.userPaymentCardMethods"  :key="paymentCard.primaryUUID">
						<v-list-item class="pl-0 pr-0 mt-0"  v-if="!isCardOnServiceAlready(paymentCard.primaryUUID)" >
							<v-hover>
							<template v-slot:default="{ isHovering, props }">							
							<v-card style="min-width:230px" class="ma-2" v-bind="props" :elevation="isHovering ? 6 : 2" @click="selectCard(this,paymentCard)">
								<v-table class="ma-1">
									<tbody>
										<tr>
											<td colspan="2" class="pl-1">
												<div class="d-flex">												
													<v-img :src="getCardIcon(paymentCard.cardBrand)" min-width="35" max-width="35" ></v-img> <p class="text-h6 mt-1 ml-2 text-truncate" >{{t( 'statics.cardBrands.'+paymentCard.cardBrand)	}}</p>
												</div>
												<div class="d-flex text-body-2 pl-1 ml-1"><b>Card Num</b> <v-spacer><p class="text-body-2" align="right">**** {{ paymentCard.cardNumberLastFour }}</p></v-spacer></div>
												<div class="d-flex pl-1 ml-1"><b>Expiry</b> <v-spacer><p class="text-body-2" align="right"> {{ paymentCard.cardExpiryMonth }}/{{ paymentCard.cardExpiryYear }}</p></v-spacer></div>
											</td>
										</tr>						
									</tbody>
								</v-table>
							</v-card>	
							</template>
							</v-hover>	
						</v-list-item>
					</div>						
				</v-list>				
			</v-col>	
		</v-row>			
	
</template>

<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import amex from '@/assets/images/Payment_Methods/Amex/48.png';
import applePay from '@/assets/images/Payment_Methods/ApplePay/48.png';
import dinersClub from '@/assets/images/Payment_Methods/DinersClub/48.png';
import discover from '@/assets/images/Payment_Methods/Discover/48.png';
import googlePay from '@/assets/images/Payment_Methods/GooglePay/48.png';
import maestro from '@/assets/images/Payment_Methods/Maestro/48.png';
import mastercard from '@/assets/images/Payment_Methods/Mastercard/48.png';
import payPal from '@/assets/images/Payment_Methods/PayPal/48.png';
import stripe from '@/assets/images/Payment_Methods/Stripe/48.png';
import unionPay from '@/assets/images/Payment_Methods/UnionPay/48.png';
import verifone from '@/assets/images/Payment_Methods/Verifone/48.png';
import visa from '@/assets/images/Payment_Methods/Visa/48.png';
import { isConditional } from '@babel/types';
const emits = defineEmits(["selectedCard"]);


const { t }  = useI18n();
const mainStore = useMainStore();

const MODE_SELECTION = 'selection';
const MODE_list = 'list';

const LIST_USER = "user";
const LIST_SERVICE = "service";

const state = reactive({		
	userPaymentCardMethods: [],
	selectedCardUUID: undefined,	
});

onMounted(() => {

});


const props = defineProps({
		creditCardConfig: {
			type: Object,
			required: true
		},
		phoneService: {
			type: Object,
			required: true
		},		
		paymentCardMethods: {
			type: Object,
				required: true
		},
		userPaymentCardMethods: {
			type: Object,
			required: true
		}
	});

	const getCardIcon = (cardType) => {
		let icon = undefined;
		switch (cardType) {
			case 'visa':
				icon = visa;
				break;
			case 'visa_debit':
				icon = visa;
				break;
			case 'amex':
				icon = amex;
				break;
			case 'amex2':
				icon = amex;
				break;
			case 'diners':
				icon = dinersClub;				
				break;
			case 'diners_14digits':
				icon = dinersClub;				
				break;
			case 'discover':
				icon = discover;				
				break;
			case 'discover2':
				icon = discover;				
				break;
			case 'mastercard_debit':
				icon = mastercard;				
				break;
			case 'mastercard_debit':
				icon = mastercard;				
				break;
			case 'mastercard_prepaid':
				icon = mastercard;				
				break;
			case 'unionpay':
				icon = unionPay;				
				break;
			case 'paypal':
				icon = payPal;				
				break;
			case 'googlepay':
				icon = googlePay;				
				break;
			case 'applepay':
				icon = applePay;				
				break;
			case 'verifone':
				icon = verifone;				
				break;
			case 'stripe':
				icon = stripe;				
				break;
			case 'maestro':
				icon = maestro;				
				break;
			default:
				icon = visa;
		}
		return icon;
	}

	const isCardOnServiceAlready = (stripePaymentMethodUUID) => {

		var onService = false;
		if (props.paymentCardMethods){
			props.paymentCardMethods.forEach( 
			(paymentCardMethod) => {					
					if (paymentCardMethod.primaryUUID == stripePaymentMethodUUID) {
						onService = true;					
					}
			}		
		);
		}
		return onService;
	}


	const selectCard = (event,paymentCard) => {
		state.selectedCardUUID = paymentCard.primaryUUID;
		if (props.creditCardConfig.mode == MODE_SELECTION) {
			addCardToService(event);
		} else {
			//for when we do more than we do right now!
		}
		
	} 
	
	const addCardToService = async (event) => {		
		const apiUrl = mainStore.getAPIUrl(props.phoneService.phoneServiceName);
		const data = new URLSearchParams();
		data.set('stripePaymentCardMethodUUID', state.selectedCardUUID);				
		await fetch(apiUrl+'/serviceBilling/addCardToService', {
			method: 'post',		
			body: data,		
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {						
						emits('selectedCard');
					}							
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});	
	}


	
 onUpdated(() => {		
		if (!props.userPaymentCardMethods) {
			listUserPaymentCards();
		} else {
			state.userPaymentCardMethods = props.userPaymentCardMethods;
		}
	});

	onMounted(() => {		
		if (!props.userPaymentCardMethods) {
			listUserPaymentCards();
		} else {
			state.userPaymentCardMethods = props.userPaymentCardMethods;
		}
	});


</script>

<style scoped>
.radio-group-full-width >>>.v-input__control {
     width: 100%
 }

 .radio-group-full-width >>>.v-selection-control-group {
     width: 100%
 }
</style>