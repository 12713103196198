<template>

	<v-container v-if="state.contentFileDescriptors" class="h-100">
		<v-row>
			<v-col class="h-100" > 
				<v-tabs  v-model="state.tabsTop" density="compact">					
					<v-tab value="select" :class="(state.tabsTop == 'select') ? 'bg-active_tab_color' : ''">
						<p class="text-h6 theme-font"><span v-if="state.browserMode == 'select'">Select</span><span v-else>Browse</span> Images</p>
					</v-tab>
					<v-tab value="upload" :class="(state.tabsTop == 'upload') ? 'bg-active_tab_color' : ''">
						<p class="text-h6 theme-font">Upload New</p>
					</v-tab>
					<v-spacer align="right">
						<v-icon class="ma-1" @click="setBrowserHeight('small')" :class="(state.browserHeightMode == 'small') ? 'bg-primary_900':''">check_box_outline_blank</v-icon><v-icon class="ma-1" @click="setBrowserHeight('medium')" :class="(state.browserHeightMode == 'medium') ? 'bg-primary_900':''">window</v-icon><v-icon class="ma-1" @click="setBrowserHeight('large')" :class="(state.browserHeightMode == 'large') ? 'bg-primary_900':''">grid_on</v-icon>
					</v-spacer>
				</v-tabs>
				<v-window v-model="state.tabsTop" class="fill-height bg-white  mt-0 pt-0">
					<v-window-item value="select">						
						<v-sheet class="d-flex flex-wrap bg-secondary_500 overflow-y-auto" :max-height="state.browserHeight" :min-height="state.browserHeight">												
							<v-card
								v-for="contentFileDescriptors,index in imageList()" :key="index"				
								:class="(isImageSelected(contentFileDescriptors.primaryUUID)) ? 'bg-primary_900':''"
								class="ma-2 pa-2 cardDimensions" style="position:relative"
								>
								<v-checkbox style="position:absolute;align:right;right:-8px;top:-14px;z-index:2000"  v-model="state.selectedImages" :value="contentFileDescriptors.primaryUUID" v-if="state.browserMode == 'select'"></v-checkbox>
								<v-img  :src="mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)" ></v-img>
							</v-card>
							
						</v-sheet>
						<v-spacer class="d-flex" v-if="state.browserMode == 'select'">
							<!--<p class="text-body-1 ma-1">{{ state.selectedImages.length }} Selected</p>-->
							<v-checkbox v-model="state.showSelectedOnly" value="1" class="text-no-wrap" label="Show Selected Only" :disabled="!state.selectedImages || state.selectedImages.length == 0"></v-checkbox>
							<v-spacer class="ma-2" align="right">
								<v-btn :class="(state.selectedImages) ? 'theme-bg-highlight':''" :disabled="!state.selectedImages || state.selectedImages.length == 0" @click="selectImages">Select</v-btn>
							</v-spacer>
						</v-spacer>
						<v-spacer style="min-height:63px" v-else>

						</v-spacer>
						
						
						</v-window-item>
						<v-window-item value="upload">
							<v-sheet class="d-flex flex-wrap bg-secondary_500 overflow-y-auto" :max-height="state.browserHeight" :min-height="state.browserHeight">
								<v-card					
									v-for="imageSrc,index in state.previewUploadImages" :key="index"				
									class="ma-2 pa-2 cardDimensions" style="position:relative" 									
								>							
								<v-img :src="imageSrc"></v-img>
							</v-card>
							</v-sheet>
							<v-spacer class="ma-2 d-flex" align="right">
									<v-file-input
													label="Select Images"
													v-bind:loading="state.uploading"
													variant="outlined"
													accept="image/png, image/jpeg, image/bmp, image/svg+xml"
													v-model="state.fileInputValue"
													@update:modelValue="imageSelected"
													multiple
													:disabled="state.uploading"													
												></v-file-input>																								
									<v-btn size="small" @click.once="upload" :class="state.selectedUploadFiles ? 'theme-bg-highlight mt-3 ml-2' : 'mt-3 ml-2'" :disabled="!state.selectedUploadFiles || state.uploading">Upload</v-btn>
							</v-spacer>
						</v-window-item>
					</v-window>				
			</v-col>
		</v-row>		
	
		
		
		
	</v-container>

</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import { useRoute, useRouter } from "vue-router";


	const router = useRouter();
	const { t }  = useI18n();
	const mainStore = useMainStore();

	const emits = defineEmits(['dataEvents']);

	const fireDataReadyEvent = () => {
			emits('dataEvents',
				{
					state: 'selectedImages',
					selectedImages: state.selectedImages,
				}
			);
	}

	const props = defineProps({
		configuration: {
			type: Object,
			required: false,
		}
	});

	const sheet = ref(null)

	const state = reactive({	
		browserMode: 'select',
		tabsTop: 'select',
		fileInputValue: undefined,
		selectedImages:  [],
		contentFileDescriptors: undefined,	
		selectedUploadFiles: undefined,
		previewUploadImages: undefined,
		browserHeightMode: 'small',
		browserHeight: 335,
		showSelectedOnly: 0,
		uploading: false,
	});

	const selectImages = () => {
		fireDataReadyEvent();
	}

	const imageList = () => {
		if (state.showSelectedOnly) {
			const selectedContentFileDescriptors = [];
			for (let j = 0; j < state.contentFileDescriptors.length;j++) {
				for (let i = 0; i < state.selectedImages.length;i++) {
					if (state.contentFileDescriptors[j].primaryUUID == state.selectedImages[i] ) {
						selectedContentFileDescriptors.push(state.contentFileDescriptors[j]);
					}
				}
			}
			return selectedContentFileDescriptors;
		} else {
			return state.contentFileDescriptors;
		}
	}

	const isImageSelected = (primaryUUID) => {
		for (let i = 0; i< state.selectedImages.length;i ++) {
			if (state.selectedImages[i] == primaryUUID) {
				return true;
			}
		}
		return false;
	}

	const imageSelected = (selectedUploadFiles) => {
		state.selectedUploadFiles = selectedUploadFiles;
		state.previewUploadImages = [];
		console.log('Field Selected '+typeof selectedUploadFiles);		
		let imageSrc = undefined;
		for (let i = 0; i < selectedUploadFiles.length;i++) {
			imageSrc = URL.createObjectURL(selectedUploadFiles[i]);
			state.previewUploadImages .push(imageSrc);
		}
	}

	const upload = () => {
		uploadFile();
	}

	const setBrowserHeight = (size) => {
		state.browserHeightMode = size;
		switch (size) {
			case "small":
				state.browserHeight = 335;				
				break;
			case "medium":
				state.browserHeight = 500;
				break;
			case "large":
				state.browserHeight = 650;
				break;
			default:
				state.browserHeight = 500;				
		}
	}

	const uploadFile = async () => {
		state.uploading = true;
		const apiUrl = mainStore.getAPIUrl();				
		var data = new FormData();
		for (let i = 0 ; i < state.selectedUploadFiles.length;i++) {
			data.append('file'+i, state.selectedUploadFiles[i]);	
		}
		data.append('appName','dan');
		
		await fetch(apiUrl+'/content/uploadContentFile', {
			method: 'post',								
			body: data,
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {			
					if (d.outcome == 1) {																					
						mainStore.displaySnackBar('file Uploaded');	
						refreshData();		
						state.uploading = false;	
						state.selectedUploadFiles = [];
						state.previewUploadImages = [];
						state.tabsTop = 'select';
						state.fileInputValue = undefined;
					}							
				}									
			)
			return response;
		}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);
		});		
	}

	const listContentFiles = async (show) => {
	const apiUrl = mainStore.getAPIUrl();				
			await fetch(apiUrl+'/content/listContentFiles', {
				method: 'get',								
				credentials: 'include',
				redirect: 'follow',		
			}).then((response) => {
				response.json().then( 
					d => {			
						if (d.outcome == 1) {															
							state.contentFileDescriptors = d.contentFileDescriptors;							
							mainStore.displaySnackBar('List Loaded');
							
						}							
					}									
				)
				return response;
			}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});		
	}
		
	const refreshData = () => {
		listContentFiles();
	}

	const processConfig = () => {
		if (props.configuration) {
			if (props.configuration.browserMode) {
				state.browserMode = props.configuration.browserMode;
			}
		}
	}

	onUpdated(() => {
		processConfig();
	});

	onMounted(() => {		
		processConfig();	
		
		refreshData();
		state.uploading = false;
	});
</script>

<style>

.v-selection-control__input > .v-icon {
	background: white;
}

.cardDimensions {
	min-width: 150px;
	min-height: 150px;
	max-width: 150px;
	max-height: 150px;
}

</style>