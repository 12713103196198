<template>
<v-container fluid class="px-6 py-6 h-100">
		<v-row>
			<v-col class="pa-0 ma-0">
				<v-row v-if="state.phoneServiceDetails">
					<v-col cols="4">
						<v-card>
							<BoxHeader headerText="Service News"/>
						
							<v-card-text>								
								<p class="theme-font-other font-italic text-caption mt-2">20-06-23</p>
								<p class="theme-font text-body-1 font-weight-bold ml-1">Service Passport Launch Date Announced</p>
								<p class="theme-font text-body-2 ml-1">With TOTSCo going live in 2026, we have announced that Service PAssport will be ready by 2029 and will be net-zero.</p>

								<p class="theme-font-other font-italic text-caption mt-2">19-06-23</p>
								<p class="theme-font text-body-1 font-weight-bold ml-1">Confusion Reigns in GPLB-SG</p>
								<p class="theme-font text-body-2 ml-1">With no launch plan visibile, how many days will it be before the team reach breaking point and throw in the towel.</p>
							</v-card-text>
						</v-card>
						
						<v-card class="mt-5">
							<BoxHeader headerText="Service Status"/>														
							<v-card-text>								
								<v-row>
									<v-col class="text-text-body-1 font-weight-bold mt-3">Service</v-col>
									<v-col class="text-text-body-1 font-weight-bold mt-3 text-center">Status</v-col>
								</v-row>
								<v-row >
									<v-col class="text-body-2 pa-1 ml-2">Connectivity</v-col>
									<v-col class="text-body-2 pa-1 text-green font-weight-bold text-center ">OK</v-col>
								</v-row>
								<v-row>
									<v-col class="text-body-2 pa-1 ml-2 ">Apps</v-col>
									<v-col class="text-body-2 pa-1 text-green font-weight-bold text-center">OK</v-col>
								</v-row>
								<v-row>
									<v-col class="text-body-2 pa-1 ml-2">Calls</v-col>
									<v-col class="text-body-2 pa-1 text-green font-weight-bold text-center">OK</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="4">
						<v-card>
							<BoxHeader headerText="Account Details"/>								
							<v-card-text>
								<v-row>
									<v-col>
										<p class="theme-font text-h6">Service Name</p>

										<p class="theme-font-other ml-5">{{  mainStore.phoneServiceName}}</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="pt-0">
										<p class="theme-font text-h6">Account Name</p>
									
									<p class="theme-font-other ml-5">{{  state.phoneServiceDetails.accountHolderName }}</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="pt-0">
										<p class="theme-font text-h6">Account Type</p>								
										<p class="theme-font-other ml-5">{{ t("statics.accountType."+ state.phoneServiceDetails.accountType) }}</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="pt-0">
										<p class="theme-font text-h6">Address</p>									
										<p class="theme-font-other ml-5">{{  state.phoneServiceDetails.addressOne }}</p>
										<p class="theme-font-other ml-5">{{  state.phoneServiceDetails.addressTwo }}</p>
										<p class="theme-font-other ml-5">{{  state.phoneServiceDetails.addressThree }}</p>
										<p class="theme-font-other ml-5">{{  state.phoneServiceDetails.townCity }}</p>
										<p class="theme-font-other ml-5">{{  state.phoneServiceDetails.county }}</p>
										<p class="theme-font-other ml-5">{{  state.phoneServiceDetails.postcode }}</p>
										<p class="theme-font-other ml-5">{{  state.phoneServiceDetails.country }}</p>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col cols="4">
						<v-card>
							<BoxHeader headerText="Billing Details"/>							
							<v-card-text>
								<v-row>
									<v-col>
										<p class="theme-font text-h6">Billing Schedule</p>							
										<p class="theme-font-other ml-5">Monthly</p>
									</v-col>
								</v-row>	
								<v-row>
									<v-col class="pt-0">
										<p class="theme-font text-h6">Billing Method</p>							
										<p class="theme-font-other ml-5">Credit Card</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="pt-0">
										<p class="theme-font text-h6">Billing Date</p>							
										<p class="theme-font-other ml-5">19th of the month</p>
									</v-col>
								</v-row>								
							</v-card-text>
						</v-card>
						
					</v-col>
					
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import CreditCardServiceListComponent from '../plustag/cards/CreditCardServiceListComponent.vue';
import BoxHeader from '../BoxHeader.vue';

const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const state = reactive({	
	tabsTop: 'home',
	phoneServiceDetails: undefined,
	paymentCardMethods: undefined,
	userPaymentCardMethods: [],
});

const serviceCreditCardConfig = {
	listType: 'service',
	mode: 'list',
};


const phoneService =  mainStore.getDomainDetails(mainStore.phoneServiceName);

const listServicePaymentCards = async () => {
		console.log('listing cards for '+mainStore.phoneServiceName);
		const apiUrl = mainStore.getAPIUrl();				
		await fetch(apiUrl+'/serviceBilling/listServiceCardPaymentMethods', {
			method: 'get',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {									
					if (d.outcome == 1) {			
						if (d.stripeCardPaymentMethods) {
							state.paymentCardMethods = d.stripeCardPaymentMethods;
						}	else {
							state.paymentCardMethods = [];
						}								
					}							
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});	
	}

onUpdated(() => {
	listServicePaymentCards();	
});


onMounted(() => {
	listServicePaymentCards();
	state.phoneServiceDetails = mainStore.phoneServiceDetails;
});

</script>

<style>

</style>
