<template>
<v-container>
	<v-form ref="form">
		
	<v-row>
		<v-col class="mt-5">
			<div class="text-h5">Account Type</div>
		</v-col>
	</v-row>
	<v-row>
		<v-col class="ml-5 mr-5">
			<v-radio-group v-model="state.phoneServiceDetails.accountType" inline>
				<v-radio label="Business"  v-bind:value="1"></v-radio>
			<!--	<v-radio label="Personal (Soon)" value="2" disabled></v-radio>-->
			</v-radio-group>
			<v-divider />
		</v-col>

	</v-row>
	<v-row>
		<v-col class="mt-5">
			<p class="text-h5">Account Name</p>
		</v-col>
	</v-row>
	<v-row>
		<v-col class="ml-5 mr-5">
			<v-row>
				<v-col><v-text-field v-model="state.phoneServiceDetails.accountHolderName"  @focusout="checkForm(this)" @input="checkForm(this)"  clearable hide-details="auto" variant="underlined" 
					:rules="formValidationRules.accountHolderName.rules" 
				label="Limited Company or Business Name*"></v-text-field></v-col>
				<v-col v-if="state.phoneService.phoneServiceName">
					<v-text-field readonly v-model="state.phoneService.phoneServiceName"  hide-details="auto" variant="underlined" 
				label="Service Name"></v-text-field>
				</v-col>
			</v-row>
			
		</v-col>
	</v-row>

	<v-row>
		<v-col class="mt-5">
			<p class="text-h5">Registered Office Address (ID1 1QD)</p>			
		</v-col>
	</v-row>
	<v-row v-if="state.showRegisteredOfficeHelp">
		<v-col class="bg-gray-100">
			<v-icon>info</v-icon> Help Text
		</v-col>
	</v-row>
	<v-row v-if="state.postCodeLookup.mode != 'lookupDisabled'">
		<v-col class="d-flex" cols="6">
			<v-text-field label="Postcode" variant="outlined" v-model="state.postCodeLookup.postCodeToLookup" class="mr-5" @keydown.enter="lookupSubscriberDetails()" ></v-text-field>
			<v-btn icon="search" :class="state.postCodeLookup.postCodeToLookup ? 'theme-bg-highlight mt-1' : 'mt-1'"
			 @click="lookupAddressDetails()"></v-btn>		
		</v-col>
	</v-row>
	<v-row v-if="state.postCodeLookup.postCodeError">
		<v-col>
			<p>{{ state.postCodeLookup.postCodeError }}</p>
		</v-col>
	</v-row>
	<v-row v-if="state.postCodeLookup.showProgress">
		<v-col>
			<v-container v-if="!state.i164IndustryHubs">
			<v-progress-circular
									indeterminate
									color="rgb(var(--v-theme-loading_spinner_color))"									
								></v-progress-circular>
			</v-container>
		</v-col>
	</v-row>
	<v-row v-if="(!state.postCodeLookup.selectedAddress) && state.postCodeLookup.postCodeLookupResults">									
		<v-col v-if="state.postCodeLookup.postCodeLookupResults.length == 0">
			No Addresses Found for that postcode
		</v-col>
		<v-col class="ml-0 pl-0" v-else>
			<p class="theme-font ml-2 pl-2 text-h6">Select an Address in <b>{{ state.postCodeLookup.postCodeToLookup }}</b></p>
			<v-list class="ml-0 pl-0">
				
					<v-list-item v-for="addressDetails,index in state.postCodeLookup.postCodeLookupResults" :key="index" active-color="vx_green_300"  variant="plain" class="pl-0">
						
						<template v-slot:prepend>
							<v-icon class="ma-2 pl-0" size="20">home</v-icon>
						</template>
						<v-list-item-title class="theme-pointer ml-0" v-if="addressDetails.addressLine2" @click="selectAddress(this,addressDetails)">{{addressDetails.addressLine1 }}, {{addressDetails.addressLine2 }}, {{ addressDetails.postTown }}</v-list-item-title>
						<v-list-item-title  class="theme-pointer" v-else @click="selectAddress(this,addressDetails)">{{addressDetails.addressLine1 }},  {{ addressDetails.postTown }}</v-list-item-title>
					</v-list-item>
		
			</v-list>			
												
		</v-col>

	</v-row>
		<v-container v-if="state.postCodeLookup.selectedAddress">
			<v-row>
				<v-col align="right">
					<v-btn class="pl-2 theme-list-action-button-background  text-action_button_text" @click="lookupNewAddress(this)" size="small" ><v-icon class="theme-list-action-icon">search</v-icon>Different Address</v-btn>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="ml-5 mr-5">
					<v-text-field v-model="state.phoneServiceDetails.addressOne" @focusin="checkForm(this)" @focusout="checkForm(this)" @input="checkForm(this)" :rules="formValidationRules.addressOne.rules" clearable hide-details="auto" variant="underlined" 
					label="Address Line One *"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="ml-5 mr-5">
					<v-text-field v-model="state.phoneServiceDetails.addressTwo" @focusin="checkForm(this)" @focusout="checkForm(this)" @input="checkForm(this)" :rules="formValidationRules.addressTwo.rules" clearable hide-details="auto" variant="underlined"
						label="Address Line Two"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="ml-5 mr-5">
					<v-text-field v-model="state.phoneServiceDetails.addressThree" @focusin="checkForm(this)" @focusout="checkForm(this)" @input="checkForm(this)" :rules="formValidationRules.addressThree.rules" clearable hide-details="auto" variant="underlined"
						label="Address Line Three"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="ml-5 mr-5">
					<v-text-field v-model="state.phoneServiceDetails.townCity" @focusin="checkForm(this)" @focusout="checkForm(this)" @input="checkForm(this)" :rules="formValidationRules.townCity.rules" clearable hide-details="auto" variant="underlined"
						label="Town / City*"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="ml-5 mr-5">
					<v-text-field v-model="state.phoneServiceDetails.county" @focusin="checkForm(this)" @focusout="checkForm(this)" @input="checkForm(this)" :rules="formValidationRules.county.rules" clearable hide-details="auto" variant="underlined"
						label="County"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="ml-5 mr-5">
					<v-text-field v-model="state.phoneServiceDetails.postcode" readOnly="true" clearable hide-details="auto" variant="underlined"
						label="Postcode*"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="ml-5 mr-5">
					
				</v-col>
			</v-row>
		</v-container>
	</v-form>
</v-container>
</template>
	


<script setup>
import { defineProps,reactive,watch,ref,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';

const mainStore = useMainStore();

const form = ref(null);

	const state = reactive({		
		onboarding: undefined,
		refreshVersion: 0,		
		dataReady: false,
		readOnly: false,
		phoneService: {},
		phoneServiceDetails: {
			accountType: 1,
		},
		postCodeLookup: {
			mode: 'postcodeLookup',
			postCodeToLookup: undefined,
			postCodeError: undefined,
			postCodeLookupResults: undefined,	
			selectedAddress: undefined,
			showProgress: false,
		},
	});

const props = defineProps({
	refreshConfiguration: {
			type: Object,
			required: true,
		},
		phoneServiceDetailsConfiguration: {
					type: Object,
					required: true
		},		
		readOnly: Boolean,	
});

const emits = defineEmits(['dataEvents']);

const lookupAddressDetails = async () => {
	if (!state.postCodeLookup) {
		state.postCodeLookup.postCodeError = 'Need to type a Postcode';
		return;
	}
	const data = new URLSearchParams();
	data.set('postcode', state.postCodeLookup.postCodeToLookup);	
	state.postCodeLookup.showProgress = true;
	const apiUrl = mainStore.getAPIUrl();				
		await fetch(apiUrl+'/voxletServices/subscriberAddressLookup', {
			method: 'post',			
			body: data,	
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == 1) {							
							state.postCodeLookup.postCodeLookupResults = d.idealPostcodeResults.postcodeLookupDetails;
							state.postCodeLookup.mode = 'selectAddress';
					} else {
						state.postCodeLookup.postCodeLookupResults = undefined;
						state.postCodeLookup.postCodeError = 'Error Looking Up Postcode Details'
					}			
					state.postCodeLookup.showProgress = false;
				}									
			)
			return response;
		}).catch((error) => {
			state.postCodeLookup.showProgress = false;
		console.log('Looks like there was a problem: \n', error);
	});		
}
const lookupNewAddress = (event) => {
	state.postCodeLookup.mode = 'selectAddress';
	state.postCodeLookup.selectedAddress = undefined;
	checkForm(event,true);
}

const selectAddress = (event,addressDetails) => {
	state.phoneServiceDetails.addressOne = addressDetails.addressLine1;
	state.phoneServiceDetails.addressTwo = addressDetails.addressLine2;
	state.phoneServiceDetails.addressThree = addressDetails.addressLine3;
	state.phoneServiceDetails.townCity = addressDetails.postTown;
	state.phoneServiceDetails.county = addressDetails.county;
	state.phoneServiceDetails.postcode = addressDetails.postcode;
	state.phoneServiceDetails.country = addressDetails.country;
	state.phoneServiceDetails.uprn = addressDetails.uprn;
	//Do this last
	state.postCodeLookup.selectedAddress = addressDetails;
	state.postCodeLookup.mode = 'lookupDisabled';
	checkForm(event,true);
}

const checkForm =  (event,validateForm) => {	
	let valid = true;
	if (state.phoneServiceDetails) {
		if (!state.phoneServiceDetails.accountHolderName || state.phoneServiceDetails.accountHolderName.length == 0) {
			const acc = state.phoneServiceDetails.accountHolderName;
			valid = false;
		}
		if (!state.phoneServiceDetails.addressOne || state.phoneServiceDetails.addressOne.length == 0) {
			valid = false;
		}
		if (!state.phoneServiceDetails.townCity || state.phoneServiceDetails.townCity.length == 0) {
			valid = false;
		}
		if (!state.phoneServiceDetails.postcode || state.phoneServiceDetails.postcode.length == 0) {
			valid = false;
		}
		if (!state.phoneServiceDetails.country || state.phoneServiceDetails.country.length == 0) {
			valid = false;
		}
	} else {
		valid = false;
	}
	if (state.postCodeLookup.mode != 'lookupDisabled') {
		valid = false;
	}
	if (validateForm) {
		form.value.validate();
	}	
	if (valid) {
		state.dataReady = true;
		fireDataReadyEvent();
		return true;
	} else {
		state.dataReady = false;
		fireDataNotReadyEvent();
		return false;
	}
	
}



const formValidationRules = {
	accountHolderName: {
		rules: [v => !!v || 'This field is required'],
	},
	addressOne: {
		rules: [v => !!v || 'This field is required'],
	},
	addressTwo: {
		rules: [v => true],
	},
	addressThree: {
		rules: [v => true],
	},
	townCity: {
		rules: [v => !!v || 'This field is required'],
	},
	county: {
		rules: [v => true],
	},	
	postcode: {
		rules: [v => !!v || 'This field is required'],
	},
	country: {
		rules: [v => !!v || 'This field is required'],
	} 
};

const fireDataReadyEvent = () => {
		emits('dataEvents',
			{
				state: 'ready',
				phoneServiceDetails: state.phoneServiceDetails,
				phoneService:  state.phoneServiceDetails,
			}
		);
}

const fireDataNotReadyEvent = () => {
		emits('dataEvents',
			{
				state: 'notReady'
			}
		);
}



const refreshState = () => {
		if (props.readOnly) {
				state.readOnly = props.readOnly;
		}
		if (props.phoneServiceDetailsConfiguration) {
			if (props.phoneServiceDetailsConfiguration.phoneServiceDetails) {
				state.phoneServiceDetails = props.phoneServiceDetailsConfiguration.phoneServiceDetails;
				state.postCodeLookup.selectedAddress = {};
				state.postCodeLookup.mode = 'lookupDisabled';
			}
			if (props.phoneServiceDetailsConfiguration.phoneService) {
				state.phoneService = props.phoneServiceDetailsConfiguration.phoneService;
			}						
		}	
		checkForm();			
	}

onUpdated((data) => {		
	console.log("onUpdated "+data);
		if (props.refreshConfiguration.version > state.refreshVersion) {
			state.refreshVersion = props.refreshConfiguration.version;			
			refreshState();
		}			
	});

	onMounted(() => {
		refreshState();
	});


</script>