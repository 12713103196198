<template>
	<v-container fluid class="px-6 py-6 h-100">
		<v-row>
				<v-col class="ma-0 pa-0">
					<v-spacer align="right">
						<v-row>
							<v-col cols="4" sm="7" md="7">
							</v-col>
							<v-col cols="5" sm="3" md="2">			
						
							</v-col>
							<v-col cols="3" sm="1" md="3" class="d-flex mt-4">
								<v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text" ><v-icon class="theme-list-action-icon">add</v-icon>Bundle</v-btn>
								<v-btn class="ml-2 theme-list-action-button-background text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
							</v-col>
						</v-row>
					</v-spacer>
				</v-col>	
			</v-row>			
			<v-row>			
				<v-col class="mt-0" >
				<v-card class="card-shadow border-radius-md listDisplay d-flex flex-column">						
						<v-table  class="" density="compact">														
							<thead class="theme-list-header-background" >
								<tr>
									<th class="text-center text-body-1 theme-listing-header-text">Name</th>															
									<th class="text-center text-body-1 theme-listing-header-text">Type</th>	
									<th class="text-center text-body-1 theme-listing-header-text">Tariff</th>	
									<th class="text-center text-body-1 theme-listing-header-text">Minutes</th>	
									<th class="text-center text-body-1 theme-listing-header-text"></th>
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="bundle in state.bundles"  :key="bundle.primaryUUID">																							
									<td class="text-center">{{ bundle.bundleName }}</td>
									<td class="text-center">{{ t('statics.bundle.bundleType.'+bundle.bundleType) }}</td>
									<td class="text-center">{{ bundle.tariffName }}</td>	
									<td class="text-center">{{ bundle.includedMinutes }}</td>					
								
									<td class="text-center">
										<v-menu location="center">
											<template v-slot:activator="{ props }">
												<v-icon v-bind="props">menu</v-icon>
											</template>

											<v-list>
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="manageBundle(bundle);">Manage Bundle</v-list-item-title>
												</v-list-item>
											</v-list>
										
										</v-menu></td>
								</tr>								
								<tr v-if="state.bundles.length == 0">
									<td colspan="5" class="theme-font text-center text-h6">No Records Found</td>
								</tr>	
								
							</tbody>

						</v-table>

						 <v-spacer></v-spacer>
							<!--<v-pagination
										v-model="state.pagination.page"
										:length="state.pagination.length"
										:total-visible="state.pagination.totalVisible"
										></v-pagination>-->
					</v-card>	
			</v-col>
		</v-row>
	</v-container>

</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import BoxHeader from '@/components/BoxHeader.vue';
import uuid from 'uuid-random';


const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const emits = defineEmits(['dataEvents']);

const state = reactive({	
	tabsTop: 'home',
	bundles: [],
	selectedBundle: undefined,
});

const fireDataReadyEvent = (event) => {				
		emits('dataEvents',
			{
				state: 'manageBundle',
				bundle: state.selectedBundle,
			}
		);
	}

const manageBundle = (bundle) => {
	state.selectedBundle = bundle;
	fireDataReadyEvent();
}

const refreshList = async () => {
			
		}


onUpdated(() => {	
		
	});


onMounted(() => {		
	console.log("mounted");
	let bundle =  {
				primaryUUID: uuid(),
				tariffUUID: uuid(),
				bundleName: 'Consumer 1000',
				tariffName: 'Main Tariff',
				bundleType: 'plan',
				includedMinutes: 1000,
				bundlePrice: '4.99'
			};	
			state.bundles.push(bundle);

			bundle =  {
				primaryUUID: uuid(),
				tariffUUID: uuid(),
				bundleName: 'Consumer 2000',
				tariffName: 'Main Tariff',
				bundleType: 'plan',
				includedMinutes: 2000,
				bundlePrice: '7.99'
			}	

			state.bundles.push(bundle);
 
});

</script>

<style>

</style>