<template>
	<v-container fluid class="px-6 py-6" v-if="state.contentPage" >		
		<v-row>
			<v-col class="d-flex mb-5">
				<p class="theme-font text-h6">{{ contentPage.pageInfo.contentReference }}</p>
				<v-spacer align="right"><p class="theme-font text-h6 mr-5">{{ contentPage.pageInfo.appName }}</p></v-spacer>
			</v-col>
		</v-row>
		<div  v-if="state.contentPage.content && state.contentPage.content.title">
		
						<ContentBlockDisplay :pageContext="pageContext" :contentConfiguration="state.contentConfiguration.title" contentFieldName="title"  :contentFragments="state.contentPage.content.title">
						</ContentBlockDisplay>					
		
		</div>
		<div v-if="state.contentPage.content && state.contentPage.content.topParagraph">
						<ContentBlockDisplay :pageContext="pageContext" :contentConfiguration="state.contentConfiguration.topParagraph" contentFieldName="topParagraph" :contentFragments="state.contentPage.content.topParagraph">
						</ContentBlockDisplay>					
		</div>
		<v-row>
			<v-col class="mt-5">
				<p class="theme-font text-h6">Command Details</p>
			</v-col>
		</v-row>
		<div v-if="state.contentPage.content && state.contentPage.content.commandDetails">
		
									
						<ContentBlockDisplay :pageContext="pageContext" :contentConfiguration="state.contentConfiguration.commandDetails" contentFieldName="commandDetails" :contentFragments="state.contentPage.content.commandDetails">
						</ContentBlockDisplay>					
				

		</div>
		
		<div v-if="state.contentPage.content && state.contentPage.content.inputProperties && state.contentPage.content.inputProperties.length >0">
			<v-row>
			<v-col>
				<p class="theme-font text-h6">Input Properties</p>
			</v-col>
		</v-row>
		<ContentBlockDisplay :pageContext="pageContext" :contentConfiguration="state.contentConfiguration.inputProperties" contentFieldName="inputProperties"  :contentFragments="state.contentPage.content.inputProperties" >
		</ContentBlockDisplay>					
		</div>
		<v-row>
			<v-col>
				<p class="theme-font text-h6">Command Response Codes</p>
			</v-col>
		</v-row>
		<div v-if="state.contentPage.content && state.contentPage.content.outcomes&& state.contentPage.content.outcomes.length > 0">
				
						<ContentBlockDisplay :pageContext="pageContext" :contentConfiguration="state.contentConfiguration.outcomes" contentFieldName="outcomes"  :contentFragments="state.contentPage.content.outcomes" >
						</ContentBlockDisplay>					
				
		</div>
		<v-row>	
			<v-col class="d-flex mt-5">
					<p class="font-italic theme-font-other text-caption">Updated:</p> <p class="ml-2 text-caption">{{ contentPage.pageInfo.updateDate }}</p>				
			</v-col>
		</v-row>
	</v-container>
</template>

<script setup>
import { inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ContentBlockDisplay from '../types/ContentBlockDisplay.vue';

const mainStore = useMainStore();

let pageContext = {
		stepCount: 0,
	};

const initialisePageContext = () => {
	pageContext = {
		stepCount: 0,
	};
}

const state = reactive({	
	contentPage: undefined,
	contentConfiguration: {
		title: {
			multiplicity: "single",
			contentTypes: [				
				{value: 'titleBlock', title:'Title'},		
			]
		},
		topParagraph: {
			multiplicity: "multiple",
			contentTypes: [
				{value: 'textBlock', title:'Paragraph'},		
				{value: 'titleBlock', title:'Title'},
				{value: 'stepBlock', title:'Step'},		
				{value: 'codeBlock', title:'Code Block'},				
			]
		},
		commandDetails: {
			multiplicity: "single",
			contentTypes: [
				{value: 'commandDetailsBlock', title:'Commmand'},
			]
		},
		inputProperties: {
			multiplicity: "multiple",
			contentTypes: [
				{value: 'inputPropertyBlock', title:'Properties'},
			]
		},
		outcomes: {
			multiplicity: "multiple",
			contentTypes: [
				{value: 'outcomeBlock', title:'Outcomes'},
			]
		}			
	}
});

const props = defineProps({		
	contentPage: {
		type: Object,
		required: true,
	}
});

onUpdated(() => {
	console.log('Updated');
	if (props.contentPage) {
		state.contentPage = props.contentPage;
	}
	initialisePageContext();
});

onMounted(() => {
	if (props.contentPage) {
		console.log('Mounted');
		state.contentPage = props.contentPage;
	}
	initialisePageContext();
});

</script>


<style scoped>


</style>