<template>
	<v-container fluid class="px-6 py-6 h-100">

		<v-container v-if="!state.i164IndustryHubs">
			<v-progress-circular
									indeterminate
									color="rgb(var(--v-theme-loading_spinner_color))"									
								></v-progress-circular>
		</v-container>
		<v-container v-if="state.i164IndustryHubs && state.i164IndustryHubs.length == 0">			
			<v-row>
				<v-col cols="12" sm="6" md="4" class="mt-5">
						<div v-if="!state.i164IndustryHubs">
							<p class="theme-font-other"><v-progress-circular
										indeterminate
										color="rgb(var(--v-theme-loading_spinner_color))"									
									></v-progress-circular></p>
						</div>
						<div v-else>
							<I164HubJoinPanel/>
						</div>														
				</v-col>
			</v-row>
		</v-container>
		<v-container fluid v-if="state.i164IndustryHubs && state.activeIndustryHub" class="h-100 px-1 py-1">						
			<v-tabs v-model="state.tabsTop">	
				<v-tab value="first" :class="(state.tabsTop== 'first') ? 'bg-active_tab_color':''">
					<v-avatar
							class="shadow border-radius-xl"
							size="32"  color="avatar_background"
						><v-icon class="vx-text-dark theme-avatar-color" size="24">hub</v-icon></v-avatar>							
							<p class="ml-2 text-h6 theme-font">Network Hub</p>
				</v-tab>					
				<v-tab :value="'memberIdentity_'+index" v-for="i164HubMemberIdentity,index in state.activeI164HubMember.i164HubMemberIdentities" :key="index" :class="(state.tabsTop== 'memberIdentity_'+index) ? 'bg-active_tab_color':''">
					<p class="text-h6 theme-font">Identity: {{ i164HubMemberIdentity.gainingProviderTradingName }}</p>
				</v-tab>
				<!--<v-tab value="dan" :class="(state.tabsTop== 'dan') ? 'bg-active_tab_color':''">
					Dan Stuff
				</v-tab>-->
			</v-tabs>
			<v-divider/>
			<v-window v-model="state.tabsTop" class="fill-height bg-white">
				<v-window-item value="first" class="ma-2">
					<v-row>
						<v-col  cols="12" sm="6" md="4">
							<v-row>
								<v-col>
									<I164HubMember :refreshConfiguration="state.assets.i164HubMember.refreshConfiguration" :asset-data="state.activeI164HubMember" :configuration="{}" :readOnly="(true)"/>
								</v-col>
							</v-row>						
							<v-row>
								<v-col>
									<I164HubStatus :refreshConfiguration="state.assets.i164HubStatus.refreshConfiguration" :asset-data="state.activeIndustryHub" :configuration="{}" :readOnly="(true)"/>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<I164HubParticipation :refreshConfiguration="state.assets.i164HubCommunityMembership.refreshConfiguration" :asset-data="state.i164HubCommunityMemberships" :configuration="{}" :readOnly="(true)"/>
								</v-col>
							</v-row>		
						</v-col>	
						<v-col  cols="12" sm="6" md="4" v-if="state.activeI164HubMember">
							<v-row >
								<v-col>
									<I164HubMemberEncryptionKeys :refreshConfiguration="state.assets.i164HubMemberEncryptionKey.refreshConfiguration" :asset-data="state.activeI164HubMember.i164HubMemberEncryptionKeys" @dataEvents="i164HubMemberEncryptionKeyEvent"  :configuration="{}" :readOnly="(true)"/>
								</v-col>
							</v-row>
							
						</v-col>													
						<v-col  cols="12" sm="6" md="4">

						</v-col>
					</v-row>
				</v-window-item>
				<v-window-item :value="'memberIdentity_'+index" v-for="i164HubMemberIdentity,index in state.activeI164HubMember.i164HubMemberIdentities" :key="index" class="ma-2">
					<v-row>
						<v-col cols="12" sm="4">
							<v-row>
								<v-col class="">
									<I164HubMemberIdentity :refreshConfiguration="state.assets.i164HubMemberIdentity.refreshConfiguration" :asset-data="i164HubMemberIdentity" :configuration="{}" :readOnly="(true)"/>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<I164HubMemberIdentityDataTypes :refreshConfiguration="state.assets.i164HubMemberIdentity.refreshConfiguration" :asset-data="i164HubMemberIdentity.i164HubMemberIdentityDataTypes" :configuration="{}" :readOnly="(true)"/>												
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-btn @click="deployConfiguration()">Deploy</v-btn>
								</v-col>
							</v-row>
						</v-col>
						<v-col cols="12" sm="8" class="">
							<v-tabs v-model="state.tabsInner">
								<v-tab value="messagesSending" :class="(state.tabsInner == 'messagesSending') ? 'bg-active_sub_tab_color':''" density="compact">
									<p class="text-h7 theme-font">Message Sending</p>
								</v-tab>
								<v-tab value="messagesReceiving" :class="(state.tabsInner == 'messagesReceiving') ? 'bg-active_sub_tab_color':''" density="compact">
									<p class="text-h7 theme-font">Messages Receiving</p>
								</v-tab>
								<v-tab value="messageDelivery" :class="(state.tabsInner == 'messageDelivery') ? 'bg-active_sub_tab_color':''" density="compact">
									<p class="text-h7 theme-font">Delivery Rules</p>
								</v-tab>							
							</v-tabs>
							<v-window v-model="state.tabsInner">
								<v-window-item value="messagesSending" class="ma-2">
									<v-row>
										<v-col class="ma-2"><p>These are the connection settings for sending messages to the hub from your servers.</p></v-col>
									</v-row>
									<v-row v-for="i164HubMemberEndpointKey,index in i164HubMemberIdentity.i164HubMemberEndpointKeys" :key="index">
										<v-col>
											<I164HubMemberEndpointKey :refreshConfiguration="state.assets.i164HubMemberEndpointKey.refreshConfiguration" :asset-data="i164HubMemberEndpointKey" :configuration="{}" @dataEvents="i164HubMemberEndpointKeyEvent"   :readOnly="(true)"/>
										</v-col>
									</v-row>
								</v-window-item>
								<v-window-item value="messagesReceiving" class="ma-2">
									<v-row>
										<v-col class="ma-2 d-flex"><p>These are the connection settings for receiving messages from the hub to your servers.</p>
											<!--<v-spacer align="right"><v-btn  size="small" class="pl-2 theme-list-action-button-background  text-action_button_text mr-5" ><v-icon class="theme-list-action-icon">add</v-icon>Endpoint Group</v-btn></v-spacer>				-->
										</v-col>
									</v-row>
									<v-row v-for="i164HubMemberEndpointGroup,index in i164HubMemberIdentity.i164HubMemberEndpointGroups" :key="index">
										<v-col>
											<I164HubMemberEndpointGroup :refreshConfiguration="state.assets.i164HubMemberEndpointGroup.refreshConfiguration" :asset-data="i164HubMemberEndpointGroup" @dataEvents="i164HubMemberEndpointGroupEvent"  :configuration="{}" :readOnly="(true)"/>
											
										</v-col>
									</v-row>
								</v-window-item>
							</v-window>

						</v-col>
					</v-row>
				</v-window-item>
				<v-window-item value="dan">
					<v-row>
						<v-col cols="12" sm="6" md="4">
						
							<v-row>
								<v-col><v-btn @click="createNetworkHubClick();">Create NETWORK Hub</v-btn></v-col>	<v-col><v-btn @click="loadNetworkHubsClick();">Load NETWORK Hub</v-btn></v-col>
							</v-row>
							

							<v-row v-for="i164IndustryHub,index in state.i164IndustryHubs" :key="index">
								<v-col>
									{{i164IndustryHub.primaryUUID}}
								</v-col>
								<v-col>
									{{i164IndustryHub.onboarding}}
								</v-col>		
								<v-col  cols="10">
									<v-btn @click="deleteHub(i164IndustryHub.primaryUUID)">DELETE</v-btn><v-btn @click="submitHub(i164IndustryHub.primaryUUID)">SUBMIT</v-btn><v-btn @click="approveHub(i164IndustryHub.primaryUUID)">APPROVE</v-btn><v-btn @click="endHub(i164IndustryHub.primaryUUID)">END</v-btn>
								</v-col>			
							</v-row>
						</v-col>
					</v-row>
				</v-window-item>
			</v-window>			
		</v-container>
		
		
	</v-container>
	
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import Popper from "vue3-popper";
	import ContextHelpPopper from "@/components/common/ContextHelpPopper.vue";
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import Quota from '@/microServices/quotas';
	import OuterCard from '@/components/common/OuterPopCard.vue';
	import I164HubJoinPanel from './components/I164HubJoinPanel.vue';
	import I164HubMember from './components/I164HubMember.vue';
	import I164HubStatus from './components/I164HubStatus.vue';
	import I164HubParticipation from './components/I164HubParticipation.vue';
	import I164HubMemberEndpointGroup from './components/I164HubMemberEndpointGroup.vue';
	import I164HubMemberIdentity from './components/I164HubMemberIdentity.vue';
	import I164HubMemberIdentityDataTypes from './components/I164HubMemberIdentityDataTypes.vue';
	import I164HubMemberEndpointKey from './components/I164HubMemberEndpointKey.vue';
	import I164HubMemberEncryptionKeys from './components/I164HubMemberEncryptionKeys.vue';
	

	const route = useRoute();
	const router = useRouter();


	const { t }  = useI18n();
	
	const mainStore = useMainStore();
	Quota.initialiseStore(mainStore);
	
	const state = reactive({		
		i164IndustryHubs: undefined,
		activeIndustryHub: undefined,
		i164HubMemberUUID: undefined,
		activeI164HubMember: undefined,
		i164HubCommunityMemberships: undefined,
		assets: {
			i164HubMember: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubMemberIdentity: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubStatus: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubCommunityMembership: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubMemberEndpointGroup: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubMemberEndpointKey: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
			i164HubMemberEncryptionKey: {								
				refreshConfiguration: {
					onboarding: undefined,		
					version:1
				},
				dataReady: false,
			},
		},
		tabsTop: 'first',
		tabsInner: 'messagesSending',
		filterValues: {
			timePeriod: 'day'
		},
		pagination: {
					page: 2,
					length: 4,
					totalVisible: 7,
				},	
		filters: {		
			timePeriod: [
				{value: 'day', title:'Day View'},
				{value: 'week', title:'Week View'},
				{value: 'month', title:'Month View'}				
			],			
		},	
	});

	

	const i164HubMemberEndpointKeyEvent = (event) => {
		console.log('i164HubMemberEndpointKeyEvent '+event.state);
		if (event.state == 'refreshRequired') {
			console.log("Refreshing Data");
			loadIndustryHub(state.activeIndustryHub.primaryUUID);
		}
	}

	const i164HubMemberEndpointGroupEvent = (event) => {
		console.log('i164HubMemberEndpointGroupEvent '+event.state);
		if (event.state == 'refreshRequired') {
			console.log("Refreshing Data");
			loadIndustryHub(state.activeIndustryHub.primaryUUID);
		}
	}

	const i164HubMemberEncryptionKeyEvent = (event) => {
		console.log('i164HubMemberEndpointGroupEvent '+event.state);
		if (event.state == 'refreshRequired') {
			console.log("Refreshing Data");
			loadIndustryHub(state.activeIndustryHub.primaryUUID);
		}
	}


	const changeTimePeriod = () => {
		console.log('changing time period');
	}

	const deleteHub = (voxletInstanceUUID) => {
		deleteHubRequest(voxletInstanceUUID);

	}
	const approveHub = (voxletInstanceUUID) => {
		approveHubRequest(voxletInstanceUUID);
	}

	const submitHub = (voxletInstanceUUID) => {
		submitHubRequest(voxletInstanceUUID);

	}


	const endHub = (voxletInstanceUUID) => {
		endHubRequest(voxletInstanceUUID);

	}


	const endHubRequest = async (voxletInstanceUUID) => {
		const data = new URLSearchParams();
		data.set('voxletInstanceUUID', voxletInstanceUUID);	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/endTelecomsIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								listNetworkHubs();
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}
	
	
	const submitHubRequest = async (voxletInstanceUUID) => {
		const data = new URLSearchParams();
		data.set('voxletInstanceUUID', voxletInstanceUUID);	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/submitTelecomsIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								listNetworkHubs();
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}

	const approveHubRequest = async (voxletInstanceUUID) => {
		const data = new URLSearchParams();
		data.set('voxletInstanceUUID', voxletInstanceUUID);	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/approveTelecomsIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								listNetworkHubs();
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}

	const deleteHubRequest = async (voxletInstanceUUID) => {
		const data = new URLSearchParams();
		data.set('voxletInstanceUUID', voxletInstanceUUID);	
		const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/deleteTelecomsIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								listNetworkHubs();
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
	}

	const checkQuotas = () => {
		fetchQuotas();
	}

	const fetchQuotas = async () => {
			const configuration = {
				resourceNames : ['i164.voxlets.networkHub.pojo.I164HubMember','i164.voxlets.networkHub.pojo.I164HubMemberIdentity'],
				resourceAllocationType: 4,
			}
			const data = new URLSearchParams();
			data.set('quotaRequestConfiguration', JSON.stringify(configuration));	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/voxletServices/fetchQuotas', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {							
								
								
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}

		const deployConfiguration = async () => {		
			const data = new URLSearchParams();
			state.activeI164HubMember;
			data.set('voxletInstanceUUID', state.activeI164HubMember.voxletInstanceUUID);		
			data.set('i164HubMemberUUID', state.activeI164HubMember.primaryUUID);		
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/deployMembershipConfiguration', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {							
								console.log(d);	
								mainStore.displaySnackBar('Deploying Configuration');																			
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}

	const listNetworkHubs = async () => {			
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/listIndustryHubs', {
					method: 'get',			
					//body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {															
								state.i164IndustryHubs = d.i164IndustryHubs;
								if (d.i164IndustryHubs.length >= 1) {									
									mainStore.appDataStore.networkHub.i164IndustryHubUUID = d.i164IndustryHubs[0].primaryUUID;
									loadIndustryHub(d.i164IndustryHubs[0].primaryUUID);

								}
								
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
		}

		const loadIndustryHub = async (voxletInstanceUUID) => {			
			const data = new URLSearchParams();
			data.set('voxletInstanceUUID', voxletInstanceUUID);	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/loadIndustryHub', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {							
								console.log(d);
								if (state.i164HubMemberUUID) {
									state.activeIndustryHub = d.i164IndustryHub;
									listHubCommunityMemberships();
									selectI164HubMember();
								} else {
									//Push to first member by default
									if (d.i164IndustryHub.i164HubMembers[0]) {
										console.log("pusing to router");
										router.push({name: 'networkHubSelectedMember',params: {i164HubMemberUUID: d.i164IndustryHub.i164HubMembers[0].primaryUUID }});
									} else {
										//Should not be possible!
									}
									
								}
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
		}

		const listHubCommunityMemberships = async (voxletInstanceUUID) => {			
			const data = new URLSearchParams();
			data.set('voxletInstanceUUID', voxletInstanceUUID);	
			const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/networkHub/listHubCommunityMemberships', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {							
								console.log(d);
								state.i164HubCommunityMemberships = d.i164HubCommunityMemberships;
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
		}

		

		const selectI164HubMember = (i164HubMemberUUID) => {
			if (!i164HubMemberUUID) {
				i164HubMemberUUID = state.i164HubMemberUUID;
			}		
			const i164HubMembers = state.activeIndustryHub.i164HubMembers;
			for (let i = 0; i < i164HubMembers.length;i++) {
				if (i164HubMembers[i].primaryUUID == i164HubMemberUUID) {
					state.activeI164HubMember = i164HubMembers[i];
					break;
				}
			}	
			refreshAllComponents();	
		}

		const refreshConfiguration = (assetName) => {				
			switch (assetName){
				case 'i164HubMemberEndpointGroup':				
					state.assets.i164HubMemberEndpointGroup.refreshConfiguration.version++;				
					state.assets.i164HubMemberEndpointGroup.refreshConfiguration = {
						onboarding: state.assets.i164HubMemberEndpointGroup.refreshConfiguration.onboarding,
						version: state.assets.i164HubMemberEndpointGroup.refreshConfiguration.version
					};				
					break;
				case 'i164HubMemberEndpointKey':				
					state.assets.i164HubMemberEndpointKey.refreshConfiguration.version++;				
					state.assets.i164HubMemberEndpointKey.refreshConfiguration = {
						onboarding: state.assets.i164HubMemberEndpointKey.refreshConfiguration.onboarding,
						version: state.assets.i164HubMemberEndpointKey.refreshConfiguration.version
					};				
					break;
			}									
		}

		const refreshAllComponents = () => {
			refreshConfiguration('i164HubMemberEndpointGroup');
			refreshConfiguration('i164HubMemberEndpointKey');				
		}

	onUpdated(() => {	
		if (route.params.i164HubMemberUUID) {
			state.i164HubMemberUUID = route.params.i164HubMemberUUID;			
		} 
		listNetworkHubs();
	});

	onMounted(() => {
		if (route.params.i164HubMemberUUID) {
			state.i164HubMemberUUID = route.params.i164HubMemberUUID;			
		} 
		listNetworkHubs();
	});
</script>

<style scoped>
	.card-floater {
		overflow: visible;
	}

	.help-format {
		font-size: 10px;
		text-decoration: underline;
		text-decoration-style: dashed;
		cursor: pointer;
	}

	:root {
    --popper-theme-background-color: #333333;
    --popper-theme-background-color-hover: #333333;
    --popper-theme-text-color: #ffffff;
    --popper-theme-border-width: 1px;
    --popper-theme-border-style: solid;
    --popper-theme-border-radius: 6px;
    --popper-theme-padding: 32px;
    --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
  }
	
</style>