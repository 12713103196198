<template>
	<v-container  class="px-6 h-100">
			<v-row>
				<v-col class="ma-0 pa-0">
					<v-spacer align="right">
						<v-row>
							<v-col cols="4" sm="7" md="7">
							</v-col>
							<v-col cols="5" sm="3" md="2">			
							<!--<v-select
									:items="state.filters.recordCount"				
									label="Showing"
									variant="outlined"
									item-title="title"
									item-value="value"	
									@update:modelValue="changeRecordCount"								
									v-model="state.filterValues.recordCount"
								></v-select>-->	
							</v-col>
							<v-col cols="3" sm="1" md="3" class="d-flex mt-4">
								<v-btn size="small" class="pl-2 theme-list-action-button-background  text-action_button_text" @click="viewTariffForm()"><v-icon class="theme-list-action-icon">add</v-icon>Tariff</v-btn>
								<v-btn class="ml-2 theme-list-action-button-background text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
							</v-col>
						</v-row>
					</v-spacer>
				</v-col>	
			</v-row>			
			<v-row>			
				<v-col class="mt-0" >	
					<v-card class="card-shadow border-radius-md listDisplay d-flex flex-column">						
						<!--	startDate: undefined,
								endDate: undefined,			
								tariffName: undefined,
								tariffDescription : undefined,
								minimumCharge: undefined,
								pricePer: undefined, -->
						<v-table  class="" density="compact">														
							<thead class="theme-list-header-background" >
								<tr>
									<th class="text-center text-body-1 theme-listing-header-text">Based On</th>															
									<th class="text-center text-body-1 theme-listing-header-text">Name</th>
									<th class="text-center text-body-1 theme-listing-header-text">Start Date</th>
									<th class="text-center text-body-1 theme-listing-header-text">Minimum Charge</th>
									<th class="text-center text-body-1 theme-listing-header-text">Frequency</th>
									<th class="text-center text-body-1 theme-listing-header-text">Pricing</th>
									<th class="text-center text-body-1 theme-listing-header-text"></th>
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="tariff in state.tariffs"  :key="tariff.primaryUUID">																							
									<td class="text-center "><p v-if="tariff.tariffType == 'custom'">{{ tariff.tariffType }}</p><p v-if="tariff.tariffType == 'managed'">{{ tariff.parentTariffName }}</p></td>
									<td class="text-center">{{ tariff.tariffName }}</td>						
									<td class="text-center"><p v-if="tariff.startDate">{{ tariff.startDate}}</p><p v-else>Not Started</p></td>										
									<td class="text-center">{{ tariff.minimumCharge }}p</td>	
									<td class="text-center">{{ t('statics.tariff.pricePer.'+tariff.pricePer) }}</td>	
									<td class="text-center "><div class="d-flex"><v-icon class="text-warning">warning</v-icon><p class="ml-1 font-weight-bold">Not Ready</p></div></td>	
									<td class="text-center">
										<v-menu location="center">
											<template v-slot:activator="{ props }">
												<v-icon v-bind="props">menu</v-icon>
											</template>

											<v-list>
												<v-list-item>
													<v-list-item-title class="theme-pointer" @click="manageTariff(tariff);">Manage Pricing</v-list-item-title>
												</v-list-item>
											</v-list>
										
										</v-menu></td>
								</tr>								
								<tr v-if="state.tariffs.length == 0">
									<td colspan="5" class="theme-font text-center text-h6">No Records Found</td>
								</tr>	
								
							</tbody>

						</v-table>

						 <v-spacer></v-spacer>
							<!--<v-pagination
										v-model="state.pagination.page"
										:length="state.pagination.length"
										:total-visible="state.pagination.totalVisible"
										></v-pagination>-->
					</v-card>	
				</v-col>
			</v-row>
			<v-row class="pd-10">
				&nbsp;
			</v-row>			
		</v-container>
		<v-dialog v-model="state.showTariffForm">
			<v-row>
				<v-col md="4" sm="2">					
				</v-col>
				<v-col cols="12" sm="8" md="4">
					<v-card>
						<BoxHeader headerText="Create Tariff" :closeButton="true" @close="closeTariffForm"/>										
						<v-card-text>		
							<TariffForm :tariff="state.selectedTariff" @dataEvents="tariffComplete"/>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4" sm="2">					
				</v-col>
			</v-row>

		</v-dialog>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import TariffForm from './components/TariffForm.vue';
	import BoxHeader from '@/components/BoxHeader.vue';
	import uuid from 'uuid-random';

	
	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const emits = defineEmits(['dataEvents']);

	const state = reactive({
		tariffs: [],
		showTariffForm: false,		
		selectedTariff: undefined,
		filters: {		
			recordCount: [
				{value: 10, title:'10'},
				{value: 25, title:'25'},
				{value: 50, title:'50'},
				{value: 100, title:'100'},					
			],			
		},
		values: {
			pricePer: [
				{value: 1 , title:'Per Second'},
				{value: 2, title:'Per Minute'},					
			]
		}		
	});

	const fireDataReadyEvent = (event) => {				
		emits('dataEvents',
			{
				state: 'manageTariff',
				tariff: state.selectedTariff,
			}
		);
	}

	const manageTariff = (tariff) => {
		state.selectedTariff = tariff;
		fireDataReadyEvent();
	}

	const tariffComplete = (event) => {
		console.log(JSON.stringify(event.tariff));
		state.tariffs.push(event.tariff);
		state.showTariffForm = false;
	}

	const viewTariff = async (primaryUUID) => {
		//router.push({name: 'invoiceDisplay',params: {serviceAccountInvoiceUUID: primaryUUID }});
	}

	const viewTariffForm = (tariff) => {
		state.selectedTariff = tariff;
		state.showTariffForm = true;
	}
	
	const closeTariffForm = (event) => {		
		state.showTariffForm = false;
	}

	const listTariffs = async () => {
			/**
				const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/serviceBilling/listTarrifs', {
					method: 'get',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == 1) {
									state.serviceAccountInvoices = d.serviceAccountInvoices;
							}							
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
			});		
			*/
		}

		const calcSubTotal = (serviceAccountInvoice) => {
			let subTotal = 0;
			if (serviceAccountInvoice) {
				for (let i = 0; i < serviceAccountInvoice.serviceAccountInvoiceItems.length;i ++) {
					subTotal += serviceAccountInvoice.serviceAccountInvoiceItems[i].amount;
				}
			}
			return subTotal;
		}

		const calcVat = (serviceAccountInvoice) => {
			let subTotal = calcSubTotal(serviceAccountInvoice);
			return (subTotal * (serviceAccountInvoice.vatForThisInvoice/100))			
		}

		const calcTotal = (serviceAccountInvoice) => {
			const total = calcSubTotal(serviceAccountInvoice)+ calcVat(serviceAccountInvoice);
			return total;
		}


		const refreshList = async () => {
			
		}


		onUpdated(() => {	
			
		});


		onMounted(() => {
			let tariff =  {
				primaryUUID: uuid(),
				parentTariffUUID: uuid(),
				parentTariffName: 'Magrathea Gold',
				tariffType:  'managed',	
				startDate: undefined,
				endDate: undefined,			
				tariffName: 'Main Tariff',
				tariffDescription : 'Our Default Tariff for Bundles',
				minimumCharge: 1,
				pricePer: 1,
			};	
			state.tariffs.push(tariff);
			//manageTariff(tariff);
			tariff =  {
				primaryUUID: undefined,
				parentTariffUUID: undefined,
				parentTariffName: undefined,
				tariffType:  'custom',	
				startDate: undefined,
				endDate: undefined,			
				tariffName: 'Offer Tariff',
				tariffDescription : 'Special Offer',
				minimumCharge: 3,
				pricePer: 2,
			}	
			state.tariffs.push(tariff);
		});

</script>

<style scoped>

    
</style>