<template>
	<v-container class="pt-0">
		FORM
	</v-container>
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import uuid from 'uuid-random';


	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const props = defineProps({
			
  });

	const state = reactive({
		dataReady: false,
		
	});
</script>

<style>

</style>