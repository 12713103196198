<template>
		<div class="theme-mainPanel fill-height" >					
			<v-row class="mb-5 fill-height" >
				<v-col md="4" ></v-col>
				<v-col md="4" class="fill-height d-flex align-center justify-center" >
					<v-card  class="card-shadow border-radius-md loadingBox" >
						<v-card-text class="align-center ma-5"  v-if="!state.requested">
							<v-row>
							<v-col class="ma-5"><v-img :src="servedUpLogo" max-width="150" ></v-img></v-col>
						</v-row>
						<v-row>
							<v-col>
								<p class="text-h5 theme-font vx-text-dark">
									Your Email is not Verified
								</p>
							</v-col>								
						</v-row>
						<v-row>
							<v-col>
									<p class="text-body-2 ma-2">We need to verify your email address.</p>
									<p class="text-body-2 ma-2"><b v-if="mainStore.loggedInStatus">{{mainStore.loggedInStatus.loggedInUserDomains.user.email}}</b></p>									
									<p class="text-body-2 ma-2">Please check your INBOX or SPAM folder for a verification email.</p>
									<p class="text-body-2 ma-2">If you cannot find this email then we can resend it.</p>								
								</v-col>							
						</v-row>
						
						<v-row>
							<v-col class="text-center mt-5">
									<v-btn @click="resendVerificationEmail()">RESEND EMAIL</v-btn>
								</v-col>				
						</v-row>
						</v-card-text>
						<v-card-text class="align-center ma-5"  v-if="state.requested">
							<v-row>
							<v-col class="ma-5"><v-img :src="servedUpLogo" max-width="150" ></v-img></v-col>
						</v-row>
						<v-row>
							<v-col>
								<p class="text-h5 theme-font vx-text-dark">
									Resent Verification Email
								</p>
							</v-col>								
						</v-row>
						<v-row>
							<v-col>
									<p class="text-body-2 ma-2">We have sent another verification email to your address.</p>
									<p class="text-body-2 ma-2"><b v-if="mainStore.loggedInStatus">{{mainStore.loggedInStatus.loggedInUserDomains.user.email}}</b></p>									
									<p class="text-body-2 ma-2">Please check your INBOX or SPAM folder for a verification email.</p>							
								</v-col>							
						</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col md="4" ></v-col>
			</v-row>
				</div>
</template>

<script setup>
	import { inject, ref, reactive, onMounted,onUnmounted } from 'vue';
	import {useMainStore} from '../stores/MainStore.js';
	import voxhubLogo from '@/assets/images/voxhub_greenOnWhite.svg';
	import servedUpLogo from '@/assets/images/servedup-shadow-300x39.png';

	const state = reactive({
		requested: false,	
		requestOutcome: 0,			
	});

	const mainStore = useMainStore();

	const resendVerificationEmail = async () => {

		
				await fetch(mainStore.getAPIUrl('plustag')+'/plustag/resendEmailVerification', {
					method: 'POST',
					body: data,
					credentials: 'include',
    			redirect: 'follow',																									
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)		
							state.requestOutcome = d.outcome;					
							if (d.outcome == 1 ) {
								console.log("Account Created");	
								state.requested = true;															
							} else if (d.outcome == -1) {//Internal Failure						
							
							} else if (d.outcome == -99998) {//Internal Error

							} else if (d.outcome == -99989) {//Internal Error
							
							} else {
								state.requestOutcome = d.outcome;
							}
						}									
					)
					return response;
				}).catch((error) => {
					console.log('Looks like there was a problem: \n', error);
					
			});		


	}

</script>

<style scoped>

</style>