<template>
<v-container fluid class="px-6 py-6 h-100">		
		<v-row>
			<v-col>						
				<v-card>							
					<v-card-text>
						<v-row>
							<v-col class="d-flex ml-5 mr-5 mb-0 pb-0">
								<v-text-field  @focusin="valid(this)" @focusout="valid(this)"  @input="valid(this)"  @keyup.native.enter="validInput(this)" label="Search Text" variant="outlined" v-model="state.search.searchText"></v-text-field>
								<v-btn icon="search"  :class="state.search.ready ? 'theme-bg-highlight mt-1 ml-5' : 'mt-1 ml-5'" :disabled="!state.search.ready"  @click="searchContent()"></v-btn> 
							</v-col>
						</v-row>
						<v-row>
							<v-col class="d-flex ml-5">
								<v-spacer class="d-flex">
									<p class="theme-font text-h6">Apps:</p>
									<div class="d-flex flex-wrap">
										<v-chip v-if="!state.search.appName || state.search.appName.length == 0" class="ma-1 mt-2" size="x-small">All</v-chip>
										<v-chip v-for="appName in state.search.appName "  class="ma-1 mt-2" size="x-small">{{ lookUpTitle(state.filters.apps,appName) }}</v-chip>
									</div>
								</v-spacer>
								<v-spacer class="d-flex">
									<p class="theme-font text-h6">Type:</p>
									<div class="d-flex flex-wrap">
										<v-chip v-if="!state.search.pageType || state.search.pageType.length == 0" class="ma-1 mt-2" size="x-small">All</v-chip>
										<v-chip v-for="pageType in state.search.pageType"  class="ma-1 mt-2" size="x-small">{{ lookUpTitle(state.filters.pageTypes,pageType) }}</v-chip>
									</div>
								</v-spacer>
								<v-spacer class="d-flex">
									<p class="theme-font text-h6">Audience:</p>
									<div class="d-flex flex-wrap">
										<v-chip v-if="!state.search.audience || state.search.audience.length == 0" class="ma-1 mt-2" size="x-small">All</v-chip>
										<v-chip v-for="audience in state.search.audience"  class="ma-1 mt-2" size="x-small">{{ lookUpTitle(state.filters.audience,audience) }}</v-chip>
									</div>
								</v-spacer>
								<v-spacer>
									<v-btn class="theme-bg-highlight" size="x-small" @click="toggleFilterDisplay(event)">Filter</v-btn>
								</v-spacer>
							</v-col>
						</v-row>						
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
		<v-row v-if="state.contentPages">			
			<v-col >
				<v-card class="">		
					<v-card-text>
						<v-row>
							<v-col>
								<v-tabs v-model="state.tabsTop" density="compact">	
									<v-tab value="results" :class="(state.tabsTop== 'results') ? 'bg-active_sub_tab_color':''">
										<p class="text-h6 theme-font">Results</p>
									</v-tab>					
									<v-tab  v-for="contentPage,index in state.openPages" :key="index" :class="(state.tabsTop== 'contentPage_'+contentPage.primaryUUID) ? 'bg-active_sub_tab_color':''" :value="'contentPage_'+contentPage.primaryUUID" class="pr-0">
										<p class="text-h6 theme-font"> {{ contentPage.pageInfo.contentReference }}</p> <v-icon @click.prevent.stop="closeContentPage(this,contentPage)" size="x-small" class="ml-4 mb-4">close</v-icon>
									</v-tab>
								</v-tabs>						
								<v-divider></v-divider>
							</v-col>									
						</v-row>
						<v-row>
							<v-col>
								<v-window v-model="state.tabsTop" class="fill-height bg-white">
									<v-window-item value="results" class="ma-2">
										<v-row v-for="contentPage,index in state.contentPages"  :key="index">
											<v-col class="theme-pointer" @click="openContentPage(this,contentPage)">
												<v-divider v-if="index != 0"></v-divider>	
												<v-hover>
													<template v-slot:default="{ isHovering, props }">
														<div :class="(isHovering) ? 'bg-secondary_900':''">
															<div v-bind="props" class="d-flex mt-5">
																<v-icon>article</v-icon><p class="ml-8 text-body-1">{{contentPage.summaryTitle}}</p>
															
																	<v-spacer align="right">
																		<div class="d-inline-flex">
																			<p class="theme-font-other text-body-1 ml-5 mr-2">{{  t("statics.appNames."+contentPage.pageInfo.appName)}}</p>
																			<p class="text-uppercase theme-font text-body-1 ml-5 mr-2" style="min-width:100px">{{contentPage.pageInfo.contentReference}}</p>
																		</div>
																	</v-spacer>																		
															</div>
															<div class="ma-5" v-if="contentPage.summaryDescription">
																<p class="text-body-1">{{contentPage.summaryDescription}}</p>
															</div>
														</div>
													</template>
												</v-hover>
											</v-col>
										</v-row>
										<v-row v-if="state.contentPages && state.contentPages.length == 0">
											<v-col>
												<p class="theme-font test-h4">No Documents Found</p>
											</v-col>													
										</v-row>
									</v-window-item>
									<v-window-item  class="fill-height" v-for="page,index in state.openPages" :key="index" :value="'contentPage_'+page.primaryUUID">												
										<ArticleDisplay :contentPage="page" v-if="page.pageInfo.pageType == 'article'" @goToContent="goToContent"/>
										<FaqDisplay :contentPage="page" v-if="page.pageInfo.pageType == 'faq'" @goToContent="goToContent"/>	
										<CommandDisplay :contentPage="page" v-if="page.pageInfo.pageType == 'command'" @goToContent="goToContent"/>		
										<HelpButtonDisplay :contentPage="page" v-if="page.pageInfo.pageType == 'helpButton'" @goToContent="goToContent"/>												
									</v-window-item>
								</v-window>
							
								
							</v-col>
						</v-row>
						
						
					
				<!--<v-pagination
						v-model="state.pagination.page"
						:length="state.pagination.length"
						:total-visible="state.pagination.totalVisible"
						></v-pagination>-->
					</v-card-text>	
				</v-card>	
			</v-col>
		</v-row>
		<v-row v-if="state.searching">
			<v-col>
				<v-spacer class="d-flex"><v-progress-circular
									indeterminate
									color="rgb(var(--v-theme-loading_spinner_color))"	
									class="mt-1"								
								></v-progress-circular><p class="text-h4 theme-font ml-5">Searching</p>
							</v-spacer>
			</v-col>
		</v-row>			
	</v-container>
	<v-dialog persistent v-model="state.filterValues.show">
		<v-row>
				<v-col md="3" sm="2">					
				</v-col>
				<v-col cols="12" sm="8" md="6">
					<v-card>
						<v-card-text>
							<v-row>
							<v-col class="pa-0" >
								<v-row class="theme-box-header-background">
									<v-col class="d-flex ma-2">
										<p class="theme-font text-h5 text-white">Search Filters</p>
										<v-spacer align="right" @click="closeFilter(this);"><v-icon>close</v-icon></v-spacer>
									</v-col>	
								</v-row>
								<v-row>
									<v-col class="ma-5 mb-0" >
										<v-select
											v-model="state.search.appName"
											:items="state.filters.apps"
											variant="outlined"
											label="App Name"
											item-title="title"
											item-value="value"	
											multiple
											@update:modelValue="valid(this)"	
										>
											<template v-slot:selection="{ item, index }">
												<v-chip v-if="index < 3">
													<span class="text-caption">{{ item.title }}</span>
												</v-chip>
												<span
													v-if="index === 3"
													class="text-grey text-caption align-self-center"
												>
													(+{{ state.search.appName.length - 3 }} others)
												</span>
											</template>
										</v-select>

						
										<v-select
											v-model="state.search.pageType"
											:items="state.filters.pageTypes"
											variant="outlined"
											label="Type"
											item-title="title"
											item-value="value"	
											multiple
											@update:modelValue="valid(this)"	
										>
											<template v-slot:selection="{ item, index }">
												<v-chip v-if="index < 3">
													<span class="text-caption">{{ item.title }}</span>
												</v-chip>
												<span
													v-if="index === 3"
													class="text-grey text-caption align-self-center"
												>
													(+{{ state.search.pageType.length - 3 }} others)
												</span>
											</template>
										</v-select>

										<v-select
											v-model="state.search.audience"
											:items="state.filters.audience"
											variant="outlined"
											label="Audience"
											item-title="title"
											item-value="value"	
											multiple
											@update:modelValue="valid(this)"	
										>
											<template v-slot:selection="{ item, index }">
												<v-chip v-if="index < 3">
													<span class="text-caption">{{ item.title }}</span>
												</v-chip>
												<span
													v-if="index === 3"
													class="text-grey text-caption align-self-center"
												>
													(+{{ state.search.audience.length - 3 }} others)
												</span>
											</template>
										</v-select>
									</v-col>
								</v-row>
								<v-row>
									<v-col class="mt-0 mb-5 mr-5" align="right">
										<v-btn class="theme-bg-highlight" @click="closeFilter(this)">Change</v-btn>
									</v-col>
								</v-row>
							</v-col>
						</v-row>									
						</v-card-text>
						
					</v-card>
				</v-col>
			<v-col md="3" sm="2">					
			</v-col>
		</v-row>
	</v-dialog>


</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import ArticleDisplay from './pages/ArticleDisplay.vue';
import FaqDisplay from './pages/FaqDisplay.vue';
import CommandDisplay from './pages/CommandDisplay.vue';
import HelpButtonDisplay from './pages/HelpButtonDisplay.vue';


const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const state = reactive({	
	searching: false,
	search : { 
		ready: false,
		searchText: undefined,
		appName: [],
		pageType: [],
		audience: [],
	},
	contentPages: undefined,
	openPages: [],
	filterValues: {
			recordCount: 10,
		},					
	pagination: {
					page: 2,
					length: 4,
					totalVisible: 7,
		},
	tabsTop: 'results',
	filters: {
		pageTypes: [
			//{value: 'all' , title:'Show All'},
			{value: 'article', title:'Article'},
			{value: 'webPage', title:'Web Page'},
			{value: 'faq', title:'FAQ'},							
			{value: 'command' , title:'Command'},
			{value: 'helpButton' , title:'Help Button'},							
		],	
		apps: [
			///{value: 'all' , title:'Show All'},
			{value: 'servicePassport' , title:'Service Passport'},
			{value: 'lines', title:'Lines'},			
			{value: 'developer', title:'Developer'},					
			],				
		audience : [
			//{value: 'all' , title:'Show All'},
			{value: 'general' , title:'General'},
			{value: 'technical', title:'Technical'},		
			{value: 'developer', title:'Developer'},
			{value: 'financial', title:'Financial'},
			{value: 'business', title:'Business'},
		]
	},
});

const {showContentUUID } = storeToRefs(mainStore);

watch(showContentUUID, (to,from) => {
	console.log("showContentUUID in changed from "+from+" to "+to)	;
	if (to) {
		loadContent(to);
	}
	
})


const goToContent = (event) => {
	console.log(event);
	loadContent(event.primaryUUID);
}

const openContentPage = (event,contentPage) => {
	let openNewTab = true;
	for (let i = 0; i < state.openPages.length;i++) {
		if (state.openPages[i].primaryUUID == contentPage.primaryUUID) {
			openNewTab = false;
			break;
		}
	}
	if (openNewTab) {
		state.openPages.push(contentPage);
	}	
	state.tabsTop = 'contentPage_'+contentPage.primaryUUID;
}

const closeContentPage = (event,contentPage) => {
	for (let i = 0; i < state.openPages.length;i++) {
		if (state.openPages[i].primaryUUID == contentPage.primaryUUID) {
			state.openPages.splice(i, 1);
			break;
		}
	}
	state.tabsTop = 'results';
}

const loadContent = async (primaryUUID) => {
	const data = new URLSearchParams();		
	data.set('contentPageInfoUUID', primaryUUID);	
	const apiUrl = mainStore.getAPIUrl();				
	return await fetch(apiUrl+'/content/loadContentPage', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {		
								mainStore.displaySnackBar("Page Loaded");
								openContentPage(null,d.contentPage);
								mainStore.showContentUUID = undefined;																			
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
}

const lookUpTitle = (values,value) => {
	for (let i = 0;values.length;i++) {
		if (values[i].value == value) {
			return values[i].title;
		}
	}
	return undefined;
}

const valid = (event) => {
	//if (state.search.searchText && state.search.searchText.length > 0) {
		state.search.ready = true;
	//}  else {
	//	state.search.ready = false;
//	}
	
}

const validInput = (event) => {
	valid(event);
	if (state.search.ready) {
		searchContent();
  }
}


const toggleFilterDisplay = (event) => {
	state.filterValues.show = true;
}

const closeFilter = (event) => {
	state.filterValues.show = false;
	validInput(event);
}

const searchContent = async () => {
	state.searching = true;
	const data = new URLSearchParams();		
	if (state.search.searchText) {
		data.set('searchText', state.search.searchText);			
	}
	if (state.search.audience && state.search.audience.length > 0) {
		data.set('audience', state.search.audience);	
	}
	if (state.search.appName && state.search.appName.length > 0) {
		data.set('appName', state.search.appName);	
	}
	if (state.search.pageType && state.search.pageType.length > 0) {
		data.set('pageType', state.search.pageType);	
	}
	const apiUrl = mainStore.getAPIUrl();				

	await fetch(apiUrl+'/documentation/searchDocumentation', {
		method: 'post',			
		body: data,	
		credentials: 'include',
		redirect: 'follow',		
	}).then((response) => {
		response.json().then( 
			d => {			
				if (d.outcome == 1) {															
					state.contentPages = d.contentPages;
				}	else {
					mainStore.displaySnackBar('OOps! '+d.message);
				}						
				state.searching = false;
			}									
		)
		return response;
	}).catch((error) => {
	console.log('Looks like there was a problem: \n', error);
});		

}

const goToContentList = (event) => {
	router.push({name: 'listContent'});
}


const viewContent = (contentPage) => {
	router.push({name: 'article',params: {contentPageInfoUUID: contentPage.pageInfo.primaryUUID }});
}




</script>

<style>

</style>