<template>
	<v-dialog persistent v-model="state.show">	
		<v-row v-if="state.contentPage">
			<v-col md="3" sm="2" lg="4">					
			</v-col>
			<v-col cols="12" sm="8" md="6" lg="4">
				<v-card elevation="6">
					<v-card-text>			
						<v-row>
							<v-col class="pa-0" >
								<BoxHeader :headerText="state.contentPage.summaryTitle" closeButton="true" @close="close"/>
								<!--<v-row class="theme-box-header-background">
									<v-col class="d-flex ma-2">
										<p class="theme-font text-h5 text-white">{{ state.contentPage.summaryTitle }}</p>
										<v-spacer align="right" @click="close(this);"><v-icon>close</v-icon></v-spacer>
									</v-col>	
								</v-row>-->
							</v-col>
						</v-row>	
						<ArticleDisplay :contentPage="state.contentPage" v-if="state.contentPage.pageInfo.pageType == 'article'"/>
						<FaqDisplay :contentPage="state.contentPage" v-if="state.contentPage.pageInfo.pageType == 'faq'"/>	
						<CommandDisplay :contentPage="state.contentPage" v-if="state.contentPage.pageInfo.pageType == 'command'"/>	
						<HelpButtonDisplay :contentPage="state.contentPage" v-if="state.contentPage.pageInfo.pageType == 'helpButton'" :helpButton="(true)"/>		
					</v-card-text>
				</v-card>
			</v-col>
			<v-col md="3" sm="2" lg="4">					
			</v-col>
		</v-row>		
		<v-row v-if="state.notFound">
			<v-col md="3" sm="2" lg="4">					
			</v-col>
			<v-col cols="12" sm="8" md="6" lg="4">
				<v-card elevation="6">
					<v-card-text>			
						<v-row>
							<v-col class="pa-0" >
								<v-row class="theme-box-header-background">
									<v-col class="d-flex ma-2">
										<p class="theme-font text-h5 text-white">Inline Help</p>
										<v-spacer align="right" @click="close(this);"><v-icon>close</v-icon></v-spacer>
									</v-col>	
								</v-row>
							</v-col>
						</v-row>	
						<v-row>
							<v-col class="ma-5">
								<p class="theme-font text-h6">
									404 Content Not Found
								</p>								
								<p class="theme-font-other ma-5">
									Sorry, it seems we have missed this one or there is an error.
								</p>
								<p class="theme-font-other text-caption">
									Content Tag: <i>{{ state.permaLinkName }}</i>
								</p>
							</v-col>
						</v-row>						
					</v-card-text>
				</v-card>
			</v-col>
			<v-col md="3" sm="2" lg="4">					
			</v-col>	
		</v-row>	
	</v-dialog>
</template>

<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import content from '../content/content.js';
import ArticleDisplay from '../content/pages/ArticleDisplay.vue';
import FaqDisplay from '../content/pages/FaqDisplay.vue';
import CommandDisplay from '../content/pages/CommandDisplay.vue';
import HelpButtonDisplay from '../content/pages/HelpButtonDisplay.vue';
import BoxHeader from '../BoxHeader.vue';


const { t }  = useI18n();

const mainStore = useMainStore();
content.initialiseStore(mainStore);

const state = reactive({		
		show: false,			
		commandPageInfoUUID: undefined,
		permaLinkName: undefined,
		contentPage: undefined,
		notFound: false,
		version: undefined,
});

const emits = defineEmits(['close']);

const close = (event) => {	
	emits('close');	
}


const props = defineProps({
		show: Boolean,
		permaLinkName: String,
		commandPageInfoUUID: String,
});

const loadContentPage = () => {
	if (state.commandPageInfoUUID) {
		content.loadContent(
			(data) => {
				state.contentPage = data.contentPage;
				state.version = data.contentPage.pageInfo.version;	
			}
		);
	}
	if (state.permaLinkName) {
		
		content.loadPermaLinkContent(
			state.permaLinkName,
			(data) => {
				if (data.contentPage) {
					state.contentPage = data.contentPage;
					state.version = data.contentPage.pageInfo.version;					
				} else {
					state.notFound = true;					
				}

			}
		);
	}
}

onUpdated(() => {		
	state.show = props.show;	
	if (props.commandPageInfoUUID) {
		if (props.commandPageInfoUUID != state.commandPageInfoUUID) {
			state.commandPageInfoUUID = props.commandPageInfoUUID;
			content.setContentPageInfoUUID(state.commandPageInfoUUID);
			loadContentPage();
		}	
	}
	if (props.permaLinkName) {
		if (props.permaLinkName != state.permaLinkName) {
			state.permaLinkName = props.permaLinkName;
			loadContentPage();
		}
	}
});

onMounted(() => {
	state.show = props.show;
	if (props.commandPageInfoUUID) {
		if (props.commandPageInfoUUID != state.commandPageInfoUUID) {
			state.commandPageInfoUUID = props.commandPageInfoUUID;
			content.setContentPageInfoUUID(state.commandPageInfoUUID);
			loadContentPage();
		}	
	}
	if (props.permaLinkName) {
		if (props.permaLinkName != state.permaLinkName) {
			state.permaLinkName = props.permaLinkName;
			loadContentPage();
		}
	}
	
	
});
</script>

<style>
</style>