<template>
			<div v-if="state.contentFragments"  :class="(state.edit) ? 'theme-pointer w-100':''" >
				<div v-for="contentFragment,index in state.contentFragments" :key="index">
					<v-hover>
					<template v-slot:default="{ isHovering, props }">
						<div v-bind="props"  class="d-flex w-100" :class="(isHovering && state.edit) ? 'elevation-2':''" >
							<div v-if="contentFragment.type == 'splashTemplate'" class="theme-pointer w-100">						
								<v-responsive v-if="contentFragment.imageType == 'image'">
									<div style="width:100%;min-height:20px" :style="(contentFragment.imageType == 'background' && contentFragment.imageUUID) ? getBackgroundImageStyle(contentFragment) : ''">								
										<v-img  v-if="contentFragment.imageType == 'image'" :src="mainStore.getContentImageUrl(contentFragment.imageUUID)"/>
										<div v-html="contentFragment.text"  :class="(contentFragment.outerClass) ? contentFragment.outerClass : ''" :style="(contentFragment.outerStyle) ? contentFragment.outerStyle : ''" v-else></div>
									</div>
								</v-responsive>
								<div style="width:100%;min-height:20px" :style="(contentFragment.imageType == 'background' && contentFragment.imageUUID) ? getBackgroundImageStyle(contentFragment) : ''" v-else>																		
										<div v-html="contentFragment.text"  :class="(contentFragment.outerClass) ? contentFragment.outerClass : ''" :style="(contentFragment.outerStyle) ? contentFragment.outerStyle : ''" ></div>
								</div>	
							</div>
							<div v-if="contentFragment.type == 'relatedPages'" class="d-flex theme-pointer">
								<p class="text-caption mt-1 ml-10">{{ contentFragment.contentReference }}</p><p class="theme-font-other text-body-1 ml-5 text-decoration-underline" v-if="contentFragment.alternateTitle">{{ contentFragment.alternateTitle }}</p><p class="theme-font-other text-body-1 ml-5 text-decoration-underline" v-else>{{ contentFragment.summaryTitle }}</p>
							</div>
							<div v-if="contentFragment.type == 'imageBlock'">
								<!--<v-responsive :max-width="contentFragment.width" :min-width="contentFragment.width" :max-height="contentFragment.height" :min-height="contentFragment.height">			-->
										<v-img :src="mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)" :width="contentFragment.width" :height="contentFragment.height"/>				
								<!---</v-responsive>-->
							</div>
							<div v-if="contentFragment.type == 'textImageBlock'">
								<!--<v-responsive :max-width="contentFragment.width" :min-width="contentFragment.width" :max-height="contentFragment.height" :min-height="contentFragment.height">			-->
									<div v-if="contentFragment.imagePosition == 'left'" class="d-flex">
										<v-img :src="mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)" :width="contentFragment.width" :height="contentFragment.height"/>
										<p class="theme-content-textBlock theme-font-other text-body-1 text-justify" :class="'ml-'+state.pageConfiguration.bodyLeftIndent+' mr-'+state.pageConfiguration.bodyRightIndent+' mt-'+state.pageConfiguration.bodyLineSpacing+' mb-'+state.pageConfiguration.bodyLineSpacing" v-html="contentFragment.text"></p>
									</div>
									<div v-if="contentFragment.imagePosition == 'right'" class="d-flex">									
										<p class="theme-content-textBlock theme-font-other text-body-1 text-justify" :class="'ml-'+state.pageConfiguration.bodyLeftIndent+' mr-'+state.pageConfiguration.bodyRightIndent+' mt-'+state.pageConfiguration.bodyLineSpacing+' mb-'+state.pageConfiguration.bodyLineSpacing" v-html="contentFragment.text"></p>
										<v-img :src="mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)" :width="contentFragment.width" :height="contentFragment.height"/>
									</div>
								<!---</v-responsive>-->
							</div>
							<div v-if="contentFragment.type == 'textBlock'"  >
								<p class="theme-content-textBlock theme-font-other text-body-1 text-justify" :class="'ml-'+state.pageConfiguration.bodyLeftIndent+' mr-'+state.pageConfiguration.bodyRightIndent+' mt-'+state.pageConfiguration.bodyLineSpacing+' mb-'+state.pageConfiguration.bodyLineSpacing" v-html="contentFragment.text"></p>
							</div>
							<div v-if="contentFragment.type == 'codeBlock'" class="bg-secondary_800 w-100" :class="'ml-'+state.pageConfiguration.bodyLeftIndent+' mr-'+state.pageConfiguration.bodyRightIndent+' mt-'+state.pageConfiguration.bodyLineSpacing+' mb-'+state.pageConfiguration.bodyLineSpacing" style="border: 1px solid black;position:relative;overflow:hidden">
								<v-btn  style="position:absolute;align:right;right:0;" size="x-small" @click="copyToClipBoard(contentFragment.text)">										
										<p class="text-caption">COPY</p>
									</v-btn>
								<code class="pa-0 text-black"><pre  class="ma-5">{{ contentFragment.text }}</pre></code>
							</div>
							<div v-if="contentFragment.type == 'stepBlock'"  >
								<p class="theme-content-stepBlock" :class="'ml-'+state.pageConfiguration.stepLeftIndent+' ml-'+state.pageConfiguration.stepRightIndent+' mt-'+state.pageConfiguration.stepLineSpacing+' mb-'+state.pageConfiguration.stepLineSpacing">{{ stepFront(index,contentFragment) }} {{ contentFragment.text }}</p>
								{{ stepEnd(index,contentFragment) }}
							</div>
							<div v-if="contentFragment.type == 'titleBlock'" >
								<p class="theme-content-titleBlock" :class="'text-h'+contentFragment.size" >{{ contentFragment.text }}</p>
							</div>
							<div v-if="contentFragment.type == 'commandOutcomeBlock'" class="w-100" >																			
								<v-row v-if="(index == 0)">
									<v-col><p class="font-weight-bold text-center">Outcome</p></v-col>
									<v-col></v-col>
								</v-row>
								<v-row>
									<v-col><p class="font-weight-bold text-center">{{ contentFragment.outcome }}</p></v-col>
									<v-col><p class="text-left">{{ contentFragment.message }}</p></v-col>
								</v-row>																	
							</div>
							<div v-if="contentFragment.type == 'commandDetailBlock'" class="ma-2 w-100" >
								<v-table>
									<tbody>
										<tr>	
											<td colspan="2">
												<p>{{ contentFragment.description}}</p>
											</td>
										</tr>
										<tr>	
											<td class="font-weight-bold">App</td><td><p>{{ contentFragment.appName}}</p></td>
										</tr>
										<tr>	
											<td class="font-weight-bold">Command Group</td><td><p>{{ contentFragment.commandGroup.replaceAll('.','/') }}</p></td>
										</tr>										
										<tr>	
											<td class="font-weight-bold">Version</td><td><p>{{ contentFragment.version}}</p></td>
										</tr>
										<tr>	
											<td class="font-weight-bold">Auth Types</td><td>
												<p>{{ contentFragment.commandAuthTypes.replaceAll(',',' ')}}</p>
											</td>
										</tr>
										<tr>	
											<td class="font-weight-bold">Endpoint URL</td><td>
												<p>https://{{ mainStore.phoneServiceName }}.api.i164.co.uk/api/{authType}/{{ contentFragment.commandGroup.replaceAll('.','/') }}</p>
											</td>
										</tr>										
									</tbody>
								</v-table>
							</div>
							<div v-if="contentFragment.type == 'propertySchemaBlock'" class="ma-2 w-100" >
								<v-table class=" full-width" density="compact">									
									<tbody>
										<tr class="w-100">									
											<td style="vertical-align:top" class="w-33">
												<p class="theme-font text-h6 font-weight-bold" >{{ contentFragment.propertyName }}</p>
												<p class="theme-font-other text-body-2 mt-2" >{{ contentFragment.description }}</p>
											</td>
											<td class="w-66" style="vertical-align:top">
												<v-table>
													<tbody>
														<tr>	
															<td class="font-weight-bold">Required</td><td><p>{{ contentFragment.required}}</p></td>
														</tr>
														<tr v-if="contentFragment.propertySchema">	
															<td class="font-weight-bold">type</td><td><p>{{ contentFragment.propertySchema.type }}</p></td>
														</tr>
														<tr v-if="contentFragment.propertySchema && contentFragment.propertySchema.minLength">	
															<td class="font-weight-bold">minLength</td><td>{{ contentFragment.propertySchema.minLength }}</td>
														</tr>
														<tr v-if="contentFragment.propertySchema && contentFragment.propertySchema.maxLength">	
															<td class="font-weight-bold">maxLength</td><td>{{ contentFragment.propertySchema.maxLength }}</td>
														</tr>
														<tr v-if="contentFragment.propertySchema && contentFragment.propertySchema.pattern">	
															<td class="font-weight-bold">pattern</td><td>{{ contentFragment.propertySchema.pattern }}</td>
														</tr>
														<tr v-if="contentFragment.propertySchema && contentFragment.propertySchema.exampleValue">	
															<td class="font-weight-bold">exampleValue</td><td>{{ contentFragment.propertySchema.exampleValue }}</td>
														</tr>
													</tbody>
												</v-table>												
											</td>
											
										</tr>																							
									</tbody>
								</v-table>
								<v-divider></v-divider>																	
							</div>
							<v-spacer align="right" class="mr-5 text-no-wrap w-20 absolute">								
								<v-icon size="x-small" :class="(isHovering && state.edit) ? '':'d-none'"  class="mt-0 text-disabled" @click="moveUpContent(this,index,contentFragment)" v-if="index > 0">arrow_upward</v-icon>
								<v-icon size="x-small" :class="(isHovering && state.edit) ? '':'d-none'"  class="mt-0 text-disabled" @click="moveDownContent(this,index,contentFragment)" v-if="index +1 < state.contentFragments.length">arrow_downward</v-icon>
								<v-icon size="x-small" :class="(isHovering && state.edit) ? '':'d-none'"  class="mt-0 text-disabled" @click="editContent(this,contentFragment)">edit_note</v-icon>
								<v-icon size="x-small" :class="(isHovering && state.edit) ? '':'d-none'"  class="mt-0 text-disabled" @click="deleteContent(this,index,contentFragment)">delete</v-icon>
							</v-spacer>						
						</div>
						<div v-if="state.contentConfiguration.multiplicity == 'multiple' || state.contentFragments.length == 0 ">
							<ContentCreator :contentConfiguration="state.contentConfiguration" :position="(index+1)" :contentFieldName="state.contentFieldName" @dataEvents="dataReadyFired"  :edit="state.edit"/>
						</div>
					</template>
					</v-hover>
				</div>				
			</div>
			<div v-if="!state.contentFragments && state.edit">
				DEFAULT
			</div>

	<v-dialog persistent v-model="state.showDialog">
		<v-row>
			<v-col v-if="state.contentConfiguration">
				<ContentForm :contentConfiguration="state.contentConfiguration" :contentFragment="state.contentFragment" action="update" :contentFieldName="state.contentFieldName" :position="state.position" @close="closeDialog(event)" @dataEvents="dataReadyFired"/>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ContentForm from './ContentForm.vue';
import ContentCreator from '../pages/components/ContentCreator.vue';

const { t }  = useI18n();
	
const router = useRouter();

const emits = defineEmits(['dataEvents']);

const dataReadyFired = (_contentEvent) => {
		emits('dataEvents',_contentEvent);
		console.log("dataReadyFired");
		closeDialog();
	}

const mainStore = useMainStore();

const props = defineProps({		
	contentConfiguration: {
		type: Object,
		required: true,
	},
	contentFieldName: {
		type: String,
		required: true,			
	},
	edit: {
		type: Boolean,
		required: true,
	},
	contentFragments: {
		type: Array,
		required: true,
	},
	pageContext: {
		type: Object,
		required:true,
	},
	pageConfiguration: {
		type: Object,
		required: false,
	}
});

const state = reactive({	
	dataReady: false,
	showDialog: false,
	counting: true,	
	pageConfiguration: {
		bodyLeftIndent: 3,
		bodyRightIndent: 3,
		bodyLineSpacing: 3,
		stepLeftIndent: 4,
		stepRightIndent: 3,
		stepLineSpacing: 3,
	},
	edit: false,
	contentConfiguration: undefined,
	contentFieldName: undefined,
	contentFragments: undefined,
	contentFragment: undefined,
});

const stepFront = (index,contentFragment) => {	
	if (contentFragment.bulletType == 'count') {
		props.pageContext.stepCount = props.pageContext.stepCount+1;			
		return props.pageContext.stepCount+'.';
	}
}

const stepEnd = (index,contentFragment) => {
	if (!contentFragment.keepCounting) {
		props.pageContext.stepCount = 0;
	}
}

const  getBackgroundImageStyle = (contentFragment) => {
	let extra = '';
	if (contentFragment.backgroundStyle) {
		extra = contentFragment.backgroundStyle;
	}
	return "background: url('"+mainStore.getContentImageUrl(contentFileDescriptors.primaryUUID)+"') no-repeat ;background-size: cover;"+extra;
}

const closeDialog = (event) => {
	state.showDialog = false;
}


const editContent = (event,contentFragment) => {
	state.contentFragment = contentFragment;
	state.showDialog = true;	
}

const moveDownContent = (event,position,contentFragment) => {
	emits('dataEvents',
			{
				state: 'ready',
				action: 'moveDown',
				position: position,
				contentFieldName: state.contentFieldName,
				contentFragment: contentFragment,				
			}
		);
}

const copyToClipBoard = (textToCopy) => {
	navigator.clipboard.writeText(textToCopy);
	mainStore.displaySnackBar("Text Copied");
}


const moveUpContent = (event,position,contentFragment) => {
	emits('dataEvents',
			{
				state: 'ready',
				action: 'moveUp',
				position: position,
				contentFieldName: state.contentFieldName,
				contentFragment: contentFragment,				
			}
		);
}

const deleteContent = (event,position,contentFragment) => {
	emits('dataEvents',
			{
				state: 'ready',
				action: 'delete',
				position: position,
				contentFieldName: state.contentFieldName,
				contentFragment: contentFragment,				
			}
		);
}


onUpdated(() => {
	if (props.edit != undefined) {
		state.edit = props.edit;
	}
});

onMounted(() => {
	if (props.contentConfiguration) {
		state.contentConfiguration = props.contentConfiguration;
		//console.log(" contentConfiguration is "+state.contentConfiguration);
	} 
	if (props.contentFieldName) {
		state.contentFieldName = props.contentFieldName;
		//console.log(" contentFieldName is "+state.contentFieldName);
	} 
	if (props.edit != undefined) {
		state.edit = props.edit;
	}
	if (props.contentFragments) {
		state.contentFragments = props.contentFragments;
		//console.log(" contentFragment is "+state.contentFragment);
	} 	
	if (props.pageConfiguration) {
		state.pageConfiguration = props.pageConfiguration;
	}
});
</script>

<style>
.hovering {
	border: 1px black solid;
}
</style>