<template>
	<v-container fluid class="px-6 py-6 h-100">
			<v-row>
				<v-col class="pa-0 ma-0">
					<v-row>
						<v-col cols="4">
							<v-card>
								<BoxHeader headerText="Developer Updates"/>							
								<v-card-text>												
									<p class="theme-font-other font-italic text-caption mt-2">01-07-23</p>
									<p class="theme-font text-body-1 font-weight-bold ml-2">REST API Launched</p>
									<p class="theme-font text-body-2 ml-2">Developer Keys are now available for the first release of our REST API.</p>
									<p class="theme-font-other font-italic text-caption mt-2">07-07-23</p>
									<p class="theme-font text-body-1 font-weight-bold ml-2">Web Hooks</p>
									<p class="theme-font text-body-2 ml-2">The preview of our Web Hook events service will go live by the end of July</p>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card>
								<BoxHeader headerText="Rest API"/>							
								<v-card-text>												
									<v-spacer class="d-flex">
										<v-icon class="mt-1 text-green">check_circle</v-icon><p class="theme-font text-h6  ml-2">Enabled</p>
									</v-spacer>		
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="4">
							<v-card>
								<BoxHeader headerText="Web Hooks"/>							
								<v-card-text>	
									<v-spacer class="d-flex">
										<v-icon class="mt-1 text-green">check_circle</v-icon><p class="theme-font text-h6  ml-2">Enabled</p>
									</v-spacer>																				
								</v-card-text>
							</v-card>
						</v-col>
						
					</v-row>
				</v-col>
			</v-row>
		</v-container>
	</template>
	
	<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
import BoxHeader from '../BoxHeader.vue';
	
	const { t }  = useI18n();
		
	const router = useRouter();
	
	const mainStore = useMainStore();
	
	const state = reactive({	
		tabsTop: 'home',	
	});
	
	
	onMounted(() => {

	});
	
	</script>
	
	<style>
	
	</style>
	