<template>
<OuterCard>
	<v-row >
		<v-col class="pa-0">
			<v-row class="theme-box-header-background">
				<v-col class="d-flex ma-2 mb-0">
					<p class="theme-font text-h5 text-white">Your Credit Cards</p>
				</v-col>		
			</v-row>					
				<v-row  v-if="state.userPaymentCardMethods.length > 0">
					<v-col class="mt-0 pt-0">
						<CreditCardUserListComponent :userPaymentCardMethods="state.userPaymentCardMethods" :paymentCardMethods="state.paymentCardMethods" :creditCardConfig="userCreditCardConfig" :phoneService="plustagPhoneService" @selectedCard="selectedCard(this)"/>
					</v-col>
				</v-row>
				<v-row v-else>
					<v-col class="ml-5 d-flex">
						<p class="mb-4">You have no cards on your account.</p> <!--<v-btn size="small" class="ml-10 pl-2 theme-list-action-button-background  text-action_button_text"  @click="addCard(this)"><v-icon class="theme-list-action-icon">add</v-icon>New Card</v-btn>-->
					</v-col>
				</v-row>			
			</v-col>
	</v-row>
</OuterCard>

</template>

<script setup>
import { defineProps,reactive,onMounted,onUpdated } from 'vue';
import OuterCard from '@/components/common/OuterPopCard.vue';
import { useRoute, useRouter } from "vue-router";
import CreditCardUserListComponent from './CreditCardUserListComponent.vue';
import {useMainStore} from '@/stores/MainStore.js';

const mainStore = useMainStore();

const router = useRouter();

const userCreditCardConfig = {
	mode: 'list'
};

const plustagPhoneService =  mainStore.getDomainDetails('plustag');

const state = reactive({		
	paymentCardMethods: [],
	userPaymentCardMethods: [],
	selectedCardUUID: undefined,
	pollingNow: false,
});


const addCard = (e) => {

		createCheckoutSession();
}




const createCheckoutSession = async () => {
		console.log('creating checkout for '+props.phoneService.phoneServiceName);
		const popup = openCardWindow();
		const apiUrl = mainStore.getAPIUrl(props.phoneService.phoneServiceName);				
		await fetch(apiUrl+'/serviceBilling/createCheckoutSession', {
			method: 'get',				
			credentials: 'include',
			redirect: 'follow',		
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)							
					if (d.outcome == -3) {
					} else {
						popup.location = d.url;
					}							
				}									
			)
			return response;
		}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);

		});		
		}


		const anyCards = () => {
			var anyCards = false;
			if (props.paymentCardMethods.length == 0 && props.userPaymentCardMethods.length > 0) {
				return true;
			} else {
				props.paymentCardMethods.forEach(
					(paymentCardMethod) => {	
						props.userPaymentCardMethods.forEach(
								(userPaymentCardMethod) => {
									if (userPaymentCardMethod.primaryUUID == paymentCardMethod.primaryUUID) {
										anyCards = true;					
									}
								}
							);								
					}		
				);
			
			}
			return anyCards;
		}

		const openCardWindow = () => {
			const winHtml = `<!DOCTYPE html>
					<html>
							<head>
									<title>Add Credit Card</title>
							</head>
							<body>
									<h1>Loading Credit Card Form</h1>
							</body>
					</html>`;

			const winUrl = URL.createObjectURL(
					new Blob([winHtml], { type: "text/html" })
			);

			return window.open(winUrl,'stripe','toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=600px,height=800px');

		}


const listUserPaymentCards = async () => {
	const apiUrl = mainStore.getAPIUrl('plustag');	
	state.pollingNow = true;			
	await fetch(apiUrl+'/serviceBilling/listUserCardPaymentMethods', {
		method: 'get',				
		credentials: 'include',
		redirect: 'follow',		
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d);						
				if (d.outcome == 1) {				
					if (d.stripeCardPaymentMethods) {
						state.userPaymentCardMethods = d.stripeCardPaymentMethods;
					}	else {
						state.userPaymentCardMethods = [];
					}	
				}							
				state.pollingNow = false;
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);
		state.pollingNow = false;
	});	
}
	

onMounted(() => {
	listUserPaymentCards();
});
</script>

<style setup>
</style>