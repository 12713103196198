const config = {
	store: undefined,
	contentPageInfoUUID: undefined,
	contentPage: undefined
}

const _processPageInfoEvent = (_pageInfoEvent,callback) => {
	switch (_pageInfoEvent.action) {
		case 'update': 
		const contentPageInfoInput = {
			version: config.contentPage.pageInfo.version,		
			appName: config.contentPage.pageInfo.appName,
			pageType: config.contentPage.pageInfo.pageType,
			pageName: config.contentPage.pageInfo.pageName,
			bookDisplayOnly: config.contentPage.pageInfo.bookDisplayOnly,
			bookUUID: config.contentPage.pageInfo.bookUUID,
			status: config.contentPage.pageInfo.status,
			description: config.contentPage.pageInfo.description, 	 	
			allowedRoles: config.contentPage.pageInfo.allowedRoles,
			permissions: config.contentPage.pageInfo.permissions,
			audience: config.contentPage.pageInfo.audience, 	
			keywords: config.contentPage.pageInfo.keywords,
			categories: config.contentPage.pageInfo.categories,
			labels: config.contentPage.pageInfo.labels,
			createdBy: config.contentPage.pageInfo.createdBy,
			licenses: config.contentPage.pageInfo.licenses,
			showInSearch: config.contentPage.pageInfo.showInSearch,	
			permaLinkName: config.contentPage.pageInfo.permaLinkName,			
		};
		console.log("CPI "+JSON.stringify(contentPageInfoInput));
		_updatePageInfo(contentPageInfoInput,callback)
		break;	
	}
}



const _processContentEvent = (_contentEvent,callback) => {

	_validateContentEvent(_contentEvent);
	console.log('Content Field Name '+_contentEvent.contentFieldName+' at '+_contentEvent.position);
	console.log('Action '+_contentEvent.action);
	console.log('Content Fragment '+JSON.stringify(_contentEvent.contentFragment));
	switch (_contentEvent.action) {
		case 'add':
			_insertContentFragment(_contentEvent,callback);			
			break;
		case 'update': 
			_updateContentFragment(_contentEvent,callback);
			break;
		case 'delete':
			_deleteContentFragment(_contentEvent,callback);
			break;
		case 'moveUp':
			_moveUpContentFragment(_contentEvent,callback);
			break;
		case 'moveDown':
			_moveDownContentFragment(_contentEvent,callback);
			break;
	}
	return config.contentPage;
}

const _validateContentEvent = (_contentEvent) => {
	if (_contentEvent == null) {
		throw "Content Event is null";
	}
	if (!_contentEvent.action) {
		throw "Content Event action is null";
	}
	if (!_contentEvent.contentFieldName) {
		throw "Content Event contentFieldName is null";
	}
	if (!_contentEvent.contentFragment) {
		throw "Content Event contentFragment is null";
	}
}

const _moveUpContentFragment = (_contentEvent,callback) => {
	let contentField = _getContentField(_contentEvent.contentFieldName);
	var tmp = contentField.splice(_contentEvent.position,1);
	console.log('Moving'+JSON.stringify(tmp));
	contentField.splice(_contentEvent.position-1,0,tmp[0]);
	_persistContentFragment(_contentEvent,callback);
	
}


const _moveDownContentFragment = (_contentEvent,callback) => {
	let contentField = _getContentField(_contentEvent.contentFieldName);
	var tmp = contentField.splice(_contentEvent.position,1);
	console.log('Moving'+JSON.stringify(tmp));
	contentField.splice(_contentEvent.position+1,0,tmp[0]);
	_persistContentFragment(_contentEvent,callback);
	
}

const  _insertContentFragment = (_contentEvent,callback) => {
	//Fetch contentField
	console.log('Content Before '+JSON.stringify(config.contentPage));
	let contentField = _getContentField(_contentEvent.contentFieldName)	
	if (!contentField) {
		contentField = [];	
	}
	contentField.splice(_contentEvent.position, 0, _contentEvent.contentFragment);	
	_setContentField(_contentEvent.contentFieldName,contentField)
	_persistContentFragment(_contentEvent,callback);
	console.log('Content Now '+JSON.stringify(config.contentPage));
}

const  _updateContentFragment = (_contentEvent,callback) => {
	console.log('Update Content Before '+JSON.stringify(config.contentPage));		
	_persistContentFragment(_contentEvent,callback);
	console.log('Content Now '+JSON.stringify(config.contentPage));
}

const  _deleteContentFragment = (_contentEvent,callback) => {
	//Fetch contentField
	console.log('Content Before '+JSON.stringify(config.contentPage));
	let contentField = _getContentField(_contentEvent.contentFieldName)	
	if (!contentField) {
		contentField = [];	
	}
	console.log("Deleting at "+_contentEvent.position)
	contentField.splice(_contentEvent.position, 1);	
	_persistContentFragment(_contentEvent,callback);
	console.log('Content Now '+JSON.stringify(config.contentPage));
}

const _persistContentFragment = (_contentEvent,callback) => {
	const contentPageInput = {
		version: config.contentPage.pageInfo.version,
		content: [],
	};
	const contentField = {
		propertyName: _contentEvent.contentFieldName,
		propertyValue: config.contentPage.content[_contentEvent.contentFieldName],
	}
	contentPageInput.content.push(contentField);
	_updateContent(contentPageInput,callback);
	console.log('Persist '+JSON.stringify(contentPageInput));
}

const _persistContentPage = (callback) => {
	const contentPageInput = {
		version: config.contentPage.pageInfo.version,
		content: [],
	};
	if (config.contentPage.content) {
		const properties = Object.keys(config.contentPage.content);
		let propertyName = undefined;
		let contentField = undefined;
		for (let i = 0; i < properties.length;i ++) {
			propertyName = properties[i];
			console.log(propertyName);
			contentField = {
				propertyName: propertyName,
				propertyValue: config.contentPage.content[propertyName],
			}			
			contentPageInput.content.push(contentField);
		}
	}
	_updateContent(contentPageInput,callback);
}

const _setContentPage = (_contentPage) => {
	if (_contentPage) {
		config.contentPage = _contentPage;
	}
}

const _getContentField = (contentFieldName) => {
	if (config.contentPage) {
		return config.contentPage.content[contentFieldName];
	} else {
		throw 'contentPage is null';
	}
}

const _setContentField = (contentFieldName,contentFieldValue) => {
	if (config.contentPage) {
		config.contentPage.content[contentFieldName] = contentFieldValue;
	} else {
		throw 'contentPage is null';
	}
}

const _updatePageInfo = async (contentPageInfoInput,callback) => {
	const data = new URLSearchParams();		
	data.set('contentPageInfoUUID', config.contentPageInfoUUID);		
	data.set('contentPageInfo', JSON.stringify(contentPageInfoInput));	
	const apiUrl = config.store.getAPIUrl();				
	return await fetch(apiUrl+'/content/updateContentPageInfo', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {		
								config.store.displaySnackBar("Content Page Info Updated");
								console.log('Updated');	
								callback(d);
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
}

const _updateContent = async (contentPageInput,callback) => {
	const data = new URLSearchParams();		
	data.set('contentPageInfoUUID', config.contentPageInfoUUID);		
	data.set('content', JSON.stringify(contentPageInput));	
	const apiUrl = config.store.getAPIUrl();				
	return await fetch(apiUrl+'/content/updateContentPage', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {		
								config.store.displaySnackBar("Content Updated");
								console.log('Updated');	
								callback(d);
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
}

const _loadContent = async (callback) => {
	const data = new URLSearchParams();		
	data.set('contentPageInfoUUID', config.contentPageInfoUUID);	
	const apiUrl = config.store.getAPIUrl();				
	return await fetch(apiUrl+'/content/loadContentPage', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {		
								config.store.displaySnackBar("Page Loaded");
								config.contentPage = d.contentPage;						
								console.log(JSON.stringify(d.contentPage));
								callback(d);							
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
}

const _loadPermaLinkContent = async (permaLinkName,callback) => {
	const data = new URLSearchParams();		
	data.set('permaLinkName', permaLinkName);	
	const apiUrl = config.store.getAPIUrl();				
	return await fetch(apiUrl+'/documentation/loadPermaLinkContentPage', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {		
								config.store.displaySnackBar("Page Loaded");									
								//console.log(JSON.stringify(d.contentPage));
								callback(d);							
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
}

export default {
	initialiseStore: (store) => {
		config.store = store;
	},
	setContentPage: (_contentPage) => {
		config.contentPage = _contentPage;
	},
	getContentPage: () => {
		return config.contentPage;
	},
	setContentPageInfoUUID: (_contentPageInfoUUID) => {
		config.contentPageInfoUUID = _contentPageInfoUUID;
	},
	loadContent	: (callback) => {
		_loadContent(callback);
	},
	persistContentPage: (callback) => {
		_persistContentPage(callback);
	},
	loadPermaLinkContent : (permaLinkName,callback) => {
		_loadPermaLinkContent(permaLinkName,callback);
	},
	validateContentEvent: (_contentEvent) => {
		return _validateContentEvent(_contentEvent);
	},
	processContentEvent: (_contentEvent,callback) => {
		return _processContentEvent(_contentEvent,callback);
	},
	processPageInfoEvent:(_pageInfoEvent,callback) => {
		return _processPageInfoEvent(_pageInfoEvent,callback);
	},
};