<template>
	
			<div v-bind="props"  v-if="state.edit">
				<v-row>
					<v-col class="d-flex ma-0 pa-0 mt-3 mb-3">									
						<v-hover>
								<template v-slot:default="{ isHovering, props }">
									<div v-bind="props" class="d-flex w-100" >
										<v-divider class="mt-3 mr-3" :color="(isHovering) ? 'red':'black'" :thickness="(isHovering) ? '2':'1'" ></v-divider>
								
											<v-icon  :class="(isHovering) ? 'elevation-2':''" class="text-disabled" @click="createNewContent(this)" size="x-small" >add</v-icon> 
									
											<v-divider class="mt-3 mr-3" :color="(isHovering) ? 'red':'black'" :thickness="(isHovering) ? '2':'1'" ></v-divider> <p style="width:250px" class="text-disabled">{{state.contentFieldName}}</p> 
								</div>
								</template>
							</v-hover>
					</v-col>
				</v-row>
				</div>
		
	<v-dialog persistent v-model="state.showDialog">
		<v-row>
			<v-col v-if="state.contentConfiguration">
				<ContentForm :contentConfiguration="state.contentConfiguration" action="add" :contentFieldName="state.contentFieldName" :position="state.position" @close="closeDialog(event)" @dataEvents="dataReadyFired"/>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script setup>

import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';
import ContentForm from '../../types/ContentForm.vue';

const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const emits = defineEmits(['dataEvents']);

const fireDataReadyEvent = () => {
		emits('dataEvents',
			{
				state: 'ready',
			}
		);
}

const fireDataNotReadyEvent = () => {
		emits('dataEvents',
			{
				state: 'notReady'
			}
		);
}

const props = defineProps({		
	contentConfiguration: {
		type: Object,
		required: true,
	},
	contentFieldName: {
		type: String,
		required: true,			
	},
	edit: {
		type: Boolean,
		required: true,
	},
	position: {
		type: Number,
	}
});

	const state = reactive({	
		dataReady: false,	
		edit: false,
		contentConfiguration: undefined,			
		contentFieldName: undefined,
		showDialog: false,
		position: 0,
	});

	const dataReadyFired = (_contentEvent) => {
		emits('dataEvents',_contentEvent);
		console.log("dataReadyFired");
	}

	const closeDialog = (event) => {
		state.showDialog = false;
	}

	const createNewContent = (event) => {
		state.showDialog = true;
	}

	onUpdated(() => {
		if (props.edit != undefined) {
				state.edit = props.edit;
			}
	});

	onMounted(() => {
			if (props.contentConfiguration) {
				state.contentConfiguration = props.contentConfiguration;
				//console.log(" contentConfiguration is "+state.contentConfiguration);
			} 
			if (props.contentFieldName) {
				state.contentFieldName = props.contentFieldName;
				//console.log(" contentFieldName is "+state.contentFieldName);
			} 
			if (props.position) {
				state.position = props.position;
			} else {
				state.position = 0;
			}
			if (props.edit != undefined) {
				state.edit = props.edit;
			}
	});

</script>

<style>

</style>