<template>

	<v-row v-if="state.pageInfo.key">
		<v-col>
			KEY
		</v-col>
	</v-row>
	<v-row v-else>
		<v-col>
			<v-row>
				<v-col>
					<v-text-field  @focusin="updatePageName(this)" @focusout="updatePageName(this)"  @input="updatePageName(this)" label="Page Name *" variant="outlined" v-model="state.pageInfo.pageName"></v-text-field>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-select
								:items="state.values.apps"				
								label="App Name"
								variant="outlined"
								item-title="title"
								item-value="value"																	
								v-model="state.pageInfo.appName"
								@update:modelValue="updateApp(this)"								
							></v-select>
				</v-col>
			</v-row>			
			<v-row>
				<v-col>
					<v-select
								:items="state.values.pageTypes"				
								label="Page Type"
								variant="outlined"
								item-title="title"
								item-value="value"																	
								v-model="state.pageInfo.pageType"
								@update:modelValue="updatePageType(this)"								
							></v-select>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<v-btn  :disabled="!state.dataReady"  :class="(state.dataReady) ? 'theme-bg-highlight' :''" @click="createPage(this)">Create Page</v-btn>
				</v-col>
			</v-row>
		</v-col>
	</v-row>
</template>

<script setup>
import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';

const { t }  = useI18n();
	
const router = useRouter();

const mainStore = useMainStore();

const emits = defineEmits(['dataEvents'])

const state = reactive({	
	dataReady: false,
	search : { 
		text: undefined,
		ready: false,
	},
	pageInfo: {
		primaryUUID: undefined,
		key: undefined,	
		appName: undefined,
		pageType: undefined,
		pageName: undefined,
		bookDisplayOnly: undefined,	
		bookKey: undefined,
		masterLicense: undefined,	
		masterPackage: undefined,				
		creationStatus: undefined,
		status: undefined,
		labels: undefined,	
		audience: undefined,
		showInSearch: undefined,	
		licenses: undefined,	
		description: undefined,	
		allowedRoles: undefined,	
		keywords: undefined,	
		permissions: undefined,	
		categories: undefined,
	},
	values: {		
		pageTypes: [	
			{value: 'article', title:'Article'},
			{value: 'webPage', title:'Web Page'},
			{value: 'faq', title:'FAQ'},							
			{value: 'command' , title:'Command'},
			{value: 'helpButton' , title:'Help Button'},
			{value: 'splash' , title:'Splash Page'},		
		],	
		apps: [
			{value: 'i164' , title:'I164'},
			{value: 'servedUp' , title:'Served Up'},
			{value: 'servicePassport' , title:'Service Passport'},
			{value: 'lines', title:'Lines'},
			{value: 'developer', title:'Developer'},					
		],			
	},	
});

const valid = (event) => {
	state.dataReady = (state.pageInfo.appName && state.pageInfo.pageType && state.pageInfo.pageName);
}

const updateApp = (event) => {
	valid(event);
}

const updatePageType = (event) => {
	valid(event);
}

const updatePageName = (event) => {
	valid(event);
}

const jumpToPageType = () => {
	switch (state.pageInfo.pageType) {
		case 'article':
			router.push({name: 'article',params: {contentPageInfoUUID: state.pageInfo.primaryUUID }});
			break;
	}
	
}

 const createPage = (event) => {
	console.log('createPage')
	if (!state.dataReady) {
		console.log('Cannot Create Page, data not ready')
		return;
	}
	createPageCommand();
 }

const createPageCommand = async () => {
	const apiUrl = mainStore.getAPIUrl();			
	const data = new URLSearchParams();		
	data.set('pageName', state.pageInfo.pageName);	
	data.set('appName', state.pageInfo.appName);	
	data.set('pageType', state.pageInfo.pageType);	
	/**data.set('bookDisplayOnly', state.pageInfo.bookDisplayOnly);	
	data.set('bookKey', state.pageInfo.bookKey);
	data.set('appName', state.pageInfo.appName);	
	data.set('masterLicense', state.pageInfo.masterLicense);	
	data.set('masterPackage', state.pageInfo.masterPackage);	
	data.set('key', state.pageInfo.key);	
	data.set('creationStatus', state.pageInfo.creationStatus);
	data.set('status', state.pageInfo.status);	
	data.set('labels', state.pageInfo.labels);	
	data.set('audience', state.pageInfo.audience);	
	data.set('showInSearch', state.pageInfo.showInSearch);	
	data.set('licenses', state.pageInfo.licenses);	
	data.set('description', state.pageInfo.description);	
	data.set('allowedRoles', state.pageInfo.allowedRoles);	
	data.set('keywords', state.pageInfo.keywords);	
	data.set('permissions', state.pageInfo.permissions);	
	data.set('categories', state.pageInfo.categories);*/
	await fetch(apiUrl+'/content/createContentPage', {
		method: 'post',			
		body: data,	
		credentials: 'include',
		redirect: 'follow',		
	}).then((response) => {
		response.json().then( 
			d => {			
				if (d.outcome == 1) {														
					mainStore.displaySnackBar("Page Created");
					emits('dataEvents',
							{
								state: 'pageCreated'
							}
						);					
				}							
			}									
		)
		return response;
	}).catch((error) => {
	console.log('Looks like there was a problem: \n', error);
});		

}




</script>

<style>

</style>