import {useMainStore} from '@/stores/MainStore.js';

const config = {
	store: undefined,
}


const _listAppDescriptors = async (callback) => {
	const apiUrl = config.store.getAPIUrl();				
				await fetch(apiUrl+'/discovery/listAppDescriptors', {
					method: 'get',			
					//body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {															
								_buildCommandList(d.appDescriptors,callback);
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
}

const _buildCommandList = (appDescriptors,callback) => {
	const size = appDescriptors.length;
	const commandSelectList = [];
	let selectItem;
	for (let i = 0; i < size;i++) {
		for (let j=0; j < appDescriptors[i].commandDescriptors.length;j++) {
			selectItem = {
				value: appDescriptors[i].commandDescriptors[j].commandGroup,
				title: appDescriptors[i].commandDescriptors[j].commandGroup,
			}
			commandSelectList.push(selectItem);
		}
	}
	callback(commandSelectList);
}




const _syncCommandContent = (commandGroup,contentPage,callback) => {
	_loadCommandSchema(commandGroup,contentPage,
		(commandSchema) => {	
			//console.log(JSON.stringify(commandSchema))
			//console.log(JSON.stringify(contentPage))			
			_syncOutcomes(commandSchema,contentPage);
			_syncCommandDetails(commandSchema,contentPage);
			_syncInputProperties(commandSchema,contentPage)
			callback();
		}
	)
}


const _loadCommandSchema = async (commandGroup,contentPage,callback) => {
	if (!commandGroup) {
		return;
	}
	const data = new URLSearchParams();		
	data.set('commandGroup', commandGroup);				
	const apiUrl = config.store.getAPIUrl();				
				await fetch(apiUrl+'/discovery/commandSchema', {
					method: 'post',			
					body: data,	
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {			
							if (d.outcome == 1) {																							
								callback(d.commandSchema);
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);
			});		
}


const _syncOutcomes = (commandSchema,contentPage) => {
	const outcomesContent = contentPage.content.outcomes;
	if (commandSchema.outcomes) {
		for (let i = 0;i < commandSchema.outcomes.length; i++) {
			_mergeOutcomeData(outcomesContent,commandSchema.outcomes[i]);		
		}
	}
}

const _mergeOutcomeData = (outcomesContent,schemaOutcome) => {
	let match = false;
	for (let i = 0; i < outcomesContent.length;i++) {
		if (outcomesContent[i].outcome == schemaOutcome.outcome) {
			outcomesContent[i].message = schemaOutcome.message;
			match = true;
			break;
		}
	}
	if (!match) {
		schemaOutcome.type = 'commandOutcomeBlock';
		outcomesContent.push(schemaOutcome);
	}
}

const _syncCommandDetails = (commandSchema,contentPage) => {
	let commandDetail = contentPage.content.commandDetails[0];
	if (!commandDetail) {
		commandDetail = {
			type : 'commandDetailBlock',
		};
		contentPage.content.commandDetails.push(commandDetail);
	}	
	commandDetail.appName = commandSchema.appName;
	commandDetail.commandGroup = commandSchema.commandGroup;
	commandDetail.version = commandSchema.version;
	commandDetail.description = commandSchema.description;
	commandDetail.allowedRoles = commandSchema.allowedRoles;
	commandDetail.domainScope = commandSchema.domainScope;
	commandDetail.permissions = commandSchema.permissions;
	commandDetail.commandAuthTypes = '';
	for (let i = 0; i < commandSchema.commandConfiguration.commandAuthTypes.length;i++) {
		commandDetail.commandAuthTypes += commandSchema.commandConfiguration.commandAuthTypes[i];
		if (i+1 < commandSchema.commandConfiguration.commandAuthTypes.length) {
			commandDetail.commandAuthTypes += ',';
		}
	}	
}

/**
 * 
 * inputProperties: [ {
		propertyName: "",
		description: "",
		required: true,
		propertySchema: {
		
		}	
	}
]} 
 */
const _syncInputProperties = (commandSchema,contentPage) => {
	const inputProperties = contentPage.content.inputProperties;
	for (let i = 0;i < commandSchema.propertySchemas.length; i++) {
		_mergeInputProperties(inputProperties,commandSchema.propertySchemas[i]);		
	}
}

const _mergeInputProperties = (inputProperties,propertySchema) => {
	let match = false;
	for (let i = 0; i < inputProperties.length;i++) {
		if (inputProperties[i].propertyName == propertySchema.propertyName) {
			inputProperties[i].description = propertySchema.description;
			inputProperties[i].required = propertySchema.required;
			inputProperties[i].propertySchema = propertySchema.propertySchema;
			match = true;
			break;
		}
	}
	if (!match) {
		propertySchema.	type = 'propertySchemaBlock';
		inputProperties.push(propertySchema);
	}
}


export default {
	initialiseStore: (store) => {
		config.store = store;
	},
	loadCommandGroups: (callback) => {
		_listAppDescriptors(callback);
	},	
	syncCommandContent: (commandGroup,contentPage,callback) => {
		_syncCommandContent(commandGroup,contentPage,callback);
	}
};