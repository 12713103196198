<template>

<div class="theme-mainPanel fill-height" >					
			<v-row class="mb-5 fill-height" >
				<v-col md="2" ></v-col>
				<v-col md="8" class="fill-height d-flex align-center justify-center" >
					<v-container v-if="!state.completed">
						<v-row class="mb-10">
							<v-col>
						<v-card class="card-floater card-shadow border-radius-md loadingBox" >
							<div class="px-2 pt-4 pb-0 d-flex">
							<v-avatar
									color="success border-radius-xl mt-n8"
									class="shadow theme-background-avatar"
									size="48"
								>
									<v-icon class="text-white" size="30">mail</v-icon
									>
								</v-avatar>	
								<p class="theme-font text-h4 ml-5" v-if="state.verificationOutcome != 909">You have an Invite to Join a Service</p>								
								<p class="theme-font text-h4 ml-5" v-else>Invite Expired</p>														
							</div>
						<v-card-text class="align-center" v-if="state.verified && state.phoneServiceUserParticipationInvite">
							<div class="vx-green-lighter-background">
								<p class="text-body-1 theme-font-other ma-2"><b>To:</b> {{ state.phoneServiceUserParticipationInvite.toFirstName }} {{ state.phoneServiceUserParticipationInvite.toLastName }} ({{ state.phoneServiceUserParticipationInvite.toEmailAddress }}) </p>							
								<p class="text-body-1 theme-font-other ma-2"><b>From:</b> {{ state.phoneServiceUserParticipationInvite.fromFirstName }} {{ state.phoneServiceUserParticipationInvite.fromLastName }}  ({{ state.phoneServiceUserParticipationInvite.fromEmailAddress }})  on behalf of {{ state.phoneServiceUserParticipationInvite.fromServiceName}}</p>
							</div>
							<p class="text-body-1 theme-font-other ma-2">To join <b>{{ state.phoneServiceUserParticipationInvite.fromServiceName}}</b> as:-</p>
							<div v-for="grant in state.phoneServiceUserParticipationInvite.phoneServiceUserGrants" :key="grant.primaryUUID">
								<p class="text-body-1 theme-font-other ml-5">{{ t("statics.roles."+grant.roleName.replaceAll('.','_')) }}</p>
							</div>	
							
							<div v-if="state.phoneServiceUserParticipationInvite.extraMessageToUser">
								<p class="text-body-1 theme-font-other ma-2">They included the following message: -</p>	
								<p class="text-body-1 theme-font-other ml-5 ma-2 mr-5"><i>{{ state.phoneServiceUserParticipationInvite.extraMessageToUser }}</i></p>
							</div>
						
							
					
							<div v-if="state.processType == 'userLoggedIn' && state.loggedInStatus">	
								<v-divider class="mt-5 mb-5"></v-divider>
								<div v-if="state.isInTargetAlready">
									<v-row>
										<v-col>
											<p class="text-body-1 theme-font-other ma-2">You are already a User in the service they are inviting you to.</p>
											<p class="text-body-1 theme-font-other ma-2">If this was a mistake or you are sharing browsers, you need to logout of this account to continue. </p>
										</v-col>
								</v-row>							
								<v-row>
										<v-col align="center">
											<v-btn :class="(state.displayUserForm ) ? 'theme-bg-highlight': ''" @click="state.logout = true;">LOGOUT TO CONTINUE</v-btn>
										</v-col>
								</v-row>
								</div>		
								<div v-else>
									<p class="text-body-1 theme-font-other ma-2">You are logged in as <b>{{ state.loggedInStatus.loggedInUserDomains.user.email  }}</b></p>
									<div v-if="state.loggedInStatus.loggedInUserDomains.user.email != state.phoneServiceUserParticipationInvite.toEmailAddress">
										<p class="text-body-1 theme-font-other ma-2">This invite is for the {{state.phoneServiceUserParticipationInvite.toEmailAddress}} you need to switch accounts to accept or reject this. </p>
										<v-row class="mt-5">
														<v-col align="center"><v-btn class="theme-bg-highlight" @click="state.logout = true;">SWITCH ACCOUNT</v-btn>	<p class="align-center theme-font-other  text-caption">You will be logged out to first.</p></v-col>
												</v-row>
									</div>										
									<div v-else>
										<p class="text-body-1 theme-font-other ma-2">Add the <b>{{ state.phoneServiceUserParticipationInvite.fromServiceName}}</b> you service list on this account.</p>
										<v-container>
												<v-row>
														<v-col align="center"><v-btn :class="(!state.displayUserForm) ? 'theme-bg-highlight': ''" @click="acceptInvitation();">Accept Invite</v-btn></v-col>
												</v-row>
												<v-row class="">
														<v-col class="d-flex align-center justify-center"><v-divider class="mr-5 mt-5 mb-5"></v-divider> OR <v-divider class="ml-5 mt-5 mb-5"></v-divider></v-col>
												</v-row>
												<v-row class="">
													<v-col>
															<p class="text-body-1 theme-font-other ma-2"><v-textarea
														name="rejectionMessage"
														label="Reason For Rejection"
														v-model="state.rejectionMessage"
														hint="Leave this emtpy if you don't wish to give a reason!"
													></v-textarea></p>												
														</v-col>											
												</v-row>
												<v-row class="">
														<v-col align="center"><v-btn :class="(state.displayUserForm) ? 'theme-bg-highlight': ''" @click="rejectInvitation()">REJECT INVITE</v-btn></v-col>
												</v-row>
										</v-container>	
									</div>
								</div>						
							</div>
							<div v-else>
								<v-divider class="mt-5"></v-divider>
								<v-container>
									<v-row>
												<v-col>
													<p class="text-body-1 theme-font-other ma-2">To accept or reject this invite please login to your <b>{{ state.phoneServiceUserParticipationInvite.toEmailAddress }}</b> account. </p>
												</v-col>
										</v-row>
									<v-row>
												<v-col align="center"><v-btn  @click="goToLogin(true);" >LOGIN</v-btn></v-col>
										</v-row>
									
									
								</v-container>
							</div>	
						</v-card-text>
						
						<v-card-text v-if="state.verificationOutcome == 909">

								<p class="text-body-1 theme-font-other ma-2">This Invite has Expired</p>
									<p class="text-body-1 theme-font-other ma-2">It was sent by {{ state.phoneServiceUserParticipationInvite.fromFirstName }} {{ state.phoneServiceUserParticipationInvite.fromLastName }} asking you to join the service operated by <b>{{ state.phoneServiceUserParticipationInvite.fromServiceName}}</b> using your <b>{{ state.phoneServiceUserParticipationInvite.toEmailAddress }}</b> address.</p>
									<p class="text-body-1 theme-font-other ma-2">Please contact them to request that the re-send this invite.</p>

						</v-card-text>
						<v-card-text v-if="state.verificationOutcome == 919">
							<v-row>
										<v-col>
											<p class="text-body-1 theme-font-other ma-2">You sent this invite! You cannot invite yourself to a service.</p>
											<p class="text-body-1 theme-font-other ma-2">If this was a mistake or you are sharing browsers, you need to logout of this account to continue. </p>
										</v-col>
								</v-row>							
								<v-row>
										<v-col align="center">
											<v-btn :class="(state.displayUserForm ) ? 'theme-bg-highlight': ''" @click="state.logout = true;">LOGOUT TO CONTINUE</v-btn>
										</v-col>
								</v-row>
							</v-card-text>
							<v-card-text v-if="state.verificationOutcome == -1">
								<v-row>
										<v-col>
											<p class="text-body-1 theme-font-other ma-2">An Error has Occured.</p>											
										</v-col>
								</v-row>		
							</v-card-text>
							<v-card-text  class="d-flex align-center ml-5" v-else-if="!state.verificationOutcome">
									<v-progress-circular
										indeterminate
										color="#A4B122"							
									></v-progress-circular><p class="text-h4 theme-font ml-10">Loading</p>
								</v-card-text>							
					</v-card>
					</v-col>
				</v-row>			
			</v-container>
			<v-container v-else>
				<v-row class="mb-10">
					<v-col>
						<v-card class="card-floater card-shadow border-radius-md loadingBox" >
							<div class="px-2 pt-4 pb-0 d-flex">
							<v-avatar
									color="success border-radius-xl mt-n8"
									class="shadow"
									size="48"
								>
									<v-icon class="text-white" size="30">mail</v-icon
									>
								</v-avatar>	
								<p class="theme-font text-h4 ml-5" v-if="state.inviteAccepted">Invite Accepted</p>	
								<p class="theme-font text-h4 ml-5" v-if="state.inviteRejected">Invite Rejected</p>	
							</div>
							<v-card-text class="align-center">
								<div v-if="state.inviteRejected">
									<p>You have rejected this invite to join the {{ state.phoneServiceUserParticipationInvite.fromServiceName}} service.</p>
								</div>
								<div v-if="state.inviteAccepted">
									<p>You have accepted this invite to join the {{ state.phoneServiceUserParticipationInvite.fromServiceName}} service.</p>
								</div>
								<v-row>
								<v-col class="text-center mt-5">
									<v-btn @click="quit(this)">Continue</v-btn>
									</v-col>
								</v-row>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
			</v-col>
			<v-col md="2" ></v-col>
		</v-row>		
			
	</div>

		<LogoutDialog :logout="state.logout" @close="closeLogout()" @logout="loggedOut()"/>		
</template>

<script setup>
import LogoutDialog from '@/components/LogoutDialog.vue';
import { reactive, onMounted,onUnmounted } from 'vue';
import { useRoute, useRouter } from "vue-router";
import {useMainStore} from '@/stores/MainStore.js';
import { useI18n } from 'vue-i18n';

const { t }  = useI18n();
const route = useRoute();
const router = useRouter();

const mainStore = useMainStore();


const state = reactive({
		logout: false,
		logoutPath: 'login',
		loggedIn: false,
		loggedInStatus: undefined,
		accessCode: undefined,
		verificationType: 'inviteUserToService',
		quartermass: true,
		processType: '',
		verified:  false,
		verificationOutcome: undefined,
		verificationDetails: {
			emailAddress: undefined,
		},
		displayUserForm: false,
		verificationData: undefined,
		phoneServiceUserParticipation: undefined,
		phoneServiceUserParticipationInvite: undefined,
		inviteAccepted: false,
		inviteRejected: false,
		completed: false,
		rejectionMessage: '',
		isInTargetAlready: false,
});

if (route.params.accessCode) {
	state.accessCode = route.params.accessCode;
}


const quit = () => {
	router.push({name: 'login'});	
}

const closeLogout = () => {
	state.logout = false;
}


const loggedOut = () => {
	state.processType = '';
	verifyAccessCode();
}

const goToLogin = async (comeBack) => {
		
	let apiUrl = mainStore.getBaseUrl('plustag')+'/logout';	
	await fetch(apiUrl, {
		method: 'POST',
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1 || d.outcome == -3) {//user might already be logged out
					if (comeBack) {
						mainStore.lastName = 'inviteExistingUserToService';					
						mainStore.lastParams = {accessCode: state.accessCode};
					}
					router.push({name: 'login'});		
				} 
				
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);			
	});			
}


const verifyAccessCode = async (accessCode,verificationType) => {	
	const data = new URLSearchParams();
		
		await fetch(mainStore.getBaseUrl('plustag')+'/quartermass/accessCodeVerification/'+state.accessCode+"/"+state.verificationType, {
			method: 'POST',
			body: data,
			credentials: 'include',
			redirect: 'follow',																									
		}).then((response) => {
			response.json().then( 
				d => {
					console.log(d)
											
					if (d.outcome == 1) {						
						state.verificationDetails = d;
						state.verified = true;
						fetchUserInviteDetails();
					} else if (d.outcome == 908) {//Already Logged In
						state.processType = 'userLoggedIn';
						state.quartermass = false;
						state.verified = true;//accessCode verified but user is different
						fetchUserInviteDetails();
						getLoggedInDetails();
						mainStore.displaySnackBar(d.outcome);
					} else if (d.outcome == 909) {//Expired, so not verified
						mainStore.displaySnackBar(d.outcome+' expired ');
						state.verified = false;
						state.phoneServiceUserParticipationInvite = d.phoneServiceUserParticipationInvite;
					} else if (d.outcome == 919) {//Expired, so not verified						
						state.verified = false;
						state.phoneServiceUserParticipationInvite = d.phoneServiceUserParticipationInvite;
					} else {
						mainStore.displaySnackBar(d.outcome);
					}
					state.verificationOutcome = d.outcome;	
				}									
			)
			return response;
		}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
			
	});		
}

const isUserInDomainUIUD = (phoneServiceUUID) => {
		if (!state.loggedInStatus) {return false;}
		let inDomain = false;
		state.loggedInStatus.loggedInUserDomains.loggedInUserDomainDetails.forEach(
			(domain) => {								
				if (domain.phoneServiceUUID == phoneServiceUUID) {
					inDomain = true;								
				}
			}
		)
		return inDomain;
	}

const fetchUserInviteDetails = async () => {	
	const data = new URLSearchParams();
	data.set('accessCode',state.accessCode);
	let apiUrl = mainStore.getQuartermassAPIUrl('plustag');
	let command = 'fetchUserInviteDetails';
	if (state.processType == 'userLoggedIn') {
		apiUrl = mainStore.getAPIUrl('plustag');
		command = 'fetchLoggedInUserInviteDetails';
	}
	await fetch(apiUrl+'/phoneServices/'+command, {
		method: 'POST',
		body: data,
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {
					state.verificationData = d.verificationData;
					state.phoneServiceUserParticipation = d.phoneServiceUserParticipation;
					if (!state.phoneServiceUserParticipation) {
						state.verificationOutcome = 909;
						state.verified = false;
					} else {
						state.isInTargetAlready = isUserInDomainUIUD(d.phoneServiceUserParticipation.phoneServiceUUID);		
					}
					state.phoneServiceUserParticipationInvite = d.phoneServiceUserParticipationInvite;
								
				} 
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);			
	});		
}

const acceptInvitation = async () => {	
	const data = new URLSearchParams();
	data.set('accessCode',state.accessCode);
	data.set('choice','existingAccount');
	let apiUrl = mainStore.getAPIUrl('plustag');
	let command = 'acceptRejectLoggedInUserInvite';
	await fetch(apiUrl+'/phoneServices/'+command, {
		method: 'POST',
		body: data,
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {					
					state.inviteAccepted = true;
					state.completed = true;
				} 
				mainStore.displaySnackBar(d.message);
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);			
	});		
}

const rejectInvitation = async () => {	
	const data = new URLSearchParams();
	data.set('accessCode',state.accessCode);
	data.set('rejectionMessage',state.rejectionMessage);
	data.set('choice','rejectRequest');
	let apiUrl = mainStore.getAPIUrl('plustag');
	let command = 'acceptRejectLoggedInUserInvite';
	await fetch(apiUrl+'/phoneServices/'+command, {
		method: 'POST',
		body: data,
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {					
					state.inviteRejected= true;
					state.completed = true;
				} 
				mainStore.displaySnackBar(d.message);
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);			
	});		
}


const getLoggedInDetails = async () => {	
	let apiUrl = mainStore.getAPIUrl('plustag')+'/plustag/loggedInStatus';	
	await fetch(apiUrl, {
		method: 'POST',
		credentials: 'include',
		redirect: 'follow',																									
	}).then((response) => {
		response.json().then( 
			d => {
				console.log(d)									
				if (d.outcome == 1) {
					state.loggedInStatus = d;					
				} 				
			}									
		)
		return response;
	}).catch((error) => {
		console.log('Looks like there was a problem: \n', error);
			
	});		
}


mainStore.displaySnackBar('List Updated');
onMounted  (() => {
	verifyAccessCode();
});


</script>

<style scoped>
	.theme-mainPanel {
		background-color: #eeeeee;
		background: url('/src/assets/images/wavy-lines-offwhite.svg') no-repeat;		
		background-size: cover;
	}


	.card-floater {
		overflow: visible;
	}
</style>