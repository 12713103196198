<template>
	<v-container>
		<v-row>
			<v-col class="">
				<v-card class="mb-2">
					<BoxHeader headerText="Status"/>
					<v-card-text class="">
						<v-row>
							<v-col>
								<p class="theme-font text-h6">Current Live Tariff</p>
								<p class="text-caption ml-5">3rd July 2023 to 2nd August 2023</p>
								<div class="d-flex mt-2">
									<v-icon class="text-green">check</v-icon> <p class="theme-font-other ml-5 text-body-1"> July Full Tariff</p>
								</div>
							</v-col>
						</v-row>
						<v-row>
							<v-col>
								<p class="theme-font text-h6">Forthcoming Updates</p>
								<v-divider/>
								<p class="text-caption ml-5">24th July 2023</p>
								<div class="d-flex mt-2">
									<v-icon class="text-amber">close</v-icon> <div><p class="theme-font-other ml-5 text-body-1">Destination Update</p><p class=" ml-5 text-caption">Acknowledgement Required</p></div>
								</div>
								<v-divider/>
								<p class="text-caption ml-5">3rd August 2023</p>
								<div class="d-flex mt-2">
									<v-icon class="text-amber">close</v-icon>
									<div>
										<p class="theme-font-other ml-5 text-body-1">August Full Tariff</p>
										<p class=" ml-5 text-caption">Acknowledgement Required</p>
										<p class=" ml-5 text-caption"><span class="font-weight-bold">5</span> New Destinations</p>
										<p class=" ml-5 text-caption"><span class="font-weight-bold">13</span> Price Warnings</p>
									</div>
								</div>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>	
				<!--<v-card class="mb-2">
					<BoxHeader headerText="Tariff Details"/>
					<v-card-text class="">
						<v-row>
							<v-col>
								<p class="theme-font text-h6">Name</p>

								<p class="theme-font-other ml-5">{{ tariff.tariffName }}</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-0">
								<p class="theme-font text-h6">Type</p>
							
							<p class="theme-font-other ml-5" v-if="tariff.tariffType == 'custom'">{{ tariff.tariffType }}</p><p v-if="tariff.tariffType == 'managed'" class="theme-font-other ml-5">{{ tariff.parentTariffName }}</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-0">
								<p class="theme-font text-h6">Start Date</p>								
								<p v-if="tariff.startDate" class="theme-font-other ml-5">{{ tariff.startDate}}</p><p class="theme-font-other ml-5"  v-else>Not Started</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-0">
								<p class="theme-font text-h6">Minimum</p>								
								<p class="theme-font-other ml-5">{{ tariff.minimumCharge }}p</p>
							</v-col>
						</v-row>
						<v-row>
							<v-col class="pt-0">
								<p class="theme-font text-h6">Billing </p>								
								<p class="theme-font-other ml-5">{{ t('statics.tariff.pricePer.'+tariff.pricePer) }}</p>
							</v-col>
						</v-row>				
					</v-card-text>
				</v-card>	-->
			</v-col>
			<v-col class="">
				<v-card class="mb-2">
					<BoxHeader headerText="Timeline"/>
					<v-card-text class="">
						<DateTimeline :timelineData="timelineData"/>
					</v-card-text>
				</v-card>	
			</v-col>
			<v-col>
				
			</v-col>
		</v-row>
	</v-container>	
</template>

<script setup>
	import { defineProps,inject, ref, reactive, watch,onMounted,onUpdated } from 'vue';
	import { useRoute, useRouter } from "vue-router";
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import BoxHeader from '@/components/BoxHeader.vue';
	import DateTimeline from './components/DateTimeline.vue';

	
	const { t }  = useI18n();
	const mainStore = useMainStore();
	const router = useRouter();

	const emits = defineEmits(['dataEvents']);

	const state = reactive({


	});

	const props = defineProps({		
		tariff: {
			type: Object,
			required: true,
		}	
	});


	const timelineData = [
		{
			startDate: '3rd July 2023',
			label: 'July Full Tariff',
			//description: 'Complete Tariff Update',			
		},
		{
			startDate: '24th July 2023',
			label: 'Destination Update',
			description: 'USA Mobile',
		},
		{
			startDate: '3rd August 2023',
			label: 'August Full Tariff',
			//description: 'Complete Tariff Update',
		}

	];

	onUpdated(() => {	
		
	});


	onMounted(() => {
	
	});
</script>

<style>
</style>