const config = {
	store: undefined,
	userQuotas: ['user:core:phoneService:createService'],
	serviceQuotas: ['service:networkHub:telecoms:createHub',
									'service:networkHub:telecoms:createMemberIdentity',
									'service:servicePassport:consumer:access',
									'service:servicePassport:consumer:passportCount',
									'service:servicePassport:business:access',
									'service:lines:access',
									'service:lines:basicLine:lineCount',
									'service:content:access',
									'service:content:documentCount'
								]
};

const refresh = () => {
	
	const userConfiguration = {
		resourceNames : config.userQuotas,
		resourceAllocationType: 4,
	}
	fetchQuotas(userConfiguration);
	const serviceConfiguration = {
		resourceNames : config.serviceQuotas,
		resourceAllocationType: 2,
	}
	fetchQuotas(serviceConfiguration);
}

const updateQuotaData = (resourceAllocationType,quotaSummaries) => {
	if (resourceAllocationType == 4) {
			config.store.quotaData.userQuotas = quotaSummaries;
	} else if (resourceAllocationType == 2) {
		config.store.quotaData.serviceQuotas = quotaSummaries;
	}
	if (!config.store.initialiserCheckList.fetchQuotas) {//check if we have all loaded now
		if (config.store.quotaData.userQuotas.length > 0 && config.store.quotaData.serviceQuotas.length > 0){
			config.store.initialiserCheckList.fetchQuotas = true;
			config.store.isAppLoaded();
		}
	}
}

const fetchQuotas = async (configuration) => {
		console.log('Fetch Method');
		const data = new URLSearchParams();
		data.set('quotaRequestConfiguration', JSON.stringify(configuration));	
		const apiUrl = config.store.getAPIUrl();				
			await fetch(apiUrl+'/voxletServices/fetchQuotas', {
				method: 'post',			
				body: data,	
				credentials: 'include',
				redirect: 'follow',		
			}).then((response) => {
				response.json().then( 
					d => {
						console.log(d)							
						if (d.outcome == 1) {							
								updateQuotaData(configuration.resourceAllocationType,d.quotaDetails)
						}							
					}									
				)
				return response;
			}).catch((error) => {
			console.log('Looks like there was a problem: \n', error);
		});	
}

const _fetchQuota = (_resourceName) => {
	if (_resourceName.startsWith('user:')) {
		const userQuotas = config.store.quotaData.userQuotas;
		for (const index in userQuotas) {
			if (userQuotas[index].resourceName == _resourceName) {
				return userQuotas[index];								
			}
		}	
		return undefined;
	} else if (_resourceName.startsWith('service:')) {
		const serviceQuotas = config.store.quotaData.serviceQuotas;
		for (const index in serviceQuotas) {
			if (serviceQuotas[index].resourceName == _resourceName) {
				return serviceQuotas[index];								
			}
		}	
		return undefined;
	} else {
		console.log("resourceName "+_resourceName+" invalid");
		throw new Error("resourceName "+_resourceName+" invalid");
	}
}

const _hasAnyQuota = (resourceName) => {
	const quota = _fetchQuota(resourceName);
	if (quota) {
		return (quota.quota > 0);		
	}
	return false;	
}

const _hasSpareQuota = (resourceName) => {
	const quota = _fetchQuota(resourceName);
	if (quota) {
		return (quota.quota - quota.quotaUsed > 0);		
	}
	return false;		
}

const _hasActiveQuota = (resourceName) => {
	const quota = _fetchQuota(resourceName);
	if (quota) {
		return (quota.quotaUsed > 0);		
	}
	return false;	
}

export default {
	initialiseStore: (store) => {
		config.store = store;
	},
	refreshQuotas: () => {
		refresh();
		console.log('Refreshing Quotas');		
	},
	fetchQuota: (resourceName) => {
		return _fetchQuota(resourceName);
	},
	hasSpareQuota: (resourceName) => {
		return _hasSpareQuota(resourceName);
	},	
	hasAnyQuota: (resourceName) => {
		return _hasAnyQuota(resourceName);
	},
	hasActiveQuota: (resourceName) => {
		return _hasActiveQuota(resourceName);
	},
};