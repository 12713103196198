<script setup>
    import { ref } from "vue";

    const props = defineProps({
        name: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: "un-named"
        }
    });

    const name = ref(props.name);
    defineExpose({name});

</script>

<template>
    <div class="layoutBlock">
        {{ title }}
    </div>
</template>

<style scoped>


</style>