<template>

  	<v-container fluid class="px-6 py-0 h-100">			
			<v-row>
				<v-col align="right">		
					<v-btn class="ml-2 theme-list-action-button-background   text-action_button_text" @click="refreshList()" size="small"><v-icon class="theme-list-action-icon">refresh</v-icon></v-btn>
				</v-col>
			</v-row>
			<v-row>			
				<v-col md="0">	
					<v-card class="card-shadow border-radius-md d-flex flex-column">												
						<v-table  class="" density="compact">														
							<thead class="theme-list-header-background">
								<tr>
									<th class="text-center text-h6 theme-listing-header-text">Telephone Number</th>
									<th class="text-center text-h6 theme-listing-header-text">Created</th>	
									<th class="text-center text-h6 theme-listing-header-text">App</th>	
									<th class="text-center text-h6 theme-listing-header-text">Status</th>									
								</tr>							
							</thead>						
							<tbody>
								<tr v-for="number in state.numbers"  :key="number.primaryUUID" @click="selectNumber(number)">
									<td class="text-center ">{{ number.telephoneNumber }}</td>
									<td class="text-center">{{ number.createDate }}</td>	
									<td class="text-center ">Basic Line</td>	
									<td class="text-center ">{{ t('statics.lifecyclePhases.number.'+number.onboarding) }}</td>									
								</tr>
								<tr v-if="state.numbers.length == 0">
									<td colspan="4" class="theme-font text-center text-h6">No Records Found</td>
								</tr>																
								<!--<tr>
									<td colspan="3"><subscriberDetailsDialogue v-model="state.dialogDetails.showDialog" :dialogDetails="state.dialogDetails" @close="close()"/></td>
								</tr>	-->
							</tbody>

						</v-table>

							<!--<v-pagination
										v-model="state.pagination.page"
										:length="state.pagination.length"
										:total-visible="state.pagination.totalVisible"
										></v-pagination>-->
					</v-card>	
				</v-col>
			</v-row>
			<v-row class="pd-10">
				&nbsp;
			</v-row>			
		</v-container>
</template>

<script setup>
  import { defineProps,inject, ref, reactive, watch,onMounted } from 'vue';
	import {useMainStore} from '@/stores/MainStore.js';
	import { useI18n } from 'vue-i18n';
	import SubscriberDetailsDialogue from './SubscriberDetailsDialogue.vue';
	const { t }  = useI18n();

	const props = defineProps({
        phoneServiceName: {
            type: String
        }
    });

		const mainStore = useMainStore();

const state = reactive({
				dialogDetails: {
					showDialog: false,
					allocatedNumber: {}
				},				
        numbers: [],
				pagination: {
					page: 2,
					length: 4,
					totalVisible: 7,
				},				
				filterValues: {
					filterByPhase: 'all',
					recordCount: 25,
				},
				filters: {
					lifecyclePhases: [
					{ phase: 'all', phaseDescription: 'Show All Phases' },
          { phase: 'preCommitment', phaseDescription: 'Pre Commitment' },
          { phase: 'ready', phaseDescription: 'Ready' },
          { phase: 'ended', phaseDescription: 'Ended' },
        ],
				recordCount: [
					{value: 10, title:'10 Numbers'},
					{value: 25, title:'25 Numbers'},
					{value: 50, title:'50 Numbers'},
					{value: 100, title:'100 Numbers'},					
					],
			}
    });

	const selectNumber = (allocatedNumber) => {
		console.log(allocatedNumber.telephoneNumber);
		state.dialogDetails.allocatedNumber = allocatedNumber;
		state.dialogDetails.showDialog = true;
	}

	const close = () => {
		state.dialogDetails.showDialog = false;
	}

	

	const refreshList = () => {
		mainStore.displaySnackBar("Refreshing");
		getAllocatedNumbers();
	}

	const changeRecordCount = (value) => {
		console.log("Value is "+value);
	}

	const doSearch = (value) => {
		console.log("Value is "+value);		
	}

	const getAllocatedNumbers = async () => {
				const apiUrl = mainStore.getAPIUrl();				
				await fetch(apiUrl+'/numbers/listAllocatedNumbers', {
					method: 'get',				
					credentials: 'include',
    			redirect: 'follow',		
				}).then((response) => {
					response.json().then( 
						d => {
							console.log(d)							
							if (d.outcome == -3) {
							} else {
									state.numbers = d.allocatedNumbers;
							}							
						}									
					)
					return response;
				}).catch((error) => {
				console.log('Looks like there was a problem: \n', error);

			});		
		}

	onMounted(() => {
			getAllocatedNumbers();
	});




</script>

<style scoped>

	.listRow {
		height: 100%;	
	}

	.listDisplay {	
		height: calc( 100% - 100px);
	}

	.listTable {	
		height: calc( 100% - 100px);
	}
</style>
